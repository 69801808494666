<template>
  <div class="timeline_bar">
    <div class="time hidden-pad-up" @click="dateModal = true">
      {{ format_time }}
    </div>
    <div class="control_group">
      <div class="arrow decrease" @click="changeTime('des')"></div>
      <div class="main hidden-pad-down">
        <div class="time" @click="dateModal = true">
          <img src="@/assets/icon-edit.svg" class="hidden-pad-up" />
          {{ format_time }}
        </div>
        <!-- <VueCtkDateTimePicker v-model="source_time" /> -->
        <v-dialog ref="dialog" v-model="dateModal" width="290px" persistent>
          <!-- step1.選擇日期 -->
          <v-date-picker
            class="picker date-picker"
            v-show="dateModal && selectDateTimeStep == 1"
            v-model="temp_date"
            :first-day-of-week="0"
            locale="zh-cn"
            scrollable
            header-color="rgba(217, 217, 217, 0.25)"
            color="primary"
            max="9999-12-31"
            min="0676-01-01"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="dateModal = false">取消</v-btn>
            <v-btn
              text
              color="error"
              @click="selectDateTimeStep = 2"
              :disabled="new Date(temp_date) == 'Invalid Date'"
              >時間</v-btn
            >
          </v-date-picker>
          <!-- step2.選擇時間 -->
          <v-time-picker
            class="picker time-picker"
            v-show="dateModal && selectDateTimeStep == 2"
            v-model="temp_time"
            scrollable
            ampm-in-title
            header-color="rgba(217, 217, 217, 0.25)"
            color="primary"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="selectDateTimeStep = 1"
              >日期</v-btn
            >
            <v-btn text color="error" @click="saveDateTime()">確認</v-btn>
          </v-time-picker>
        </v-dialog>
      </div>
      <div class="selectBar">
        <div
          class="select"
          @click.stop="activeSelect = !activeSelect"
          :class="{ activeSelect: activeSelect }"
        >
          <div class="select__selected">
            {{ selecterShow }}
            <img src="@/assets/icon_downward.svg" alt />
          </div>
          <v-slide-y-reverse-transition>
            <div class="select__box" v-show="activeSelect">
              <div
                class="select__item"
                :class="{ active: selecterShow === timeMode.text }"
                v-for="timeMode in selecter"
                :key="timeMode.value"
                @click="switchMode(timeMode)"
              >
                {{ timeMode.text }}
              </div>
            </div>
          </v-slide-y-reverse-transition>
        </div>
      </div>
      <div class="arrow increase" @click="changeTime('add')"></div>
    </div>
  </div>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";
export default {
  components: { VueCtkDateTimePicker },
  props: {
    defaultTime: {
      type: String,
      default: moment().format("YYYY/MM/DD HH:mm"),
    },
    format_time: {
      type: String,
      default: moment().format("YYYY/MM/DD HH:mm"),
    },
    nowTimeNowPlaceIng: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //TODO:時間軸的時間，原生時間物件，方便做加減用而已，只在組件內存在
      source_time: new Date(this.defaultTime),
      temp_date: new Date().toISOString().substr(0, 7), //暫時，popup確認時才更新source_time
      temp_time: "00:00", //暫時，popup確認時才更新source_time
      selectDateTimeStep: 1, //兩個步驟(一為選日期，二為選時間)
      timeChange_interval: "1-M",
      selecterShow: "1個月",
      selecter: [
        {
          value: "1-h",
          text: "1小時",
        },
        {
          value: "12-h",
          text: "12小時",
        },
        {
          value: "1-d",
          text: "1天",
        },
        {
          value: "1-M",
          text: "1個月",
        },
        {
          value: "6-M",
          text: "6個月",
        },
        {
          value: "1-y",
          text: "1年",
        },
        {
          value: "now",
          text: "回到現在",
        },
      ],
      dateModal: false,
      activeSelect: false,
      timer: null, //節流防抖
    };
  },
  computed: {
    // TODO:我要的格式"1990/01/31 09:30"
    format_time_scope: {
      get() {
        return this.props.format_time;
      },
      set(value) {
        this.$emit("update:format_time", value);
      },
    },
  },
  watch: {
    //TODO:監聽，該computed變化時，通知父組件
    source_time(val) {
      // this.$emit("timeline_update", this.format_time);
      this.format_time_scope = moment(val).format("YYYY/MM/DD HH:mm");
    },
    format_time(val) {
      this.source_time = new Date(val);
    },
  },
  methods: {
    switchMode(timeMode) {
      if (timeMode.value === "now") {
        this.source_time = new Date();
      }
      this.timeChange_interval = timeMode.value;
      this.selecterShow = timeMode.text;
    },
    saveDateTime() {
      this.source_time = new Date(
        moment(`${this.temp_date} ${this.temp_time}`)
      );
      this.dateModal = false;
      this.selectDateTimeStep = 1;
    },
    changeTime(type) {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.timer = null;
        }, 200);
        //判斷now
        if (this.timeChange_interval == "now") {
          this.$messagePopup(
            "請設定時間區間",
            "由於您已選擇「回到現在」的選項，若欲進行下一步操作請設定時間區間。",
            "我瞭解了"
          );
          return;
        } else {
          //非now
          this.$emit("update:nowTimeNowPlaceIng", false);
          let number = this.timeChange_interval.split("-")[0];
          let interval = this.timeChange_interval.split("-")[1];
          this.source_time = new Date(
            moment(this.source_time).add(
              type == "add" ? number : -number,
              interval
            )
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.timeline_bar {
  display: flex;
  width: 100%;
  max-width: 451px;
  & > .control_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  padding: 24px;
  font-size: 16px;
  margin-bottom: 30px;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  @include pad {
    flex-direction: column;
    margin: 0 auto 30px;
    width: 520px;
    padding: 10px 28px;
    > .time {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
  @include mobile {
    width: 320px;
  }
  .main {
    @include center;
    flex-direction: column;
  }
  .time {
    width: 190px;
    height: 32px;
    background: #f0f2f4;
    box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    color: $theme-color-2;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: 0.06em;
    padding: 0 16px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 30px;
    font-weight: 500;
    background: #f0f2f4;
    @include center;
    cursor: pointer;
    box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
      4px 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    &.decrease:after,
    &.increase:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 3px;
      width: 22px;
      border-radius: 3px;
      background-color: $theme-color-2;
    }
    &.increase:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 22px;
      width: 3px;
      border-radius: 3px;
      background-color: $theme-color-2;
    }
  }
  .selectBar {
    display: flex;
    align-items: center;
  }
}
//下拉選單
.select {
  @include rect(92px, 32px, $theme-color-3, 14px);
  cursor: pointer;
  position: relative;
  z-index: 15;

  .select__selected {
    width: 100%;
    height: 32px;
    @include center;
    font-weight: bold;
    color: $secondary-color-1;
    font-size: 14px;
    white-space: nowrap;
    img {
      width: 24px;
      height: 24px;
      transition: 0.3s;
    }
  }

  .select__box {
    @include rect(92px, auto, $theme-color-3, 14px);
    position: absolute;
    top: 0;
    overflow: hidden;
    transition: 0.3s;

    .select__item {
      width: 100%;
      height: 32px;
      @include center;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 1.28px;
      color: $secondary-color-3;
      background-color: $theme-color-3;

      &:hover {
        background-color: $special_bg_color2;
      }

      &.active {
        color: $theme-color-1;
        font-weight: bold;
      }
    }
  }

  &.activeSelect {
    img {
      transform: rotate(180deg);
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "row-wrap custom-container" },
        [
          _vm.nowUserData && _vm.TcAstralInfo
            ? _c(
                "div",
                { staticClass: "params_box" },
                [
                  _c("TimeLine", {
                    attrs: {
                      format_time: _vm.format_time,
                      defaultTime: _vm.format_time,
                      nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng
                    },
                    on: {
                      "update:format_time": function($event) {
                        _vm.format_time = $event
                      },
                      "update:nowTimeNowPlaceIng": function($event) {
                        _vm.nowTimeNowPlaceIng = $event
                      },
                      "update:now-time-now-place-ing": function($event) {
                        _vm.nowTimeNowPlaceIng = $event
                      }
                    }
                  }),
                  _c("Params", {
                    attrs: {
                      TcAstralInfo: _vm.TcAstralInfo,
                      AstrolabeType: "C2",
                      role: _vm.role,
                      moondayObj: _vm.moondayObj
                    },
                    on: {
                      "update:moondayObj": function($event) {
                        _vm.moondayObj = $event
                      },
                      "update:moonday-obj": function($event) {
                        _vm.moondayObj = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.TcAstralInfo
            ? _c("MoonDayCTA", {
                staticClass: "hidden-pad-up mt_10 mb_10",
                attrs: {
                  data2: _vm.TcAstralInfo.AstralRadians,
                  nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng,
                  format_time: _vm.format_time,
                  mode: "astrometryMode",
                  moondayObj: _vm.moondayObj
                },
                on: { toNowTimeNowPlace: _vm.toNowTimeNowPlace }
              })
            : _vm._e(),
          _vm.TcAstralInfo
            ? _c("NewAstrolabe", {
                attrs: {
                  data1: _vm.TcAstralInfo.AstralRadians,
                  data2: null,
                  type: 0,
                  styleArray: _vm.styleArray,
                  role: _vm.role,
                  Ret1: _vm.TcAstralInfo.RetAstralSet,
                  showNowTime: true,
                  format_time: _vm.format_time,
                  mode: "astrometryMode",
                  nowTimeNowPlaceBtn: true,
                  nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng,
                  nowPlace: _vm.Longitude + ", " + _vm.Latitude,
                  moondayObj: _vm.moondayObj
                },
                on: { toNowTimeNowPlace: _vm.toNowTimeNowPlace }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
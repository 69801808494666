import { render, staticRenderFns } from "./LoginError.vue?vue&type=template&id=16781ecf&scoped=true&"
import script from "./LoginError.vue?vue&type=script&lang=js&"
export * from "./LoginError.vue?vue&type=script&lang=js&"
import style0 from "./LoginError.vue?vue&type=style&index=0&id=16781ecf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16781ecf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\40420\\OneDrive\\桌面\\星學會\\DaMou-Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16781ecf')) {
      api.createRecord('16781ecf', component.options)
    } else {
      api.reload('16781ecf', component.options)
    }
    module.hot.accept("./LoginError.vue?vue&type=template&id=16781ecf&scoped=true&", function () {
      api.rerender('16781ecf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LoginError.vue"
export default component.exports
<template>
  <div>
    <canvas ref="astrolabe" class="canvasShadow"></canvas>
  </div>
</template>

<!--该组件共四个参数-->
<!-- width:canvas图片尺寸 -->
<!-- panStyle:星盘样式 专业 A32 文字 清新 温暖 黑夜-->
<!-- type:星盘类型 0單盤 1雙盤 2配对 -->
<!-- data1:雙盤内圈/單盤 数据源 -->
<!-- data2:雙盤外圈数据源 -->
<script>
// import {
//   dealSingleData,
//   dealDoubleData,
//   dealSinglePlanet,
//   dealDoublePlanet,
// } from "../js/dealNatalData2.js";
import dealMethodsCreater from "../js/dealNatalData3.js";
import {
  drawCircleAngle,
  drawCircleOrigin,
  drawIconAngle,
  drawRingOrigin,
  drawTextAngle,
  drawLineConn,
  drawLineAngle,
  drawImgAngle,
  drawCircleOriginShadow,
} from "../js/utilCanvas.js";
import WebFont from "webfontloader";
export default {
  name: "canvas-astrolabe",
  data() {
    return {
      dealSingleData: null,
      dealDoubleData: null,
      dealSinglePlanet: null,
      dealDoublePlanet: null,
      planetOrderEnglish: [
        "Sun",
        "Moon",
        "Mercury",
        "Venus",
        "Mars",
        "Jupiter",
        "Saturn",
        "Uranus",
        "Neptune",
        "Pluto",
        "Ascendant",
        "Midheaven",
        "Chiron",
        "Ceres",
        "Pallas",
        "Juno",
        "Vesta",
        "NoNode",
        "SoNode",
        "Lilith",
        "Fortune",
        "Fortune",
        "EaPoint",
        "Descendant",
        "IC", // 假設這個對應
      ],
    };
  },
  props: [
    "width",
    "configItem",
    "panStyle",
    "type",
    "data1",
    "data2",
    "Ret1",
    "role",
  ],
  computed: {},
  created: function () {
    this.canvasInit();
    // console.log("before");
    let { dealSingleData, dealDoubleData, dealSinglePlanet, dealDoublePlanet } =
      dealMethodsCreater(this.role);
    // console.log("dealMethodsCreater(this.role)", dealMethodsCreater(this.role));
    this.dealSingleData = dealSingleData;
    this.dealDoubleData = dealDoubleData;
    this.dealSinglePlanet = dealSinglePlanet;
    this.dealDoublePlanet = dealDoublePlanet;
  },
  mounted: function () {
    this.drawXingPan();
    WebFont.load({
      custom: {
        families: ["iconfont"],
        // urls : ['app.vue']  //字体声明处，页面不需要引入该样式
      },
      loading: () => {},
      active: () => {
        //字体渲染完成后 绘制星盘
        this.drawXingPan();
      },
      fontinactive: () => {
        this.drawXingPan();
      },
      timeout: 3000, //三秒后加载失败 调用fontinactive event
    });
  },
  watch: {
    //监听数据，若参数改变则重绘
    width: function () {
      //画布宽度
      this.drawXingPan();
    },
    configItem: function () {
      this.drawXingPan();
    },
    panStyle: function () {
      this.drawXingPan();
    },
    type: function () {
      //绘盘类型——0單盤1雙盤2配对盘
      this.drawXingPan();
    },
    data1() {
      this.drawXingPan();
    },
    data2() {
      this.drawXingPan();
    },
  },
  methods: {
    //初始化绘盘库函数drawAstrolabe
    canvasInit() {
      //若库函数已存在则直接返回——注：库函数有修改时要注释掉
      // if('undefined' != typeof drawAstrolabe){return;}
      (function (window, role) {
        let {
          dealSingleData,
          dealDoubleData,
          dealSinglePlanet,
          dealDoublePlanet,
        } = dealMethodsCreater(role);

        var iconPlanet = [
          //行星图标-文字
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          /* 上升 */ "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          /* 福點 */ "",
          "",
          "",
          "",
          "",
        ];
        var textPlanet = [
          //行星名称-文字
          "日",
          "月",
          "水",
          "金",
          "火",
          "木",
          "土",
          "天",
          "海",
          "冥",
          "升",
          "頂",
          "凱",
          "谷",
          "智",
          "婚",
          "灶",
          "北",
          "南",
          "莉",
          "福",
          "宿",
          "東",
          "降",
          "底",
        ];
        var imgPlanet = [
          require("@/assets/astral-txt/astral-txt_1.svg"),
          require("@/assets/astral-txt/astral-txt_2.svg"),
          require("@/assets/astral-txt/astral-txt_3.svg"),
          require("@/assets/astral-txt/astral-txt_4.svg"),
          require("@/assets/astral-txt/astral-txt_5.svg"),
          require("@/assets/astral-txt/astral-txt_6.svg"),
          require("@/assets/astral-txt/astral-txt_7.svg"),
          require("@/assets/astral-txt/astral-txt_8.svg"),
          require("@/assets/astral-txt/astral-txt_9.svg"),
          require("@/assets/astral-txt/astral-txt_10.svg"),
          require("@/assets/astral-txt/astral-txt_11.svg"),
          require("@/assets/astral-txt/astral-txt_12.svg"),
          require("@/assets/astral-txt/astral-txt_13.svg"),
          require("@/assets/astral-txt/astral-txt_14.svg"),
          require("@/assets/astral-txt/astral-txt_15.svg"),
          require("@/assets/astral-txt/astral-txt_16.svg"),
          require("@/assets/astral-txt/astral-txt_17.svg"),
          require("@/assets/astral-txt/astral-txt_18.svg"),
          require("@/assets/astral-txt/astral-txt_19.svg"),
          require("@/assets/astral-txt/astral-txt_20.svg"),
          require("@/assets/astral-txt/astral-txt_21.svg"),
          require("@/assets/astral-txt/astral-txt_22.svg"),
          require("@/assets/astral-txt/astral-txt_23.svg"),
          require("@/assets/astral-txt/astral-txt_24.svg"),
          require("@/assets/astral-txt/astral-txt_25.svg"),
        ];
        var iconStar = [
          //星座图标-文字, 天秤 牡羊
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        var textStar = [
          //星座名称-文字
          "牡羊",
          "金牛",
          "雙子",
          "巨蟹",
          "獅子",
          "處女",
          "天秤",
          "天蠍",
          "射手",
          "魔羯",
          "水瓶",
          "雙魚",
        ];

        var rOutOuter;
        var sizeDashed; //虚线尺寸
        var colorStar = []; //星座标识相关的配色方案
        //var colorPalace = []; //宫位标识相关的配色方案
        var colorPalace = "#ffffff";
        var colorPlanet = []; //行星标识相关的配色方案
        function setFlagColor(baseColor) {
          //设置星体、宫位、星座颜色(基准是基色baseColor)
          for (var i = 0; i < 12; i++) {
            colorStar[i] = baseColor[i % 4];
            //colorPalace[i] = baseColor[i % 4];
          }
          var colorMoon = "#7163FF"; //月亮配色——紫色
          var colorLittle = "#EE6DAF"; //小行星配色——粉色
          var colorNorth = "#57E4DB"; //北交点配色——淡蓝色
          var colorSouth = "#57E4DB"; //南交點配色——淡蓝色
          let baseC = "#088b82";
          let exC = "#e99a00"; //顏色改這邊
          colorPlanet = [
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            exC,
            exC,
            exC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
            baseC,
          ];
        }
        /**白底版配色(專業版)
         **/
        function getWhiteColor() {
          //setFlagColor(["red", "#6A5A0C", "#046A4A", "#003366"]);
          setFlagColor(["#FF445B", "#FFA844", "#28CAB3", "#66E0F4"]); //火土風水

          return {
            // colorOutCircle: "#656565",//外环颜色
            colorOutCircle: "#ffffff", //"#B9B9B9",//外环颜色
            colorInCircle: "#ffffff", //内环颜色
            colorMidCircle: "#ffffff", //中圈颜色
            colorPalaceLine: "#ffffff", //"#99cccc",//宫位线颜色
            colorXudian: "#57447e", //"#ff9999",//虚点宫位线颜色
            colorDu: "#ffffff", //"#000000",//专业版度颜色
            colorFen: "#ee6daf", //"#757575",//专业版分颜色
          };
        }
        /**黑底版配色
         **/
        function getBlackColor() {
          //setFlagColor(["red", '#DF9210', '#39E03E', '#5695C5']);
          setFlagColor(["#FF445B", "#FFA844", "#28CAB3", "#66E0F4"]); //火土風水
          return {
            colorOutCircle: "#ff0000",
            colorPalaceLine: "#404D4C",
            colorXudian: "#DADADA",
            colorOutCircle: "rgba(41,41,41,0.6)", //星盤外圈顏色
            colorPalaceLine: "rgba(41,41,41,0.6)", //星盤刻度顏色
            colorXudian: "rgba(41,41,41,0.6)", //一般版的十字與專業版的宮位劃分
            colorNumber: "rgba(41,41,41,0.6)", //1~12宮位數字
            outStartIcon: "#1d1d1d", //外圈星座符號顏色'
            // icon
          };
        }
        //顏色加深
        function getBlackColor_pro() {
          setFlagColor(["#FF445B", "#FFA844", "#28CAB3", "#66E0F4"]); //火土風水
          return {
            colorOutCircle: "#ff0000",
            colorPalaceLine: "#000",
            colorXudian: "#DADADA",
            colorOutCircle: "#000", //星盤外圈顏色
            colorXudian: "#000", //一般版的十字與專業版的宮位劃分
            colorNumber: "#000", //1~12宮位數字
            outStartIcon: "#0709B5", //外圈星座符號顏色'
            // icon
          };
        }
        /**清新版配色
         **/
        function getFreshColor() {
          setFlagColor(["red", "#DF9210", "#39E03E", "#5695C5"]);
          return {
            colorCircle: "#46D3E2",
            colorBackground: "#D5FBFF",
            colorXudian: "#46D3E2",
            colorPalaceLine: "#A7E6ED",
          };
        }
        /**温暖版配色
         **/
        function getWramColor() {
          setFlagColor(["red", "#DF9210", "#39E03E", "#5695C5"]);
          return {
            colorCircle: "#FFADB7",
            colorBackground: "#FFDDE1",
            colorXudian: "#FFADB7",
            colorPalaceLine: "#FED1D6",
          };
        }
        /**黑夜版配色
         **/
        function getDarkColor() {
          setFlagColor(["red", "#DF9210", "#39E03E", "#5695C5"]);
          return {
            colorRingOut: "#464646",
            colorRingMid: "#4A5A73",
            colorRingIn: "#3C3D44",
            colorCircle: "white",
            colorBackground: "#FFDDE1",
            colorXudian: "#C3C067",
            colorPalaceLine: "#605F4D",
          };
        }
        var ctx = null;
        //特殊部分要加粗，十字線加粗，套用ctx2
        var ctx2 = null;

        var configItem = [];

        var drawAstrolabe = function (_ctx, _width, _configItem, pType, style) {
          //设置画布
          var width = _width;
          ctx = _ctx;
          ctx.lineWidth = 2;
          // ctx2.lineWidth = 10;
          configItem = _configItem;
          ctx.translate(width, width);
          rOutOuter = width - 1;
          sizeDashed = rOutOuter * 0.02;
          //返回画图函数
          var handler = {
            單盤: {
              专业: drawProSingle,
              文字: drawTextSingle,
              A32: drawA32Single,
              清新: drawFreshSingle,
              温暖: drawWarmSingle,
              黑夜: drawDarkSingle,
              JOE: drawJoeSingle,
              JOE2: drawJoe2Single,
              JOE3: drawJoe2Single_pro,
            },
            雙盤: {
              专业: drawProDouble,
              JOE: drawJoeDouble,
              文字: drawTextDouble,
              A32: drawA32Double,
              清新: drawFreshDouble,
              温暖: drawWarmDouble,
              黑夜: drawDarkDouble,
            },
            法达: {
              专业: drawProFirdaria,
            },
          };
          var draw = handler[pType][style];
          return draw;
        };
        //$單盤專業版

        function drawProSingle(objSingle) {
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.015,
            configItem
          );
          //2、专业版配色(hard code)
          // var proColor = getWhiteColor();
          var proColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rAngle = rOutOuter * 0.858;
          var rOutInner = rOutOuter * 0.909;
          var rInOuter = rOutOuter * 0.489;
          var rInInner = rOutOuter * 0.489;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPlanetFlag = rInOuter + (rOutInner - rInOuter) * 0.75;
          var rPhasePoint = rInInner * 1.45;
          var rMidDu = rInOuter + (rOutInner - rInOuter) * 0.565;
          var rMidStar = rInOuter + (rOutInner - rInOuter) * 0.379;
          var rMidFen = rInOuter + (rOutInner - rInOuter) * 0.207;
          var sizeStarFlag = rOutOuter * 0.12; //宮位星座
          var sizePalaceNum = rOutOuter * 0.045; //宮位
          var sizePlanetFlag = rOutOuter * 0.077;
          var sizeDu = rOutOuter * 0.04; //度字号
          var sizeFen = rOutOuter * 0.035; //分字号
          var sizeMidStar = rOutOuter * 0.07; //中圈星座字号
          //4、开始画图
          drawProSinglePan();
          function drawProSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#030420
            //1、画底图：四个圈
            drawCircleOrigin(ctx, rOutOuter, "#f0f2f4");
            drawCircleOrigin(ctx, rOutInner, proColor.colorOutCircle);
            // drawCircleOrigin(ctx, rInOuter, 'gray');
            drawCircleOrigin(ctx, rAngle, proColor.colorOutCircle);
            // 刻度
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? proColor.colorPalaceLine
                  : proColor.colorPalaceLine;
              i % 5 == 0
                ? drawLineAngle(ctx, -rAngle, -rOutInner, i / 360, color)
                : drawLineAngle(
                    ctx,
                    -rAngle * 1.02,
                    -rOutInner,
                    i / 360,
                    color
                  );
            }
            //2、画宫位相关：宫位线、宫位编号、对应星座、度、分
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              var starNum = palace[i][2];
              var du = palace[i][3];
              var fen = palace[i][4];
              var colorLine =
                i % 3 == 0 ? proColor.colorXudian : proColor.colorPalaceLine;
              //十字線加粗
              if (i % 3 == 0) {
                ctx.lineWidth = 3;
              }
              drawLineAngle(
                ctx,
                -rInInner * 0.001,
                -rOutInner,
                angle,
                proColor.colorXudian
              );
              ctx.lineWidth = 2;
              var angleNum = (angle + angleNext) / 2;
              // drawTextAngle(ctx, rInMid, angleNum, i + 1, colorPalace, sizePalaceNum);
              drawIconAngle(
                ctx,
                rOutMid,
                angle,
                iconStar[starNum],
                proColor.outStartIcon,
                sizeStarFlag
              );
              drawTextAngle(
                ctx,
                rOutMid,
                i < 6 ? angle - 1 / 70 : angle + 1 / 70,
                du,
                proColor.outStartIcon,
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                rOutMid,
                i < 6 ? angle + 1 / 70 : angle - 1 / 70,
                fen,
                proColor.outStartIcon,
                sizeFen
              );
              var starNext = palace[(i + 1) % 12][2];
              var starGap = (starNext - starNum + 12) % 12;
              if (starGap > 1) {
                var angleRefer = angle + 1 / 70;
                var angleGap = (angleNext - angle - 1 / 35) / starGap;
                var starJieDuo;
                for (var j = 1; j < starGap; j++) {
                  starJieDuo = (starNum + j) % 12;

                  drawIconAngle(
                    ctx,
                    rOutMid,
                    angleRefer + j * angleGap,
                    iconStar[starJieDuo],
                    colorStar[starJieDuo],
                    sizeStarFlag
                  );
                }
              }
            }
            //3、画行星相关(用调整后的角度):行星、对应星座、度、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var starNum = planetSingle[i][2];
              var du = planetSingle[i][3];
              var fen = planetSingle[i][4];

              drawIconAngle(
                ctx,
                rPlanetFlag,
                planetSingle[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rMidStar,
                angleAdjust,
                iconStar[starNum],
                proColor.outStartIcon,
                sizeMidStar
              ); //color: colorPlanet[i]
              drawTextAngle(
                ctx,
                rMidDu,
                angleAdjust,
                du,
                proColor.outStartIcon,
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                rMidFen,
                angleAdjust,
                fen,
                proColor.outStartIcon,
                sizeFen
              );
            }
            //4、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  // "#f00",
                  item[5],
                  sizeDashed
                );
              }
              // drawLineConn(ctx, rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed);
            });
          }
        }
        //$單盤開發版
        function drawJoeSingle(objSingle) {
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.015,
            configItem
          );
          //2、专业版配色(hard code)
          // var proColor = getWhiteColor();
          var proColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rAngle = rOutOuter * 0.909;
          var rOutInner = rOutOuter * 0.909;
          var rInOuter = rOutOuter * 0.65;
          var rInInner = rOutOuter * 0.3;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rOutOuter) / 2;
          var rPlanetFlag = rInOuter + (rOutInner - rInOuter) * 0.8;
          var rPhasePoint = rInInner * 2.145;
          var rMidDu = rInOuter + (rOutInner - rInOuter) * 0.575;
          var rMidStar = rInOuter + (rOutInner - rInOuter) * 0.349;
          var rMidFen = rInOuter + (rOutInner - rInOuter) * 0.147;
          var sizeStarFlag = rOutOuter * 0.12; //宮位星座
          var sizePalaceNum = rOutOuter * 0.045; //宮位
          var sizePlanetFlag = rOutOuter * 0.077;
          var sizeDu = rOutOuter * 0.04; //度字号
          var sizeFen = rOutOuter * 0.035; //分字号
          var sizeMidStar = rOutOuter * 0.07; //中圈星座字号
          //4、开始画图
          drawProSinglePan();
          function drawProSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#030420
            drawRingOrigin(ctx, rOutInner, "#ddd"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rInOuter, "#f0f2f4"); //星盘底色#f0f2f4
            //1、画底图：四个圈
            drawCircleOrigin(ctx, rOutOuter, "#f0f2f4");
            drawCircleOrigin(ctx, rOutInner, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rInOuter, proColor.colorOutCircle);
            //2、画宫位相关：宫位线、宫位编号、对应星座、度、分
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              var starNum = palace[i][2];
              var du = palace[i][3];
              var fen = palace[i][4];
              var colorLine =
                i % 3 == 0 ? proColor.colorXudian : proColor.colorPalaceLine;
              //十字線加粗
              if (i % 3 == 0) {
                ctx.lineWidth = 3;
              }
              drawLineAngle(
                ctx,
                -rInInner * 2.17,
                -rOutInner,
                angle,
                i % 3 == 0 ? "#666" : "rgba(41,41,41,0.2)"
              );
              ctx.lineWidth = 2;
              var angleNum = (angle + angleNext) / 2;
              // drawTextAngle(ctx, rInMid, angleNum, i + 1, colorPalace, sizePalaceNum);
              drawIconAngle(
                ctx,
                rOutMid,
                angle,
                iconStar[starNum],
                "#000",
                sizeStarFlag
              );
              drawTextAngle(
                ctx,
                rOutMid,
                i < 6 ? angle - 1 / 70 : angle + 1 / 70,
                du,
                "#000",
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                rOutMid,
                i < 6 ? angle + 1 / 70 : angle - 1 / 70,
                fen,
                "#000",
                sizeFen
              );
              var starNext = palace[(i + 1) % 12][2];
              var starGap = (starNext - starNum + 12) % 12;
              if (starGap > 1) {
                var angleRefer = angle + 1 / 70;
                var angleGap = (angleNext - angle - 1 / 35) / starGap;
                var starJieDuo;
                for (var j = 1; j < starGap; j++) {
                  starJieDuo = (starNum + j) % 12;

                  drawIconAngle(
                    ctx,
                    rOutMid,
                    angleRefer + j * angleGap,
                    iconStar[starJieDuo],
                    colorStar[starJieDuo],
                    sizeStarFlag
                  );
                }
              }
            }
            //3、画行星相关(用调整后的角度):行星、对应星座、度、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var starNum = planetSingle[i][2];
              var du = planetSingle[i][3];
              var fen = planetSingle[i][4];

              drawIconAngle(
                ctx,
                rPlanetFlag,
                planetSingle[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rMidStar,
                angleAdjust,
                iconStar[starNum],
                "#000",
                sizeMidStar
              ); //color: colorPlanet[i]
              drawTextAngle(ctx, rMidDu, angleAdjust, du, "#000", sizeDu, true);
              drawTextAngle(ctx, rMidFen, angleAdjust, fen, "#000", sizeFen);
            }
            //4、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  item[5],
                  sizeDashed
                );
              }
              // drawLineConn(ctx, rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed);
            });
          }
        }

        //$國師指定板
        function drawJoe2Single(objSingle, Ret1Array) {
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.015,
            configItem
          );
          //2、专业版配色(hard code)
          // var proColor = getWhiteColor();
          var proColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          let theRealOuter = rOutOuter; //暫存邊界
          rOutOuter = rOutOuter * 0.94; //圓圈範圍縮小
          let theRealOuterMid = (theRealOuter + rOutOuter) / 2;

          var rAngle = rOutOuter * 0.909;
          var rOutInner = rOutOuter * 0.88;
          var rInOuter = rOutOuter * 0.4;
          var rInInner = rOutOuter * 0.22;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rOutOuter) / 2;
          var rPlanetFlag = rInOuter + (rOutInner - rInOuter) * 0.64;
          var rPhasePoint = rInInner * 2.7;
          var rMidDu = rInOuter + (rOutInner - rInOuter) * 0.49;
          var rMidStar = rInOuter + (rOutInner - rInOuter) * 0.33;
          var rMidFen = rInOuter + (rOutInner - rInOuter) * 0.21;
          var sizeStarFlag = rOutOuter * 0.12; //宮位星座
          var sizePalaceNum = rOutOuter * 0.045; //宮位
          var sizePlanetFlag = rOutOuter * 0.077;
          var sizeDu = rOutOuter * 0.04; //度字号
          var sizeFen = rOutOuter * 0.035; //分字号
          var sizeMidStar = rOutOuter * 0.07; //中圈星座字号
          //4、开始画图
          drawProSinglePan();
          function drawProSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#030420
            drawRingOrigin(ctx, rOutInner, "#f0f2f4"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rInOuter, "#f0f2f4"); //星盘底色#f0f2f4
            //1、画底图：四个圈
            // drawCircleOrigin(ctx, theRealOuter, "#f0f2f4"); //星盘底色#030420
            drawCircleOrigin(ctx, rOutOuter, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rPlanetFlag * 1.19, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rOutInner, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rInOuter, proColor.colorOutCircle);
            // 刻度
            for (var i = 0; i < 180; i++) {
              var color =
                i % 5 == 0
                  ? proColor.colorPalaceLine
                  : proColor.colorPalaceLine;
              i % 5 == 0
                ? drawLineAngle(
                    ctx,
                    -rPlanetFlag * 1.19,
                    -rOutInner,
                    i / 180,
                    color
                  )
                : drawLineAngle(
                    ctx,
                    -rPlanetFlag * 1.21,
                    -rOutInner,
                    i / 180,
                    color
                  );
            }
            //2、画宫位相关：宫位线、宫位编号、对应星座、度、分
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              var starNum = palace[i][2];
              var du = palace[i][3];
              var fen = palace[i][4];
              var colorLine =
                i % 3 == 0 ? proColor.colorXudian : proColor.colorPalaceLine;
              //十字線加粗
              if (i % 3 == 0) {
                ctx.lineWidth = 4;
              }
              drawLineAngle(
                ctx,
                0,
                -theRealOuter,
                angle,
                i % 3 == 0 ? "#000" : "#000"
              );
              ctx.lineWidth = 2;
              var angleNum = (angle + angleNext) / 2;
              // drawTextAngle(ctx, rInMid, angleNum, i + 1, colorPalace, sizePalaceNum);
              drawTextAngle(
                ctx,
                theRealOuterMid,
                i < 6 ? angle - 1 / 100 : angle + 1 / 100,
                du,
                "#000",
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                theRealOuterMid,
                i < 6 ? angle + 1 / 100 : angle - 1 / 100,
                fen,
                "#000",
                sizeFen
              );
              var starNext = palace[(i + 1) % 12][2];
              var starGap = (starNext - starNum + 12) % 12;
              // if (starGap > 1) {
              //   var angleRefer = angle + 1 / 70;
              //   var angleGap = (angleNext - angle - 1 / 35) / starGap;
              //   var starJieDuo;
              //   for (var j = 1; j < starGap; j++) {
              //     starJieDuo = (starNum + j) % 12;

              //     drawIconAngle(
              //       ctx,
              //       rOutMid,
              //       angleRefer + j * angleGap,
              //       iconStar[starJieDuo],
              //       colorStar[starJieDuo],
              //       sizeStarFlag
              //     );
              //   }
              // }
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              //星座邊界線
              drawLineAngle(ctx, -rOutInner, -rOutOuter, angleStar, "#000");

              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                "#0709B5",
                sizeStarFlag
              );
            }
            //3、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  0,
                  sizeDashed
                );
              }
              // drawLineConn(ctx, rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed);
            });
            //4、画行星相关(用调整后的角度):行星、对应星座、度、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var starNum = planetSingle[i][2];
              var du = planetSingle[i][3];
              var fen = planetSingle[i][4];

              drawIconAngle(
                ctx,
                rPlanetFlag,
                planetSingle[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rMidStar,
                angleAdjust,
                iconStar[starNum],
                "#000",
                sizeMidStar
              );
              drawTextAngle(ctx, rMidDu, angleAdjust, du, "#000", sizeDu, true);
              drawTextAngle(ctx, rMidFen, angleAdjust, fen, "#000", sizeFen);
              //JOE新增 逆行
              let hasRet = Ret1Array[i] && angleAdjust != 0;

              if (hasRet) {
                drawIconAngle(
                  ctx,
                  rPlanetFlag * 1.085,
                  angleAdjust, //往星體右下偏移顯示 - 0.01
                  "R",
                  "#f21111",
                  sizeDu
                );
              }
              //JOE新增 星體至刻度 連線
              drawLineConn(
                ctx,
                rPlanetFlag * (hasRet ? 1.135 : 1.07),
                planetSingle[i][5],
                rOutInner,
                planetSingle[i][1],
                "#666",
                1,
                sizeDashed
              );
            }
          }
        }
        //$國師指定板__特殊顏色
        function drawJoe2Single_pro(objSingle, Ret1Array) {
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.015,
            configItem
          );
          //2、专业版配色(hard code)
          // var proColor = getWhiteColor();
          var proColor = getBlackColor_pro();
          //3、布局&尺寸，以rOutOuter为基准
          let theRealOuter = rOutOuter; //暫存邊界
          rOutOuter = rOutOuter * 0.94; //圓圈範圍縮小
          let theRealOuterMid = (theRealOuter + rOutOuter) / 2;

          var rAngle = rOutOuter * 0.909;
          var rOutInner = rOutOuter * 0.88;
          var rInOuter = rOutOuter * 0.4;
          var rInInner = rOutOuter * 0.22;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rOutOuter) / 2;
          var rPlanetFlag = rInOuter + (rOutInner - rInOuter) * 0.67;
          var rPhasePoint = rInInner * 2.7;
          var rMidDu = rInOuter + (rOutInner - rInOuter) * 0.49;
          var rMidStar = rInOuter + (rOutInner - rInOuter) * 0.33;
          var rMidFen = rInOuter + (rOutInner - rInOuter) * 0.21;
          var sizeStarFlag = rOutOuter * 0.12; //宮位星座
          var sizePalaceNum = rOutOuter * 0.045; //宮位
          var sizePlanetFlag = rOutOuter * 0.077;
          var sizeDu = rOutOuter * 0.04; //度字号
          var sizeFen = rOutOuter * 0.035; //分字号
          var sizeMidStar = rOutOuter * 0.077; //中圈星座字号
          //4、开始画图
          drawProSinglePan();
          function drawProSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#030420
            drawRingOrigin(ctx, rOutInner, "#f0f2f4"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rInOuter, "#f0f2f4"); //星盘底色#f0f2f4
            //1、画底图：四个圈
            // drawCircleOrigin(ctx, theRealOuter, "#f0f2f4"); //星盘底色#030420
            drawCircleOrigin(ctx, rOutOuter, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rPlanetFlag * 1.17, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rOutInner, proColor.colorOutCircle);
            drawCircleOrigin(ctx, rInOuter, proColor.colorOutCircle);

            ctx.lineWidth = 2;
            // 刻度
            for (var i = 0; i < 180; i++) {
              var color =
                i % 5 == 0
                  ? proColor.colorPalaceLine
                  : proColor.colorPalaceLine;
              i % 5 == 0
                ? drawLineAngle(
                    ctx,
                    -rPlanetFlag * 1.17,
                    -rOutInner,
                    i / 180,
                    color
                  )
                : drawLineAngle(
                    ctx,
                    -rPlanetFlag * 1.19,
                    -rOutInner,
                    i / 180,
                    color
                  );
            }
            //2、画宫位相关：宫位线、宫位编号、对应星座、度、分
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              var starNum = palace[i][2];
              var du = palace[i][3];
              var fen = palace[i][4];
              var colorLine =
                i % 3 == 0 ? proColor.colorXudian : proColor.colorPalaceLine;

              //十字線加粗
              if (i % 3 == 0) {
                ctx.lineWidth = 4;
              }
              drawLineAngle(
                ctx,
                0,
                -theRealOuter,
                angle,
                i % 3 == 0 ? proColor.colorPalaceLine : proColor.colorPalaceLine
              );
              ctx.lineWidth = 2;
              var angleNum = (angle + angleNext) / 2;
              // drawTextAngle(ctx, rInMid, angleNum, i + 1, colorPalace, sizePalaceNum);
              drawTextAngle(
                ctx,
                theRealOuterMid,
                i < 6 ? angle - 1 / 100 : angle + 1 / 100,
                du,
                proColor.colorPalaceLine,
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                theRealOuterMid,
                i < 6 ? angle + 1 / 100 : angle - 1 / 100,
                fen,
                proColor.colorPalaceLine,
                sizeFen
              );
              var starNext = palace[(i + 1) % 12][2];
              var starGap = (starNext - starNum + 12) % 12;
              // if (starGap > 1) {
              //   var angleRefer = angle + 1 / 70;
              //   var angleGap = (angleNext - angle - 1 / 35) / starGap;
              //   var starJieDuo;
              //   for (var j = 1; j < starGap; j++) {
              //     starJieDuo = (starNum + j) % 12;

              //     drawIconAngle(
              //       ctx,
              //       rOutMid,
              //       angleRefer + j * angleGap,
              //       iconStar[starJieDuo],
              //       colorStar[starJieDuo],
              //       sizeStarFlag
              //     );
              //   }
              // }
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              //星座邊界線
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                proColor.colorPalaceLine
              );

              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                proColor.outStartIcon,
                sizeStarFlag,
                true
              );
            }
            //3、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  0,
                  sizeDashed
                );
              }
              // drawLineConn(ctx, rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed);
            });
            //4、画行星相关(用调整后的角度):行星、对应星座、度、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var starNum = planetSingle[i][2];
              var du = planetSingle[i][3];
              var fen = planetSingle[i][4];

              drawIconAngle(
                ctx,
                rPlanetFlag,
                planetSingle[i][5],
                iconPlanet[i],
                proColor.colorPalaceLine,
                sizePlanetFlag
              );
              //最後一個參數ture代表要變粗
              drawIconAngle(
                ctx,
                rMidStar,
                angleAdjust,
                iconStar[starNum],
                "#000",
                sizeMidStar,
                true
              );
              drawTextAngle(
                ctx,
                rMidDu,
                angleAdjust,
                du,
                proColor.colorPalaceLine,
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                rMidFen,
                angleAdjust,
                fen,
                proColor.colorPalaceLine,
                sizeFen
              );
              //JOE新增 逆行
              if (Ret1Array[i] && angleAdjust != 0) {
                drawIconAngle(
                  ctx,
                  rMidDu * 0.7,
                  angleAdjust,
                  "R",
                  proColor.colorPalaceLine,
                  sizeDu,
                  true
                );
              }
              //JOE新增 星體至刻度 連線
              drawLineConn(
                ctx,
                rPlanetFlag * 1.07,
                planetSingle[i][5],
                rOutInner,
                planetSingle[i][5],
                "#000",
                1,
                sizeDashed
              );
            }
          }
        }

        //$單盤文字版
        function drawTextSingle(objSingle, Ret1Array) {
          // console.log('文字版')
          //console.log(objSingle, 'objSingle')
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.024,
            configItem
          );
          // console.log("單盤數據:", planetSingle, palace, phaseSingle);
          //2、文字版颜色
          var textColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.792;
          var rInOuter = rOutOuter * 0.744;
          var rInInner = rOutOuter * 0.652;
          var rOutMid = (rOutOuter + rOutInner) / 2;
          var rInMid = (rInOuter + rInInner) / 2;
          var rPlanetFlag = rInInner * 0.837;
          var rPhasePoint = rInInner * 0.746;
          let rRet = rInInner * 0.94;
          var sizePalaceNum = rOutOuter * 0.05;
          var sizeStarFlag = rOutOuter * 0.065;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          let sizeRet = rInInner * 0.065;
          //4、开始画图
          drawTextSinglePan();
          function drawTextSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色
            //1、画底图：四个圈、刻度线
            // 邊框(joe加的)
            // drawCircleOrigin(ctx, rOutOuter, "#000");
            // drawCircleOriginShadow(ctx, rOutOuter, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rOutInner, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rInOuter, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rInInner, textColor.colorOutCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? textColor.colorOutCircle
                  : textColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //十字線變粗
                ctx.lineWidth = 3;
                drawLineAngle(ctx, 0, -rOutOuter, angle, textColor.colorXudian);
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  textColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                "rgba(41,41,41,0.6)",
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                textColor.colorOutCircle
              );
              drawTextAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                textStar[i],
                "#292929",
                sizeStarFlag
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24 - 1 / 48,
                iconStar[i],
                "#292929",
                sizeStarFlag * 1.6
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var angleOriginal = planetSingle[i][1];
              //此行渲染icon
              //drawTextAngle(ctx, rPlanetFlag, angleAdjust, textPlanet[i], colorPlanet[i], sizePlanetFlag);
              //改成圖片
              drawImgAngle(ctx, rPlanetFlag, angleAdjust, imgPlanet[i]);
              //
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanetFlag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
              //JOE新增 逆行 (2024/102/05) 文字版一同新增
              let hasRet = Ret1Array[i] && angleAdjust != 0;
              if (hasRet) {
                drawIconAngle(
                  ctx,
                  rRet,
                  angleAdjust, //往星體右下偏移顯示 - 0.01
                  "R",
                  "#f21111",
                  sizeRet
                );
              }
            }
            //4、画相位
            // console.log(phaseSingle, 'phaseSingle')
            // console.log(planetSingle, 'planetSingle')
            phaseSingle.forEach((item, index) => {
              // console.log(rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed)
              // drawLineConn(ctx, rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed);
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  item[5],
                  sizeDashed
                );
              }
            });
          }
        }
        //單盤32A版
        function drawA32Single(objSingle, Ret1Array) {
          // console.log('A32版')
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.024,
            configItem
          );
          //2、文字版颜色
          var textColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.792;
          var rInOuter = rOutOuter * 0.744;
          var rInInner = rOutOuter * 0.652;
          var rOutMid = (rOutOuter + rOutInner) / 2;
          var rInMid = (rInOuter + rInInner) / 2;
          let rRet = rInInner * 0.95;
          var rPlanetFlag = rInInner * 0.857; //原始0.887
          var rPhasePoint = rInInner * 0.796;
          var sizePalaceNum = rOutOuter * 0.04;
          var sizeStarFlag = rOutOuter * 0.16; //0.07
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          let sizeRet = rOutOuter * 0.04;

          //4、开始画图
          drawA32SinglePan();
          function drawA32SinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#000000 //改為#030420
            //邊框(JOE加的)
            // drawCircleOrigin(ctx, rOutOuter, "#000");
            //1、画底图：四个圈、刻度线
            // drawCircleOriginShadow(ctx, rOutOuter, '#f00');
            // drawCircleOriginShadow(ctx, rOutOuter, textColor.colorOutCircle);

            drawCircleOrigin(ctx, rOutInner, textColor.colorOutCircle);

            drawCircleOrigin(ctx, rInOuter, textColor.colorPalaceLine);

            drawCircleOrigin(ctx, rInInner, textColor.colorOutCircle);

            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? textColor.colorOutCircle
                  : textColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 3;
                //$十字線
                drawLineAngle(ctx, 0, -rOutOuter, angle, textColor.colorXudian);
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  textColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                textColor.colorNumber,
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)

              //星座邊界線
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                textColor.colorOutCircle
              );

              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                textColor.outStartIcon,
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分

            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var angleOriginal = planetSingle[i][1];
              drawIconAngle(
                ctx,
                rPlanetFlag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanetFlag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );

              //JOE新增 逆行 (2024/102/03) 標準版一同新增
              let hasRet = Ret1Array[i] && angleAdjust != 0;
              if (hasRet) {
                drawIconAngle(
                  ctx,
                  rRet,
                  angleAdjust, //往星體右下偏移顯示 - 0.01
                  "R",
                  "#f21111",
                  sizeRet
                );
              }
            }
            //4、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  item[5],
                  sizeDashed
                );
              }
            });
          }
        }
        //單盤清新版
        function drawFreshSingle(objSingle) {
          // console.log('清新版')
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.024,
            configItem
          );
          //2、文字版颜色
          var freshColor = getFreshColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.792;
          var rInOuter = rOutOuter * 0.744;
          var rInInner = rOutOuter * 0.652;
          var rOutMid = (rOutOuter + rOutInner) / 2;
          var rInMid = (rInOuter + rInInner) / 2;
          var rPlanetFlag = rInInner * 0.887;
          var rPhasePoint = rInInner * 0.796;
          var sizePalaceNum = rOutOuter * 0.055;
          var sizeStarFlag = rOutOuter * 0.07;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawFreshSinglePan();
          // console.log('開始畫圖')
          function drawFreshSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#000000"); //星盘底色
            //1、画底图：四个圈、刻度线
            drawRingOrigin(ctx, rOutOuter, freshColor.colorBackground);
            drawCircleOrigin(ctx, rOutOuter, freshColor.colorCircle);
            drawCircleOrigin(ctx, rOutInner, freshColor.colorCircle);
            drawRingOrigin(ctx, rInOuter, "white");
            drawCircleOrigin(ctx, rInOuter, freshColor.colorCircle);
            drawCircleOrigin(ctx, rInInner, freshColor.colorCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? freshColor.colorCircle
                  : freshColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(
                  ctx,
                  0,
                  -rOutOuter,
                  angle,
                  freshColor.colorXudian
                );
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  freshColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                freshColor.colorCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                colorStar[i],
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var angleOriginal = planetSingle[i][1];
              drawIconAngle(
                ctx,
                rPlanetFlag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanetFlag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  item[5],
                  sizeDashed
                );
              }
            });
          }
        }
        //單盤温暖版
        function drawWarmSingle(objSingle) {
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.024,
            configItem
          );
          //2、文字版颜色
          var wramColor = getWramColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.792;
          var rInOuter = rOutOuter * 0.744;
          var rInInner = rOutOuter * 0.652;
          var rOutMid = (rOutOuter + rOutInner) / 2;
          var rInMid = (rInOuter + rInInner) / 2;
          var rPlanetFlag = rInInner * 0.887;
          var rPhasePoint = rInInner * 0.796;
          var sizePalaceNum = rOutOuter * 0.055;
          var sizeStarFlag = rOutOuter * 0.07;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawFreshSinglePan();
          function drawFreshSinglePan() {
            drawRingOrigin(ctx, rOutOuter, "#000000"); //星盘底色
            //1、画底图：四个圈、刻度线
            drawRingOrigin(ctx, rOutOuter, wramColor.colorBackground);
            drawCircleOrigin(ctx, rOutOuter, wramColor.colorCircle);
            drawCircleOrigin(ctx, rOutInner, wramColor.colorCircle);
            drawRingOrigin(ctx, rInOuter, "white");
            drawCircleOrigin(ctx, rInOuter, wramColor.colorCircle);
            drawCircleOrigin(ctx, rInInner, wramColor.colorCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0 ? wramColor.colorCircle : wramColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(ctx, 0, -rOutOuter, angle, wramColor.colorXudian);
                ctx.lineWidth = 4;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  wramColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                wramColor.colorCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                colorStar[i],
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var angleOriginal = planetSingle[i][1];
              drawIconAngle(
                ctx,
                rPlanetFlag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanetFlag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  item[5],
                  sizeDashed
                );
              }
            });
          }
        }
        //單盤黑夜版
        function drawDarkSingle(objSingle) {
          //1、單盤数据
          var { planetSingle, palace, phaseSingle } = dealSinglePlanet(
            objSingle,
            0.024,
            configItem
          );
          //2、文字版颜色
          var darkColor = getDarkColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.792;
          var rInOuter = rOutOuter * 0.744;
          var rInInner = rOutOuter * 0.652;
          var rOutMid = (rOutOuter + rOutInner) / 2;
          var rInMid = (rInOuter + rInInner) / 2;
          var rPlanetFlag = rInInner * 0.887;
          var rPhasePoint = rInInner * 0.796;
          var sizePalaceNum = rOutOuter * 0.055;
          var sizeStarFlag = rOutOuter * 0.07;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawDarkSinglePan();
          function drawDarkSinglePan() {
            //1、画底图：四个圈、刻度线
            drawRingOrigin(ctx, rOutOuter, darkColor.colorRingOut);
            drawCircleOrigin(ctx, rOutOuter, darkColor.colorCircle);
            drawCircleOrigin(ctx, rOutInner, darkColor.colorCircle);
            drawRingOrigin(ctx, rInOuter, darkColor.colorRingMid);
            drawCircleOrigin(ctx, rInOuter, darkColor.colorCircle);
            drawRingOrigin(ctx, rInInner, darkColor.colorRingIn);
            drawCircleOrigin(ctx, rInInner, darkColor.colorCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0 ? darkColor.colorCircle : darkColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(ctx, 0, -rOutOuter, angle, darkColor.colorXudian);
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  darkColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                darkColor.colorCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                colorStar[i],
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planetSingle[i][5];
              var angleOriginal = planetSingle[i][1];
              drawIconAngle(
                ctx,
                rPlanetFlag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanetFlag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseSingle.forEach(function (item) {
              if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                drawLineConn(
                  ctx,
                  rPhasePoint,
                  planetSingle[item[0]][1],
                  rPhasePoint,
                  planetSingle[item[1]][1],
                  "#" + item[2],
                  item[5],
                  sizeDashed
                );
              }
            });
          }
        }
        //雙盤專業版
        function drawProDouble(objDouble) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.015,
            configItem
          );
          //2、专业版配色
          var proColor = getWhiteColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.909; // 0.909
          var rInOuter = rOutOuter * 0.304; //0.304
          var rInInner = rOutOuter * 0.256; //0.256;
          var rIOMid = (rInOuter + rOutInner) / 2;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPhasePoint = rInInner * 0.96;
          var rPlanetNatal = rInOuter + (rIOMid - rInOuter) * 0.8;
          var rPlanetTransit = rIOMid + (rOutInner - rIOMid) * 0.8;
          var rNatalDu = rInOuter + (rIOMid - rInOuter) * 0.565;
          var rNatalStar = rInOuter + (rIOMid - rInOuter) * 0.379;
          var rNatalFen = rInOuter + (rIOMid - rInOuter) * 0.207;
          var rTransitDu = rIOMid + (rOutInner - rIOMid) * 0.565;
          var rTransitStar = rIOMid + (rOutInner - rIOMid) * 0.379;
          var rTransitFen = rIOMid + (rOutInner - rIOMid) * 0.207;
          var sizeNatalDu = rOutOuter * 0.037; //本命度字号
          var sizeNatalFen = rOutOuter * 0.03; //本命分字号
          var sizeTransitDu = rOutOuter * 0.037; //行运度字号
          var sizeTransitFen = rOutOuter * 0.03; //行运分字号
          var sizeStarFlag = rOutOuter * 0.12; //0.058
          var sizePalaceNum = rOutOuter * 0.045; //0.04
          var sizePlanetFlag = rOutOuter * 0.068; //0.05
          var sizeDu = rOutOuter * 0.05; //度字号0.047
          var sizeFen = rOutOuter * 0.047; //分字号0.04
          var sizeMidStar = rOutOuter * 0.07; //中圈星座字号0.035
          //4、开始画图
          drawProDoublePan();
          function drawProDoublePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rOutInner, "#ddd"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rIOMid, "#f0f2f4"); //星盘底色#f0f2f4
            //1、画底图：五个圈
            drawCircleOrigin(ctx, rOutOuter, "#f0f2f4");
            drawCircleOrigin(ctx, rOutInner, "rgba(41,41,41,0.3)");
            drawCircleOrigin(ctx, rIOMid, "rgba(41,41,41,0.3)");
            // drawCircleOrigin(ctx, rInOuter, proColor.colorInCircle);
            drawCircleOrigin(ctx, rInInner, "rgba(41,41,41,0.3)");
            //2、画宫位相关：宫位线、宫位编号、对应星座、度、分
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              var starNum = palace[i][2];
              var du = palace[i][3];
              var fen = palace[i][4];
              // var colorLine = (i % 3 ==0) ? proColor.colorXudian : proColor.colorPalaceLine;
              //十字線加粗
              if (i % 3 == 0) {
                ctx.lineWidth = 4;
              }
              drawLineAngle(
                ctx,
                -rInInner,
                -rOutInner,
                angle,
                "rgba(41,41,41,0.3)"
              );
              ctx.lineWidth = 2;
              var angleNum = (angle + angleNext) / 2;
              drawTextAngle(
                ctx,
                rInMid,
                angleNum,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angle,
                iconStar[starNum],
                "#292929",
                sizeStarFlag
              );
              drawTextAngle(
                ctx,
                rOutMid,
                angle + 1 / 70,
                du,
                "rgba(41,41,41,0.85)",
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                rOutMid,
                angle - 1 / 70,
                fen,
                "rgba(41,41,41,0.55)",
                sizeFen
              );
              var starNext = palace[(i + 1) % 12][2];
              var starGap = (starNext - starNum + 12) % 12;
              if (starGap > 1) {
                var angleRefer = angle + 1 / 70;
                var angleGap = (angleNext - angle - 1 / 35) / starGap;
                var starJieDuo;
                for (var j = 1; j < starGap; j++) {
                  starJieDuo = (starNum + j) % 12;
                  drawIconAngle(
                    ctx,
                    rOutMid,
                    angleRefer + j * angleGap,
                    iconStar[starJieDuo],
                    colorStar[starJieDuo],
                    sizeStarFlag
                  );
                }
              }
            }
            //3、画行星相关(用调整后的角度):行星、对应星座、度、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var starNum = planet1[i][2];
              var du = planet1[i][3];
              var fen = planet1[i][4];
              drawIconAngle(
                ctx,
                rPlanetNatal,
                planet1[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rNatalStar,
                angleAdjust,
                iconStar[starNum],
                "#292929",
                sizeMidStar
              );
              drawTextAngle(
                ctx,
                rNatalDu,
                angleAdjust,
                du,
                "rgba(41,41,41,0.85)",
                sizeNatalDu,
                true
              );
              drawTextAngle(
                ctx,
                rNatalFen,
                angleAdjust,
                fen,
                "rgba(41,41,41,0.55)",
                sizeNatalFen
              );
              var angleAdjust = planet2[i][5];
              var starNum = planet2[i][2];
              var du = planet2[i][3];
              var fen = planet2[i][4];
              drawIconAngle(
                ctx,
                rPlanetTransit,
                planet2[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rTransitStar,
                angleAdjust,
                iconStar[starNum],
                "#292929",
                sizeMidStar
              );
              drawTextAngle(
                ctx,
                rTransitDu,
                angleAdjust,
                du,
                "rgba(41,41,41,0.85)",
                sizeTransitDu
              );
              drawTextAngle(
                ctx,
                rTransitFen,
                angleAdjust,
                fen,
                "rgba(41,41,41,0.55)",
                sizeTransitFen
              );
            }
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
          }
        }
        //雙盤Joe版
        function drawJoeDouble(objDouble, Ret1Array) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.015,
            configItem
          );
          //2、专业版配色
          var proColor = getWhiteColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.92; // 0.909
          var rInOuter = rOutOuter * 0.38; //0.304
          var rInInner = rOutOuter * 0.34; //0.38;
          var rIOMid = (rInOuter + rOutInner) / 2; //內圈範圍
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2; //相位範圍
          var rPhasePoint = rInInner * 0.99;
          var rPlanetNatal = rInOuter + (rIOMid - rInOuter) * 0.85;
          var rPlanetTransit = rIOMid + (rOutInner - rIOMid) * 0.9;
          var rNatalDu = rInOuter + (rIOMid - rInOuter) * 0.65;
          var rNatalStar = rInOuter + (rIOMid - rInOuter) * 0.45;
          var rNatalFen = rInOuter + (rIOMid - rInOuter) * 0.3;
          var rTransitDu = rIOMid + (rOutInner - rIOMid) * 0.7;
          var rTransitStar = rIOMid + (rOutInner - rIOMid) * 0.5;
          var rTransitFen = rIOMid + (rOutInner - rIOMid) * 0.35;
          var sizeNatalDu = rOutOuter * 0.032; //本命度字号
          var sizeNatalFen = rOutOuter * 0.025; //本命分字号
          var sizeTransitDu = rOutOuter * 0.032; //行运度字号
          var sizeTransitFen = rOutOuter * 0.025; //行运分字号
          var sizeStarFlag = rOutOuter * 0.1; //0.12
          var sizePalaceNum = rOutOuter * 0.04; //045
          var sizePlanetFlag = rOutOuter * 0.05; //068
          var sizeDu = rOutOuter * 0.05; //度字号0.047
          var sizeFen = rOutOuter * 0.047; //分字号0.04
          var sizeMidStar = rOutOuter * 0.07; //中圈星座字号0.035
          //4、开始画图
          drawProDoublePan();
          function drawProDoublePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rOutInner, "#ddd"); //星盘底色#f0f2f4
            drawRingOrigin(ctx, rIOMid, "#f0f2f4"); //星盘底色#f0f2f4
            //1、画底图：五个圈
            drawCircleOrigin(ctx, rOutOuter, "#f0f2f4");
            drawCircleOrigin(ctx, rOutInner, "rgba(41,41,41,0.3)");
            drawCircleOrigin(ctx, rIOMid, "rgba(41,41,41,0.3)");
            // drawCircleOrigin(ctx, rInOuter, proColor.colorInCircle);
            drawCircleOrigin(ctx, rInInner, "rgba(41,41,41,0.3)");
            //2、画宫位相关：宫位线、宫位编号、对应星座、度、分
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              var starNum = palace[i][2];
              var du = palace[i][3];
              var fen = palace[i][4];
              // var colorLine = (i % 3 ==0) ? proColor.colorXudian : proColor.colorPalaceLine;
              //十字線加粗
              if (i % 3 == 0) {
                ctx.lineWidth = 4;
              }
              drawLineAngle(
                ctx,
                -rInInner,
                -rOutInner,
                angle,
                "rgba(41,41,41,0.3)"
              );
              ctx.lineWidth = 2;
              var angleNum = (angle + angleNext) / 2;
              drawTextAngle(
                ctx,
                rInMid * 0.99,
                angleNum,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angle,
                iconStar[starNum],
                "#292929",
                sizeStarFlag
              );
              drawTextAngle(
                ctx,
                rOutMid,
                angle + 1 / 70,
                du,
                "rgba(41,41,41,0.85)",
                sizeDu,
                true
              );
              drawTextAngle(
                ctx,
                rOutMid,
                angle - 1 / 70,
                fen,
                "rgba(41,41,41,0.55)",
                sizeFen
              );
              var starNext = palace[(i + 1) % 12][2];
              var starGap = (starNext - starNum + 12) % 12;
              if (starGap > 1) {
                var angleRefer = angle + 1 / 70;
                var angleGap = (angleNext - angle - 1 / 35) / starGap;
                var starJieDuo;
                for (var j = 1; j < starGap; j++) {
                  starJieDuo = (starNum + j) % 12;
                  drawIconAngle(
                    ctx,
                    rOutMid,
                    angleRefer + j * angleGap,
                    iconStar[starJieDuo],
                    colorStar[starJieDuo],
                    sizeStarFlag
                  );
                }
              }
            }
            //3、画行星相关(用调整后的角度):行星、对应星座、度、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var starNum = planet1[i][2];
              var du = planet1[i][3];
              var fen = planet1[i][4];
              drawIconAngle(
                ctx,
                rPlanetNatal,
                planet1[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rNatalStar,
                angleAdjust,
                iconStar[starNum],
                "#292929",
                sizeMidStar
              );
              drawTextAngle(
                ctx,
                rNatalDu,
                angleAdjust,
                du,
                "#000",
                sizeNatalDu,
                true
              );
              drawTextAngle(
                ctx,
                rNatalFen,
                angleAdjust,
                fen,
                "#000",
                sizeNatalFen
              );
              //真實點位
              // drawIconAngle(
              //   ctx,
              //   rInMid * 1.02,
              //   planet1[i][1],
              //   "●",
              //   "#000",
              //   sizeNatalFen
              // );
              //真實點位__連線
              drawLineConn(
                ctx,
                rNatalFen * 0.932,
                angleAdjust,
                rInMid * 1.02,
                planet1[i][1],
                "#666",
                0
              );
              //開始繪製外圈星體
              var angleAdjust = planet2[i][5];
              var starNum = planet2[i][2];
              var du = planet2[i][3];
              var fen = planet2[i][4];
              drawIconAngle(
                ctx,
                rPlanetTransit,
                planet2[i][5],
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawIconAngle(
                ctx,
                rTransitStar,
                angleAdjust,
                iconStar[starNum],
                "#292929",
                sizeMidStar
              );
              drawTextAngle(
                ctx,
                rTransitDu,
                angleAdjust,
                du,
                "#000",
                sizeTransitDu
              );
              drawTextAngle(
                ctx,
                rTransitFen,
                angleAdjust,
                fen,
                "#000",
                sizeTransitFen
              );
              //真實點位
              // drawIconAngle(
              //   ctx,
              //   rIOMid * 1.01,
              //   planet2[i][1],
              //   "●",
              //   "#000",
              //   sizeNatalFen
              // );
              //真實點位__連線
              drawLineConn(
                ctx,
                rTransitFen * 0.932,
                angleAdjust,
                rIOMid * 1.01,
                planet2[i][1],
                "#666",
                0
              );
              // //畫一條到刻度上的線
              // drawLineConn(
              //   ctx,
              //   rTransitFen * 0.95,
              //   angleAdjust,
              //   0,
              //   angleAdjust,
              //   "#f00",
              //   0
              // );
              //JOE新增 逆行
              if (Ret1Array[i] && angleAdjust != 0 && i < 10) {
                drawIconAngle(
                  ctx,
                  rTransitFen * 0.95,
                  angleAdjust,
                  "R",
                  "#f00",
                  sizeTransitFen
                );
              }
            }
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
          }
        }
        //雙盤文字版
        function drawTextDouble(objDouble) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.024,
            configItem
          );
          //2、文字版颜色
          var textColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.835;
          var rInOuter = rOutOuter * 0.792;
          var rInInner = rOutOuter * 0.72;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPlanet2Flag = rInInner * 0.887;
          var rPlanet1Flag = rInInner * 0.75;
          var rPhasePoint = rInInner * 0.65;
          var sizePalaceNum = rOutOuter * 0.05;
          var sizeStarFlag = rOutOuter * 0.065;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawTextDoublePan();
          function drawTextDoublePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色
            //1、画底图：四个圈、刻度线
            drawCircleOrigin(ctx, rOutOuter, "#f0f2f4");
            drawCircleOrigin(ctx, rOutInner, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rInOuter, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rInInner, textColor.colorOutCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? textColor.colorOutCircle
                  : textColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(ctx, 0, -rOutOuter, angle, textColor.colorXudian);
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  textColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                "rgba(41,41,41,0.6)",
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                textColor.colorOutCircle
              );
              drawTextAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                textStar[i],
                "#000",
                sizeStarFlag
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24 - 1 / 48,
                iconStar[i],
                "#000",
                sizeStarFlag * 1.6
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var angleOriginal = planet1[i][1];
              //此行渲染icon
              //drawTextAngle(ctx, rPlanet1Flag, angleAdjust, textPlanet[i], colorPlanet[i], sizePlanetFlag);
              //改成圖片
              drawImgAngle(ctx, rPlanet1Flag, angleAdjust, imgPlanet[i]);
              //
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanet1Flag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
              var angle2Adjust = planet2[i][5];
              var angle2Original = planet2[i][1];
              //此行渲染icon
              //drawTextAngle(ctx, rPlanet2Flag, angle2Adjust, textPlanet[i], colorPlanet[i], sizePlanetFlag);
              //改成圖片
              drawImgAngle(ctx, rPlanet2Flag, angle2Adjust, imgPlanet[i]);
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angle2Original,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angle2Original,
                rPlanet2Flag - sizePlanetFlag / 2,
                angle2Adjust,
                colorPlanet[i],
                0
              );
            }

            //4、画相位
            // phaseSingle.forEach((item, index) => {
            //   if(configItem[item[0]] == '1' && configItem[item[1]] == '1'){
            //     drawLineConn(ctx, rPhasePoint, planetSingle[item[0]][1], rPhasePoint, planetSingle[item[1]][1], "#" + item[2], item[5], sizeDashed);
            //   }
            // })
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
            // phaseDouble.forEach(function(item){
            //   if(item[4] != 'he'){
            //     drawLineConn(ctx, rPhasePoint, planet2[item[0]][1], rPhasePoint, planet1[item[1]][1], "#" + item[2], item[5], sizeDashed);
            //   }
            // })
          }
        }
        //雙盤A32版
        function drawA32Double(objDouble) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.024,
            configItem
          );
          //2、文字版颜色
          var textColor = getBlackColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.835;
          var rInOuter = rOutOuter * 0.792;
          var rInInner = rOutOuter * 0.72;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPlanet2Flag = rInInner * 0.887;
          var rPlanet1Flag = rInInner * 0.75;
          var rPhasePoint = rInInner * 0.65;
          var sizePalaceNum = rOutOuter * 0.05;
          var sizeStarFlag = rOutOuter * 0.11; //0.07
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawA32DoublePan();
          function drawA32DoublePan() {
            drawRingOrigin(ctx, rOutOuter, "#f0f2f4"); //星盘底色
            //1、画底图：四个圈、刻度线
            drawCircleOrigin(ctx, rOutOuter, "#f0f2f4");
            drawCircleOrigin(ctx, rOutInner, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rInOuter, textColor.colorOutCircle);
            drawCircleOrigin(ctx, rInInner, textColor.colorOutCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? textColor.colorOutCircle
                  : textColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(ctx, 0, -rOutOuter, angle, textColor.colorXudian);
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  textColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                "rgba(41,41,41,0.6)",
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                textColor.colorOutCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                "#292929",
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var angleOriginal = planet1[i][1];
              drawIconAngle(
                ctx,
                rPlanet1Flag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanet1Flag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
              var angle2Adjust = planet2[i][5];
              var angle2Original = planet2[i][1];
              drawIconAngle(
                ctx,
                rPlanet2Flag,
                angle2Adjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angle2Original,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angle2Original,
                rPlanet2Flag - sizePlanetFlag / 2,
                angle2Adjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
          }
        }
        //雙盤清新版
        function drawFreshDouble(objDouble) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.024,
            configItem
          );
          //2、文字版颜色
          var freshColor = getFreshColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.835;
          var rInOuter = rOutOuter * 0.792;
          var rInInner = rOutOuter * 0.72;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPlanet2Flag = rInInner * 0.887;
          var rPlanet1Flag = rInInner * 0.75;
          var rPhasePoint = rInInner * 0.65;
          var sizePalaceNum = rOutOuter * 0.055;
          var sizeStarFlag = rOutOuter * 0.07;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawFreshDoublePan();
          function drawFreshDoublePan() {
            drawRingOrigin(ctx, rOutOuter, "#000000"); //星盘底色
            //1、画底图：四个圈、刻度线
            drawRingOrigin(ctx, rOutOuter, freshColor.colorBackground);
            drawCircleOrigin(ctx, rOutOuter, freshColor.colorCircle);
            drawCircleOrigin(ctx, rOutInner, freshColor.colorCircle);
            drawRingOrigin(ctx, rInOuter, "white");
            drawCircleOrigin(ctx, rInOuter, freshColor.colorCircle);
            drawCircleOrigin(ctx, rInInner, freshColor.colorCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? freshColor.colorCircle
                  : freshColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(
                  ctx,
                  0,
                  -rOutOuter,
                  angle,
                  freshColor.colorXudian
                );
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  freshColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                freshColor.colorCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                colorStar[i],
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var angleOriginal = planet1[i][1];
              drawIconAngle(
                ctx,
                rPlanet1Flag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanet1Flag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
              var angle2Adjust = planet2[i][5];
              var angle2Original = planet2[i][1];
              drawIconAngle(
                ctx,
                rPlanet2Flag,
                angle2Adjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angle2Original,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angle2Original,
                rPlanet2Flag - sizePlanetFlag / 2,
                angle2Adjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
          }
        }
        //雙盤温暖版
        function drawWarmDouble(objDouble) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.024,
            configItem
          );
          //2、文字版颜色
          var freshColor = getWramColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.835;
          var rInOuter = rOutOuter * 0.792;
          var rInInner = rOutOuter * 0.72;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPlanet2Flag = rInInner * 0.887;
          var rPlanet1Flag = rInInner * 0.75;
          var rPhasePoint = rInInner * 0.65;
          var sizePalaceNum = rOutOuter * 0.055;
          var sizeStarFlag = rOutOuter * 0.07;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawWarmDoublePan();
          function drawWarmDoublePan() {
            drawRingOrigin(ctx, rOutOuter, "#000000"); //星盘底色
            //1、画底图：四个圈、刻度线
            drawRingOrigin(ctx, rOutOuter, freshColor.colorBackground);
            drawCircleOrigin(ctx, rOutOuter, freshColor.colorCircle);
            drawCircleOrigin(ctx, rOutInner, freshColor.colorCircle);
            drawRingOrigin(ctx, rInOuter, "white");
            drawCircleOrigin(ctx, rInOuter, freshColor.colorCircle);
            drawCircleOrigin(ctx, rInInner, freshColor.colorCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0
                  ? freshColor.colorCircle
                  : freshColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(
                  ctx,
                  0,
                  -rOutOuter,
                  angle,
                  freshColor.colorXudian
                );
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  freshColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                freshColor.colorCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                colorStar[i],
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 18; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var angleOriginal = planet1[i][1];
              drawIconAngle(
                ctx,
                rPlanet1Flag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanet1Flag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
              var angle2Adjust = planet2[i][5];
              var angle2Original = planet2[i][1];
              drawIconAngle(
                ctx,
                rPlanet2Flag,
                angle2Adjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angle2Original,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angle2Original,
                rPlanet2Flag - sizePlanetFlag / 2,
                angle2Adjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
          }
        }
        //雙盤黑夜版
        function drawDarkDouble(objDouble) {
          //1、雙盤数据
          var { palace, phaseDouble, planet1, planet2 } = dealDoublePlanet(
            objDouble,
            0.024,
            configItem
          );
          //2、文字版颜色
          var darkColor = getDarkColor();
          //3、布局&尺寸，以rOutOuter为基准
          var rOutInner = rOutOuter * 0.835;
          var rInOuter = rOutOuter * 0.792;
          var rInInner = rOutOuter * 0.72;
          var rOutMid = (rOutInner + rOutOuter) / 2;
          var rInMid = (rInInner + rInOuter) / 2;
          var rPlanet2Flag = rInInner * 0.887;
          var rPlanet1Flag = rInInner * 0.75;
          var rPhasePoint = rInInner * 0.65;
          var sizePalaceNum = rOutOuter * 0.055;
          var sizeStarFlag = rOutOuter * 0.07;
          var sizePlanetFlag = rOutOuter * 0.075;
          var sizePhasePoint = rInInner * 0.008;
          //4、开始画图
          drawDarkDoublePan();
          function drawDarkDoublePan() {
            //1、画底图：四个圈、刻度线
            drawRingOrigin(ctx, rOutOuter, darkColor.colorRingOut);
            drawCircleOrigin(ctx, rOutOuter, darkColor.colorCircle);
            drawCircleOrigin(ctx, rOutInner, darkColor.colorCircle);
            drawRingOrigin(ctx, rInOuter, darkColor.colorRingMid);
            drawCircleOrigin(ctx, rInOuter, darkColor.colorCircle);
            drawRingOrigin(ctx, rInInner, darkColor.colorRingIn);
            drawCircleOrigin(ctx, rInInner, darkColor.colorCircle);
            for (var i = 0; i < 360; i++) {
              var color =
                i % 5 == 0 ? darkColor.colorCircle : darkColor.colorPalaceLine;
              drawLineAngle(ctx, -rInOuter, -rOutInner, i / 360, color);
            }
            //2、画宫位相关：宫位线、宫位编号、星座边界线、星座
            for (var i = 0; i < 12; i++) {
              var angle = palace[i][1];
              var angleNext = i == 11 ? 1 : palace[i + 1][1];
              if (i % 3 == 0) {
                //讓十字線變粗
                ctx.lineWidth = 4;
                //$十字線
                drawLineAngle(ctx, 0, -rOutOuter, angle, darkColor.colorXudian);
                ctx.lineWidth = 2;
              } else {
                drawLineAngle(
                  ctx,
                  0,
                  -rInOuter,
                  angle,
                  darkColor.colorPalaceLine
                );
              }
              drawTextAngle(
                ctx,
                rInMid,
                (angle + angleNext) / 2,
                i + 1,
                colorPalace[i],
                sizePalaceNum
              );
              var angleStar = -palace[0][0] / 360 + i / 12; //星座边界的角度(-x轴逆时针方向)
              drawLineAngle(
                ctx,
                -rOutInner,
                -rOutOuter,
                angleStar,
                darkColor.colorCircle
              );
              drawIconAngle(
                ctx,
                rOutMid,
                angleStar + 1 / 24,
                iconStar[i],
                colorStar[i],
                sizeStarFlag
              );
            }
            //3、画行星相关:行星名称、行星点位、名称与点位连线、分
            for (var i = 0; i < 25; i++) {
              if (configItem[i] === 0) {
                continue;
              }
              var angleAdjust = planet1[i][5];
              var angleOriginal = planet1[i][1];
              drawIconAngle(
                ctx,
                rPlanet1Flag,
                angleAdjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angleOriginal,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angleOriginal,
                rPlanet1Flag - sizePlanetFlag / 2,
                angleAdjust,
                colorPlanet[i],
                0
              );
              var angle2Adjust = planet2[i][5];
              var angle2Original = planet2[i][1];
              drawIconAngle(
                ctx,
                rPlanet2Flag,
                angle2Adjust,
                iconPlanet[i],
                colorPlanet[i],
                sizePlanetFlag
              );
              drawCircleAngle(
                ctx,
                rPhasePoint,
                angle2Original,
                sizePhasePoint,
                colorPlanet[i]
              );
              drawLineConn(
                ctx,
                rPhasePoint,
                angle2Original,
                rPlanet2Flag - sizePlanetFlag / 2,
                angle2Adjust,
                colorPlanet[i],
                0
              );
            }
            //4、画相位
            phaseDouble.forEach(function (item) {
              if (item[4] != "he") {
                if (configItem[item[0]] == "1" && configItem[item[1]] == "1") {
                  drawLineConn(
                    ctx,
                    rPhasePoint,
                    planet2[item[0]][1],
                    rPhasePoint,
                    planet1[item[1]][1],
                    "#" + item[2],
                    item[5],
                    sizeDashed
                  );
                }
              }
            });
          }
        }
        function drawProFirdaria(objSingle) {
          drawProSingle(objSingle);
        }
        window.drawAstrolabe = drawAstrolabe;
      })(window, this.role);
    },
    //canvasInit()初始化結束
    drawXingPan: function () {
      var canvas = this.$refs.astrolabe;

      if (!canvas) {
        return;
      }
      canvas.setAttribute("width", this.width * 2);
      canvas.setAttribute("height", this.width * 2);
      canvas.style.width = this.width - 24 + "px";
      canvas.style.height = this.width - 24 + "px";

      ///JOE 逆行嘗試處理
      let Ret1Array = [];
      this.planetOrderEnglish.forEach((key) => {
        const value = this.Ret1[key];
        if (typeof value === "boolean") {
          Ret1Array.push(value);
        }
      });

      var ctx = canvas.getContext("2d");
      var draw;
      switch (this.type) {
        // 6.30筆記
        // 上面先定義了一堆畫星盤的func，再取一個中文名稱
        // 利用drawAstrolabe去進行設置，形成一個高級函數draw
        // 再用高級函數draw作為畫圖func，並將處理過的data作為參數
        case 0: {
          //單盤
          draw = drawAstrolabe(
            ctx,
            this.width,
            this.configItem,
            "單盤",
            this.panStyle
          );
          draw(this.dealSingleData(this.data1), Ret1Array);
          break;
        }
        case 1: {
          //雙盤
          draw = drawAstrolabe(
            ctx,
            this.width,
            this.configItem,
            "雙盤",
            this.panStyle
          );
          draw(this.dealDoubleData(this.data1, this.data2), Ret1Array);
          break;
        }
        case 2: {
          //配对盘
          draw = drawAstrolabe(
            ctx,
            this.width,
            this.configItem,
            "單盤",
            this.panStyle
          );
          var objSynastry1 = this.dealSingleData(this.data1);
          var objSynastry2 = this.dealSingleData(this.data2);
          var objSynastry = {
            palace: objSynastry1.palace,
            planet: objSynastry2.planet,
            phase: objSynastry2.phase,
          };
          draw(objSynastry, Ret1Array);
          break;
        }
        case 3: {
          //法达盘
          draw = drawAstrolabe(
            ctx,
            this.width,
            this.configItem,
            "法达",
            "专业"
          );
          draw(this.dealSingleData(this.data1));
          break;
        }
        default: {
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
canvas {
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.2), -2px -2px 4px 0 #ffffff,
    -2px -2px 4px 0 #ffffff;
  border-radius: 50%;
  /* border:1px solid #000; */
}
</style>

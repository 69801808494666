var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.userData.IsUnlockLC
    ? _c(
        "div",
        { staticClass: "row-wrap custom-container" },
        [
          _vm.nowUserData && _vm.TcAstralInfo && _vm.nowUserAstralInfo
            ? _c(
                "div",
                { staticClass: "params_box" },
                [
                  _c("TimeLine", {
                    attrs: { format_time: _vm.format_time },
                    on: {
                      "update:format_time": function($event) {
                        _vm.format_time = $event
                      }
                    }
                  }),
                  _c("div", { staticClass: "detail__tab" }, [
                    _c(
                      "div",
                      {
                        staticClass: "detail__tab__item",
                        class: {
                          activeDetailTab: _vm.activeDetailTab == "interpret"
                        },
                        on: {
                          click: function($event) {
                            _vm.activeDetailTab = "interpret"
                          }
                        }
                      },
                      [_vm._v("\n        推運解讀\n      ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "detail__tab__item",
                        class: {
                          activeDetailTab: _vm.activeDetailTab == "params"
                        },
                        on: {
                          click: function($event) {
                            _vm.activeDetailTab = "params"
                          }
                        }
                      },
                      [_vm._v("\n        詳細參數\n      ")]
                    )
                  ]),
                  _c("Interpret", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeDetailTab == "interpret",
                        expression: "activeDetailTab == 'interpret'"
                      }
                    ],
                    attrs: {
                      TcAstralInfo: _vm.TcAstralInfo,
                      BcAstralInfo: _vm.nowUserAstralInfo
                    }
                  }),
                  _c("Params", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeDetailTab == "params",
                        expression: "activeDetailTab == 'params'"
                      }
                    ],
                    staticClass: "mt-5",
                    attrs: {
                      BcAstralInfo: _vm.nowUserAstralInfo,
                      TcAstralInfo: _vm.TcAstralInfo,
                      role: _vm.role,
                      moondayObj: _vm.moondayObj
                    },
                    on: {
                      "update:moondayObj": function($event) {
                        _vm.moondayObj = $event
                      },
                      "update:moonday-obj": function($event) {
                        _vm.moondayObj = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.notice__useSunMode
            ? _c(
                "div",
                { staticClass: "notice-pri pl_10 pr_10 hidden-mobile-up" },
                [
                  _c("div", { staticClass: "notice-pri-inner" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/提示燈泡.svg"), alt: "" }
                    }),
                    _c("div", [
                      _vm._v(
                        "\n        唐老師的直播內容都是以「太陽運勢」來說明的喔～\n        "
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "action",
                          on: {
                            click: function($event) {
                              return _vm.closeNotice__useSunMode()
                            }
                          }
                        },
                        [_vm._v("不再提醒")]
                      )
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm.TcAstralInfo && _vm.nowUserAstralInfo
            ? _c("MoonDayCTA", {
                staticClass: "hidden-pad-up mt_10 mb_10",
                attrs: {
                  data2: _vm.TcAstralInfo.AstralRadians,
                  nowPlace:
                    (_vm.Longitude
                      ? _vm.Longitude
                      : _vm.deFormat(_vm.nowUserData.Longitude)) +
                    ", \n               " +
                    (_vm.Latitude
                      ? _vm.Latitude
                      : _vm.deFormat(_vm.nowUserData.Latitude)),
                  nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng,
                  format_time: _vm.format_time,
                  mode: "timeLineMode",
                  moondayObj: _vm.moondayObj
                }
              })
            : _vm._e(),
          _vm.TcAstralInfo && _vm.nowUserAstralInfo
            ? _c("NewAstrolabe", {
                attrs: {
                  data1: _vm.nowUserAstralInfo.AstralRadians,
                  data2: _vm.TcAstralInfo.AstralRadians,
                  format_time: _vm.format_time,
                  type: 1,
                  styleArray: _vm.styleArray,
                  role: _vm.role,
                  Ret1: _vm.TcAstralInfo.RetAstralSet,
                  nowPlace:
                    (_vm.Longitude
                      ? _vm.Longitude
                      : _vm.deFormat(_vm.nowUserData.Longitude)) +
                    ", \n               " +
                    (_vm.Latitude
                      ? _vm.Latitude
                      : _vm.deFormat(_vm.nowUserData.Latitude)),
                  nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng,
                  mode: "timeLineMode",
                  hiddenuserDesktopInfo: true,
                  moondayObj: _vm.moondayObj,
                  notice__useSunMode: _vm.notice__useSunMode
                },
                on: {
                  changeSystem: function($event) {
                    return _vm.changeSystemHander($event)
                  },
                  closeNotice__useSunMode: _vm.closeNotice__useSunMode
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default [
  {
    en: "Aries",
    cn: "牡羊",
    date: "3/21 - 4/19",
    img: require("../../public/img/ConstellationImgs/Aries.svg"),
    icon: '<i class="iconfont">&#xe636;</i>',
    bookImg: require("../../src/assets/運勢書共用/牡羊座.svg"),
    code: "Aries",
  },
  {
    en: "Taurus",
    cn: "金牛",
    date: "4/20 - 5/20",
    img: require("../../public/img/ConstellationImgs/Taurus.svg"),
    icon: '<i class="iconfont">&#xe637;</i>',
    bookImg: require("../../src/assets/運勢書共用/金牛座.svg"),
    code: "Taurus",
  },
  {
    en: "Gemini",
    cn: "雙子",
    date: "5/21 - 6/20",
    img: require("../../public/img/ConstellationImgs/Gemini.svg"),
    icon: '<i class="iconfont">&#xe640;</i>',
    bookImg: require("../../src/assets/運勢書共用/雙子座.svg"),
    code: "Gemini",
  },
  {
    en: "Cancer",
    cn: "巨蟹",
    date: "6/21 - 7/22",
    img: require("../../public/img/ConstellationImgs/Cancer.svg"),
    icon: '<i class="iconfont">&#xe63d;</i>',
    bookImg: require("../../src/assets/運勢書共用/巨蟹座.svg"),
    code: "Cancer",
  },
  {
    en: "Leo",
    cn: "獅子",
    date: "7/23 - 8/22",
    img: require("../../public/img/ConstellationImgs/Leo.svg"),
    icon: '<i class="iconfont">&#xe63c;</i>',
    bookImg: require("../../src/assets/運勢書共用/獅子座.svg"),
    code: "Leo",
  },
  {
    en: "Virgo",
    cn: "處女",
    date: "8/23 - 9/22",
    img: require("../../public/img/ConstellationImgs/Virgo.svg"),
    icon: '<i class="iconfont">&#xe63f;</i>',
    bookImg: require("../../src/assets/運勢書共用/處女座.svg"),
    code: "Virgo",
  },
  {
    en: "Libra",
    cn: "天秤",
    date: "9/23 - 10/22",
    img: require("../../public/img/ConstellationImgs/Libra.svg"),
    icon: '<i class="iconfont">&#xe635;</i>',
    bookImg: require("../../src/assets/運勢書共用/天秤座.svg"),
    code: "Libra",
  },
  {
    en: "Scorpio",
    cn: "天蠍",
    date: "10/23 - 11/21",
    img: require("../../public/img/ConstellationImgs/Scorpio.svg"),
    icon: '<i class="iconfont">&#xe63e;</i>',
    bookImg: require("../../src/assets/運勢書共用/天蠍座.svg"),
    code: "Scorpio",
  },
  {
    en: "Sagittarius",
    cn: "射手",
    date: "11/22 - 12/21",
    img: require("../../public/img/ConstellationImgs/Sagittarius.svg"),
    icon: '<i class="iconfont">&#xe634;</i>',
    bookImg: require("../../src/assets/運勢書共用/射手座.svg"),
    code: "Sagittarius",
  },
  {
    en: "Capricorn",
    cn: "魔羯",
    date: "12/22 - 1/19",
    img: require("../../public/img/ConstellationImgs/Capricorn.svg"),
    icon: '<i class="iconfont">&#xe63b;</i>',
    bookImg: require("../../src/assets/運勢書共用/魔羯座.svg"),
    code: "Capricorn",
  },
  {
    en: "Aquarius",
    cn: "水瓶",
    date: "1/20 - 2/18",
    img: require("../../public/img/ConstellationImgs/Aquarius.svg"),
    icon: '<i class="iconfont">&#xe63a;</i>',
    bookImg: require("../../src/assets/運勢書共用/水瓶座.svg"),
    code: "Aquarius",
  },
  {
    en: "Pisces",
    cn: "雙魚",
    date: "2/19 - 3/20",
    img: require("../../public/img/ConstellationImgs/Pisces.svg"),
    icon: '<i class="iconfont">&#xe638;</i>',
    bookImg: require("../../src/assets/運勢書共用/雙魚座.svg"),
    code: "Pisces",
  },
];

module.exports = [
    {
        imageUrl: require("@/assets/Line/astral/astral_13.svg"),
        name: "凱龍",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_14.svg"),
        name: "穀神",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_15.svg"),
        name: "智神",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_16.svg"),
        name: "婚神",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_17.svg"),
        name: "灶神",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_18.svg"),
        name: "北交",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_19.svg"),
        name: "南交",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_20.svg"),
        name: "莉莉絲",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_21.svg"),
        name: "福點",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_22.svg"),
        name: "宿命",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_23.svg"),
        name: "東昇",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_24.svg"),
        name: "下降",
        img: "",
    },
    {
        imageUrl: require("@/assets/Line/astral/astral_25.svg"),
        name: "天底",
        img: "",
    },
]
<template>
  <div class="moondayCreate">
    <h1 class="moondayCreate__title hidden-mobile-down">
      <!-- <img src="@/assets/月亮日記/月亮日記標題圖片.svg" alt="" /> -->
      <span>{{ nowMode === "MoondayEdit" ? "編輯" : "建立" }}</span>
      月亮日記
    </h1>
    <div class="moondayCreate__box">
      <div class="moondayCreate__box__title hidden-mobile-down">
        來寫寫你的感受或願望吧 :）
      </div>
      <div class="moondayCreate__box__subtitle">
        <div
          class="time"
          :class="{ editable: nowMode === 'MoondayCreate' }"
          @click="dateModal = true"
          v-if="nowMode === 'MoondayCreate'"
        >
          {{ `${editDate} ${editTime}` }}
          <img src="../../assets/月亮日記/金字編輯icon.svg" alt="" />
        </div>
        <!-- 編輯模式不可改時間 -->
        <div class="time" v-else>
          {{ `【${editDate} ${editTime}】` }}
        </div>
        <!-- <VueCtkDateTimePicker v-model="source_time" /> -->
        <v-dialog ref="dialog" v-model="dateModal" width="290px" persistent>
          <!-- step1.選擇日期 -->
          <v-date-picker
            class="picker date-picker"
            v-show="dateModal && selectDateTimeStep == 1"
            v-model="temp_date"
            :first-day-of-week="0"
            locale="zh-cn"
            scrollable
            header-color="rgba(217, 217, 217, 0.25)"
            color="primary"
            max="2200-12-31"
            min="1910-01-01"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="dateModal = false">取消</v-btn>
            <v-btn
              text
              color="error"
              @click="selectDateTimeStep = 2"
              :disabled="new Date(temp_date) == 'Invalid Date'"
              >時間</v-btn
            >
          </v-date-picker>
          <!-- step2.選擇時間 -->
          <v-time-picker
            class="picker time-picker"
            v-show="dateModal && selectDateTimeStep == 2"
            v-model="temp_time"
            scrollable
            ampm-in-title
            header-color="rgba(217, 217, 217, 0.25)"
            color="primary"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="selectDateTimeStep = 1"
              >日期</v-btn
            >
            <v-btn text color="error" @click="saveDateTime()">確認</v-btn>
          </v-time-picker>
        </v-dialog>

        是
        <span class="highline">
          【月亮{{ MoondayEdit.Constellation_CN }}日】</span
        >
        <span class="hidden-mobile-down">，</span>
        <span>寫下你在這天的觀察</span>
      </div>
      <!-- 這天寫過了 -->
      <div class="moondayCreate__box__notice" v-if="showAlreadyWriteNotice">
        <img src="../../assets/月亮日記/紅底驚嘆號.svg" alt="" />
        <div>
          你已經寫過
          <span>月亮{{ MoondayEdit.Constellation_CN }}日 ({{ editDate }})</span>
          的日記，已經無法再新增。請選擇其它日期來建立日記喔！
        </div>
      </div>
      <div class="moondayCreate__box__line hidden-mobile-down"></div>
      <div class="moondayCreate__box__input">
        <div class="moondayCreate__box__input__title">你的心情如何呢</div>
        <div class="moondayCreate__box__input__selectEmotion">
          <div
            class="moondayCreate__box__input__selectEmotion__item"
            v-for="item in emotionList"
            :key="item.id"
          >
            <div
              class="moondayCreate__box__input__selectEmotion__item__pic"
              :class="{ active: splitMoods.includes(item.id) }"
              :style="`background-color:${
                splitMoods.includes(item.id) ? item.color : ''
              } `"
              @click="moodsHandler(item.id)"
            >
              <img
                :src="item.active_icon"
                v-if="splitMoods.includes(item.id)"
              />
              <img :src="item.normal_icon" v-else />
            </div>
            <div class="moondayCreate__box__input__selectEmotion__item__label">
              {{ item.label }}
            </div>
          </div>
        </div>
        <textarea
          class="detail area1"
          cols="30"
          v-model="MoodContent"
          placeholder="來寫下今天的心情吧 :)"
          maxlength="50"
        ></textarea>
        <span>上限為 50 個字 ({{ MoodContent.length }}/50)</span>
      </div>
      <div class="moondayCreate__box__input">
        <div class="moondayCreate__box__input__title">寫下你的月亮日記</div>
        <textarea
          class="detail area2"
          cols="30"
          v-model="Content"
          placeholder="可以寫下你的月亮日記，或新月祈願時許下你的願望"
          maxlength="500"
        ></textarea>
        <span>上限為 500 個字 ({{ Content.length }}/500)</span>
      </div>
      <div class="moondayCreate__box__notice">
        <img src="@/assets/提示燈泡.svg" alt="" />
        <div>
          月亮每兩天半經過一個星座。特別提醒，觀察、記錄月亮日時，切勿鑽牛角尖，請用更寬闊的心體會喔。加油！持之以恆三個月以上。相信你將透過月亮日觀察，生活大小事的安排上都能「趨吉避凶」。
        </div>
      </div>
      <div class="moondayCreate__box__btn">
        <div class="moondayCreate__box__btn__cancel" @click="cancel">取消</div>
        <div
          class="moondayCreate__box__btn__ok"
          :class="{
            disable: showAlreadyWriteNotice,
          }"
          @click="save"
        >
          {{ nowMode === "MoondayEdit" ? "儲存" : "建立" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaiwanList from "@/js/taiwanList.json";
import emotionList from "@/js/emotionList.js";
export default {
  data() {
    return {
      emotionList,
      dateModal: false,
      selectDateTimeStep: 1,
      temp_date: this.$store.getters.MoondayEdit.Date,
      temp_time: this.$store.getters.MoondayEdit.Time,
      TcAstralInfo: null,
      showAlreadyWriteNotice: false,
    };
  },
  async created() {
    if (!this.MoondayEdit.Constellation)
      return this.$router.replace("/moonday-list");
    await this.checkAleardyWrite();
  },
  computed: {
    nowUserData() {
      return this.$store.state.nowUserData;
    },
    MoondayEdit() {
      return this.$store.getters.MoondayEdit || "重整";
    },
    nowMode() {
      return this.$route.name == "MoondayEdit"
        ? "MoondayEdit"
        : "MoondayCreate";
    },
    Content: {
      get() {
        return this.$store.getters.MoondayEdit.Content;
      },
      set(val) {
        this.$store.commit("setMoondayEdit", {
          Content: val,
        });
      },
    },
    Moods: {
      get() {
        return this.$store.getters.MoondayEdit.Moods || "";
      },
      set(val) {
        this.$store.commit("setMoondayEdit", {
          Moods: val,
        });
      },
    },
    splitMoods() {
      return this.Moods ? this.Moods.split("|") : [];
    },
    MoodContent: {
      get() {
        return this.$store.getters.MoondayEdit.MoodContent;
      },
      set(val) {
        this.$store.commit("setMoondayEdit", {
          MoodContent: val,
        });
      },
    },
    editDate: {
      get() {
        return this.$store.getters.MoondayEdit.Date;
      },
      set(val) {
        this.$store.commit("setMoondayEdit", {
          Date: val,
        });
      },
    },
    editTime: {
      get() {
        return this.$store.getters.MoondayEdit.Time || "00:00";
      },
      set(val) {
        this.$store.commit("setMoondayEdit", {
          Time: val,
        });
      },
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async save() {
      if (!this.Content || !this.MoodContent || !this.Moods) {
        this.$messagePopup(
          "您還有未填寫的內容喔",
          "再檢查一下，看有沒有確實選好「心情圖示」，並完成「心情」與「月亮日記」的記錄喔～",
          "瞭解"
        );
        return;
      }
      if (this.nowMode === "MoondayCreate") {
        delete this.MoondayEdit.MoonNoteID;

        if (await this.checkAleardyWrite()) {
          this.$store.commit("setShowAlreadyWrite", true);
        } else {
          this.$API
            .POST_MoonDayNotes(this.MoondayEdit)
            .then(({ data }) => {
              this.$store.commit("update_toast", ["已成功建立月亮日記"]);
              this.$router.push("/moonday-list");
            })
            .catch(() => {
              this.$messagePopup("建立失敗", "請稍後再試", "了解", () =>
                this.$router.go(-1)
              );
            });
        }
      } else {
        this.$API
          .PUT_MoonDayNotes(this.MoondayEdit.MoonNoteID, {
            Content: this.MoondayEdit.Content,
            MoodContent: this.MoondayEdit.MoodContent,
            Moods: this.MoondayEdit.Moods,
          })
          .then(({ data }) => {
            this.$store.commit("update_toast", ["已成功編輯月亮日記"]);

            this.$router.push("/moonday-list");
          })
          .catch(() => {
            this.$messagePopup("儲存失敗", "請稍後再試", "了解", () =>
              this.$router.go(-1)
            );
          });
      }
    },
    async checkAleardyWrite() {
      if (this.nowMode === "MoondayEdit") return false;
      let res = await this.$API.GET_MoonDayNoteCheck({
        Date: this.MoondayEdit.Date,
        Constellation: this.MoondayEdit.Constellation,
      });
      if (res.data.Data) {
        this.showAlreadyWriteNotice = true;
      } else {
        this.showAlreadyWriteNotice = false;
      }
      return res.data.Data ? true : false;
    },
    saveDateTime() {
      this.editDate = this.temp_date;
      this.editTime = this.temp_time;
      this.dateModal = false;
      this.selectDateTimeStep = 1;
      this.getNowTcAstralInfo();
    },
    //以下髒code是為了滿足月亮日記的需求= =
    async getNowTcAstralInfo() {
      let Latitude = null;
      let Longitude = null;
      let TimeZone = 8;
      let format_time = `${this.editDate} ${this.editTime}`;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // parseFloat(Longitude).toFixed(2)
            Latitude =
              position.coords.latitude > 0
                ? parseFloat(position.coords.latitude).toFixed(2) + "N"
                : parseFloat(position.coords.latitude).toFixed(2) + "S";
            Longitude =
              position.coords.longitude > 0
                ? parseFloat(position.coords.longitude).toFixed(2) + "E"
                : parseFloat(position.coords.longitude).toFixed(2) + "W";
            TimeZone = 0 - new Date().getTimezoneOffset() / 60;
          },
          (error) => {
            alert(
              "因「月亮日記」需要取得當前位置才能更精準的計算，請於瀏覽器彈窗選擇「允許/確定由本服務使用您的位置資訊」，若仍有問題請至您的裝置定位功能中開啟瀏覽器及Line的位置存取權。"
            );
          }
        );
      } else {
        alert(
          "提醒您，目前所使用的瀏覽器不支援取得當前座標地點，因此將無法正常使用「月亮日記」，若要使用此服務請換其他瀏覽器操作看看喔。"
        );
      }
      let Arg = {
        AstrolabeType: "SC",
        AstrolabeFileId: this.nowUserData.AstrolabeFileId,
        BirthTime: format_time,
        IsDaylight: this.nowUserData.IsDaylight,
        BirthLocation: {
          Place: this.findPlaceEngName(this.nowUserData.BirthPlace),
          Country: this.nowUserData.BirthCountry.split("(")[0],
          State: this.nowUserData.BirthState,
          City: this.nowUserData.BirthCity,
          CountryCode: this.findCountryCode,
          TimeZone,
          Latitude,
          Longitude,
        },
      };
      let res = await this.$API.POST_TcAstralInfo(Arg);
      if (res.Data.Result) {
        this.TcAstralInfo = res.Data.AstralInfo;
        this.getParams();
      } else {
        alert("國家地點找不到");
      }
    },
    findPlaceEngName(ChineseName) {
      let engName = TaiwanList.find((item) => {
        return item.text == ChineseName;
      });
      if (!engName) alert("找無該區");
      return engName ? engName.value : "Taipei";
    },
    //取得參數(需呼叫因為要刷新詳細參數內容、表格)
    async getParams() {
      let DetailNeed = this.$store.state["TimeLine"].mainPlanetList.concat(
        this.$store.state["TimeLine"].planetList
      );
      let detailNeed_fixed = [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];

      this.$API
        .Get_AstrolabeParams({
          SetupConfigs: detailNeed_fixed,
          AstralInfo: this.TcAstralInfo,
          AstrolabeType: "SC",
        })
        .then(({ Data }) => {
          let astro = this.$Astro.find(
            (item) => item.cn === Data.ConstellationNodes[1].Constellation
          );

          this.$store.commit("setMoondayEdit", {
            Constellation: astro.en,
            Constellation_CN: Data.ConstellationNodes[1].Constellation,
          });
          this.checkAleardyWrite();
        });
    },
    moodsHandler(id) {
      let moodsArray = this.Moods ? this.Moods.split("|") : [];
      if (moodsArray.includes(id)) {
        moodsArray = moodsArray.filter((item) => item && item !== id);
      } else {
        moodsArray.push(id);
      }
      this.Moods = moodsArray.join("|");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixin.scss";
.moondayCreate {
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  @include mobile {
    width: 100%;
    margin: auto;
  }
  &__title {
    width: 100%;
    font-weight: 500;
    font-size: 40px;
    line-height: 58px;
    color: $theme-color-2;
    display: flex;
    align-items: center;
    padding-bottom: 48px;
    img {
      width: 54px;
      margin-right: 16px;
      @include mobile {
        width: 30px;
        margin-right: 5px;
      }
    }
  }
  &__box {
    padding: 32px 60px 36px;
    width: 800px;
    min-height: 916px;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.3), -4px -4px 10px #fff,
      -4px -4px 16px #fff;
    border-radius: 20px;
    @include mobile {
      width: 100%;
      padding: 16px 24px 24px;
      min-height: 700px;
      box-shadow: unset;
    }
    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      color: #ab956c;
      margin-bottom: 12px;
    }
    &__subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #868686;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.72px;
      &__pic {
        display: inline-flex;
      }
      .highline {
        color: #ab956c;
        font-weight: 500;
      }
      .time {
        height: 32px;
        background: #f0f2f4;
        color: $theme-color-2;
        font-size: 16px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.06em;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        margin-right: 4px;
        &.editable {
          box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
          border-radius: 20px;
          width: 190px;
          margin-right: 12px;
          padding: 0 16px;
          @include mobile {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 12px;
          }
        }
        @include mobile {
          font-size: 14px;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 12px;
        }
      }
      @include mobile {
        display: block;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 28px;
        &__pic {
          display: inline-flex;
          height: 24px;
          align-items: center;
          vertical-align: top;
        }
      }
    }
    &__line {
      margin-top: 24px;
      height: 3px;
      background: #f0f2f4;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08) inset,
        3px 3px 4px 0px #fff;
    }
    &__input {
      margin-top: 20px;
      @include mobile {
        margin-top: 16px;
      }
      &__title {
        color: #9b9b9b;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 12px;
        @include mobile {
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 8px;
        }
      }
      //Select Moods
      &__selectEmotion {
        display: flex;
        margin-bottom: 20px;
        max-width: calc(100vw - 48px);
        @include mobile {
        }
        &__item {
          display: flex;
          flex-direction: column;
          margin-right: 24px;
          justify-content: center;
          @include mobile {
            margin-right: min(24px, 5%);
          }
          &__pic {
            flex-shrink: 0;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25),
              -1px -1px 4px 0px #fff, -1px -1px 4px 0px #fff;
            @include center;
            cursor: pointer;

            &.active {
              background-color: #fff;
            }
          }
          &__label {
            font-size: 14px;
            line-height: 28px;
            font-size: 14px;
            color: #868686;
            text-align: center;
            @include mobile {
              margin-top: 4px;
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }

      .detail {
        width: 100%;
        height: 480px;
        border-radius: 8px;
        box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15),
          2px 2px 4px 0 #ffffff;
        background-color: #f0f2f4;
        lighting-color: 28px;
        font-size: 18px;
        letter-spacing: 1.08px;
        color: #17445c;
        padding: 16px;
        resize: none;
        @include mobile {
          height: 439px;
          padding: 12px;
        }
        &::-webkit-scrollbar {
          height: 2px;
          width: 2px;
          transform: translateX(-2px);
          // background: #00f;
        }

        &::-webkit-scrollbar-thumb {
          background: #868686;
        }

        &:focus {
          border: none;
          outline: none;
        }
        &::placeholder {
          color: #2929293a;
        }
      }
      .detail.area1 {
        height: 138px;
        @include mobile {
          height: 195px;
        }
      }
      .detail.area2 {
        height: 480px;
        @include mobile {
          height: 439px;
        }
      }
      span {
        display: flex;
        margin-top: 4px;
        width: 100%;
        justify-content: flex-end;
        color: #9b9b9b;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1.12px;
        @include mobile {
          margin-top: 0px;
        }
      }
    }
    &__notice {
      margin-top: 20px;
      height: auto;
      padding: 26px 24px;
      border: 1px solid #d2d2d2;
      border-radius: 20px 35px 20px 35px;
      color: #868686;
      display: flex;
      align-items: flex-start;
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      span {
        color: $theme-color-2;
      }
      @include mobile {
        align-items: flex-start;
        padding: 16px 12px;
        font-size: 14px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    &__btn {
      margin-top: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        justify-content: space-between;
      }
      &__ok {
        @include btn-default(176px, 56px, 20px);
        font-size: 20px;
        line-height: 30px;
        border: 2px solid rgba(121, 141, 165, 0.3);
        margin-right: 16px;
        text-align: center;
        color: #ffffff;
        margin-left: 15px;
        @include mobile {
          height: 40px;
          font-size: 14px;
          line-height: 21px;
        }
        &.disable {
          background-color: #9b9b9b;
          color: #fff;
          font-weight: 400;
          pointer-events: none;
        }
      }
      &__cancel {
        @include btn-default(176px, 56px, 20px);
        font-size: 20px;
        line-height: 30px;
        transition: 0.2s;
        color: #9b9b9b;
        background: transparent;
        border: 1px solid #9b9b9b;
        &:hover {
          color: #9b9b9b;
          background: transparent;
          border: 1px solid #9b9b9b;
        }
        @include mobile {
          height: 40px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "params" }, [
    _c(
      "div",
      { staticClass: "params__tab" },
      _vm._l(["星位", "宮位", "相位", "科普"], function(type) {
        return _c(
          "div",
          {
            key: type,
            staticClass: "params__tab_item",
            class: { active: _vm.activeParamsTab === type },
            on: {
              click: function($event) {
                return _vm.switchTab(type)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(type) + "\n    ")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "params__content" }, [
      _vm.activeParamsTab == "星位"
        ? _c(
            "table",
            { staticClass: "table planet-table" },
            [
              _vm._m(0),
              _vm._l(_vm.ConstellationLists, function(item, index) {
                return _c("tr", { key: index, staticClass: "table__content" }, [
                  _c("td", [
                    _c("span", { staticClass: "item__name__img iconfont" }, [
                      _vm._v(_vm._s(item.img))
                    ]),
                    _vm._v("\n          " + _vm._s(item.Astral) + "\n        ")
                  ]),
                  _c("td", [_vm._v(_vm._s(item.Constellation) + "座")]),
                  _c("td", [
                    _vm._v(
                      "\n          " + _vm._s(item.Angle) + "\n\n          "
                    ),
                    index < 10
                      ? _c("span", { staticClass: "retrograde" }, [
                          _vm._v(_vm._s(item.Retrograde))
                        ])
                      : _vm._e()
                  ]),
                  _c("td", [
                    _vm._v("\n          " + _vm._s(item.House) + "\n        ")
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.activeParamsTab == "宮位"
        ? _c(
            "table",
            { staticClass: "table palace-table" },
            [
              _vm._m(1),
              _vm._l(_vm.palaces, function(item, index) {
                return _c("tr", { key: index, staticClass: "table__content" }, [
                  _c("td", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v(_vm._s(item.House))
                  ]),
                  _c("td", [_vm._v(_vm._s(item.Constellation))]),
                  _c("td", [_vm._v(_vm._s(item.MainAstral))]),
                  _c("td", [_vm._v(_vm._s(item.FlyInto1))]),
                  _c("td", [_vm._v(_vm._s(item.FlyInto2))])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.activeParamsTab == "相位"
        ? _c(
            "table",
            { staticClass: "table aspect-table" },
            [
              _vm._m(2),
              _vm._l(_vm.AspectLists, function(item, index) {
                return _c("tr", { key: index, staticClass: "table__content" }, [
                  _c("td", [
                    _c(
                      "span",
                      {
                        staticClass: "item__name__img iconfont",
                        style: {
                          color:
                            item.TcAstral.indexOf("王") > -1
                              ? "#e99a00"
                              : "#27aba3"
                        }
                      },
                      [_vm._v(_vm._s(_vm.getPlantImg(item.TcAstral)))]
                    ),
                    _vm._v(
                      "\n          " + _vm._s(item.TcAstral) + "\n        "
                    )
                  ]),
                  _c("td", [
                    _c("img", {
                      attrs: { src: _vm.phaseIcon[item.Aspect].icon, alt: "" }
                    })
                  ]),
                  _c("td", [
                    _c(
                      "span",
                      {
                        staticClass: "item__name__img iconfont",
                        style: {
                          color:
                            item.BcAstral.indexOf("王") > -1
                              ? "#e99a00"
                              : "#27aba3"
                        }
                      },
                      [_vm._v(_vm._s(item.img))]
                    ),
                    _vm._v(
                      "\n          " + _vm._s(item.BcAstral) + "\n        "
                    )
                  ]),
                  _c("td", [_vm._v(_vm._s(item.Way))]),
                  _c("td", [_vm._v(_vm._s(item.Degree))])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.activeParamsTab == "科普"
        ? _c("table", { staticClass: "table palace-table introduction" }, [
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
            _vm._m(6),
            _vm._m(7),
            _vm._m(8),
            _vm._m(9),
            _vm._m(10)
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("星體")])]),
      _c("td", [_c("span", [_vm._v("落入星座")])]),
      _c("td", [_c("span", [_vm._v("度數")])]),
      _c("td", { attrs: { colspan: "2" } }, [_c("span", [_vm._v("落入宮位")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("宮位")])]),
      _c("td", [_c("span", [_vm._v("星座")])]),
      _c("td", [_c("span", [_vm._v("宮位守護星")])]),
      _c("td", { attrs: { colspan: "2" } }, [_c("span", [_vm._v("飛入")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("行星")])]),
      _c("td", [_c("span", [_vm._v("相位")])]),
      _c("td", [_c("span", [_vm._v("本命")])]),
      _c("td", [_c("span", [_vm._v("方向")])]),
      _c("td", [_c("span", [_vm._v("容許度")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("線條顏色")])]),
      _c("td", [_c("span", [_vm._v("符號")])]),
      _c("td", [_c("span", [_vm._v("角度")])]),
      _c("td", [_c("span", [_vm._v("相位")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#ffd306" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/合相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("0°")]),
      _c("td", [_vm._v("合相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#f00" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/對分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("180°")]),
      _c("td", [_vm._v("對分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#00f" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/三分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("120°")]),
      _c("td", [_vm._v("三分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#f00" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/四分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("90°")]),
      _c("td", [_vm._v("四分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#00f" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/六分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("60°")]),
      _c("td", [_vm._v("六分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#5def60" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: {
            src: require("../../assets/相位icon/八分之三相.svg"),
            alt: ""
          }
        })
      ]),
      _c("td", [_vm._v("135°")]),
      _c("td", [_vm._v("八分之三相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#625b57" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: {
            src: require("../../assets/相位icon/十二分之五相.svg"),
            alt: ""
          }
        })
      ]),
      _c("td", [_vm._v("150°")]),
      _c("td", [_vm._v("十二分之五相")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
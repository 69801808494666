<template>
  <div class="newMoonCTA" :class="{ isCollapse }">
    <div class="newMoonCTA__toNewMoon" @click="toNewMoon()"></div>
    <div class="newMoonCTA__collapseBtn" @click="collapseHandler(true)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2484 13.3906L0.648438 2.19063L2.16695 0.889043L11.0077 11.2032L19.8484 0.889044L21.3669 2.19063L11.767 13.3906L10.2484 13.3906Z"
          fill="#AB956C"
        />
      </svg>
    </div>
    <div class="newMoonCTA__mini" @click="collapseHandler(false)"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    toNewMoon() {
      // this.$store.commit("setShowNewMoonNotice", false);
      this.$router.push({ name: "NewMoon" });
    },
    collapseHandler(value) {
      this.isCollapse = value;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.newMoonCTA {
  position: fixed;
  right: 112px;
  bottom: 92px;
  width: 200px;
  height: 205px;
  z-index: 998;
  background: url("../../assets/月亮日記/新月祈願_desk.svg") no-repeat center
    center / cover;
  @include mobile {
    right: 20px;
    bottom: 63px;
    width: 148px;
    height: 163px;
  }
  &__toNewMoon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 61px;
    cursor: pointer;
    @include mobile {
      height: 48px;
    }
  }
  &__collapseBtn {
    position: absolute;
    right: 0;
    bottom: -56px;
    width: 40px;
    height: 40px;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    @include center;
    @include mobile {
      width: 36px;
      height: 36px;
      bottom: -44px;
    }
  }
  &__mini {
    width: 100%;
    height: 100%;
    display: none;
  }

  &.isCollapse {
    width: 93px;
    height: 90px;
    background: url("../../assets/月亮日記/新月祈願縮小.svg") no-repeat center
      center / cover;
    @include pad {
      width: 66px;
      height: 62.422px;
    }
    .newMoonCTA__toNewMoon {
      display: none;
    }
    .newMoonCTA__collapseBtn {
      display: none;
    }
    .newMoonCTA__mini {
      display: block;
      cursor: pointer;
    }
  }
}
</style>

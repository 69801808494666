import axios from "axios";
import Base from "@/api/_base";
import store from "@/store";

let { ErrorHandle, TimeoutHandler } = Base;

let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
let urlencoded_config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export default {
  //Line 登入驗證
  Line_LoginAuth: (Arg) => {
    return new Promise(function (resolve, reject) {
      let Config = JSON.parse(JSON.stringify(urlencoded_config));
      let url = "https://api.line.me/oauth2/v2.1/token";
      axios
        .post(url, Arg, Config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          ErrorHandle();
          reject(err.data);
        });
    });
  },
  //line登入後取得user資料(不用)
  Get_Line_data: () => {
    return new Promise(function (resolve, reject) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      let url = "https://api.line.me/v2/profile";
      Config.headers.Authorization = "Bearer " + localStorage.LT;
      axios
        .get(url, Config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          ErrorHandle();
          reject(err.data);
        });
    });
  },
  Get_Google_data: (token) => {
    return new Promise((resolve, reject) => {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      let url = "https://oauth2.googleapis.com/tokeninfo?id_token=" + token;
      axios
        .get(url, Config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          ErrorHandle();
          reject(err.data);
        });
    });
  },
};

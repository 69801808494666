import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Index.vue";
import privacyPolicy from "./views/PrivacyPolicy.vue";
import astrolabe from "./views/Astrolabe.vue";
import timelineAstrolabe from "./views/推運功能/index.vue";
import Astrometry from "./views/天象星盤/index.vue";
import Store from "./views/占星小舖/Store.vue";
import MoondayCreate from "./views/月亮日記/MoondayCreate.vue";

import store from "./store";

const getToken = () => {
  if (window.localStorage.getItem("login_status")) {
    return true;
  } else {
    return false;
  }
};

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: Home,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: privacyPolicy,
      meta: {
        auth: false,
        needDate: false,
        pageName: "",
      },
    },
    {
      path: "/astrolabe",
      name: "astrolabe",
      meta: {
        auth: true,
        needDate: true,
        pageName: "個人星盤",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: astrolabe,
    },
    //TODO:雙人合盤
    {
      path: "/SynastryMain/:SynastryId",
      name: "SynastryMain",
      meta: {
        auth: true,
        needDate: true,
        pageName: "雙人合盤",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: () => import("./views/雙人合盤/SynastryMain.vue"),
    },
    // TODO:新雙人合盤購買
    {
      path: "/Synastry",
      name: "Synastry",
      meta: {
        auth: false,
        needDate: false,
        pageName: "合盤設定",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: false, //true 記得改
      },
      component: () => import("./views/雙人合盤/Synastry.vue"),
    },
    {
      path: "/ListDBL",
      name: "ListDBL",
      meta: {
        auth: true,
        needDate: true,
        pageName: "雙人合盤",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: () => import("./views/雙人合盤/ListDBL.vue"),
    },
    //TODO:推運功能
    {
      path: "/timelineAstrolabe",
      name: "TimelineAstrolabe",
      meta: {
        auth: true,
        needDate: true,
        pageName: "推運功能",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: timelineAstrolabe,
    },
    //TODO:天象星盤
    {
      path: "/astrometry",
      name: "Astrometry",
      meta: {
        auth: true,
        needDate: true,
        pageName: "天象星盤",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: Astrometry,
    },
    //TODO:月亮日記
    {
      path: "/moonday-create",
      name: "MoondayCreate",
      meta: {
        auth: true,
        needDate: true,
        pageName: "建立月亮日記",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: MoondayCreate,
    },
    {
      path: "/moonday-edit",
      name: "MoondayEdit",
      meta: {
        auth: true,
        needDate: true,
        pageName: "編輯月亮日記",
        logoutDirectIndexPage: "/",
      },
      component: MoondayCreate,
    },
    {
      path: "/moonday-list",
      name: "MoondayList",
      meta: {
        auth: true,
        needDate: true,
        pageName: "月亮日記列表",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: () => import("./views/月亮日記/MoondayList.vue"),
    },
    {
      path: "/moonday-note/:MoonNoteID",
      name: "MoondayNote",
      meta: {
        auth: true,
        needDate: true,
        pageName: "月亮日記",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/月亮日記/MoondayNote.vue"),
    },
    {
      path: "/moonday-newmoon",
      name: "NewMoon",
      meta: {
        auth: true,
        pageName: "月亮日記",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/月亮日記/NewMoon.vue"),
    },
    // --------------------------------$運勢書2024--------------------------------
    {
      path: "/book2024",
      name: "Book2024",
      meta: {
        auth: false,
        pageName: "",
        logoutDirectIndexPage: "/book2024",
      },
      component: () => import("./views/運勢書2024/Book.vue"),
    },
    {
      path: "/book2024-content/:id",
      redirect: "/book2024-content/:id/trend",
    },
    {
      path: "/book2024-content/:id/trend",
      name: "Book2024ContentTrend",
      meta: {
        auth: true,
        pageName: "",
        logoutDirectIndexPage: "/book2024",
        canEnterAfterLogin: false,
      },
      component: () => import("./views/運勢書2024/BookContent.vue"),
    },
    {
      path: "/book2024-content/:id/past",
      name: "Book2024ContentPast",
      meta: {
        auth: true,
        pageName: "",
        logoutDirectIndexPage: "/book2024",
        canEnterAfterLogin: false,
      },
      component: () => import("./views/運勢書2024/BookContent.vue"),
    },
    {
      path: "/book2024-content/:id/future",
      name: "Book2024ContentFuture",
      meta: {
        auth: true,
        pageName: "",
        logoutDirectIndexPage: "/book2024",
        canEnterAfterLogin: false,
      },
      component: () => import("./views/運勢書2024/BookContent.vue"),
    },
    {
      path: "/book2024-content/:id/improve",
      name: "Book2024ContentImprove",
      meta: {
        auth: true,
        pageName: "",
        logoutDirectIndexPage: "/book2024",
        canEnterAfterLogin: false,
      },
      component: () => import("./views/運勢書2024/BookContent.vue"),
    },
    {
      path: "/book2024/unlock",
      name: "Book2024UnlockList",
      meta: {
        auth: false,
        pageName: "解鎖星座",
        logoutDirectIndexPage: "/book2024",
      },
      component: () => import("./views/運勢書2024/UnlockList.vue"),
    },
    {
      path: "/book2024/my",
      name: "Book2024My",
      meta: {
        auth: false,
        pageName: "我的解答之書",
        logoutDirectIndexPage: "/book2024",
      },
      component: () => import("./views/運勢書2024/UnlockList.vue"),
    },
    {
      path: "/book2024/unlock/:id",
      name: "Book2024UnlockSingle",
      meta: {
        auth: false,
        pageName: "商品介紹",
        logoutDirectIndexPage: "/book2024",
      },
      component: () => import("./views/運勢書2024/UnlockSingle.vue"),
    },
    // --------------------------------$運勢書2024--------------------------------
    {
      path: "/book2025/astroCalendar",
      name: "Book2025AstroCalendar",
      meta: {
        auth: false,
        pageName: "",
        logoutDirectIndexPage: "/book2024",
      },
      component: () => import("./views/運勢書2025/AstroCalendar.vue"),
    },
    //TODO:占星小鋪
    {
      path: "/store",
      name: "store",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: Store,
    },
    {
      path: "/store/detail/:id",
      name: "detail",
      meta: {
        auth: true,
        needDate: false,
        pageName: "商品資訊",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/占星小舖/Detail.vue"),
    },
    {
      path: "/store/selectTarget/", //選擇解鎖星盤
      name: "selectTarget",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/占星小舖/SelectTarget.vue"),
    },
    {
      path: "/store/tobuy/", //確認購買
      name: "gotobuy",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/占星小舖/GoToBuy.vue"),
    },
    //TODO:訂單
    {
      path: "/order/checkInfo/", //確認資訊
      name: "orderCheckInfo",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/訂單流程/1.CheckInfo.vue"),
    },
    {
      path: "/order/method/", //選擇方式
      name: "orderMethod",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/訂單流程/2.Method.vue"),
    },
    {
      path: "/order/result/:id",
      name: "orderResult",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/訂單流程/4.Result.vue"),
    },
    //TODO:會員相關
    {
      path: "/member-create",
      name: "member-create",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/MemberCreate.vue"),
    },
    {
      path: "/member",
      name: "member",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/Member.vue"),
    },
    {
      path: "/member/record",
      name: "member-record",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/會員相關/Record.vue"),
    },
    {
      path: "/member/detail/:orderId",
      name: "member-detail",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/會員相關/Detail.vue"),
    },
    {
      path: "/member/importList",
      name: "member-importList",
      meta: {
        auth: true,
        needDate: false,
        pageName: "平台可歸戶帳號",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/會員相關/ImportList.vue"),
    },
    {
      path: "/member-edit",
      name: "member-edit",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/MemberEdit.vue"),
    },
    {
      path: "/member/giftBox",
      name: "member-giftBox",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/會員相關/GiftBox.vue"),
    },
    //好友列表(新)
    {
      path: "/friendList",
      name: "friendList",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
        canEnterAfterLogin: true,
      },
      component: () => import("./views/會員相關/FriendList.vue"),
    },
    {
      path: "/friend-create",
      name: "friend-create",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/FriendCreatePage.vue"),
    },
    {
      path: "/friend-edit",
      name: "friend-edit",
      meta: {
        auth: true,
        needDate: false,
        pageName: "",
        logoutDirectIndexPage: "/",
      },
      component: () => import("./views/FriendEdit.vue"),
    },
    {
      path: "/iconfont",
      name: "iconfont",
      meta: {
        auth: false,
        needDate: false,
        pageName: "",
      },
      component: () => import("./views/Iconfont.vue"),
    },
    //問題回報表單
    {
      path: "/report",
      name: "report",
      meta: {
        auth: false,
        needDate: false,
        pageName: "",
      },
      component: () => import("./views/Report.vue"),
    },
    {
      path: "*",
      name: "404",
      component: () => import("./views/404.vue"),
    },
  ],
});
router.beforeResolve((to, from, next) => {
  if (to.meta.auth) {
    // 需要登入才可進入
    if (!getToken()) {
      if (!from.name) {
        window.location.href =
          to.meta.logoutDirectIndexPage || window.location.origin;
        return false;
      }
      store.commit("setLoginDialogStatus", true);
      if (to.meta.canEnterAfterLogin) {
        window.localStorage.setItem("directionPath", to.fullPath);
      } else {
        //登入後留在原地
        window.localStorage.setItem("directionPath", from.fullPath);
      }
      return false;
    }
  }
  next();
});

export default router;

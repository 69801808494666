<template>
  <div>
    <section
      class="row-wrap custom-container"
      v-if="nowUserData && nowUserAstralInfo"
    >
      <div class="detail">
        <div class="detail__tab">
          <div
            class="detail__tab__item"
            :class="{ activeDetailTab: activeDetailTab == 'interpret' }"
            @click="activeDetailTab = 'interpret'"
          >
            星盤解讀
          </div>
          <div
            class="detail__tab__item"
            :class="{ activeDetailTab: activeDetailTab == 'params' }"
            @click="activeDetailTab = 'params'"
          >
            詳細參數
          </div>
        </div>
        <!--星盤解讀-->
        <div class="interpret" v-if="activeDetailTab == 'interpret'">
          <div class="interpret__tab">
            <transition name="fade">
              <div
                class="interpret__notice"
                v-show="interpretTabNotice == true"
              >
                <img src="@/assets/icon_swipe.svg" alt />
                <span>左右滑動選擇解讀</span>
              </div>
            </transition>
            <!-- swiper -->
            <swiper id="sw" :options="swiperInterpret" style="padding: 5px 5px">
              <!-- 順序: 上升、太陽、月亮、水星、金星、火星、木星、土星、天王星、海王星、冥王星、天頂 -->
              <swiper-slide
                v-for="astro in interpretData"
                :key="astro.id"
                class="interpret__tab__item"
                @click.native="changeInterpret(astro.name)"
                :class="{
                  activeInterpretTab: astro.name === NowInterpret.name,
                }"
              >
                <span class="iconfont interpret__icon">{{ astro.img }}</span>
                <span class="interpret__text">{{ astro.name }}</span>
              </swiper-slide>
            </swiper>
            <!-- swiper end -->
          </div>
          <!-- 星座解讀 -->
          <div
            class="interpret__star"
            @click="openStar = !openStar"
            :class="{ openStar: openStar }"
          >
            <div class="interpret__star__title">
              {{ NowInterpret.name }}
              <img class="interpret__star__img" :src="NowInterpret.img1" alt />
              {{ NowInterpret.Title2 }}
              <div class="interpret__star__degree">
                {{ NowInterpret.Angle1 }}
                <br />
                <span style="transform: translateX(-1px)">{{
                  NowInterpret.Angle2
                }}</span>
              </div>
            </div>
            <div class="interpret__star__openText">點擊展開解讀</div>
            <img
              class="interpret__star__openImg"
              src="@/assets/icon_downward.svg"
            />
            <!-- <div class="interpret__star__house">{{ NowInterpret.House}}</div> -->
            <div class="interpret__line__new"></div>
            <div class="interpret__star__subtitle">
              <!-- <span class="subtitle__line"></span> -->
              <span class="subtitle__text">{{ NowInterpret.SubTitle }}</span>
              <!-- <span class="subtitle__line"></span> -->
            </div>
            <div
              class="interpret__star__content"
              v-html="NowInterpret.Content"
            ></div>
          </div>
          <!-- 宮位解讀 -->
          <div
            class="interpret__house"
            @click="openHouse = !openHouse"
            :class="{ openHouse: openHouse }"
            v-if="NowInterpret.HouseContent"
          >
            <div class="interpret__house__title">宮位解讀</div>
            <div class="interpret__house__house">{{ NowInterpret.House }}</div>
            <!-- 有花錢解鎖 -->
            <div v-if="$store.state.nowUserData.IsUnlockBC">
              <div
                class="interpret__house__subtitle"
                v-html="NowInterpret.HouseTitle"
              ></div>
              <img
                class="interpret__house__img"
                src="@/assets/icon_downward.svg"
              />
              <div
                class="interpret__house__content"
                v-html="NowInterpret.HouseContent"
                :class="{ 'text-center': NowInterpret.name == '上升' }"
              ></div>
            </div>
            <!-- 等待解鎖中 -->
            <div v-else>
              <div class="toUnlock_cta" @click="toStore('BC')">
                <img src="../assets/locked.svg" alt="" />
                點此解鎖
              </div>
            </div>
          </div>
          <!-- 行運解讀 -->
          <!-- <div
            class="interpret__house mt-6"
            v-if="!$store.state.userData.IsUnlockLC"
          >
            <div class="interpret__house__title">推運功能</div>
            <div class="interpret__house__house">{{ NowInterpret.House }}</div>
           
            <div>
              <div class="toUnlock_cta" @click="toStore('LC')">
                <img src="../assets/locked.svg" alt="" />
                點此解鎖
              </div>
            </div>
          </div> -->
        </div>
        <!--詳細參數-->
        <div class="params" v-if="activeDetailTab == 'params'">
          <div class="params__tab">
            <div
              class="params__tab_item mt-4"
              :class="{ active: activeParamsTab === type }"
              v-for="type in ['星位', '宮位', '相位', '統計']"
              :key="type"
              @click="switchTab(type)"
            >
              {{ type }}
            </div>
          </div>
          <div class="params__content">
            <!--1.星位-->
            <table class="table planet-table" v-if="activeParamsTab == '星位'">
              <tr class="table__title">
                <td><span>星體</span></td>
                <td><span>落入星座</span></td>
                <td><span>度數</span></td>
                <td colspan="2"><span>落入宮位</span></td>
              </tr>
              <tr
                v-for="(item, index) in constellationNodes"
                class="table__content"
                :key="index"
              >
                <td>
                  <!-- <img :src="item.img" alt /> -->
                  <span
                    class="item__name__img iconfont"
                    :style="{
                      color:
                        item.Astral.indexOf('王') > -1 ? '#e99a00' : '#27aba3',
                    }"
                    >{{ item.img }}</span
                  >
                  {{ item.Astral }}
                </td>
                <td>{{ item.Constellation }}座</td>
                <td>
                  {{ item.Angle }}
                  <span class="retrograde">{{ item.Retrograde }}</span>
                </td>
                <td>
                  {{ item.House }}
                </td>
              </tr>
            </table>
            <!--2.宮位-->
            <table class="table palace-table" v-if="activeParamsTab == '宮位'">
              <tr class="table__title">
                <td><span>宮位</span></td>
                <td><span>星座</span></td>
                <td><span>宮位守護星</span></td>
                <td colspan="2"><span>飛入</span></td>
              </tr>
              <tr
                v-for="(item, index) in palaces"
                class="table__content"
                :key="index"
              >
                <td style="font-size: 12px">{{ item.House }}</td>
                <td>{{ item.Constellation }}</td>
                <td>{{ item.MainAstral }}</td>
                <td>{{ item.FlyInto1 }}</td>
                <td>{{ item.FlyInto2 }}</td>
              </tr>
            </table>
            <!--3.相位-->
            <table class="phase-table" v-if="activeParamsTab == '相位'">
              <!--標題-->
              <tr class="table__title">
                <td></td>
                <td v-for="(Rowitem, index) in phases1" :key="index">
                  <div>{{ phases[index] }}</div>
                </td>
              </tr>
              <tr
                v-for="(RowItem, index) in phases1"
                class="table__content"
                :key="index"
              >
                <td>
                  <div>{{ phases[index] }}</div>
                </td>
                <td
                  v-for="(ColItem, index1) in RowItem"
                  :class="phases2[index][index1]"
                  :key="index1"
                  class=""
                >
                  <div
                    v-if="phaseIcon[ColItem]"
                    :tabindex="`${index}_${index1}`"
                    @click="showId = `${index}_${index1}`"
                    @blur="showId = ''"
                    style="cursor: pointer"
                  >
                    <img :src="phaseIcon[ColItem].icon" alt="" />
                    <div
                      class="phaseMean"
                      :id="`${index}_${index1}`"
                      :class="{ show: showId == `${index}_${index1}` }"
                    >
                      {{ phaseIcon[ColItem].deg }}度
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <!--3-1.相位中的科普-->
            <table
              class="table palace-table introduction mt_20"
              style="border-radius: 0px"
              v-if="activeParamsTab == '相位'"
            >
              <tr class="table__title">
                <td><span>線條顏色</span></td>
                <td><span>符號</span></td>
                <td><span>角度</span></td>
                <td><span>相位</span></td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #ffd306"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/合相.svg" alt="" />
                </td>
                <td>0°</td>
                <td>合相</td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #f00"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/對分相.svg" alt="" />
                </td>
                <td>180°</td>
                <td>對分相</td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #00f"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/三分相.svg" alt="" />
                </td>
                <td>120°</td>
                <td>三分相</td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #f00"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/四分相.svg" alt="" />
                </td>
                <td>90°</td>
                <td>四分相</td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #00f"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/六分相.svg" alt="" />
                </td>
                <td>60°</td>
                <td>六分相</td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #5def60"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/八分之三相.svg" alt="" />
                </td>
                <td>135°</td>
                <td>八分之三相</td>
              </tr>
              <tr class="table__content">
                <td>
                  <div
                    class="introduction__line"
                    style="background-color: #625b57"
                  ></div>
                </td>
                <td>
                  <img src="@/assets/相位icon/十二分之五相.svg" alt="" />
                </td>
                <td>150°</td>
                <td>十二分之五相</td>
              </tr>
              <!-- <div class="introduction">
          <div class="introduction__title">相位線條介紹</div>
          <div class="introduction__content">
           <p style="color:#007500">——— 120°</p>
           <p style="color:#0066cc">——— 60°</p>
           <p style="color:#EA0000">——— 90°</p>
           <p style="color:#0000c6">——— 180°</p>
          </div>
        </div> -->
            </table>
            <!--4.統計-->
            <div v-if="activeParamsTab == '統計'">
              <!--格局-->
              <table class="table pattern-table">
                <tr class="table__title">
                  <td><span>格局</span></td>
                  <td><span>詳情</span></td>
                </tr>
                <!--(測試)-->
                <tr class="table__content" v-for="item in P" :key="item.Title">
                  <td style="width: 25%">{{ item.Title }}</td>
                  <td>
                    <span
                      v-for="(item1, index) in item.Content.split('/')"
                      :key="index"
                    >
                      {{ item1 }}
                      <br />
                    </span>
                  </td>
                </tr>
              </table>
              <!--共有三個: 陰陽姓、三大模式、四大元素-->
              <table class="table stats-table">
                <tr class="table__title">
                  <td style="width: 25%"><span>陰陽性</span></td>
                  <td><span>包含星體</span></td>
                </tr>
                <!--(測試)-->
                <tr class="table__content" v-for="item in Y" :key="item.Title">
                  <td>{{ item.Title }}</td>
                  <td>
                    <span
                      v-for="(item1, index) in item.Content.split('/')"
                      :key="index"
                      >{{ item1 }}</span
                    >
                  </td>
                </tr>
              </table>
              <table class="table stats-table">
                <tr class="table__title">
                  <td style="width: 25%"><span>三大模式</span></td>
                  <td><span>包含星體</span></td>
                </tr>
                <!--(測試)-->
                <tr class="table__content" v-for="item in T" :key="item.Title">
                  <td>{{ item.Title }}</td>
                  <td>
                    <span
                      v-for="(item1, index) in item.Content.split('/')"
                      :key="index"
                      >{{ item1 }}</span
                    >
                  </td>
                </tr>
              </table>
              <table class="table stats-table">
                <tr class="table__title">
                  <td style="width: 25%"><span>四大元素</span></td>
                  <td><span>包含星體</span></td>
                </tr>
                <!--(測試)-->
                <tr class="table__content" v-for="item in Q" :key="item.Title">
                  <td>{{ item.Title }}</td>
                  <td>
                    <span
                      v-for="(item1, index) in item.Content.split('/')"
                      :key="index"
                      >{{ item1 }}</span
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--星盤-->
      <div class="content" v-if="nowUserData">
        <div class="contentUser hidden-pad-down">
          <div class="user">
            <div class="user__photo">
              <v-avatar size="58" class="user__photo__img">
                <img :src="nowUserData.ProfilePhotoSrc" />
              </v-avatar>
            </div>

            <!-- 使用者資訊電腦版 -->

            <div class="user__info">
              <div class="user__info__name">
                {{ $NameFormat(nowUserData.Name) }}
              </div>
              <div class="user__info__birth">
                {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }}
                <span class="user__info__birth--space mx_4"> | </span>
                {{
                  `${deFormat(nowUserData.Longitude)}, 
                 ${deFormat(nowUserData.Latitude)}`
                }}
                <img
                  src="@/assets/日光節約icon.svg"
                  v-if="nowUserData.IsDaylight"
                />
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="user__change">
              <img @click="toFriend" src="@/assets/icon_change.svg" alt />
            </div>
          </div>
        </div>
        <div class="astrolabe">
          <!-- //使用者資訊手機版 -->
          <div class="userDesktopInfo mb_16">
            <div class="userDesktopInfo__btnGroup">
              <div
                class="userDesktopInfo__btn"
                @click.stop="userDesktopInfoOpen = !userDesktopInfoOpen"
              >
                <div>{{ $NameFormat(nowUserData.Name) }}</div>
                <div class="ml_3 pt_1">
                  <img
                    src="@/assets/icon_downward.svg"
                    :style="
                      userDesktopInfoOpen ? 'transform:rotate(180deg)' : ''
                    "
                  />
                </div>
              </div>
            </div>

            <div v-show="userDesktopInfoOpen" class="pt_4">
              <div class="userDesktopInfo__place mt_5">
                <img src="@/assets/map-pin.svg" alt="" />
                {{ `${nowUserData.BirthCountry}  ${nowUserData.BirthCity}` }}
              </div>
              <div class="userDesktopInfo__birth mt_1">
                {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }}
                <span class="userDesktopInfo__birth--space mx_4"> | </span>
                {{
                  `${deFormat(nowUserData.Longitude)}, 
                 ${deFormat(nowUserData.Latitude)}`
                }}
                <img
                  src="@/assets/日光節約icon.svg"
                  v-if="nowUserData.IsDaylight"
                />
              </div>
            </div>
          </div>
          <div class="astrolabe__wrap">
            <!-- swiper -->
            <div class="swiper">
              <swiper id="SW" :options="swiperAstrolabe">
                <swiper-slide
                  class="astrolabe__item"
                  v-for="(item, index) in style"
                  :key="item.id"
                >
                  <!-- 提示框 -->
                  <div class="toast toast-s" v-if="canshow[index]">
                    {{ item.displayName }}版
                  </div>
                  <div class="astrolabe__bg" id="astrolabe__bg">
                    <canvasAstrolabe
                      v-if="dataChange"
                      role="Astrolabe"
                      class="canvas-pan"
                      :width="paramPan.width"
                      :configItem="panConfigItem"
                      :panStyle="item.name"
                      :type="paramPan.type"
                      :data1="nowUserAstralInfo.AstralRadians"
                      :data2="paramPan.data2"
                      :Ret1="nowUserAstralInfo.RetAstralSet"
                    ></canvasAstrolabe>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="switch">
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </div>
            </div>
            <!-- swiper end -->

            <div class="astrolabe__setting" @click.stop="openSetup()">
              <img src="@/assets/icon_setting.svg" alt />
            </div>
            <!-- 手機版切換好友 -->
            <div class="user__change-m hidden-pad-up" @click.stop="toFriend()">
              <img src="@/assets/icon_change.svg" alt />
            </div>
          </div>
          <!-- 星盤設置  -->
          <SetAstrolabe
            role="Astrolabe"
            :activeSetup="activeSetup"
            :phasePriority="true"
            @close="activeSetup = false"
            @dataChange="dataChangeHandler($event)"
          />
        </div>
      </div>
    </section>

    <!-- 提示框，兩秒消失 -->
    <transition name="fade">
      <div class="toast2" v-if="showSwitch">已切換成{{ switchName }}的星盤</div>
    </transition>
  </div>
</template>
<script>
import canvasAstrolabe from "@/components/canvas-astrolabe.vue";
import SetAstrolabe from "@/components/SetAstrolabe.vue";
import phaseMixin from "@/mixin/phaseMixin";

export default {
  mixins: [phaseMixin],
  components: {
    canvasAstrolabe,
    SetAstrolabe,
  },
  data() {
    return {
      nowUserAstralInfo: null,
      userDesktopInfoOpen: true,
      showId: "",
      paramPan: {
        width: 520,
        maxWidth: 600,
      }, //星盤所需資料
      screenWidth: document.body.clientWidth,
      items: ["星位", "宮位", "相位", "統計"],
      style: [
        { name: "文字", displayName: "文字", show: false, id: "alert1" },
        { name: "A32", displayName: "標準", show: false, id: "alert2" },
        // { name: "专业", displayName: "專業", show: false, id: "alert3" },
        // { name: "JOE", displayName: "Joe自行開發", show: false, id: "alert4" },
        { name: "JOE2", displayName: "新專業", show: false, id: "alert5" },
        // { name: "JOE3", displayName: "顏色改動", show: false, id: "alert6" },
      ],
      canshow: [false, false, false],
      activeSelect: false,
      NowInterpret: [{ name: "太陽" }],
      activeDetailTab: "interpret",
      activeInterpretTab: false,
      interpretTabNotice: false,
      openHouse: true,
      openStar: true,
      //設置
      dataChange: true,
      showSwitch: false,
      switchName: null,
      activeSetup: false,
      //星座對照
      constellation: [
        {
          name: "牡羊",
          img: require("@/assets/constellations/con_1.svg"),
        },
        {
          name: "金牛",
          img: require("@/assets/constellations/con_2.svg"),
        },
        {
          name: "雙子",
          img: require("@/assets/constellations/con_3.svg"),
        },
        {
          name: "巨蟹",
          img: require("@/assets/constellations/con_4.svg"),
        },
        {
          name: "獅子",
          img: require("@/assets/constellations/con_5.svg"),
        },
        {
          name: "處女",
          img: require("@/assets/constellations/con_6.svg"),
        },
        {
          name: "天秤",
          img: require("@/assets/constellations/con_7.svg"),
        },
        {
          name: "天蠍",
          img: require("@/assets/constellations/con_8.svg"),
        },
        {
          name: "射手",
          img: require("@/assets/constellations/con_9.svg"),
        },
        {
          name: "魔羯",
          img: require("@/assets/constellations/con_10.svg"),
        },
        {
          name: "水瓶",
          img: require("@/assets/constellations/con_11.svg"),
        },
        {
          name: "雙魚",
          img: require("@/assets/constellations/con_12.svg"),
        },
      ],
      //順序: 上升、太陽、月亮、水星、金星、火星、木星、土星、天王星、海王星、冥王星、天頂
      //"","","","","","","","","","","",""
      interpretData: [
        {
          img: "",
          name: "上升",
        },
        {
          img: "",
          name: "太陽",
        },
        {
          img: "",
          name: "月亮",
        },
        {
          img: "",
          name: "水星",
        },
        {
          img: "",
          name: "金星",
        },
        {
          img: "",
          name: "火星",
        },
        {
          img: "",
          name: "木星",
        },
        {
          img: "",
          name: "土星",
        },
        {
          img: "",
          name: "天王",
        },
        {
          img: "",
          name: "海王",
        },
        {
          img: "",
          name: "冥王",
        },
      ],
      //
      planets: require("@/js/planets"),
      planets2: require("@/js/planets2"),

      palaces: [
        {
          House: "1宮(本命宮)",
          Constellation: "巨蟹",
          MainAstral: "月亮",
          FlyInto1: "天蠍",
          FlyInto2: "4宮",
        },
        {
          House: "2宮(財帛宮)",
          Constellation: "巨蟹",
          MainAstral: "月亮",
          FlyInto1: "天蠍",
          FlyInto2: "4宮",
        },
        {
          House: "3宮(兄弟宮)",
          Constellation: "巨蟹",
          MainAstral: "月亮",
          FlyInto1: "天蠍",
          FlyInto2: "4宮",
        },
      ],
      phases: [
        "日",
        "月",
        "水",
        "金",
        "火",
        "木",
        "土",
        "天",
        "海",
        "冥",
        "升",
        "頂",
      ],
      phases1: [],
      phases2: [],
      P: [],
      Q: [],
      T: [],
      Y: [],
      statsList: ["陰陽性", "三大模式", "四大元素"],
      //解讀Swiper
      swiperInterpret: {
        slidesPerView: "auto",
        spaceBetween: 12,
        freeMode: true,
      },
      activeParamsTab: "星位",
      //星盤Swiper
      swiperAstrolabe: {
        initialSlide: 1,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "custom-bullet",
          bulletActiveClass: "custom-bullet-active",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swiper = document.getElementById("SW").swiper;
            let i = swiper.activeIndex;
            this.changeShow(i);
          },
        },
      },
      //容許度Swiper
      swiperTolerance: {
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: true,
      },
      //設置部分變數
      mainSelList: [
        {
          name: "",
        },
      ],
      // 7个主星
      selPhase: [
        {
          name: "",
        },
      ],
      phaseSelList: [0.5, 1, 1.5],
      editPhaseIndex: 0,
      selPhaseModel: [
        {
          name: "",
        },
      ],
      mainClick: [
        {
          name: "",
        },
      ], // 主星修改容许度
      selNumList: [
        {
          name: "",
        },
      ],
      saveBox: [
        {
          name: "",
        },
      ],
      mainPlanetList: [
        {
          name: "",
        },
      ],
      planetList: [
        {
          name: "",
        },
      ],
      nowEditTolerance: [
        {
          name: "",
        },
      ],
      //星位參數
      constellationNodes: [],
    };
  },
  computed: {
    panConfigItem() {
      //七個主要星
      let main = this.$store.state.Astrolabe.mainPlanetList.slice(0, 7);
      //其他星
      let planet = this.$store.state.Astrolabe.planetList;
      return [
        ...main.map((item) => (item.toggleSelect ? 1 : 0)), //星盤設置設定檔
        ...planet.map((item) => (item.toggleSelect ? 1 : 0)),
      ];
    },
    UserData() {
      return this.$store.state.userData; //星盤設置設定檔
    },
    nowUserData() {
      return this.$store.state.nowUserData; //星盤設置設定檔
    },
  },
  methods: {
    dataChangeHandler($event) {
      this.dataChange = $event;
      if ($event) {
        this.getParams();
      }
    },
    changeShow(index) {
      this.canshow = [false, false, false];
      this.canshow[index] = true;
      setTimeout(() => {
        this.canshow = [false, false, false];
      }, 800);
    },
    switchTab(type) {
      this.activeParamsTab = type || "星位";
    },
    openSetup() {
      this.activeSetup = !this.activeSetup;
    },
    toFriend() {
      this.$router.push(`/friendList?mode=astrolabeMode`);
    },
    changeInterpret(type) {
      this.interpretData.forEach((item, index) => {
        if (item.name === type) {
          this.NowInterpret = item;
        }
      });
    },
    //取得參數(需呼叫因為要刷新詳細參數內容、表格)
    getParams() {
      let DetailNeed = this.$store.state.Astrolabe.mainPlanetList.concat(
        this.$store.state.Astrolabe.planetList
      );
      let detailNeed_fixed = [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];
      this.$API
        .POST_AstrolabeParams({
          SetupConfigs: detailNeed_fixed,
          AstralInfo: this.nowUserAstralInfo,
          AstrolabeType: "C2",
        })
        .then((data) => {
          data = data.Data;
          //星位
          this.constellationNodes = data.ConstellationNodes;
          this.constellationNodes.forEach((item) => {
            let planet = this.planets.find(
              (planet) => planet.name == item.Astral
            );
            let planets2 = this.planets2.find(
              (planet) => planet.name == item.Astral
            );
            if (planet) {
              item.img = planet.img;
              item.imageUrl = planet.imageUrl;
            }
            if (planets2) {
              item.img = planets2.img;
              item.imageUrl = planets2.imageUrl;
            }
          });

          for (let i = 0; i < this.planets.length; i++) {
            if (
              this.planets[i].star === "" ||
              this.planets[i].star === null ||
              this.planets[i].star === undefined
            ) {
              this.planets[i].show = false;
            } else {
              this.planets[i].star = this.planets[i].star.slice(0, 2) + "座";
              this.planets[i].show = true;
            }
          }

          this.palaces = data.HouseNodes;

          // //相位
          this.phases1 = [];
          for (let i = 0; i < data.PhaseNodes.length; i++) {
            this.phases1.push(data.PhaseNodes[i].Phases);
          }
          //參考索引值
          let CssArr = [
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
          ];
          for (let i = 0; i < this.phases1.length; i++) {
            for (let j = 0; j < this.phases1[i].length; j++) {
              if (this.phases1[i][j] === "合") {
                CssArr[i][j] = "phase-neutral";
              } else if (
                this.phases1[i][j] === "沖" ||
                this.phases1[i][j] === "刑" ||
                this.phases1[i][j] === "梅"
              ) {
                CssArr[i][j] = "phase-ominous";
              } else if (
                this.phases1[i][j] === "拱" ||
                this.phases1[i][j] === "六合"
              ) {
                CssArr[i][j] = "phase-lucky";
              } else {
                CssArr[i][j] = " ";
              }
            }
          }
          this.phases2 = CssArr;

          // //統計
          this.P = data.StatisticsNode.P_Notes;
          this.Q = data.StatisticsNode.Q_Nodes;
          this.T = data.StatisticsNode.T_Nodes;
          this.Y = data.StatisticsNode.Y_Nodes;
        });
    },
    toStore(mode) {
      let orderObj = this.$store.state.orderCreater;
      orderObj.AstrolabeFileIds = [
        this.$store.state.nowUserData.AstrolabeFileId * 1,
      ];
      orderObj.Quantity = 1;
      orderObj.Type = 0;
      switch (mode) {
        case "BC":
          orderObj.ProductID = this.$store.state.productList.find(
            (item) => item.Code === "HouseInterpretation"
          ).ProductID;

          orderObj.Code = "HouseInterpretation";
          break;
        case "LC":
          orderObj.ProductID = this.$store.state.productList.find(
            (item) => item.Code === "TransitChart"
          ).ProductID;

          orderObj.Code = "TransitChart";
          break;
      }
      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/store/tobuy/");
    },
    resize() {
      // let width = document.getElementById("astrolabe__bg").clientWidth;
      // this.paramPan.width = width;
    },
    //取得解讀文字(刷新)
    getInterpret() {
      let IsMyself = true;
      if (this.$store.state.nowUserData.Relationship === "自己") {
      } else {
        IsMyself = false;
      }
      this.$API
        .POST_AstrolabeInterpretations({
          MemberId: this.$store.state.userData.MemberId + "",
          AstrolabeFileId: this.$store.state.nowUserData.AstrolabeFileId,
        })
        .then((rstDatas) => {
          this.interpretData.forEach((item, index) => {
            rstDatas.Data.forEach((i, ind) => {
              if (item.name === i.Title1) {
                item.Title2 = i.Title2;
                item.Angle1 = i.Angle1;
                item.Angle2 = i.Angle2;
                item.House = i.House;
                item.HouseTitle = i.HouseTitle;
                item.SubTitle = i.SubTitle;
                item.Content = i.Content;
                item.HouseContent = i.HouseContent;
                this.constellation.forEach((item1) => {
                  if (item1.name === i.Title2) {
                    item.img1 = item1.img;
                  }
                });
              }
            });
          });
          this.changeInterpret("太陽");
        });
    },
  },
  async created() {
    //刪除登入導向redirect
    this.$store.commit("setLoading_force", true);
    if (!this.$store.state.nowUserData) {
      this.$router.replace("/");
      this.$store.commit("setLoading_force", false);
      return;
    }
    // window.addEventListener("resize", this.resize);
    //TODO:用AstrolabeFileId撈一次資料，因為有可能經過編輯，不能直接用store的
    //這樣的資料流不太好，未來預計優化
    if (this.nowUserData) {
      const res = await this.$API.Get_AstrolabeFile(
        this.nowUserData.AstrolabeFileId
      );
      this.$store.commit("set_nowUserData", res.Data);
      const nowUserAstralInfo = await this.$API.Post_AstralInformation({
        AstrolabeFileId: this.nowUserData.AstrolabeFileId,
        HouseSystem: 0,
        IsSolarHouse: false,
      });
      this.nowUserAstralInfo = nowUserAstralInfo.Data;
      this.getParams();
      this.getInterpret();
      this.$store.commit("setLoading_force", false);
    }
  },
  destroyed() {
    // window.removeEventListener("resize", this.resize);
  },
  mounted() {
    window.addEventListener("click", () => (this.activeSelect = false));
    window.addEventListener("click", () => (this.activeSetup = false));

    let paramPan = {
      width: 520,
      maxWidth: 600, //最大屏幕宽度
      type: 0,
      data2: null,
      panStyle: "A32",
    };
    if (window.innerWidth < 1263) {
      paramPan.width = 760 - 4;
    }
    if (window.innerWidth < 860) {
      paramPan.width = window.innerWidth - 4;
    }
    if (window.innerWidth < 768) {
      this.paramPan.width = window.innerWidth - 12;
    }

    this.paramPan = paramPan;
    this.changeShow(0);

    setTimeout(() => {
      this.interpretTabNotice = true;
      setTimeout(() => {
        this.interpretTabNotice = false;
      }, 3000);
    }, 500);

    //切換好友
    if (window.localStorage.getItem("friendState") === "Switch") {
      this.switchName = this.nowUserData.Name;
      this.showSwitch = true;
      setTimeout(() => {
        this.showSwitch = false;
        window.localStorage.removeItem("friendState");
      }, 2000);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
@import "@/scss/Astrolabe.scss";

.box {
  width: 100%;
  height: 500px;
}

.row-wrap {
  @include align(space-around, flex-start);
  margin: 36px auto;
  @include laptop {
    flex-direction: column;
    @include center;
    .detail {
      order: 2;
    }
  }
  @include pad {
    margin: 0 auto;
    padding: 0;
  }
}

.detail {
  width: 44%; //448px
  @include align(flex-start, center);
  flex-direction: column;
  margin-right: 30px;
  @include laptop {
    margin-right: 0;
    margin-top: 30px;
    width: 70%;
  }
  @include pad {
    width: 100%;
    padding: 0 16px;
  }

  .detail__tab {
    width: 100%;
    @include align(center, center);

    .detail__tab__item {
      @include btn(50%, 48px, transparent, $theme-color-1, 20px);
      border: solid 2px $theme-color-1;
      transition: 0.2s;

      &.activeDetailTab {
        background-color: $theme-color-1;
        color: $theme-color-3;
        border: none;
        font-weight: bold;
      }

      @include mobile {
        height: 36px;
        font-size: 16px;
      }
    }
  }

  .interpret {
    width: 100%;

    .interpret__tab {
      position: relative;
      margin-top: 24px;
      @include align(flex-start, center);

      .interpret__notice {
        @include center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        background-color: rgba(240, 242, 244, 0.8);
        img {
          position: relative;
          width: 54px;
          height: 54px;
          animation: swipe 0.5s linear infinite both alternate;
          @keyframes swipe {
            from {
              left: 0px;
            }
            to {
              left: -30px;
            }
          }
          @include mobile {
            width: 32px;
            height: 32px;
          }
        }
        span {
          margin-left: 8px;
          font-size: 14px;
          font-weight: 500;
          color: $theme-color-2;
          letter-spacing: 0.56px;
          @include mobile {
            font-size: 12px;
            letter-spacing: 0.48px;
          }
        }
      }

      .interpret__tab__item {
        @include rect(48px, 72px, $theme-color-3, 24px);
        @include center;
        flex-direction: column;
        box-shadow: -1px -1px 4px 0 #ffffff, 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
        // transition: 0.2s;
        cursor: pointer;

        .interpret__icon {
          font-size: 18px;
          color: #27aba3;
        }

        .interpret__text {
          writing-mode: vertical-rl;
          color: $secondary-color-3;
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: 0.56px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11) {
          .interpret__icon {
            color: #e99a00;
          }
        }

        &:hover,
        &.activeInterpretTab {
          background-color: $theme-color-1;

          .interpret__icon {
            color: #ffffff;
          }

          .interpret__text {
            color: #ffffff;
          }
        }
      }
    }

    .interpret__star {
      margin: 32px 0;
      padding: 18px 32px 28px;
      @include rect(100%, auto, $theme-color-3, 20px);
      @include align(flex-start, center);
      flex-direction: column;
      position: relative;
      cursor: pointer;
      @include mobile {
        padding-right: 20px;
        padding-left: 20px;
      }
      &__openText {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.08em;
        color: #9b9b9b;
        opacity: 1;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 12px;
      }
      //打開星座解讀
      &.openStar {
        .interpret__star__openText {
          opacity: 0;
        }
        .interpret__line__new {
          opacity: 1;
          max-height: 1000px;
          margin: 32px 0;
          @include pad {
            margin: 28px 0;
          }
        }
        .interpret__star__content {
          max-height: 1000px;
          opacity: 1;
        }
        .interpret__star__subtitle {
          max-height: 1000px;
          opacity: 1;
          margin-bottom: 16px;
          @include pad {
            margin-bottom: 14px;
          }
        }
        .interpret__star__openImg {
          transform: rotate(180deg);
        }
      }
      .interpret__star__openImg {
        position: absolute;
        right: 12px;
        bottom: 12px;
      }
      .interpret__star__title {
        position: relative;
        @include center;
        @include text-2;
        width: 100%;

        .interpret__star__img {
          width: 64px;
          height: 64px;
          margin: 0 8px;
          @include mobile {
            width: 56px;
            height: 56px;
          }
        }

        .interpret__star__degree {
          @include center;
          flex-direction: column;
          padding-left: 3px;
          position: absolute;
          right: 0;
          width: 36px;
          height: 60px;
          border-radius: 6px;
          box-shadow: inset 4px 4px 10px 0 rgba(0, 0, 0, 0.08),
            2px 2px 4px 0 #ffffff;
          background-color: $theme-color-3;
          font-size: 14px;
          line-height: 1.29;
          letter-spacing: 0.56px;
          color: rgba($color: $secondary-color-1, $alpha: 0.6);
          text-align: end;
          @include mobile {
            width: 28px;
            height: 48px;
            font-size: 10px;
          }
        }
      }

      .interpret__star__house {
        @include center;
        width: 120px;
        height: 24px;
        background-color: $theme-color-1;
        color: $theme-color-3;
        margin: 10px 0 24px 0;
      }
      .interpret__line__new {
        margin: 0;
        width: 100%;
        height: 3px;
        box-shadow: inset 4px 4px 10px 0 rgba(0, 0, 0, 0.08),
          3px 3px 4px 0 #ffffff;
        background-color: #f0f2f4;
        max-height: 0;
        opacity: 0;
        transition: 0.5s;
      }

      .interpret__star__subtitle {
        margin-bottom: 0;
        width: 100%;
        max-height: 0;
        opacity: 0;
        transition: 0.5s;

        @include center;
        .subtitle__text {
          color: $theme-color-2;
          font-size: 16px;
          margin: 0 10px;
          @include pad {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .interpret__star__content {
        @include text-3("#292d2d");
        font-size: 16px;
        margin-bottom: 30px;
        line-height: 30px !important;
        transition: 0.5s;
        max-height: 0;
        opacity: 0;
        /* margin-top: 12px; */
        margin: 12px;
        @include pad {
          font-size: 14px;
          line-height: 24px !important;
        }
      }
    }

    .interpret__house {
      position: relative;
      padding: 28px 32px;
      @include rect(100%, auto, $theme-color-3, 20px);
      @include align(flex-start, center);
      min-height: 120px;
      flex-direction: column;
      cursor: pointer;
      @include mobile {
        padding-right: 20px;
        padding-left: 20px;
      }

      .interpret__house__title {
        @include text-2;
      }

      .interpret__house__house {
        position: absolute;
        top: 12px;
        right: 37px;
        width: 36px;
        height: 60px;
        @include center;
        color: $theme-color-1;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        writing-mode: vertical-lr;
        box-shadow: 2px 2px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
      }
      .interpret__house__subtitle {
        width: 100%;
        transition: 0.5s;

        @include center;
        color: $theme-color-2;
        font-size: 16px;
        margin: 24px 0 0;
        @include pad {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .interpret__house__img {
        position: absolute;
        right: 12px;
        bottom: 12px;
        transition: transform 0.5s;
      }

      .interpret__house__content {
        @include text-3("#292d2d");
        overflow: hidden;
        max-height: 0;
        margin-top: 0;
        margin-bottom: 0;
        opacity: 0;
        transition: max-height 0.5s 0.1s, opacity 0.5s, margin-top 0.5s;
      }
      //還沒解鎖
      .toUnlock_cta {
        margin-top: 26px;
        @include btn(268px, 56px, $theme-color-2, #fff);
      }
      //打開宮位解讀
      &.openHouse {
        .interpret__house__img {
          transform: rotate(180deg);
        }

        .interpret__house__content {
          max-height: 1000px;
          margin-top: 18px;
          margin-bottom: 30px;
          opacity: 1;
        }
      }
    }
  }

  //星位
  .params {
    width: 100%;

    .params__select {
      @include rect(100px, auto, $theme-color-3, 16px);
      cursor: pointer;
      position: relative;
      margin: 24px 0;
      z-index: 15;

      .params__select__selected {
        width: 100%;
        height: 32px;
        @include center;
        @include text-1;
        font-size: 16px;

        img {
          width: 24px;
          height: 24px;
          transition: 0.3s;
        }
      }

      .params__select__box {
        @include rect(100px, auto, $theme-color-3, 16px);
        position: absolute;
        top: 0;
        overflow: hidden;
        transition: 0.3s;

        .params__select__item {
          width: 100%;
          height: 32px;
          @include center;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.25;
          letter-spacing: 1.28px;
          color: $secondary-color-3;
          background-color: $theme-color-3;

          &:hover {
            background-color: $special_bg_color2;
          }

          &.activeParamsTab {
            color: $theme-color-1;
            font-weight: bold;
          }
        }
      }

      &.activeSelect {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>

import Base from "@/api/_base";
import store from "@/store";

let { ProductAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

let timer = {};
ProductAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    if (config.closeLoadAnimation) {
    } else {
      store.commit("setLoading", true);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
ProductAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    store.commit("setLoading", false);
    return response;
  },
  function (error) {
    console.log("error", error);
    clearTimeout(timer[error.config.url]);
    store.commit("setLoading", false);
    return Promise.reject(error);
  }
);
export default {
  //-----------------------商店---------------------
  //取得-商品列表資料*
  GET_Products: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/Store/Products`, Config);
    }
  },
  //商品明細資料
  GET_ProductDetail: async (ProductID) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/Store/ProductDetail/${ProductID}`, Config);
    }
  },
  //購買-商店服務*
  POST_Order: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await ProductAPI.post(`/Store/BuyProduct`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/Store/BuyProduct"]);
        ErrorHandle("POST_Order error", {
          Method: "post",
          Url: "/Store/BuyProduct",
          Msg: err,
        },
          false);
      }
    }
  },

  //兌換：商品券
  PUT_ExchangeProductVoucher: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.put(`/Store/ProductVoucher`, Arg, Config);
    }
  },

  //檢核：商品券
  GET_CheckBuyGift: async (ReceiverMemberID, ProductID) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/Stores/CheckBuyGift?ReceiverMemberID=${ReceiverMemberID}&ProductID=${ProductID}`, Config);
    }
  },

  //-----------------------月亮日記---------------------
  //建立月亮日記
  POST_MoonDayNotes: async (MoondayEditFormData) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.post(`/Moons/Note`, MoondayEditFormData, Config);
    }
  },
  GET_MoonDayNoteList: async (Arg) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    let { Content, PageSize, PageIndex, OrderBy, OrderWay } = Arg;
    return await ProductAPI.get(
      `/Moons/Note/Lists?Content=${Content}&PageSize=${PageSize}&PageIndex=${PageIndex}&OrderBy=${OrderBy}&OrderWay=${OrderWay}`,
      Config
    );
  },
  GET_MoonDayNoteDetail: async (MoonNoteID) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    return await ProductAPI.get(`/Moons/Note/${MoonNoteID}`, Config);
  },
  GET_MoonDayNoteCheck: async ({ Date, Constellation }) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    return await ProductAPI.get(
      `/Moons/Note/Check?Date=${Date}&Constellation=${Constellation}`,
      Config
    );
  },
  PUT_MoonDayNotes: async (MoonNoteID, MoondayEditFormData) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.put(
        `/Moons/Note/${MoonNoteID}`,
        MoondayEditFormData,
        Config
      );
    }
  },
  POST_DownloadMoonDay: async (arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      Config.responseType = "blob";
      return await ProductAPI.post(`/Moons/Note/Download`, arg, Config);
    }
  },
  GET_MoonDayNoteMoodStatistics: async ({ Constellation, Content }) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(
        `/Moons/Note/MoodStatistics?Constellation=${Constellation}&Content=${Content}`,
        Config
      );
    }
  },
  GET_MoonDayCalendar: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      // &ShowHourRange=${8}&HourRange=${600}
      return await ProductAPI.get(`/Moons/Calendar?Type=${0}`, Config);
    }
  },
  DELETE_MoonDayNotes: async (MoonNoteID) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.delete(`/Moons/Note/${MoonNoteID}`, Config);
    }
  },
  //-----------------------運勢之書2024(解答之書)---------------------
  // 取得-運勢書列表資料
  GET_BOOKLIST: async () => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    return await ProductAPI.get(`/Books/List`, Config);
  },
  GET_BOOKCONTENT: async (BookID, Constellation) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(
        `/Books/${BookID}/Content/${Constellation}`,
        Config
      );
    }
  },
  //-----------------------訂單---------------------
  //兌換碼查詢
  GET_ExchangeCode: async (OrderNo) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`/Orders/${OrderNo}/ExchangeCode`, Config);
    }
  },
  //取得-購買紀錄資料*
  Get_PurchaseRecordList: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(`Orders/List`, Config);
    }
  },
  //取得-購買紀錄明細資料*
  Get_PurchaseRecord: async (OrderNo) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      return await ProductAPI.get(
        `/Orders/${OrderNo}`,
        Config
      );
    }
  },
};

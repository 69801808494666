import Base from "@/api/_base";
import store from "@/store";

let { GatewayAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
let timer = {};
GatewayAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
GatewayAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {
  //----------------基本------------------
  //寄送問題回爆
  SendReport: async (Arg) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers["Content-Type"] = "multipart/form-data";
    try {
      let { data } = await GatewayAPI.post(
        `/NotifyMail/ProblemReturnMail`,
        Arg,
        Config
      );
      return data;
    } catch (err) {
      clearTimeout(timer["/NotifyMail/ProblemReturnMail"]);
      ErrorHandle("SendReport error", {
        Method: "post",
        Url: "/NotifyMail/ProblemReturnMail",
        Msg: err,
      });
    }
  },
  //發送訂單聯絡我們
  POST_ContactUsMail: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await GatewayAPI.post(
          `/NotifyMail/ContactUsMail`,
          Arg,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/NotifyMail/ContactUsMail"]);
        ErrorHandle("POST_ContactUsMail error", {
          Method: "post",
          Url: "/NotifyMail/ContactUsMail",
          Msg: err,
        });
      }
    }
  },
  //發送-會員驗證碼通知信
  POST_MemberCaptchaMail: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await GatewayAPI.post(
          `/NotifyMail/MemberCaptchaMail`,
          Arg,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/NotifyMail/MemberCaptchaMail"]);
        ErrorHandle("POST_MemberCaptchaMail error", {
          Method: "post",
          Url: "/NotifyMail/MemberCaptchaMail",
          Msg: err,
        });
      }
    }
  },
};

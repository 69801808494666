var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "group" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", [
      _c("a", { attrs: { href: "javascript:;" }, on: { click: _vm.weChat } }, [
        _c("img", {
          attrs: {
            src: require("../assets/Index/social/chart.svg"),
            alt: "微信"
          }
        })
      ])
    ]),
    _vm._m(2),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.youtube.com/channel/UCK7LdglLCApOTaylxX8hW2Q",
            target: "_blank"
          }
        },
        [
          _c("img", {
            attrs: { src: require("../assets/Index/social/yt.svg"), alt: "" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/JesseTang11/",
            target: "_blank"
          }
        },
        [
          _c("img", {
            attrs: { src: require("../assets/Index/social/fb.svg"), alt: "" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        { attrs: { href: "https://weibo.com/u/1688213071", target: "_blank" } },
        [
          _c("img", {
            attrs: {
              src: require("../assets/Index/social/Vector.svg"),
              alt: "微博"
            }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.instagram.com/jessetang11/?hl=zh-tw",
            target: "_blank"
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../assets/Index/social/Instagram.svg"),
              alt: "IG"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newMoonCTA", class: { isCollapse: _vm.isCollapse } },
    [
      _c("div", {
        staticClass: "newMoonCTA__toNewMoon",
        on: {
          click: function($event) {
            return _vm.toNewMoon()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "newMoonCTA__collapseBtn",
          on: {
            click: function($event) {
              return _vm.collapseHandler(true)
            }
          }
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "22",
                height: "14",
                viewBox: "0 0 22 14",
                fill: "none"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M10.2484 13.3906L0.648438 2.19063L2.16695 0.889043L11.0077 11.2032L19.8484 0.889044L21.3669 2.19063L11.767 13.3906L10.2484 13.3906Z",
                  fill: "#AB956C"
                }
              })
            ]
          )
        ]
      ),
      _c("div", {
        staticClass: "newMoonCTA__mini",
        on: {
          click: function($event) {
            return _vm.collapseHandler(false)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
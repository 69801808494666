<template>
  <div>
    <section class="row-wrap" v-if="nowUserData">
      <div class="detail">
        <div class="detail__tab">
          <div
            class="detail__tab__item"
            :class="{ activeDetailTab: activeDetailTab == 'interpret' }"
            @click="activeDetailTab = 'interpret'"
          >
            星盤解讀
          </div>
          <div
            class="detail__tab__item"
            :class="{ activeDetailTab: activeDetailTab == 'friend' }"
            @click="activeDetailTab = 'friend'"
          >
            好友選擇
          </div>
        </div>
        <!--星盤解讀-->
        <div class="pan_interpret" v-if="activeDetailTab == 'interpret'">
          <!-- <div class="interpret" v-for="(item,index) in sortInterpret" :key="index">
                    <v-layout class="interpret__title">
                        <img :src="item.Astral1IMG" />
                        <span class="interpret__astral">{{ item.Astral1 }}</span>
                        <span class="interpret__phase">{{ item.Aspect }}</span>
                        <img :src="item.Astral2IMG" />
                        <span class="interpret__astral">{{ item.Astral2 }}</span>
                        <v-spacer></v-spacer>
                        <div class="interpret__power">
                        <font-awesome-icon
                            v-for="(i , ind) in  item.AspectPower"
                            :key="ind"
                            class="star"
                            icon="star"
                        ></font-awesome-icon>
                        </div>
                    </v-layout>
                    <div class="interpret__content">{{ item.Content }}</div>
                    </div> -->
        </div>
        <!--好友列表-->
        <div class="pan_friendList" v-if="activeDetailTab == 'friend'">
          <div class="btn__wrap">
            <button class="user__btn" @click="changeData()">
              取得合盤(選兩筆)
            </button>
          </div>
          <ul>
            <li
              class="friend_set"
              v-for="(set, index) in friendList"
              :key="index"
            >
              {{ set.Title }}
              <ul>
                <li
                  class="friend_item"
                  v-for="friend in set.AstrolabeFiles"
                  :key="friend.AstrolabeFileId"
                >
                  <input
                    @change="selectData(friend.AstrolabeFileId)"
                    type="checkbox"
                    :checked="
                      friendListCheck.indexOf(`${friend.AstrolabeFileId}`) > -1
                    "
                    :id="friend.AstrolabeFileId"
                  />
                  <label :for="friend.AstrolabeFileId"
                    >{{ friend.Name }} - {{ friend.BirthDay }} -
                    {{ friend.City }}</label
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <!--星盤-->
      <div class="content">
        <!-- 比較時 -->
        <div class="user" v-if="Data2">
          <div class="user__info">
            <img :src="Data.ProfilePhotoSrc" />
            <div class="user__info__name">{{ Data.Name }}</div>
          </div>
          <p>
            <span>影響</span>
            <span>→</span>
          </p>
          <div class="user__info">
            <img :src="Data2.ProfilePhotoSrc" />
            <div class="user__info__name">{{ Data2.Name }}</div>
          </div>
        </div>
        <div class="user" v-else>
          <div class="user__info">
            <img :src="nowUserData.ProfilePhotoSrc" />
            <div class="user__info__name">
              {{ $NameFormat(nowUserData.Name) }}
            </div>
          </div>
          <p>
            <span>組合</span>
            <span>+</span>
          </p>
          <div class="user__info">
            <img :src="nowUserData2.ProfilePhotoSrc" />
            <div class="user__info__name">{{ nowUserData2.Name }}</div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <!-- 切換模式start -->
        <ul class="pan_type">
          <li @click="changeType1()" :class="{ active: showType == 'type1' }">
            比較 1
          </li>
          <li @click="changeType2()" :class="{ active: showType == 'type2' }">
            比較 2
          </li>
          <li @click="changeType3()" :class="{ active: showType == 'type3' }">
            組合
          </li>
        </ul>
        <!-- 切換模式End -->
        <div class="astrolabe">
          <div class="astrolabe__wrap">
            <!-- swiper -->
            <div class="swiper">
              <swiper id="SW" :options="swiperAstrolabe">
                <swiper-slide
                  class="astrolabe__item"
                  v-for="item in style"
                  :key="item.id"
                >
                  <div class="astrolabe__bg" id="astrolabe__bg">
                    <canvasAstrolabe
                      v-if="dataChange"
                      class="canvas-pan"
                      :width="paramPan.width"
                      :configItem="panConfigItem"
                      :panStyle="item.name"
                      :type="paramPan.type"
                      :data1="
                        Data.AstralInfo ? Data.AstralInfo.AstralRadians : Data
                      "
                      :data2="Data2 ? Data2.AstralInfo.AstralRadians : Data2"
                    ></canvasAstrolabe>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="user__info__birth-m hidden-pad-up">
                {{ nowUserData.BirthDay }}&emsp;{{ nowUserData.BirthTime }}
              </div>
              <div class="switch">
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </div>
            </div>
            <!-- swiper end -->
          </div>
          <!-- 星盤設置開關  -->
          <div class="astrolabe__setting" @click.stop="openSetup()">
            <img src="@/assets/icon_setting.svg" alt />
          </div>
          <!-- 星盤設置  -->
          <SetAstrolabe
            :activeSetup="activeSetup"
            @close="activeSetup = false"
            @dataChange="dataChangeHandler($event)"
          />
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import canvasAstrolabe from "@/components/canvas-astrolabe.vue";
import SetAstrolabe from "@/components/SetAstrolabe.vue";

export default {
  components: {
    NavBar,
    Footer,
    canvasAstrolabe,
    SetAstrolabe,
  },
  data() {
    return {
      Data: this.$store.state.nowUserData, //星盤設置設定檔
      Data2: this.$store.state.nowUserData2, //星盤設置設定檔
      activeDetailTab: "interpret",
      //強迫更新用
      dataChange: true,
      //星盤設置開關
      activeSetup: false,
      //星盤Swiper
      swiperAstrolabe: {
        initialSlide: 1,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "custom-bullet",
          bulletActiveClass: "custom-bullet-active",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swiper = document.getElementById("SW").swiper;
            let i = swiper.activeIndex;
            this.changeShow(i);
          },
        },
      },
      style: [
        { name: "文字", displayName: "文字", show: false, id: "alert1" },
        { name: "A32", displayName: "標準", show: false, id: "alert2" },
        { name: "专业", displayName: "專業", show: false, id: "alert3" },
      ],
      //星盤所需資料
      paramPan: {
        width: 400,
        maxWidth: 500, //最大屏幕宽度
        type: 1,
        data2: null,
        panStyle: "A32",
      },
      friendList: [],
      friendListCheck: [],
      showType: "type1",
    };
  },
  computed: {
    panConfigItem() {
      return this.$store.state.panConfig.planetSet.configItem; //星盤設置設定檔
    },
    UserData() {
      return this.$store.state.userData; //星盤設置設定檔
    },
    nowUserData() {
      return this.$store.state.nowUserData; //星盤設置設定檔
    },
    nowUserData2() {
      return this.$store.state.nowUserData2; //星盤設置設定檔
    },
  },
  methods: {
    changeShow(index) {
      this.canshow = [false, false, false];
      this.canshow[index] = true;
      setTimeout(() => {
        this.canshow = [false, false, false];
      }, 800);
    },
    //星盤設置開關
    openSetup() {
      this.activeSetup = !this.activeSetup;
    },
    //星盤設置更新
    dataChangeHandler($event) {
      this.dataChange = $event;
      if ($event) {
        //重新呼叫取得星盤解讀
        // this.getParams()
      }
    },
    changeType1() {
      this.showType = "type1";
      this.Data = this.$store.state.nowUserData;
      this.Data2 = this.$store.state.nowUserData2;
      this.paramPan.type = 1;
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
      }, 1);
    },
    changeType2() {
      this.showType = "type2";
      this.Data = this.$store.state.nowUserData2;
      this.Data2 = this.$store.state.nowUserData;
      this.paramPan.type = 1;
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
      }, 1);
    },
    changeType3() {
      this.showType = "type3";
      let compare1 = this.$store.state.nowUserData.AstralInfo;
      let compare2 = this.$store.state.nowUserData2.AstralInfo;

      let composeData = [];

      compare1.AstralRadians.forEach((item, index) => {
        composeData[index] =
          (Number(compare1.AstralRadians[index]) +
            Number(compare2.AstralRadians[index])) /
          2;
        if (
          Number(compare1.AstralRadians[index]) <
            Number(compare2.AstralRadians[index]) &&
          Number(compare1.AstralRadians[index]) < 180
        ) {
          composeData[index] = (composeData[index] + 180) % 360;
        }
      });
      this.Data = composeData;
      this.Data2 = null;
      this.paramPan.type = 0;
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
      }, 1);
    },
    selectData(id) {
      if (this.friendListCheck.indexOf(id) > -1) {
        //代表他已經在裡面了
        this.friendListCheck.splice(this.friendListCheck.indexOf(id), 1);
      } else {
        // 不然就新增
        this.friendListCheck.push(id);
      }
    },
    changeData() {
      //取得雙人合盤按下後
      if (this.friendListCheck.length == 2) {
        //只能有兩個id
        let data1_id = this.friendListCheck[0];
        let data2_id = this.friendListCheck[1];
        // 到好友名單中去撈那兩個id
        this.friendList.forEach((set) => {
          let Data1 = set.AstrolabeFiles.find((item) => {
            return item.AstrolabeFileId == data1_id;
          });
          if (Data1) {
            this.Data = Data1;
            this.$store.state.nowUserData = this.Data;
          }
          let Data2 = set.AstrolabeFiles.find((item) => {
            return item.AstrolabeFileId == data2_id;
          });
          if (Data2) {
            this.Data2 = Data2;
            this.$store.state.nowUserData2 = this.Data2;
          }
        });
      } else {
        alert("請選擇兩筆");
      }
    },
  },
  mounted() {
    this.$API.Get_MemberInfo().then((data) => {
      //先判斷好友是否有一位以上
      if (!data.Data.AstrolabeFriendLists.length > 1) {
        alert("請先新增一位以上好友");
        this.$router.push("/friend");
      }
      if (this.$store.state.needUpdate == true) {
        this.$store.state.nowUserData = data.Data.AstrolabeFriendLists[0];
        this.$store.state.nowUserData2 = data.Data.AstrolabeFriendLists[1];
        this.$store.state.needUpdate = false;
      }
      if (this.$store.state.nowUserData === null) {
        this.$store.state.nowUserData = data.Data.AstrolabeFriendLists[0];
      }
      if (this.$store.state.nowUserData2 === null) {
        this.$store.state.nowUserData2 = data.Data.AstrolabeFriendLists[1];
      }
      this.Data = this.$store.state.nowUserData;
      this.Data2 = this.$store.state.nowUserData2;

      //好友列表
      this.friendList = data.Data.AstrolabeFileSets;
      this.friendList.forEach((set) => {
        set.AstrolabeFiles.forEach((item) => {
          let isCheck = false;
          if (
            item.AstrolabeFileId == this.nowUserData.AstrolabeFileId ||
            item.AstrolabeFileId == this.nowUserData2.AstrolabeFileId
          ) {
            this.friendListCheck.push(item.AstrolabeFileId);
          }
        });
      });
      //判斷目前套用的好友是否還在列表種
      if (this.friendListCheck.indexOf(this.Data2.AstrolabeFileId) < 0) {
        this.$store.state.nowUserData2 = data.Data.AAstrolabeFriendLists[1];
        this.Data2 = this.$store.state.nowUserData2;
      }
    });
  },
  created() {
    if (process.env.NODE_ENV == "production") {
      this.$router.push({ path: "/" });
    }
    if (window.localStorage.getItem("login_status")) {
      this.$store.commit("updateLoginStatus", true);
    } else {
      this.$store.commit("updateLoginStatus", false);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/DBL.scss";
</style>

<template>
  <div class="group">
    <div>
      <a
        href="https://www.youtube.com/channel/UCK7LdglLCApOTaylxX8hW2Q"
        target="_blank"
      >
        <img src="../assets/Index/social/yt.svg" alt="" />
      </a>
    </div>

    <div>
      <a href="https://www.facebook.com/JesseTang11/" target="_blank">
        <img src="../assets/Index/social/fb.svg" alt="" />
      </a>
    </div>

    <div>
      <a href="javascript:;" @click="weChat">
        <img src="../assets/Index/social/chart.svg" alt="微信" />
      </a>
    </div>

    <div>
      <a href="https://weibo.com/u/1688213071" target="_blank">
        <img src="../assets/Index/social/Vector.svg" alt="微博" />
      </a>
    </div>

    <div>
      <a href="https://www.instagram.com/jessetang11/?hl=zh-tw" target="_blank">
        <img src="../assets/Index/social/Instagram.svg" alt="IG" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    weChat() {
      this.$emit("weChat");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.group {
  width: 344px;
  display: flex;
  position: relative;
  z-index: 20;
  justify-content: space-between;
  div {
    width: 56px;
    padding-top: 56px;
    border-radius: 50%;
    position: relative;
    background: url("../assets/Index/social/icon_bg.svg");
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    @include mobile {
      width: 36px;
      padding-top: 36px;
    }
    img {
      width: 80%;
      height: 80%;
      padding: 20%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-55%, -55%);
    }
  }
}
</style>

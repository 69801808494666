<template>
  <footer class="footerWrap" :class="{ bookStyle: bookStyle }">
    <div class="row-wrap custom-container">
      <div class="row-left">
        <a href="mailto:service@astroinfo.com.tw">
          <div class="email">
            <img src="@/assets/運勢書共用/social_email.svg" v-if="bookStyle" />
            <img src="@/assets/icon_email.svg" v-else />
          </div>
        </a>
        <div class="links">
          <div class="link" @click="toReport">問題回報</div>
          <div class="link" @click="toPrivacy">隱私及服務條款</div>
        </div>
      </div>
      <div class="social">
        <a
          href="https://www.instagram.com/jessetang11/?hl=zh-tw"
          target="_blank"
          class="social__btn"
        >
          <div class="btn__bg">
            <img src="@/assets/運勢書共用/social_ig.svg" v-if="bookStyle" />
            <img src="@/assets/social_ig.svg" v-else />
          </div>
        </a>
        <a
          href="https://liff.line.me/1645278921-kWRPP32q/?accountId=jessetang"
          target="_blank"
          class="social__btn"
        >
          <div class="btn__bg">
            <img src="@/assets/運勢書共用/social_line.svg" v-if="bookStyle" />

            <img src="@/assets/social_line.svg" v-else />
          </div>
        </a>
        <a
          href="https://www.facebook.com/JesseTang11/"
          target="_blank"
          class="social__btn"
        >
          <div class="btn__bg">
            <img src="@/assets/運勢書共用/social_fb.svg" v-if="bookStyle" />

            <img src="@/assets/social_fb.svg" v-else />
          </div>
        </a>
      </div>
    </div>
    <div class="copyright">
      <span> Copyright © 2020 DaMou. All rights reserved. </span>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    bookStyle() {
      const bookRegex = /^\/book202[4-7]/;
      return bookRegex.test(this.$route.path);
    },
  },
  methods: {
    toPrivacy() {
      this.$router.push({ path: "/privacy-policy" });
    },
    toReport() {
      this.$router.push({ path: "/report" });
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";

.footerWrap {
  margin-top: 44px;
  @include rect(100%, 264px, $theme-color-3, 0);
  border-radius: 50px 50px 0 0;

  @include laptop {
    height: auto;
  }
  .row-wrap {
    @include align(space-between, center);
    height: 180px;
    @include laptop {
      flex-direction: column;
      padding: 0px 16px 30px;
      height: auto;
    }
    @include mobile {
      padding: 24px 16px 16px;
    }
  }
  .row-left {
    @include align(flex-start, center);
  }
  .email {
    width: 160px;
    height: 160px;
    margin-right: 87px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    @include laptop {
      margin-right: 32px;
    }
    @include mobile {
      width: 50px;
      height: 50px;
    }
  }
  .links {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.96px;
    color: $secondary-color-3;
    cursor: pointer;
    .link {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include laptop {
      display: flex;
      .link {
        margin-bottom: 0px;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @include mobile {
      font-size: 14px;
    }
  }
  .social {
    @include center;
    .social__btn {
      border-right: 1px solid rgba($color: $secondary-color-2, $alpha: 0.5);
      &:nth-child(1) {
        padding-right: 40px;
      }
      &:nth-child(2) {
        padding: 0 40px;
      }
      &:nth-child(3) {
        padding-left: 40px;
        border-right: none;
      }
      @include mobile {
        &:nth-child(1) {
          padding-right: 24px;
        }
        &:nth-child(2) {
          padding: 0 24px;
        }
        &:nth-child(3) {
          padding-left: 24px;
          border-right: none;
        }
      }
      .btn__bg {
        width: 80px;
        height: 80px;
        @include center;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }

        @include mobile {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .copyright {
    @include center;
    width: 100%;
    height: 84px;
    background-color: rgba($color: $secondary-color-2, $alpha: 0.2);
    text-align: center;
    font-size: 20px;
    font-weight: 100;
    line-height: 1.8;
    letter-spacing: 1.6px;
    color: $secondary-color-3;
    @include mobile {
      font-size: 10px;
      height: 24px;
    }
  }
}

.bookStyle.footerWrap {
  position: relative;
  z-index: 1;
  background-color: #214c73;
  box-shadow: 0px -3px 7px rgba(0, 0, 0, 0.1);
  .links {
    color: #fff;
  }
  .social {
    @include center;
    .social__btn {
      border-right: 1px solid rgba($color: #fff, $alpha: 0.5);
      &:nth-child(1) {
        padding-right: 40px;
      }
      &:nth-child(2) {
        padding: 0 40px;
      }
      &:nth-child(3) {
        padding-left: 40px;
        border-right: none;
      }
      @include mobile {
        &:nth-child(1) {
          padding-right: 24px;
        }
        &:nth-child(2) {
          padding: 0 24px;
        }
        &:nth-child(3) {
          padding-left: 24px;
          border-right: none;
        }
      }
    }
  }

  .copyright {
    background-color: rgba($color: #fff, $alpha: 0.2);
    color: #a9a9a9;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "params" }, [
    _c(
      "div",
      { staticClass: "params__tab" },
      _vm._l(["星位", "宮位", "相位", "統計"], function(type) {
        return _c(
          "div",
          {
            key: type,
            staticClass: "params__tab_item",
            class: { active: _vm.activeParamsTab === type },
            on: {
              click: function($event) {
                return _vm.switchTab(type)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(type) + "\n    ")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "params__content" }, [
      _vm.activeParamsTab == "星位"
        ? _c(
            "table",
            { staticClass: "table planet-table" },
            [
              _vm._m(0),
              _vm._l(_vm.constellationNodes, function(item, index) {
                return _c("tr", { key: index, staticClass: "table__content" }, [
                  _c("td", [
                    _c(
                      "span",
                      {
                        staticClass: "item__name__img iconfont",
                        style: {
                          color:
                            item.Astral.indexOf("王") > -1
                              ? "#e99a00"
                              : "#27aba3"
                        }
                      },
                      [_vm._v(_vm._s(item.img))]
                    ),
                    _vm._v("\n          " + _vm._s(item.Astral) + "\n        ")
                  ]),
                  _c("td", [_vm._v(_vm._s(item.Constellation) + "座")]),
                  _c("td", [
                    _vm._v(
                      "\n          " + _vm._s(item.Angle) + "\n          "
                    ),
                    _c("span", { staticClass: "retrograde" }, [
                      _vm._v(_vm._s(item.Retrograde))
                    ])
                  ]),
                  _c("td", [
                    _vm._v("\n          " + _vm._s(item.House) + "\n        ")
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.activeParamsTab == "宮位"
        ? _c(
            "table",
            { staticClass: "table palace-table" },
            [
              _vm._m(1),
              _vm._l(_vm.palaces, function(item, index) {
                return _c("tr", { key: index, staticClass: "table__content" }, [
                  _c("td", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v(_vm._s(item.House))
                  ]),
                  _c("td", [_vm._v(_vm._s(item.Constellation))]),
                  _c("td", [_vm._v(_vm._s(item.MainAstral))]),
                  _c("td", [_vm._v(_vm._s(item.FlyInto1))]),
                  _c("td", [_vm._v(_vm._s(item.FlyInto2))])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.activeParamsTab == "相位"
        ? _c(
            "table",
            { staticClass: "phase-table" },
            [
              _c(
                "tr",
                { staticClass: "table__title" },
                [
                  _c("td"),
                  _vm._l(_vm.phases1, function(Rowitem, index) {
                    return _c("td", { key: index }, [
                      _c("div", [_vm._v(_vm._s(_vm.phases[index]))])
                    ])
                  })
                ],
                2
              ),
              _vm._l(_vm.phases1, function(RowItem, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "table__content" },
                  [
                    _c("td", [_c("div", [_vm._v(_vm._s(_vm.phases[index]))])]),
                    _vm._l(RowItem, function(ColItem, index1) {
                      return _c(
                        "td",
                        { key: index1, class: _vm.phases2[index][index1] },
                        [
                          _vm.phaseIcon[ColItem]
                            ? _c(
                                "div",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { tabindex: index + "_" + index1 },
                                  on: {
                                    click: function($event) {
                                      _vm.showId = index + "_" + index1
                                    },
                                    blur: function($event) {
                                      _vm.showId = ""
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.phaseIcon[ColItem].icon,
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phaseMean",
                                      class: {
                                        show: _vm.showId == index + "_" + index1
                                      },
                                      attrs: { id: index + "_" + index1 }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.phaseIcon[ColItem].deg) +
                                          "度\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.activeParamsTab == "相位"
        ? _c(
            "table",
            {
              staticClass: "table palace-table introduction mt_20",
              staticStyle: { "border-radius": "0px" }
            },
            [
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6),
              _vm._m(7),
              _vm._m(8),
              _vm._m(9)
            ]
          )
        : _vm._e(),
      _vm.activeParamsTab == "統計"
        ? _c("div", [
            _c(
              "table",
              { staticClass: "table pattern-table" },
              [
                _vm._m(10),
                _vm._l(_vm.P, function(item) {
                  return _c(
                    "tr",
                    { key: item.Title, staticClass: "table__content" },
                    [
                      _c("td", { staticStyle: { width: "25%" } }, [
                        _vm._v(_vm._s(item.Title))
                      ]),
                      _c(
                        "td",
                        _vm._l(item.Content.split("/"), function(item1, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item1) +
                                "\n              "
                            ),
                            _c("br")
                          ])
                        }),
                        0
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _c(
              "table",
              { staticClass: "table stats-table" },
              [
                _vm._m(11),
                _vm._l(_vm.Y, function(item) {
                  return _c(
                    "tr",
                    { key: item.Title, staticClass: "table__content" },
                    [
                      _c("td", [_vm._v(_vm._s(item.Title))]),
                      _c(
                        "td",
                        _vm._l(item.Content.split("/"), function(item1, index) {
                          return _c("span", { key: index }, [
                            _vm._v(_vm._s(item1))
                          ])
                        }),
                        0
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _c(
              "table",
              { staticClass: "table stats-table" },
              [
                _vm._m(12),
                _vm._l(_vm.T, function(item) {
                  return _c(
                    "tr",
                    { key: item.Title, staticClass: "table__content" },
                    [
                      _c("td", [_vm._v(_vm._s(item.Title))]),
                      _c(
                        "td",
                        _vm._l(item.Content.split("/"), function(item1, index) {
                          return _c("span", { key: index }, [
                            _vm._v(_vm._s(item1))
                          ])
                        }),
                        0
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _c(
              "table",
              { staticClass: "table stats-table" },
              [
                _vm._m(13),
                _vm._l(_vm.Q, function(item) {
                  return _c(
                    "tr",
                    { key: item.Title, staticClass: "table__content" },
                    [
                      _c("td", [_vm._v(_vm._s(item.Title))]),
                      _c(
                        "td",
                        _vm._l(item.Content.split("/"), function(item1, index) {
                          return _c("span", { key: index }, [
                            _vm._v(_vm._s(item1))
                          ])
                        }),
                        0
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("星體")])]),
      _c("td", [_c("span", [_vm._v("落入星座")])]),
      _c("td", [_c("span", [_vm._v("度數")])]),
      _c("td", { attrs: { colspan: "2" } }, [_c("span", [_vm._v("落入宮位")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("宮位")])]),
      _c("td", [_c("span", [_vm._v("星座")])]),
      _c("td", [_c("span", [_vm._v("宮位守護星")])]),
      _c("td", { attrs: { colspan: "2" } }, [_c("span", [_vm._v("飛入")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("線條顏色")])]),
      _c("td", [_c("span", [_vm._v("符號")])]),
      _c("td", [_c("span", [_vm._v("角度")])]),
      _c("td", [_c("span", [_vm._v("相位")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#ffd306" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/合相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("0°")]),
      _c("td", [_vm._v("合相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#f00" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/對分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("180°")]),
      _c("td", [_vm._v("對分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#00f" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/三分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("120°")]),
      _c("td", [_vm._v("三分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#f00" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/四分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("90°")]),
      _c("td", [_vm._v("四分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#00f" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("../../assets/相位icon/六分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("60°")]),
      _c("td", [_vm._v("六分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#5def60" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: {
            src: require("../../assets/相位icon/八分之三相.svg"),
            alt: ""
          }
        })
      ]),
      _c("td", [_vm._v("135°")]),
      _c("td", [_vm._v("八分之三相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#625b57" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: {
            src: require("../../assets/相位icon/十二分之五相.svg"),
            alt: ""
          }
        })
      ]),
      _c("td", [_vm._v("150°")]),
      _c("td", [_vm._v("十二分之五相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("格局")])]),
      _c("td", [_c("span", [_vm._v("詳情")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", { staticStyle: { width: "25%" } }, [
        _c("span", [_vm._v("陰陽性")])
      ]),
      _c("td", [_c("span", [_vm._v("包含星體")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", { staticStyle: { width: "25%" } }, [
        _c("span", [_vm._v("三大模式")])
      ]),
      _c("td", [_c("span", [_vm._v("包含星體")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", { staticStyle: { width: "25%" } }, [
        _c("span", [_vm._v("四大元素")])
      ]),
      _c("td", [_c("span", [_vm._v("包含星體")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
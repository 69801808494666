var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nowUserData
    ? _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "contentUser hidden-pad-down" }, [
            _c(
              "div",
              { staticClass: "user" },
              [
                _c(
                  "div",
                  { staticClass: "user__photo" },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass: "user__photo__img",
                        attrs: { size: "58" }
                      },
                      [
                        !_vm.nowTimeNowPlaceIng
                          ? _c("img", {
                              attrs: { src: _vm.nowUserData.ProfilePhotoSrc }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/推運星盤/nowTimeNowPlace.png")
                              }
                            })
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "user__info" }, [
                  _c("div", { staticClass: "user__info__name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.nowTimeNowPlaceIng
                            ? "此時此地"
                            : _vm.$NameFormat(_vm.nowUserData.Name)
                        ) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "user__info__birth" },
                    [
                      !_vm.showNowTime
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.nowUserData.BirthDay +
                                    ", " +
                                    _vm.nowUserData.BirthTime
                                ) +
                                "\n            "
                            ),
                            _c(
                              "span",
                              { staticClass: "user__info__birth--space mx_4" },
                              [_vm._v(" | ")]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.deFormat(_vm.nowUserData.Longitude) +
                                    ", \n               " +
                                    _vm.deFormat(_vm.nowUserData.Latitude)
                                ) +
                                "\n            "
                            ),
                            _vm.nowUserData.IsDaylight
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/日光節約icon.svg")
                                  }
                                })
                              : _vm._e()
                          ]
                        : _vm.nowTimeNowPlaceIng
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm
                                    .moment(new Date(_vm.format_time))
                                    .format("YYYY/MM/DD, HH:mm")
                                ) +
                                "\n            "
                            ),
                            _c(
                              "span",
                              { staticClass: "user__info__birth--space mx_4" },
                              [_vm._v(" | ")]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.nowPlace) +
                                "\n          "
                            )
                          ]
                        : [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/newAstrolabe/time.svg"),
                                alt: ""
                              }
                            }),
                            _vm._v(
                              "\n            當前時間：" +
                                _vm._s(
                                  _vm
                                    .moment(new Date(_vm.format_time))
                                    .format("YYYY/MM/DD, HH:mm")
                                ) +
                                "\n          "
                            )
                          ]
                    ],
                    2
                  )
                ]),
                _c("v-spacer"),
                _c("div", { staticClass: "user__change" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icon_change.svg"),
                      alt: ""
                    },
                    on: { click: _vm.toFriend }
                  })
                ])
              ],
              1
            ),
            _c("div", { staticClass: "contentUser__line mt_7" }),
            !_vm.EnabledMoonNote
              ? _c("div", { staticClass: "contentUser__moonday mt_11" }, [
                  _vm._v("\n      新功能"),
                  _c("img", {
                    attrs: { src: require("@/assets/moon.svg"), alt: "" }
                  }),
                  _c("span", [_vm._v("月亮日記")]),
                  _c(
                    "div",
                    {
                      staticClass: "toUnlock_cta",
                      on: {
                        click: function($event) {
                          return _vm.toStore()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/locked.svg"), alt: "" }
                      }),
                      _vm._v("\n        點此解鎖\n      ")
                    ]
                  )
                ])
              : _vm.moondayObj.Constellation
              ? _c("div", { staticClass: "contentUser__moonday mt_11" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/moon.svg"), alt: "" }
                  }),
                  _c("span", [
                    _vm._v("月亮" + _vm._s(_vm.moondayObj.Constellation) + "日")
                  ]),
                  _vm._v("\n      ，記錄當下的感受吧:)\n      "),
                  _c(
                    "div",
                    {
                      staticClass: "contentUser__moonday__toNote",
                      on: {
                        click: function($event) {
                          return _vm.addMoonNoteHandler()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icon-edit.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ])
              : _c("div", { staticClass: "contentUser__moonday mt_11" }, [
                  _vm._v("讀取中...")
                ])
          ]),
          _c(
            "div",
            { staticClass: "astrolabe" },
            [
              _c(
                "div",
                { staticClass: "userDesktopInfo mb_16 hidden-pad-down" },
                [
                  _vm.notice__useSunMode && _vm.mode == "timeLineMode"
                    ? _c("div", { staticClass: "userDesktopInfo__notice" }, [
                        _vm._m(0),
                        _c(
                          "div",
                          {
                            staticClass: "userDesktopInfo__notice__action",
                            on: {
                              click: function($event) {
                                return _vm.$emit("closeNotice__useSunMode")
                              }
                            }
                          },
                          [_vm._v("\n          不再提醒\n        ")]
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "userDesktopInfo__btnGroup mt_8" }, [
                    _c(
                      "div",
                      {
                        staticClass: "userDesktopInfo__btn",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.userDesktopInfoOpen = !_vm.userDesktopInfoOpen
                          }
                        }
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$NameFormat(_vm.nowUserData.Name)) +
                              "\n            "
                          ),
                          _vm.data2
                            ? _c("span", [_vm._v("  (內圈)")])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "ml_3 pt_1" }, [
                          _c("img", {
                            style: _vm.userDesktopInfoOpen
                              ? "transform:rotate(180deg)"
                              : "",
                            attrs: {
                              src: require("@/assets/icon_downward.svg")
                            }
                          })
                        ])
                      ]
                    ),
                    _vm.mode == "timeLineMode"
                      ? _c(
                          "div",
                          {
                            staticClass: "userDesktopInfo__btn",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.userDesktopInfoOpen = !_vm.userDesktopInfoOpen
                              }
                            }
                          },
                          [
                            _c("div", [_vm._v("推運 (外圈)")]),
                            _c("div", { staticClass: "ml_3 pt_1" }, [
                              _c("img", {
                                style: _vm.userDesktopInfoOpen
                                  ? "transform:rotate(180deg)"
                                  : "",
                                attrs: {
                                  src: require("@/assets/icon_downward.svg")
                                }
                              })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.nowTimeNowPlaceBtn
                      ? _c(
                          "div",
                          {
                            staticClass: "userDesktopInfo__btn",
                            class: {
                              nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.$emit("toNowTimeNowPlace")
                              }
                            }
                          },
                          [_vm._v("\n          此時此地\n        ")]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.userDesktopInfoOpen,
                          expression: "userDesktopInfoOpen"
                        }
                      ],
                      staticClass: "userDesktopInfo__content pt_4"
                    },
                    [
                      _c("div", { staticClass: "userDesktopInfo__item" }, [
                        _c(
                          "div",
                          { staticClass: "userDesktopInfo__place mt_5" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/map-pin.svg"),
                                alt: ""
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.nowUserData.BirthCountry +
                                    "  " +
                                    _vm.nowUserData.BirthCity
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "userDesktopInfo__birth mt_1" },
                          [
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.nowUserData.BirthDay +
                                      ", " +
                                      _vm.nowUserData.BirthTime
                                  ) +
                                  "\n              "
                              ),
                              !_vm.data2
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "userDesktopInfo__birth--space mx_4"
                                    },
                                    [
                                      _vm._v(
                                        "\n                |\n              "
                                      )
                                    ]
                                  )
                                : _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.deFormat(_vm.nowUserData.Longitude) +
                                      ", \n               " +
                                      _vm.deFormat(_vm.nowUserData.Latitude)
                                  ) +
                                  "\n              "
                              ),
                              _vm.nowUserData.IsDaylight
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/assets/日光節約icon.svg")
                                    }
                                  })
                                : _vm._e()
                            ]
                          ],
                          2
                        )
                      ]),
                      _vm.data2
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "userDesktopInfo__item userDesktopInfo__item--right"
                            },
                            [
                              _vm.mode == "timeLineMode" &&
                              !_vm.nowTimeNowPlaceIng
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "userDesktopInfo__place mt_5"
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/map-pin.svg"),
                                          alt: ""
                                        }
                                      }),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.nowUserData.BirthCountry +
                                              "  " +
                                              _vm.nowUserData.BirthCity
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.nowTimeNowPlaceIng
                                ? _c("div", { staticClass: "mt_5" }, [
                                    _vm._v("當前地點")
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "userDesktopInfo__birth mt_1" },
                                [
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm
                                            .moment(new Date(_vm.format_time))
                                            .format("YYYY/MM/DD,HH:mm")
                                        ) +
                                        "\n              "
                                    ),
                                    !_vm.data2
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "userDesktopInfo__birth--space mx_4"
                                          },
                                          [
                                            _vm._v(
                                              "\n                |\n              "
                                            )
                                          ]
                                        )
                                      : _c("br"),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.nowPlace) +
                                        "\n              "
                                    )
                                  ]
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "astrolabe__wrap",
                  class: { timeLineModeWrap: _vm.mode == "timeLineMode" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "swiper" },
                    [
                      _c(
                        "swiper",
                        { attrs: { id: "SW", options: _vm.swiperAstrolabe } },
                        _vm._l(_vm.styleArray, function(item, index) {
                          return _c(
                            "swiper-slide",
                            { key: item.id, staticClass: "astrolabe__item" },
                            [
                              _vm.canshow[index]
                                ? _c("div", { staticClass: "toast toast-s" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.displayName) +
                                        "版\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm.canshowMode == "普拉希德"
                                ? _c("div", { staticClass: "toast toast-s" }, [
                                    _vm._v(
                                      "\n              普拉希德宮位\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm.canshowMode == "太陽運勢"
                                ? _c("div", { staticClass: "toast toast-s" }, [
                                    _vm._v(
                                      "\n              太陽運勢宮位\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "astrolabe__bg",
                                  attrs: { id: "astrolabe__bg" }
                                },
                                [
                                  _vm.dataChange
                                    ? _c("canvasAstrolabe", {
                                        staticClass: "canvas-pan",
                                        attrs: {
                                          width: _vm.paramPan.width - 2,
                                          configItem: _vm.panConfigItem,
                                          panStyle: item.name,
                                          type: parseInt(_vm.type),
                                          data1: _vm.data1,
                                          data2: _vm.data2,
                                          Ret1: _vm.Ret1,
                                          role: _vm.role
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.mode == "astrometryMode" &&
                                !_vm.nowTimeNowPlaceIng,
                              expression:
                                "mode == 'astrometryMode' && !nowTimeNowPlaceIng"
                            }
                          ],
                          staticClass: "user__info__birth-m hidden-pad-up"
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/newAstrolabe/time.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v(
                            "\n          當前時間：" +
                              _vm._s(
                                _vm
                                  .moment(new Date(_vm.format_time))
                                  .format("YYYY/MM/DD,HH:mm")
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.mode == "astrometryMode" &&
                                _vm.nowTimeNowPlaceIng,
                              expression:
                                "mode == 'astrometryMode' && nowTimeNowPlaceIng"
                            }
                          ],
                          staticClass: "user__info__birth-m hidden-pad-up"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm
                                  .moment(new Date(_vm.format_time))
                                  .format("YYYY/MM/DD, HH:mm")
                              ) +
                              "\n          "
                          ),
                          _c(
                            "span",
                            { staticClass: "user__info__birth--space mx_4" },
                            [_vm._v(" | ")]
                          ),
                          _vm._v(
                            "\n          " + _vm._s(_vm.nowPlace) + "\n        "
                          )
                        ]
                      ),
                      _vm.styleArray.length > 1
                        ? _c("div", { staticClass: "switch" }, [
                            _c("div", {
                              staticClass: "swiper-button-prev",
                              attrs: { slot: "button-prev" },
                              slot: "button-prev"
                            }),
                            _c("div", {
                              staticClass: "swiper-pagination",
                              attrs: { slot: "pagination" },
                              slot: "pagination"
                            }),
                            _c("div", {
                              staticClass: "swiper-button-next",
                              attrs: { slot: "button-next" },
                              slot: "button-next"
                            })
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.mode != "timeLineMode"
                    ? _c(
                        "div",
                        {
                          staticClass: "astrolabe__setting",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.openSetup()
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/icon_setting.svg"),
                              alt: ""
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.mode == "timeLineMode"
                    ? _c("div", { staticClass: "TC__setting" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changeSystem()
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "mr-1",
                              attrs: {
                                src: require("@/assets/推運星盤/changeMode.svg")
                              }
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.System == 0,
                                    expression: "System == 0"
                                  }
                                ]
                              },
                              [_vm._v("普拉"), _c("br"), _vm._v("西德")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.System == 2,
                                    expression: "System == 2"
                                  }
                                ]
                              },
                              [_vm._v("太陽"), _c("br"), _vm._v("運勢")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openSetup()
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/icon_setting.svg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "user__change-m hidden-pad-up",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.toFriend()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icon_change.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ]
              ),
              _c("SetAstrolabe", {
                attrs: { activeSetup: _vm.activeSetup, role: _vm.role },
                on: {
                  close: function($event) {
                    _vm.activeSetup = false
                  },
                  dataChange: function($event) {
                    return _vm.dataChangeHandler($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "userDesktopInfo__notice__content" }, [
      _c("img", { attrs: { src: require("@/assets/提示燈泡.svg"), alt: "" } }),
      _c("span", [_vm._v("唐老師的直播內容都是以「太陽運勢」來說明的喔～")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
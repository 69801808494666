var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.app_ready
    ? _c(
        "v-app",
        { class: { bookStyle: _vm.bookStyle }, attrs: { id: "all" } },
        [
          _c("NavBar", { attrs: { navlogined: _vm.afterLogin } }),
          _c("v-main", [_c("router-view")], 1),
          _c("Footer"),
          _c("Toast"),
          _vm.loading || _vm.loading_force ? _c("loadingAnimation") : _vm._e(),
          _c("Warnging"),
          _c("Message"),
          _c("AlreadyWriteVue"),
          _c("Notice"),
          _c("ImageNotice"),
          _vm.showRegistation ? _c("Registration") : _vm._e(),
          _vm.todayIsNewMoon && _vm.showNewMoonNotice
            ? _c("NewMoonCTAVue", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.excludeNewMoonCTA.includes(
                      _vm.currentRouteName
                    ),
                    expression: "!excludeNewMoonCTA.includes(currentRouteName)"
                  }
                ]
              })
            : _vm._e(),
          _c("SelectAstrolabePopup")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
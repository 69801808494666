var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-slide-y-reverse-transition", [
        _vm.activeSetup
          ? _c(
              "div",
              {
                staticClass: "setup",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.activeSetup = true
                  }
                }
              },
              [
                !_vm.activeTolerance
                  ? _c("img", {
                      staticClass: "setup__scroll",
                      attrs: {
                        src: require("@/assets/icon_scroll.svg"),
                        alt: "提示下滑icon"
                      }
                    })
                  : _vm._e(),
                _c("img", {
                  staticClass: "setup__close",
                  attrs: {
                    src: require("@/assets/icon_close.svg"),
                    alt: "關閉按鈕"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.popupHandler.apply(null, arguments)
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "setup__wrap",
                    class: { activeTolerance: _vm.activeTolerance }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "setup__box planet-list" },
                      [
                        _c("div", { staticClass: "setup__title" }, [
                          _c("span", { staticClass: "title__text left" }, [
                            _vm._v("行星設置")
                          ]),
                          _c("span", { staticClass: "title__text center" }, [
                            _vm._v("容許度")
                          ]),
                          !_vm.phasePriority
                            ? _c("div", { staticClass: "setup__btnGroup" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "setup__outer",
                                    class: { active: _vm.openStrict },
                                    on: {
                                      click: function($event) {
                                        return _vm.strictHandler()
                                      }
                                    }
                                  },
                                  [_c("div", { staticClass: "setup__inner" })]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "嚴謹" +
                                      _vm._s(_vm.openStrict ? "開啟" : "關閉")
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._l(_vm.mainPlanetList, function(item, index) {
                          return _c(
                            "div",
                            { key: "A" + index, staticClass: "setup__item" },
                            [
                              _c("div", { staticClass: "item__name" }, [
                                _c(
                                  "span",
                                  { staticClass: "item__name__img iconfont" },
                                  [_vm._v(_vm._s(item.img))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "item__name__text" },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "item__num",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openTolerance(
                                        item,
                                        index,
                                        true
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.val))]
                              ),
                              _c("div", {
                                staticClass: "item__btn",
                                class: { toggleSetup: item.toggleSelect },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.mainItemClick(item, index, true)
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        _vm._l(_vm.planetList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "setup__item" },
                            [
                              _c("div", { staticClass: "item__name" }, [
                                _c(
                                  "span",
                                  { staticClass: "item__name__img iconfont" },
                                  [_vm._v(_vm._s(item.img))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "item__name__text" },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]),
                              _c("div", {
                                staticClass: "item__btn",
                                class: { toggleSetup: item.toggleSelect },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.planetSelectItemClick(
                                      item,
                                      index
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "setup__box phase-list",
                        class: { phasePriority: _vm.phasePriority }
                      },
                      [
                        _c("div", { staticClass: "setup__title" }, [
                          _c("span", { staticClass: "title__text left" }, [
                            _vm._v("相位設置")
                          ]),
                          _c("span", { staticClass: "title__text center" }, [
                            _vm._v("容許度")
                          ]),
                          _vm.phasePriority
                            ? _c("div", { staticClass: "setup__btnGroup" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "setup__outer",
                                    class: { active: _vm.openStrict },
                                    on: {
                                      click: function($event) {
                                        return _vm.strictHandler()
                                      }
                                    }
                                  },
                                  [_c("div", { staticClass: "setup__inner" })]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "嚴謹" +
                                      _vm._s(_vm.openStrict ? "開啟" : "關閉")
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._l(_vm.selPhase, function(item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.name.indexOf("144") == -1,
                                  expression: "item.name.indexOf('144') == -1"
                                }
                              ],
                              key: index,
                              staticClass: "setup__item"
                            },
                            [
                              _c("div", { staticClass: "item__name" }, [
                                _c(
                                  "span",
                                  { staticClass: "item__name__text" },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "item__num",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openTolerance(item, index)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.val))]
                              ),
                              _c("div", {
                                staticClass: "item__btn",
                                class: { toggleSetup: item.toggleSelect },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.selPhaseSelectItemClick(
                                      item,
                                      index
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                ),
                _vm.activeTolerance
                  ? _c("div", { staticClass: "tolerance" }, [
                      _c("div", { staticClass: "tolerance__title" }, [
                        _vm._v("容許度")
                      ]),
                      _c("div", { staticClass: "tolerance__notice" }, [
                        _vm._v(
                          "\n          建議採用預設值，可以提升星盤的使用體驗哦！\n        "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "tolerance__box" },
                        [
                          _c(
                            "swiper",
                            {
                              staticStyle: { padding: "5px 5px" },
                              attrs: { options: _vm.swiperTolerance }
                            },
                            _vm._l(_vm.phaseSelList, function(item, index) {
                              return _c(
                                "swiper-slide",
                                {
                                  key: index,
                                  staticClass: "item",
                                  class: {
                                    clickTolerance:
                                      item === _vm.nowEditTolerance.val
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.phaseSelVal(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.activeSetup
          ? _c(
              "div",
              {
                staticClass: "dialog__overlay",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.close()
                    _vm.activeTolerance = false
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center align-center",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.activeSetup = true
                      }
                    }
                  },
                  [
                    _vm.activeSetup
                      ? _c(
                          "div",
                          {
                            staticClass: "setup",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.activeSetup == true
                              }
                            }
                          },
                          [
                            !_vm.activeTolerance
                              ? _c("img", {
                                  staticClass: "setup__scroll",
                                  attrs: {
                                    src: require("@/assets/icon_scroll.svg"),
                                    alt: ""
                                  }
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "setup__close",
                              attrs: {
                                src: require("../assets/icon_menu-close-dark.svg")
                              },
                              on: { click: _vm.popupHandler }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "setup__wrap",
                                class: { activeTolerance: _vm.activeTolerance }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "setup__box planet-list" },
                                  [
                                    _c("div", { staticClass: "setup__title" }, [
                                      _c(
                                        "span",
                                        { staticClass: "title__text left" },
                                        [_vm._v("行星設置")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "title__text center" },
                                        [_vm._v("容許度")]
                                      ),
                                      !_vm.phasePriority
                                        ? _c(
                                            "div",
                                            { staticClass: "setup__btnGroup" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "setup__outer mr-2",
                                                  class: {
                                                    active: _vm.openStrict
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.strictHandler()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "setup__inner"
                                                  })
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  "嚴謹" +
                                                    _vm._s(
                                                      _vm.openStrict
                                                        ? "開啟"
                                                        : "關閉"
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._l(_vm.mainPlanetList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: "A" + index,
                                          staticClass: "setup__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item__name" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__name__img iconfont"
                                                },
                                                [_vm._v(_vm._s(item.img))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__name__text"
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "item__num",
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openTolerance(
                                                    item,
                                                    index,
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(item.val))]
                                          ),
                                          _c("div", {
                                            staticClass: "item__btn",
                                            class: {
                                              toggleSetup: item.toggleSelect
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.mainItemClick(
                                                  item,
                                                  index,
                                                  true
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    }),
                                    _vm._l(_vm.planetList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "setup__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item__name" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__name__img iconfont"
                                                },
                                                [_vm._v(_vm._s(item.img))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__name__text"
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "item__btn",
                                            class: {
                                              toggleSetup: item.toggleSelect
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.planetSelectItemClick(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "setup__box phase-list",
                                    class: { phasePriority: _vm.phasePriority }
                                  },
                                  [
                                    _c("div", { staticClass: "setup__title" }, [
                                      _c(
                                        "span",
                                        { staticClass: "title__text left" },
                                        [_vm._v("相位設置")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "title__text right" },
                                        [_vm._v("容許度")]
                                      ),
                                      _vm.phasePriority
                                        ? _c(
                                            "div",
                                            { staticClass: "setup__btnGroup" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "setup__outer mr-2",
                                                  class: {
                                                    active: _vm.openStrict
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.strictHandler()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "setup__inner"
                                                  })
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  "嚴謹" +
                                                    _vm._s(
                                                      _vm.openStrict
                                                        ? "開啟"
                                                        : "關閉"
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._l(_vm.selPhase, function(item, index) {
                                      return _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.name.indexOf("144") == -1,
                                              expression:
                                                "item.name.indexOf('144') == -1"
                                            }
                                          ],
                                          key: index,
                                          staticClass: "setup__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item__name" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__name__text"
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "item__num",
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openTolerance(
                                                    item,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(item.val))]
                                          ),
                                          _c("div", {
                                            staticClass: "item__btn",
                                            class: {
                                              toggleSetup: item.toggleSelect
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.selPhaseSelectItemClick(
                                                  item,
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            ),
                            _vm.activeTolerance
                              ? _c("div", { staticClass: "tolerance" }, [
                                  _c(
                                    "div",
                                    { staticClass: "tolerance__title" },
                                    [_vm._v("容許度")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "tolerance__notice" },
                                    [
                                      _vm._v(
                                        "\n              建議採用預設值，可以提升星盤的使用體驗哦！\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "tolerance__box" },
                                    [
                                      _c(
                                        "swiper",
                                        {
                                          staticStyle: { padding: "5px 5px" },
                                          attrs: {
                                            options: _vm.swiperTolerance
                                          }
                                        },
                                        _vm._l(_vm.phaseSelList, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "swiper-slide",
                                            {
                                              key: index,
                                              staticClass: "item",
                                              class: {
                                                clickTolerance:
                                                  item ===
                                                  _vm.nowEditTolerance.val
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.phaseSelVal(item)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(item))]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Base from "@/api/_base";
import store from "@/store";

let { CalculateAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
let urlencoded_config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
let timer = {};
CalculateAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
CalculateAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {
  //----------------基本------------------

  //----------------本命------------------
  //取得-星體資訊資料(新)
  Post_AstralInformation: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/NatalChart/AstralInformation`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/NatalChart/AstralInformation"]);
        ErrorHandle("Post_AstralInformation error", {
          Method: "post",
          Url: "/NatalChart/AstralInformation",
          Msg: err,
        });
      }
    }
  },
  //取得-解讀資料
  Get_AstrolabeInterpretations: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/NatalChart/Interpretations`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/NatalChart/Interpretations"]);
        ErrorHandle("Get_AstrolabeInterpretations error", {
          Method: "post",
          Url: "/NatalChart/Interpretations",
          Msg: err,
        });
      }
    }
  },
  //取得-詳細參數資料
  Get_AstrolabeParams: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      Config.closeLoadAnimation = true; //關閉動畫
      try {
        let { data } = await CalculateAPI.post(
          `/NatalChart/Parameter`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/NatalChart/Parameter"]);
        ErrorHandle("Get_AstrolabeParams error", {
          Method: "post",
          Url: "/NatalChart/Parameter",
          Msg: err,
        });
      }
    }
  },
  //----------------推運盤------------------
  //取得-星體資訊資料(輸入任意時間地點取得弧度)
  POST_TcAstralInfo: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.closeLoadAnimation = true; //關閉動畫
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/TransitChart/AstralInformation`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/TransitChart/AstralInformation"]);
        ErrorHandle("POST_TcAstralInfo error", {
          Method: "post",
          Url: "/TransitChart/AstralInformation",
          Msg: err,
        });
      }
    }
  },
  //取得推運星盤解讀
  POST_LcInterpretations: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.closeLoadAnimation = true; //關閉動畫
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/TransitChart/Interpretations`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/TransitChart/Interpretations"]);

        ErrorHandle("POST_LcInterpretations error", {
          Method: "post",
          Url: "/TransitChart/Interpretations",
          Msg: err,
        });
      }
    }
  },
  //取得時間軸詳細參數
  POST_TcAstrolabeParams: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.closeLoadAnimation = true; //關閉動畫
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await CalculateAPI.post(
          `/TransitChart/Parameters`,
          { AesEncryptRequestParameters: Encrypt(JSON.stringify(Arg)) },
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/TransitChart/Parameters"]);

        ErrorHandle("POST_LcInterpretations error", {
          Method: "post",
          Url: "/TransitChart/Parameters",
          Msg: err,
        });
      }
    }
  },
};

import moment from 'moment'
export default {
  data() {
    return {
      //相位map
      phaseIcon: {
        合: {
          deg: "0",
          icon: require("@/assets/相位icon/合相.svg"),
        },
        沖: {
          deg: "180",
          icon: require("@/assets/相位icon/對分相.svg"),
        },
        刑: {
          deg: "90",
          icon: require("@/assets/相位icon/四分相.svg"),
        },
        梅: {
          deg: "150",
          icon: require("@/assets/相位icon/十二分之五相.svg"),
        },
        八分之三: {
          deg: "135",
          icon: require("@/assets/相位icon/八分之三相.svg"),
        },
        拱: {
          deg: "120",
          icon: require("@/assets/相位icon/三分相.svg"),
        },
        六合: {
          deg: "60",
          icon: require("@/assets/相位icon/六分相.svg"),
        },
        //api
        Conjunction: {
          deg: "0",
          icon: require("@/assets/相位icon/合相.svg"),
        },
        Opposition: {
          deg: "180",
          icon: require("@/assets/相位icon/對分相.svg"),
        },
        Square: {
          deg: "90",
          icon: require("@/assets/相位icon/四分相.svg"),
        },
        梅: {
          deg: "150",
          icon: require("@/assets/相位icon/十二分之五相.svg"),
        },
        八分之三: {
          deg: "135",
          icon: require("@/assets/相位icon/八分之三相.svg"),
        },
        Trine: {
          deg: "120",
          icon: require("@/assets/相位icon/三分相.svg"),
        },
        Sextile: {
          deg: "60",
          icon: require("@/assets/相位icon/六分相.svg"),
        },
      },
    };
  },
  methods: {
    deFormat(string) {
      let stringArray = string.split(".");
      if (stringArray.length > 1) {
        stringArray[1] = stringArray[1].slice(2, 4);
        return stringArray.join("");
      } else {
        return stringArray.join("");
      }
    },
    birthFormat(time) {
      return moment(time).format('YYYY/MM/DD HH:mm')
    }
  },
};

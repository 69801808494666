<template>
  <div class="params">
    <div class="params__tab">
      <div
        class="params__tab_item"
        :class="{ active: activeParamsTab === type }"
        v-for="type in ['星位', '宮位', '相位', '科普']"
        :key="type"
        @click="switchTab(type)"
      >
        {{ type }}
      </div>
    </div>
    <div class="params__content">
      <!--1.星位-->
      <table class="table planet-table" v-if="activeParamsTab == '星位'">
        <tr class="table__title">
          <td><span>星體</span></td>
          <td><span>落入星座</span></td>
          <td><span>度數</span></td>
          <td colspan="2"><span>落入宮位</span></td>
        </tr>
        <tr
          v-for="(item, index) in ConstellationLists"
          class="table__content"
          :key="index"
        >
          <td>
            <!-- <img :src="item.img" alt /> -->
            <span class="item__name__img iconfont">{{ item.img }}</span>
            {{ item.Astral }}
          </td>
          <td>{{ item.Constellation }}座</td>
          <td>
            {{ item.Angle }}

            <span class="retrograde" v-if="index < 10">{{
              item.Retrograde
            }}</span>
          </td>
          <td>
            {{ item.House }}
          </td>
        </tr>
      </table>
      <!--2.宮位-->
      <table class="table palace-table" v-if="activeParamsTab == '宮位'">
        <tr class="table__title">
          <td><span>宮位</span></td>
          <td><span>星座</span></td>
          <td><span>宮位守護星</span></td>
          <td colspan="2"><span>飛入</span></td>
        </tr>
        <tr
          v-for="(item, index) in palaces"
          class="table__content"
          :key="index"
        >
          <td style="font-size: 12px">{{ item.House }}</td>
          <td>{{ item.Constellation }}</td>
          <td>{{ item.MainAstral }}</td>
          <td>{{ item.FlyInto1 }}</td>
          <td>{{ item.FlyInto2 }}</td>
        </tr>
      </table>
      <!--3.相位-->
      <table class="table aspect-table" v-if="activeParamsTab == '相位'">
        <tr class="table__title">
          <td><span>行星</span></td>
          <td><span>相位</span></td>
          <td><span>本命</span></td>
          <td><span>方向</span></td>
          <td><span>容許度</span></td>
        </tr>
        <tr
          v-for="(item, index) in AspectLists"
          class="table__content"
          :key="index"
        >
          <td>
            <span
              class="item__name__img iconfont"
              :style="{
                color: item.TcAstral.indexOf('王') > -1 ? '#e99a00' : '#27aba3',
              }"
              >{{ getPlantImg(item.TcAstral) }}</span
            >
            {{ item.TcAstral }}
          </td>
          <td>
            <img :src="phaseIcon[item.Aspect].icon" alt="" />
          </td>
          <td>
            <span
              class="item__name__img iconfont"
              :style="{
                color: item.BcAstral.indexOf('王') > -1 ? '#e99a00' : '#27aba3',
              }"
              >{{ item.img }}</span
            >
            {{ item.BcAstral }}
          </td>
          <td>{{ item.Way }}</td>
          <td>{{ item.Degree }}</td>
        </tr>
      </table>
      <!--4.科普-->
      <table
        class="table palace-table introduction"
        v-if="activeParamsTab == '科普'"
      >
        <tr class="table__title">
          <td><span>線條顏色</span></td>
          <td><span>符號</span></td>
          <td><span>角度</span></td>
          <td><span>相位</span></td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #ffd306"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/合相.svg" alt="" />
          </td>
          <td>0°</td>
          <td>合相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #f00"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/對分相.svg" alt="" />
          </td>
          <td>180°</td>
          <td>對分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #00f"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/三分相.svg" alt="" />
          </td>
          <td>120°</td>
          <td>三分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #f00"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/四分相.svg" alt="" />
          </td>
          <td>90°</td>
          <td>四分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #00f"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/六分相.svg" alt="" />
          </td>
          <td>60°</td>
          <td>六分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #5def60"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/八分之三相.svg" alt="" />
          </td>
          <td>135°</td>
          <td>八分之三相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #625b57"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/十二分之五相.svg" alt="" />
          </td>
          <td>150°</td>
          <td>十二分之五相</td>
        </tr>
        <!-- <div class="introduction">
          <div class="introduction__title">相位線條介紹</div>
          <div class="introduction__content">
           <p style="color:#007500">——— 120°</p>
           <p style="color:#0066cc">——— 60°</p>
           <p style="color:#EA0000">——— 90°</p>
           <p style="color:#0000c6">——— 180°</p>
          </div>
        </div> -->
      </table>
    </div>
  </div>
</template>

<script>
import phaseMixin from "@/mixin/phaseMixin";

export default {
  props: ["BcAstralInfo", "TcAstralInfo", "role", "moondayObj"],
  mixins: [phaseMixin],

  data() {
    return {
      activeSelect: false,
      NowInterpret: [{ name: "" }],
      openHouse: false,
      activeParamsTab: "星位",
      planets: require("@/js/planets"),
      planets2: require("@/js/planets2"),
      palaces: null,
      AspectLists: [], //行星相位
      statistics: {
        P: [],
        Q: [],
        T: [],
        Y: [],
      },
      ConstellationLists: [], //星位
    };
  },
  mounted() {
    this.getParams();
  },
  computed: {
    DetailNeed() {
      if (this.role != "") {
        return this.$store.state[this.role].mainPlanetList.concat(
          this.$store.state[this.role].planetList
        );
      } else {
        return this.$store.state.mainPlanetList.concat(
          this.$store.state.planetList
        );
      }
    },
  },
  methods: {
    switchTab(type) {
      this.activeParamsTab = type || "星位";
    },
    //取得參數(需呼叫因為要刷新詳細參數內容、表格)
    getParams() {
      let DetailNeed = this.DetailNeed;
      let detailNeed_fixed = [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];
      this.$API
        .POST_TcAstrolabeParams({
          SetupConfigs: detailNeed_fixed,
          BcAstralInfo: this.BcAstralInfo,
          TcAstralInfo: this.TcAstralInfo,
        })
        .then((res) => {
          let data = res.Data;
          this.$emit("update:moondayObj", data.ConstellationLists[1]);
          //星位
          this.ConstellationLists = data.ConstellationLists;
          this.ConstellationLists.forEach((item) => {
            let planet = this.planets.find(
              (planet) => planet.name == item.Astral
            );
            let planets2 = this.planets2.find(
              (planet) => planet.name == item.Astral
            );
            if (planet) {
              item.img = planet.img;
              item.imageUrl = planet.imageUrl;
            }
            if (planets2) {
              item.img = planets2.img;
              item.imageUrl = planets2.imageUrl;
            }
          });
          this.$forceUpdate();

          //宮位
          this.palaces = data.HouseLists;

          //相位
          this.AspectLists = data.AspectLists;

          // //科普
          let statisticsObj = {
            P: data.Statistics.PatternLists, //格局
            Q: data.Statistics.FourElementLists, //四大元素
            T: data.Statistics.ThreeModelLists, //三大模式
            Y: data.Statistics.YinYangLists, //陰陽
          };
          this.statistics = statisticsObj;
        });
    },
    getPlantImg(name) {
      let target = this.planets.find((item) => item.name === name);
      let target2 = this.planets2.find((item) => item.name === name);
      if (target) {
        return target.img;
      } else if (target2) {
        return target2.img;
      }
    },
  },
  watch: {
    TcAstralInfo() {
      this.getParams();
    },
    BcAstralInfo() {
      this.getParams();
    },
    DetailNeed: {
      handler() {
        this.getParams();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";
@import "@/scss/Astrolabe.scss";
.introduction {
  &__line {
    margin: 0 auto;
    width: 90%;
    height: 4px;
  }
}
</style>

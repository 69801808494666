var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c(
        "FixedPopup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.noticePopup,
              expression: "noticePopup"
            }
          ]
        },
        [
          _c("div", { staticClass: "imageNotice__box" }, [
            _c("img", {
              staticClass: "imageNotice__img",
              attrs: { src: _vm.imgList[_vm.imageIdx], alt: "" }
            }),
            _c("div", {
              staticClass: "imageNotice__title",
              domProps: { innerHTML: _vm._s(_vm.title) }
            }),
            _c("div", {
              staticClass: "imageNotice__subtitle",
              domProps: { innerHTML: _vm._s(_vm.desc) }
            }),
            _c(
              "div",
              {
                staticClass: "imageNotice__btn",
                on: {
                  click: function($event) {
                    return _vm.ok()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.okText))]
            ),
            _vm.cancelText
              ? _c(
                  "div",
                  {
                    staticClass: "imageNotice__textBtn",
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("\n        " + _vm._s(_vm.cancelText) + "\n      ")]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Base from "@/api/_base";
import store from "@/store";

let { MemberAPI, Encrypt, ErrorHandle, TimeoutHandler } = Base;
let baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
let urlencoded_config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
let timer = {};
MemberAPI.interceptors.request.use(
  function (config) {
    if (timer[config.url]) {
      clearTimeout(timer[config.url]);
    }
    timer[config.url] = setTimeout(() => {
      TimeoutHandler(config.url, config.method);
    }, 30000);
    if (config.closeLoadAnimation) {
    } else {
      store.commit("setLoading", true);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
MemberAPI.interceptors.response.use(
  function (response) {
    clearTimeout(timer[response.config.url]);
    store.commit("setLoading", false);
    return response;
  },
  function (error) {
    console.log("error", error);
    clearTimeout(timer[error.config.url]);
    store.commit("setLoading", false);
    return Promise.reject(error);
  }
);
export default {
  //----------------基本------------------
  //會員登入
  Login: async (DESed) => {
    let Arg = {
      EncryptPlatformId: DESed,
      FromNode: localStorage.getItem("source") || "Website",
    };
    try {
      let { data } = await MemberAPI.post("/Basic/Login", Arg);
      return data;
    } catch (err) {
      alert("登入失敗，請稍後再試");
      clearTimeout(timer["/Basic/Login"]);

      ErrorHandle("Login", {
        Method: "POST",
        Url: "/Basic/Login",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //會員註冊
  Add_MemberInfo: async (Arg) => {
    try {
      let { data } = await MemberAPI.post("/Basic/Register", Arg);
      return data;
    } catch (err) {
      clearTimeout(timer["/Basic/Register"]);
      ErrorHandle("Add_MemberInfo", {
        Method: "POST",
        Url: "/Basic/Register",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //會員歸戶
  Add_ImportToAccount: async (Arg) => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    try {
      let { data } = await MemberAPI.post(
        "/Basic/ImportToAccount",
        Arg,
        Config
      );
      return data;
    } catch (err) {
      clearTimeout(timer["/Basic/ImportToAccount"]);
      ErrorHandle("Add_ImportToAccount", {
        Method: "POST",
        Url: "/Basic/ImportToAccount",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  POST_SetNoNeedToRemind: async () => {
    let Config = JSON.parse(JSON.stringify(baseConfig));
    Config.headers.Authorization = "Bearer " + store.state.token;
    try {
      let { data } = await MemberAPI.post(
        "/Basic/SetNoNeedToRemind",
        null,
        Config
      );
      return data;
    } catch (err) {
      clearTimeout(timer["/Basic/SetNoNeedToRemind"]);
      ErrorHandle("Update_SetNoNeedToRemind", {
        Method: "POST",
        Url: "/Basic/SetNoNeedToRemind",
        Msg: err,
        StatusCode: err.response.status || "",
      });
    }
  },
  //取得-會員資訊資料*
  Get_MemberInfo: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      let DESed = window.localStorage.getItem("AC");
      let Arg = {
        EncryptPlatformId: DESed,
      };
      try {
        let { data } = await MemberAPI.post("/Basic/Detail", Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/Basic/Detail"]);
        ErrorHandle("Get_MemberInfo error", {
          Method: "POST",
          Url: "/Basic/Detail",
          Msg: err,
        });
      }
    }
  },
  //取得-Google使用者資訊資料
  Get_Google_Info: async (id_token) => {
    try {
      let { data } = await MemberAPI.get(
        `/Basic/GoogleUserInfomation?id_token=${id_token}`
      );
      return data;
    } catch (err) {
      clearTimeout(timer[`/Basic/GoogleUserInfomation?id_token=${id_token}`]);

      ErrorHandle();
    }
  },
  //-------------------本命盤-------------------

  //新增-好友資料*
  Add_AstrolabeFile: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.post(`/AstrolabeFriend`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer[`/AstrolabeFriend`]);
        ErrorHandle("Add_AstrolabeFile error", {
          Method: "post",
          Url: "/AstrolabeFriend",
          Msg: err,
        });
      }
    }
  },
  //取得-好友資料*
  Get_AstrolabeFile: async (AstrolabeFileId) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.get(
          `/AstrolabeFriend?AstrolabeFileId=${AstrolabeFileId}`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/AstrolabeFriend"]);
        ErrorHandle("Get_AstrolabeFile error", {
          Method: "get",
          Url: "/AstrolabeFriend",
          Msg: err,
        });
      }
    }
  },
  //修改-好友資料*
  Update_AstrolabeFile: async (Arg) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.put(`/AstrolabeFriend`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/AstrolabeFriend"]);
        ErrorHandle("Update_AstrolabeFile error", {
          Method: "put",
          Url: "/AstrolabeFriend",
          Msg: err,
        });
        return false;
      }
    }
  },
  //刪除-好友資料*
  Delete_AstrolabeFile: async (AstrolabeFileId) => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.delete(
          `/AstrolabeFriend?AstrolabeFileId=${AstrolabeFileId}`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/NatalChart/Friend"]);
        ErrorHandle("Delete_AstrolabeFile error", {
          Method: "delete",
          Url: "/NatalChart/Friend",
          Msg: err,
        });
      }
    }
  },
  //禮物-取得-禮物列表資料
  Get_GiftsList: async () => {
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.get(
          `/Gifts/List`,
          Config
        );
        return data;
      } catch (err) {
        clearTimeout(timer["/Gifts/List"]);
      }
    }
  },
  //禮物-禮物啟用
  Post_AstrolabeFile: async (GiftID, Arg = null) => {
    //Arg:ProductForm (若禮物為「宮位解讀」時，則須填寫此欄位)
    if (store.state.token) {
      let Config = JSON.parse(JSON.stringify(baseConfig));
      Config.headers.Authorization = "Bearer " + store.state.token;
      try {
        let { data } = await MemberAPI.put(`/Gifts/${GiftID}`, Arg, Config);
        return data;
      } catch (err) {
        clearTimeout(timer["/Gifts/{GiftID}"]);
        ErrorHandle("Post_AstrolabeFile error", {
          Method: "put",
          Url: "/Gifts/{GiftID}",
          Msg: err,
        });
        return false;
      }
    }
  },

};

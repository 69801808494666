<template>
  <div
    class="row-wrap custom-container"
    v-if="$store.state.userData.IsUnlockLC"
  >
    <div
      class="params_box"
      v-if="nowUserData && TcAstralInfo && nowUserAstralInfo"
    >
      <TimeLine :format_time.sync="format_time" />
      <div class="detail__tab">
        <div
          class="detail__tab__item"
          :class="{ activeDetailTab: activeDetailTab == 'interpret' }"
          @click="activeDetailTab = 'interpret'"
        >
          推運解讀
        </div>
        <div
          class="detail__tab__item"
          :class="{ activeDetailTab: activeDetailTab == 'params' }"
          @click="activeDetailTab = 'params'"
        >
          詳細參數
        </div>
      </div>
      <Interpret
        v-show="activeDetailTab == 'interpret'"
        :TcAstralInfo="TcAstralInfo"
        :BcAstralInfo="nowUserAstralInfo"
      />
      <Params
        class="mt-5"
        v-show="activeDetailTab == 'params'"
        :BcAstralInfo="nowUserAstralInfo"
        :TcAstralInfo="TcAstralInfo"
        :role="role"
        :moondayObj.sync="moondayObj"
      />
    </div>
    <!-- 提醒 -->
    <div
      class="notice-pri pl_10 pr_10 hidden-mobile-up"
      v-if="notice__useSunMode"
    >
      <div class="notice-pri-inner">
        <img src="@/assets/提示燈泡.svg" alt="" />
        <div>
          唐老師的直播內容都是以「太陽運勢」來說明的喔～
          <div class="action" @click="closeNotice__useSunMode()">不再提醒</div>
        </div>
      </div>
    </div>

    <MoonDayCTA
      class="hidden-pad-up mt_10 mb_10"
      v-if="TcAstralInfo && nowUserAstralInfo"
      :data2="TcAstralInfo.AstralRadians"
      :nowPlace="`${Longitude ? Longitude : deFormat(nowUserData.Longitude)}, 
                 ${Latitude ? Latitude : deFormat(nowUserData.Latitude)}`"
      :nowTimeNowPlaceIng="nowTimeNowPlaceIng"
      :format_time="format_time"
      mode="timeLineMode"
      :moondayObj="moondayObj"
    />
    <NewAstrolabe
      v-if="TcAstralInfo && nowUserAstralInfo"
      :data1="nowUserAstralInfo.AstralRadians"
      :data2="TcAstralInfo.AstralRadians"
      :format_time="format_time"
      :type="1"
      :styleArray="styleArray"
      :role="role"
      :Ret1="TcAstralInfo.RetAstralSet"
      :nowPlace="`${Longitude ? Longitude : deFormat(nowUserData.Longitude)}, 
                 ${Latitude ? Latitude : deFormat(nowUserData.Latitude)}`"
      :nowTimeNowPlaceIng="nowTimeNowPlaceIng"
      mode="timeLineMode"
      @changeSystem="changeSystemHander($event)"
      :hiddenuserDesktopInfo="true"
      :moondayObj="moondayObj"
      :notice__useSunMode="notice__useSunMode"
      @closeNotice__useSunMode="closeNotice__useSunMode"
    >
    </NewAstrolabe>
  </div>
</template>

<script>
// TODO: 子組件事件timeline_update>updateTime>更新format_time>更新Arg>呼叫API>更新星盤
import NewAstrolabe from "@/components/星盤組件/new_astrolabe";
import TimeLine from "@/components/星盤組件/Timeline_bar";
import Interpret from "@/components/推運解讀/Interpret";
import TaiwanList from "@/js/taiwanList.json";
import moment from "moment";
import phaseMixin from "@/mixin/phaseMixin";

//詳細參數組件
import Params from "@/components/星盤組件/Params_timeline.vue";
import MoonDayCTA from "../../components/星盤組件/MoonDayCTA.vue";
export default {
  mixins: [phaseMixin],
  components: {
    NewAstrolabe,
    TimeLine,
    Params,
    Interpret,
    MoonDayCTA,
  },
  data() {
    return {
      nowUserAstralInfo: null,
      activeDetailTab: "interpret",
      role: "TimeLine",
      format_time: moment
        ? moment().format("YYYY/MM/DD HH:mm")
        : "1970/01/01 12:00",
      //時間軸資料
      TcAstralInfo: null,
      initGeo: false,
      ready: false,
      first: true,
      styleArray: [
        { name: "JOE2", displayName: "專業", show: false, id: "alert4" },
        // { name: "专业", displayName: "專業", show: false, id: "alert3" },
      ],
      Latitude: null, //"21.01N"
      Longitude: null, //""121.5E"
      TimeZone: null,
      nowTimeNowPlaceIng: true,
      waitGeoloationApiTimer: null,
      moondayObj: {},
      notice__useSunMode: false,
    };
  },
  computed: {
    nowUserData() {
      return this.$store.state.nowUserData;
    },
    findCountryCode() {
      let target = this.nowUserData.BirthCountry;
      let CountryCode = this.$CountryCode.find((item) => {
        return item.ChineseName == target;
      });
      return CountryCode ? CountryCode.Code.split(" ")[0] : "TW";
    },
    userZone() {
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.findCountryCode;
      });
      return Country ? parseFloat(Country.Zone) : 8.0;
    },
    Arg() {
      if (this.nowUserData) {
        return {
          AstrolabeType: "LC",
          AstrolabeFileId: this.nowUserData.AstrolabeFileId,
          BirthTime: this.format_time,
          IsDaylight: this.nowUserData.IsDaylight,
          BirthLocation: {
            Place: this.findPlaceEngName(this.nowUserData.BirthPlace),
            Country: this.nowUserData.BirthCountry.split("(")[0],
            State: this.nowUserData.BirthState,
            City: this.nowUserData.BirthCity,
            CountryCode: this.findCountryCode,
            TimeZone: this.TimeZone,
            Latitude: this.Latitude,
            Longitude: this.Longitude,
          },
        };
      }
      return null;
    },
  },
  async created() {
    //  alert('即將開放，敬請期待')
    this.$store.commit("setLoading_force", true);
    if (!this.$store.state.userData.IsUnlockLC) {
      let productId = this.$store.getters.productListMap["TransitChart"];
      this.$router.replace(`/store/detail/${productId}`);
      this.$store.commit("setLoading_force", false);
      return;
    }
    if (this.nowUserData) {
      const res = await this.$API.Get_AstrolabeFile(
        this.nowUserData.AstrolabeFileId
      );
      this.$store.commit("set_nowUserData", res.Data);
      const nowUserAstralInfo = await this.$API.Post_AstralInformation({
        AstrolabeFileId: this.nowUserData.AstrolabeFileId,
        HouseSystem: 2,
        IsSolarHouse: true,
      });
      this.nowUserAstralInfo = nowUserAstralInfo.Data;
    } else {
      this.$router.replace("/");
      this.$store.commit("setLoading_force", false);
      return;
    }
    if (!localStorage.getItem("notice__useSunMode")) {
      this.notice__useSunMode = true;
    }
  },
  mounted() {
    //waitGeoloationApiTimer 機制
    this.waitGeoloationApiTimer = setTimeout(() => {
      alert(
        "因「推運功能」需要取得當前位置才能更精準的計算，請於瀏覽器彈窗選擇「允許/確定由本服務使用您的位置資訊」，若仍有問題請至您的裝置定位功能中開啟瀏覽器及Line的位置存取權。"
      );
      this.TimeZone = this.userZone;
      this.Latitude = null;
      this.Longitude = null;
      this.$nextTick(() => {
        this.initGeo = true;
      });
      this.$store.commit("setLoading_force", false);
      this.nowTimeNowPlaceIng = false;
    }, 12000);
    //判斷geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          try {
            if (this.waitGeoloationApiTimer)
              clearTimeout(this.waitGeoloationApiTimer);
            this.Latitude =
              position.coords.latitude > 0
                ? parseFloat(position.coords.latitude).toFixed(2) + "N"
                : parseFloat(position.coords.latitude).toFixed(2) + "S";
            this.Longitude =
              position.coords.longitude > 0
                ? parseFloat(position.coords.longitude).toFixed(2) + "E"
                : parseFloat(position.coords.longitude).toFixed(2) + "W";
            this.TimeZone = 0 - new Date().getTimezoneOffset() / 60;
            this.$nextTick(() => {
              this.initGeo = true;
            });
          } catch (error) {
            throw error;
          }
        },
        (error) => {
          if (this.waitGeoloationApiTimer)
            clearTimeout(this.waitGeoloationApiTimer);
          alert(
            "因「推運功能」需要取得當前位置才能更精準的計算，請於瀏覽器彈窗選擇「允許/確定由本服務使用您的位置資訊」，若仍有問題請至您的裝置定位功能中開啟瀏覽器及Line的位置存取權。"
          );
          this.TimeZone = this.userZone;
          this.Latitude = null;
          this.Longitude = null;
          this.$nextTick(() => {
            this.initGeo = true;
          });
          this.$store.commit("setLoading_force", false);
          this.nowTimeNowPlaceIng = false;
        }
      );
    } else {
      if (this.waitGeoloationApiTimer)
        clearTimeout(this.waitGeoloationApiTimer);
      alert(
        "提醒您，目前所使用的瀏覽器不支援取得當前座標地點，因此將無法正常使用「天象星盤」，若要使用此服務請換其他瀏覽器操作看看喔。"
      );
      this.$store.commit("setLoading_force", false);
      this.$router.replace("/");
    }
  },
  destroyed() {
    if (this.waitGeoloationApiTimer) clearTimeout(this.waitGeoloationApiTimer);
  },
  methods: {
    closeNotice__useSunMode() {
      this.notice__useSunMode = false;
      localStorage.setItem("notice__useSunMode", false);
    },
    updateTime($event) {
      this.format_time = $event;
    },
    update_timeLineData() {
      if (!this.initGeo) return;
      if (this.Arg) {
        this.$API
          .POST_TcAstralInfo(this.Arg)
          .then((res) => {
            this.$store.commit("setLoading_force", false);
            this.ready = true;
            if (res.Data.Result) {
              this.TcAstralInfo = res.Data.AstralInfo;
            } else {
              alert("國家地點找不到");
              this.$router.replace("/friend?mode=timeline");
            }
          })
          .catch(() => {
            this.TimeZone = this.userZone;
            this.Latitude = null;
            this.Longitude = null;
            this.$nextTick(() => {
              this.initGeo = true;
            });
            this.$store.commit("setLoading_force", false);
            this.nowTimeNowPlaceIng = false;
          });
      }
    },
    findPlaceEngName(ChineseName) {
      let engName = TaiwanList.find((item) => {
        return item.text == ChineseName;
      });
      if (!engName) alert("找無該區");
      return engName ? engName.value : "Taipei";
    },
    async changeSystemHander($evnet) {
      const nowUserAstralInfo = await this.$API.Post_AstralInformation({
        AstrolabeFileId: this.nowUserData.AstrolabeFileId,
        HouseSystem: $evnet,
        IsSolarHouse: $evnet == 2,
      });
      this.nowUserAstralInfo = nowUserAstralInfo.Data;
    },
  },
  watch: {
    Arg() {
      if (this.first && this.initGeo) {
        this.first = false;
      } else {
        this.update_timeLineData();
      }
    },
    initGeo() {
      this.update_timeLineData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.row-wrap {
  @include align(space-around, flex-start);
  margin: 36px auto;
  @include laptop {
    flex-direction: column;
    @include center;
    .params_box {
      order: 2;
    }
  }
  @include pad {
    margin: 0 auto;
    padding: 0;
  }
}

.container {
  max-width: 1056px;
}

.params_box {
  width: 44%; //448px
  @include align(flex-start, center);
  flex-direction: column;
  margin-right: 30px;
  @include laptop {
    margin-right: 0;
    margin-top: 2px;
    width: 70%;
  }
  @include pad {
    width: 100%;
    padding: 0 16px;
  }
}
.detail__tab {
  width: 100%;
  @include align(center, center);

  .detail__tab__item {
    @include btn(50%, 48px, transparent, $theme-color-1, 20px);
    border: solid 2px $theme-color-1;
    transition: 0.2s;

    &.activeDetailTab {
      background-color: $theme-color-1;
      color: $theme-color-3;
      border: none;
      font-weight: bold;
    }

    @include mobile {
      height: 36px;
      font-size: 16px;
    }
  }
}
</style>

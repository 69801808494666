<template>
  <transition name="fade">
    <div class="toast" v-if="showStatus">{{ text }}</div>
  </transition>
</template>

<script>
export default {
  computed: {
    showStatus() {
      return this.$store.state.toastObj.show;
    },
    text() {
      return this.$store.state.toastObj.text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/toast.scss";
.toast {
  position: fixed;
  color: #292929;
  font-weight: 700;
  padding: 36px 16px;
  z-index: 99999;
}
</style>

<template>
  <div class="noticePopupContainer" v-show="noticePopup">
    <!-- 修改後 -->
    <div class="noticePopup" v-if="IsUnlockBC">
      <div class="noticePopup__title">
        資料修改後<br />
        需重新購買宮位解讀
      </div>
      <div class="noticePopup__content">
        <div class="noticePopup__content__icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#E2562C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.80013 3.2L6.30493 3.7704L7.10493 9.3704L7.60093 9.8H8.40093L8.89533 9.3704L9.69533 3.7704L9.20013 3.2H6.80013ZM7.40013 11.2L6.90013 11.7V12.1L7.40013 12.6H8.60013L9.10013 12.1V11.7L8.60013 11.2H7.40013Z"
              fill="white"
            />
          </svg>
        </div>
        <div>
          原本的宮位解讀將因修改資料後不再適用，需重新購買宮位解讀來進行星盤分析。
        </div>
      </div>
      <div class="noticePopup__checkbox">
        <label for="isOk">
          <div class="checkbox" :class="{ active: isOk }">
            <input type="checkbox" v-model="isOk" id="isOk" />
            <div class="checkmark"></div>
          </div>
          <div>我已了解修改資料後，需重新購買宮位解讀的說明</div>
        </label>
      </div>
      <div class="noticePopup__btn">
        <div class="noticePopup__btn__cancel" @click="cancel">取消修改</div>
        <div
          class="noticePopup__btn__ok"
          @click="save"
          :class="{ disabled: !isOk }"
        >
          確認儲存
        </div>
      </div>
    </div>
    <!-- 修改前 -->
    <div class="noticePopup" v-if="!IsUnlockBC">
      <div class="noticePopup__title">修改帳戶資料</div>
      <div class="noticePopup__subtitle mt_8">確定要修改帳戶資料嗎？</div>
      <div class="noticePopup__btn">
        <div class="noticePopup__btn__cancel" @click="cancel">取消修改</div>
        <div class="noticePopup__btn__ok" @click="save">確認儲存</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    return {
      noticePopup: false,
      event: null,
      isOk: false,
    };
  },
  created() {
    Vue.prototype.$noticePopup = (event) => {
      this.noticePopup = true;
      this.event = event;
    };
  },
  computed: {
    IsUnlockBC() {
      if (this.$store.state.userBeEdit) {
        return this.$store.state.userBeEdit.IsUnlockBC;
      } else {
        return false;
      }
    },
  },
  methods: {
    cancel() {
      this.noticePopup = false;
    },
    save() {
      this.event ? this.event() : () => {};
      this.noticePopup = false;
      this.isOk = false;
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";
.noticePopupContainer {
  @include overlay;
  width: 100vw;
  height: 100vh;
  background: rgba(240, 242, 244, 0.15);
  backdrop-filter: blur(30px);
  .noticePopup {
    width: 628px;
    padding: 32px 62px;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    @include mobile {
      width: 296px;
      padding: 24px 39px 28px;
      background: #f0f2f4;
      box-shadow: -4px -4px 12px #ffffff, -2px -2px 8px #ffffff,
        4px 4px 20px rgba(0, 0, 0, 0.2);
      border-radius: 80px;
    }
    &__title {
      text-align: center;
      letter-spacing: 0.016em;

      font-weight: 700;
      font-size: 46px;
      line-height: 68px;
      color: #17445c;
      @include mobile {
        font-size: 20px;
        line-height: 30px;
        color: #798da5;
      }
    }
    &__subtitle {
      font-weight: 500;
      line-height: 1;
      font-size: 24px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #868686;
      @include pad {
        font-size: 16px;
      }
    }
    &__content {
      margin-top: 36px;
      display: flex;
      color: #9b9b9b;
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      &__icon {
        margin-right: 12px;
        @include mobile {
          margin-right: 8px;
        }
      }
      @include mobile {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__checkbox {
      margin-top: 36px;
      display: flex;
      color: #9b9b9b;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      label {
        display: flex;
        align-items: flex-start;
      }
      .checkbox {
        position: relative;
        display: inline-block;

        transform: translatex(-4px);
        cursor: pointer;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          margin-right: 4px;
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          transform: translate(-50%, -50%);
          background: #f0f2f4;
          border: 1px solid #9b9b9b;
          box-shadow: -1px -1px 4px #ffffff, 2px 2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          @include mobile {
            margin-right: 2px;
            margin-top: 4px;
          }
        }
        &.active:before {
          background: #798da5;
          border: 1px solid #9b9b9b;
        }
      }
      input:checked ~ .checkmark {
        position: absolute;
        left: 50%;
        top: 40%;
        z-index: 999;
        width: 8px;
        height: 13px;
        border: solid #fff;
        border-width: 0px 3px 3px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        @include pad {
          top: 50%;
        }
      }
      input {
        visibility: hidden;
        margin-right: 12px;
      }
      @include mobile {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__btn {
      margin-top: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        justify-content: space-between;
      }
      &__cancel {
        @include btn-default(144px, 48px, 20px);
        font-size: 20px;
        line-height: 30px;
        border: 2px solid rgba(121, 141, 165, 0.3);
        margin-right: 16px;
        text-align: center;
        color: #ffffff;
        @include mobile {
          font-size: 14px;
          line-height: 21px;
          @include btn-default(50%, 40px, 20px);
        }
        &:hover {
          border: 0;
          background-color: rgba($color: $secondary-color-2, $alpha: 0.6);
          color: rgba($color: $secondary-color-3, $alpha: 0.6);
        }
        &:active {
          border: 0;
          background-color: $secondary-color-2;
          color: $secondary-color-3;
        }
      }
      &__ok {
        @include btn-default(144px, 48px, 20px);
        font-size: 20px;
        line-height: 30px;
        transition: 0.2s;
        @include mobile {
          height: 40px;
          font-size: 14px;
          @include btn(50%, 40px, #d9d9d9, #000, 20px);
        }
        &:hover {
          color: #fff;
        }
        &.disabled {
          pointer-events: none;
          border: 1px solid #d9d9d9;
          background-color: #f0f2f4;
          color: rgba(155, 155, 155, 0.7);
        }
        background: #fff;
        color: #798da5;
        border: 1px solid #798da5;
      }
    }
  }
}
</style>

import {
  signNames,
  planetList,
  planetNames,
  signIcons,
  planetIcons,
} from "./astrology";

const relations = [
  "恋人",
  "伴侣",
  "孩子",
  "父母",
  "家人",
  "亲友",
  "特殊关系",
  "朋友",
  "领导",
  "老师",
  "下属",
  "学生",
  "案例",
  "名人",
  "客户",
  "其他",
];
// const signNames = [
//   ['羊','牛','子','蟹','狮','女','秤','蝎','射','羯','瓶','鱼'],
//   ['白羊','金牛','双子','巨蟹','狮子','处女','天秤','天蝎','射手','魔羯','水瓶','双鱼'],
//   ['白羊座','金牛座','双子座','巨蟹座','狮子座','处女座','天秤座','天蝎座','射手座','魔羯座','水瓶座','双鱼座'],
// ];

const defaultShareConfig = {
  title: "星座学心理",
  desc: "发现你心灵的秘密",
  link: `${location.origin}/weixin/xingyu/`,
};

export {
  signNames,
  planetList,
  planetNames,
  planetIcons,
  signIcons,
  relations,
  defaultShareConfig,
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.messagePopup,
            expression: "messagePopup"
          }
        ],
        staticClass: "messagePopupContainer"
      },
      [
        _c("div", { staticClass: "messagePopup" }, [
          _c("div", {
            staticClass: "messagePopup__title",
            domProps: { innerHTML: _vm._s(_vm.title) }
          }),
          _c("div", { staticClass: "messagePopup__content" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
          ]),
          _c("div", { staticClass: "messagePopup__btn" }, [
            _vm.showCancel
              ? _c(
                  "div",
                  {
                    staticClass: "mr-4",
                    class: _vm.isNegative
                      ? "messagePopup__btn__ok"
                      : "messagePopup__btn__cancel",
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("\n          取消\n        ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: _vm.isNegative
                  ? "messagePopup__btn__cancel"
                  : "messagePopup__btn__ok",
                on: { click: _vm.ok }
              },
              [_vm._v("\n          " + _vm._s(_vm.okText) + "\n        ")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
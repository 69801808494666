<template>
  <div>
    <!-- 星盤設置 手機版 -->
    <v-slide-y-reverse-transition>
      <div class="setup" v-if="activeSetup" @click.stop="activeSetup = true">
        <img
          class="setup__scroll"
          src="@/assets/icon_scroll.svg"
          alt="提示下滑icon"
          v-if="!activeTolerance"
        />
        <img
          class="setup__close"
          src="@/assets/icon_close.svg"
          @click.stop="popupHandler"
          alt="關閉按鈕"
        />
        <div class="setup__wrap" :class="{ activeTolerance: activeTolerance }">
          <!-- 行星設置 -->
          <div class="setup__box planet-list">
            <div class="setup__title">
              <span class="title__text left">行星設置</span>
              <span class="title__text center">容許度</span>

              <div class="setup__btnGroup" v-if="!phasePriority">
                <div
                  class="setup__outer"
                  :class="{ active: openStrict }"
                  @click="strictHandler()"
                >
                  <div class="setup__inner"></div>
                </div>
                <div>嚴謹{{ openStrict ? "開啟" : "關閉" }}</div>
              </div>
            </div>
            <!-- 主要行星 -->
            <div
              class="setup__item"
              v-for="(item, index) in mainPlanetList"
              :key="'A' + index"
            >
              <div class="item__name">
                <span class="item__name__img iconfont">{{ item.img }}</span>
                <span class="item__name__text">{{ item.name }}</span>
              </div>
              <span
                class="item__num"
                @click.stop="openTolerance(item, index, true)"
                >{{ item.val }}</span
              >
              <div
                class="item__btn"
                :class="{ toggleSetup: item.toggleSelect }"
                @click.stop="mainItemClick(item, index, true)"
              ></div>
            </div>

            <!-- 其他行星 -->
            <div
              class="setup__item"
              v-for="(item, index) in planetList"
              :key="index"
            >
              <div class="item__name">
                <span class="item__name__img iconfont">{{ item.img }}</span>
                <span class="item__name__text">{{ item.name }}</span>
              </div>
              <div
                class="item__btn"
                :class="{ toggleSetup: item.toggleSelect }"
                @click.stop="planetSelectItemClick(item, index)"
              ></div>
            </div>
          </div>
          <!-- 相位設置 -->
          <div
            class="setup__box phase-list"
            :class="{ phasePriority: phasePriority }"
          >
            <div class="setup__title">
              <span class="title__text left">相位設置</span>
              <span class="title__text center">容許度</span>
              <div class="setup__btnGroup" v-if="phasePriority">
                <div
                  class="setup__outer"
                  :class="{ active: openStrict }"
                  @click="strictHandler()"
                >
                  <div class="setup__inner"></div>
                </div>
                <div>嚴謹{{ openStrict ? "開啟" : "關閉" }}</div>
              </div>
            </div>
            <!-- 相位 -->
            <div
              class="setup__item"
              v-for="(item, index) in selPhase"
              :key="index"
              v-show="item.name.indexOf('144') == -1"
            >
              <div class="item__name">
                <span class="item__name__text">{{ item.name }}</span>
              </div>
              <span
                class="item__num"
                @click.stop="openTolerance(item, index)"
                >{{ item.val }}</span
              >
              <div
                class="item__btn"
                :class="{ toggleSetup: item.toggleSelect }"
                @click.stop="selPhaseSelectItemClick(item, index)"
              ></div>
            </div>
          </div>
        </div>
        <!-- 容許度 Box -->
        <div class="tolerance" v-if="activeTolerance">
          <div class="tolerance__title">容許度</div>
          <div class="tolerance__notice">
            建議採用預設值，可以提升星盤的使用體驗哦！
          </div>
          <div class="tolerance__box">
            <swiper :options="swiperTolerance" style="padding: 5px 5px">
              <swiper-slide
                class="item"
                v-for="(item, index) in phaseSelList"
                :class="{ clickTolerance: item === nowEditTolerance.val }"
                @click.native="phaseSelVal(item)"
                :key="index"
                >{{ item }}</swiper-slide
              >
            </swiper>
          </div>
        </div>
      </div>
    </v-slide-y-reverse-transition>
    <transition name="fade">
      <div
        class="dialog__overlay"
        v-if="activeSetup"
        @click.stop="
          close();
          activeTolerance = false;
        "
      >
        <div
          class="d-flex justify-center align-center"
          @click.stop="activeSetup = true"
        >
          <div
            class="setup"
            v-if="activeSetup"
            @click.stop="activeSetup == true"
          >
            <img
              class="setup__scroll"
              v-if="!activeTolerance"
              src="@/assets/icon_scroll.svg"
              alt
            />
            <img
              class="setup__close"
              @click="popupHandler"
              src="../assets/icon_menu-close-dark.svg"
            />
            <div
              class="setup__wrap"
              :class="{ activeTolerance: activeTolerance }"
            >
              <!-- 行星設置 -->
              <div class="setup__box planet-list">
                <div class="setup__title">
                  <span class="title__text left">行星設置</span>
                  <span class="title__text center">容許度</span>

                  <div class="setup__btnGroup" v-if="!phasePriority">
                    <div
                      class="setup__outer mr-2"
                      :class="{ active: openStrict }"
                      @click="strictHandler()"
                    >
                      <div class="setup__inner"></div>
                    </div>
                    <div>嚴謹{{ openStrict ? "開啟" : "關閉" }}</div>
                  </div>
                </div>
                <!-- 主要行星 -->
                <div
                  class="setup__item"
                  v-for="(item, index) in mainPlanetList"
                  :key="'A' + index"
                >
                  <div class="item__name">
                    <span class="item__name__img iconfont">{{ item.img }}</span>
                    <span class="item__name__text">{{ item.name }}</span>
                  </div>
                  <span
                    class="item__num"
                    @click.stop="openTolerance(item, index, true)"
                    >{{ item.val }}</span
                  >
                  <div
                    class="item__btn"
                    :class="{ toggleSetup: item.toggleSelect }"
                    @click.stop="mainItemClick(item, index, true)"
                  ></div>
                </div>

                <!-- 其他行星 -->
                <div
                  class="setup__item"
                  v-for="(item, index) in planetList"
                  :key="index"
                >
                  <div class="item__name">
                    <span class="item__name__img iconfont">{{ item.img }}</span>
                    <span class="item__name__text">{{ item.name }}</span>
                  </div>
                  <div
                    class="item__btn"
                    :class="{ toggleSetup: item.toggleSelect }"
                    @click.stop="planetSelectItemClick(item, index)"
                  ></div>
                </div>
              </div>
              <!-- 相位設置 -->
              <div
                class="setup__box phase-list"
                :class="{ phasePriority: phasePriority }"
              >
                <div class="setup__title">
                  <span class="title__text left">相位設置</span>
                  <span class="title__text right">容許度</span>
                  <div class="setup__btnGroup" v-if="phasePriority">
                    <div
                      class="setup__outer mr-2"
                      :class="{ active: openStrict }"
                      @click="strictHandler()"
                    >
                      <div class="setup__inner"></div>
                    </div>
                    <div>嚴謹{{ openStrict ? "開啟" : "關閉" }}</div>
                  </div>
                </div>
                <!-- 相位 -->
                <div
                  class="setup__item"
                  v-for="(item, index) in selPhase"
                  :key="index"
                  v-show="item.name.indexOf('144') == -1"
                >
                  <div class="item__name">
                    <span class="item__name__text">{{ item.name }}</span>
                  </div>
                  <span
                    class="item__num"
                    @click.stop="openTolerance(item, index)"
                    >{{ item.val }}</span
                  >
                  <div
                    class="item__btn"
                    :class="{ toggleSetup: item.toggleSelect }"
                    @click.stop="selPhaseSelectItemClick(item, index)"
                  ></div>
                </div>
              </div>
            </div>
            <!-- 容許度 Box -->
            <div class="tolerance" v-if="activeTolerance">
              <div class="tolerance__title">容許度</div>
              <div class="tolerance__notice">
                建議採用預設值，可以提升星盤的使用體驗哦！
              </div>
              <div class="tolerance__box">
                <swiper :options="swiperTolerance" style="padding: 5px 5px">
                  <swiper-slide
                    class="item"
                    v-for="(item, index) in phaseSelList"
                    :class="{ clickTolerance: item === nowEditTolerance.val }"
                    @click.native="phaseSelVal(item)"
                    :key="index"
                    >{{ item }}</swiper-slide
                  >
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    activeSetup: {
      type: Boolean,
    },
    role: {
      type: String,
      default: "Astrolabe",
    },
    phasePriority: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //設置
      openStrict: false,
      activeTolerance: false,
      clickTolerance: true,
      toggleSetup: false,
      DetailNeed: null,
      showSwitch: false,
      switchName: null,
      //容許度Swiper
      swiperTolerance: {
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: true,
      },
      //設置部分變數
      mainSelList: [
        {
          name: "",
        },
      ],
      // 7个主星
      selPhase: [
        {
          name: "",
        },
      ],
      phaseSelList: [0.5, 1, 1.5],
      editPhaseIndex: 0,
      selPhaseModel: [
        {
          name: "",
        },
      ],
      mainClick: [
        {
          name: "",
        },
      ], // 主星修改容许度
      selNumList: [
        {
          name: "",
        },
      ],
      saveBox: [
        {
          name: "",
        },
      ],
      mainPlanetList: [
        {
          name: "",
        },
      ],
      planetList: [
        {
          name: "",
        },
      ],
      nowEditTolerance: [
        {
          name: "",
        },
      ],
    };
  },
  methods: {
    popupHandler() {
      this.$forceUpdate();
      this.activeTolerance ? (this.activeTolerance = false) : this.close();
    },
    close() {
      this.$emit("close");
    },
    strictHandler() {
      if (this.openStrict) {
        this.openStrict = false;
        this.Default();
      } else {
        this.openStrict = true;
        this.Strict();
      }
    },
    Default() {
      this.$store.commit(`${this.role}/resetSetting`);
      this.mainPlanetList.forEach((item, index) => {
        item.toggleSelect = this.$store.state[this.role].mainPlanetList[
          index
        ].defaultSelect;
      });
      this.planetList.forEach((item, index) => {
        if (index < 5) {
          item.toggleSelect = true;
        } else {
          item.toggleSelect = false;
        }
      });
      this.$emit("dataChange", false);
      setTimeout(() => {
        this.$emit("dataChange", true);
      }, 1);
    },

    Strict() {
      this.$store.commit(`${this.role}/resetSettingStrict`);
      this.mainPlanetList.forEach((item, index) => {
        item.toggleSelect = this.$store.state[this.role].mainPlanetList[
          index
        ].strictSelect;
      });
      this.planetList.forEach((item, index) => {
        if (index < 5) {
          item.toggleSelect = true;
        } else {
          item.toggleSelect = false;
        }
      });
      this.$emit("dataChange", false);
      setTimeout(() => {
        this.$emit("dataChange", true);
      }, 1);
    },
    planetSelectItemClick(item, index) {
      this.$store.state[this.role].planetList[
        index
      ].toggleSelect = !item.toggleSelect;
      //這邊要強迫他更新
      this.$forceUpdate();
      this.$emit("dataChange", false);
      setTimeout(() => {
        this.$emit("dataChange", true);
      }, 1);
    },
    openTolerance(item, index, type) {
      this.activeTolerance = !this.activeTolerance;
      this.nowEditTolerance = item;

      this.editPhaseIndex = index;
      if (type) {
        this.mainClick = true;
      } else {
        this.mainClick = false;
        this.editPhaseIndex = index;
      }
      if (this.phaseSelList.length < 15) {
        for (let i = 2; i <= item.range; i++) {
          this.phaseSelList.push(i);
        }
      }
    },
    selPhaseSelectItemClick(item, index) {
      this.$emit("dataChange", false);

      item.toggleSelect = !item.toggleSelect;
      this.$store.commit(
        `${this.role}/setMainPlanetList`,
        this.mainPlanetList.concat(this.selPhase)
      );

      setTimeout(() => {
        this.$emit("dataChange", true);
      }, 1);
    },
    phaseSelVal(value) {
      if (this.mainClick) {
        this.mainPlanetList[this.editPhaseIndex].val = value;
        this.$store.commit(
          `${this.role}/setMainPlanetList`,
          this.mainPlanetList.concat(this.selPhase)
        );
      } else {
        this.selPhase[this.editPhaseIndex].val = value;
        this.$store.commit(
          `${this.role}/setMainPlanetList`,
          this.mainPlanetList.concat(this.selPhase)
        );
      }
      //這邊要強迫他更新
      this.$forceUpdate();
      this.$emit("dataChange", false);
      setTimeout(() => {
        this.$emit("dataChange", true);
      }, 1);
      this.activeTolerance = !this.activeTolerance;
    },
    mainItemClick(item, index, type) {
      if (type) {
        if (index === 0 || index === 1) {
          return;
        }
        item.toggleSelect = !item.toggleSelect;

        this.$store.state[this.role].mainPlanetList[
          index
        ].mainPlanetList = !item.toggleSelect;
        //這邊要強迫他更新
        this.$forceUpdate();
        this.$emit("dataChange", false);
        setTimeout(() => {
          this.$emit("dataChange", true);
        }, 1);
      }
    },
  },
  computed: {
    panConfigItem() {
      //七個主要星
      let main = this.$store.state[this.role].mainPlanetList.slice(0, 7);
      //其他星
      let planet = this.$store.state[this.role].planetList;
      return [
        ...main.map((item) => (item.toggleSelect ? 1 : 0)), //星盤設置設定檔
        ...planet.map((item) => (item.toggleSelect ? 1 : 0)),
      ];
    },
    UserData() {
      return this.$store.state.userData; //星盤設置設定檔
    },
    nowUserData() {
      return this.$store.state.nowUserData; //星盤設置設定檔
    },
    storeMainPlanetList() {
      let mainPlanetList;
      this.role == ""
        ? (mainPlanetList = this.$store.state.mainPlanetList)
        : (mainPlanetList = this.$store.state[this.role].mainPlanetList);
      return mainPlanetList; //星盤設置設定檔
    },
    storePlanetList() {
      let planetList;
      this.role == ""
        ? (planetList = this.$store.state.planetList)
        : (planetList = this.$store.state[this.role].planetList);
      return planetList; //星盤設置設定檔
    },
  },
  created() {
    this.selNumList = Object.assign([], this.panConfigItem);
    //這邊根本沒有深拷貝到，我傻眼，待補坑(補丸在拔掉watch)
    this.mainPlanetList = Object.assign([], this.storeMainPlanetList).slice(
      0,
      7
    );
    this.selPhase = Object.assign([], this.storeMainPlanetList).slice(7);
    this.planetList = this.storePlanetList;
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
//手機版的setup面板(含通用格式)
.setup {
  position: absolute;
  bottom: -65px; //-42px
  left: 0;
  z-index: 90;
  @include rect(100%, calc(32% + 42px), $theme-color-3, 50px);
  border-radius: 50px 50px 0 0;
  padding-top: 48px;
  display: none;
  @include mobile {
    display: block;
  }
  @include laptop {
    height: 43vh;
    bottom: 0;
  }
  @include pad {
    position: fixed;
  }
  .setup__close {
    position: absolute;
    top: 5%;
    right: 3%;
  }

  .setup__scroll {
    position: absolute;
    right: 8px;
    bottom: 8px;
    animation: flicker 1s linear infinite both alternate;
    @include mobile {
      right: 2px;
      width: 22px;
      height: 22px;
      z-index: 2;
    }
  }

  @keyframes flicker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .setup__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 50px 32px 50px;
    display: flex;
    flex-direction: column;
    & > div.phasePriority {
      order: -1;
    }
    &::-webkit-scrollbar {
      display: none; //隱藏 scroll bar
    }

    @include mobile {
      padding: 0 32px 32px;
    }

    &.activeTolerance {
      overflow-y: hidden;
    }

    .setup__box {
      padding-bottom: 12px;
      .setup__title {
        @include align(space-between, center);
        padding: 0 0px 0 24px;
        position: relative;
        @include mobile {
          padding: 0 16px;
        }
        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .title__text {
          font-size: 18px;
          font-weight: bold;
          color: $theme-color-1;
          @include mobile {
            font-size: 16px;
          }
        }
        .setup__btnGroup {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: $secondary-color-3;
        }
        .setup__outer {
          height: 24px;
          width: 36px;
          border-radius: 100px;
          position: relative;
          border: 2px solid $theme-color-1;
          background-color: #fff;
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
          transition: 0.2s;
          margin-right: 8px;
          @include pad {
            height: 20px;
            width: 30px;
            margin-right: 4px;
          }
          &.active {
            background-color: $theme-color-1;
            .setup__inner {
              transform: translate(13px, -50%);
            }
          }
          .setup__inner {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: #fff;
            border: 2px solid $theme-color-1;
            transition: 0.2s;
            position: absolute;
            top: 50%;
            left: -3px;
            transform: translate(0, -50%);
            @include pad {
              height: 20px;
              width: 20px;
            }
          }
        }
        .setup__default {
          @include btn(64px, 24px, $secondary-color-2, #fff, 14px);
          border-radius: 6px;
          &:hover {
            background-color: rgba($color: $secondary-color-2, $alpha: 0.6);
            color: rgba($color: #ffffff, $alpha: 0.6);
          }

          &:active {
            background-color: $secondary-color-3;
            color: $secondary-color-2;
          }

          @include mobile {
            width: 48px;
            height: 16px;
            border-radius: 4px;
            font-size: 10px;
            &:hover {
              background-color: $secondary-color-3;
              color: $secondary-color-2;
            }
          }
        }

        .setup__space {
          width: 64px;
          height: 24px;
          @include mobile {
            width: 48px;
            height: 16px;
          }
        }
      }

      .setup__item {
        padding: 0 40px 0 28px;
        margin-top: 10px;
        min-height: 40px;
        @include rect-s(100%, auto, $theme-color-3, 10px);
        @include align(space-between, center);
        @include mobile {
          padding: 4px 24px 4px 16px;
          margin-top: 8px;
          height: auto;

          min-height: auto;
        }

        .item__name {
          flex: 1; //
          .item__name__img {
            font-size: 18px;
            color: #27aba3;
            margin-right: 8px;
          }

          .item__name__text {
            font-size: 18px;
            line-height: 0;
            color: $theme-color-1;
            @include mobile {
              font-size: 14px;
            }
          }
        }

        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11) {
          .item__name {
            .item__name__img {
              color: #e99a00;
            }
          }
        }

        .item__num {
          flex: 1; //
          padding-left: 32px; //
          font-size: 16px;
          line-height: 1.63;
          letter-spacing: 1.28px;
          color: $secondary-color-3;
          text-decoration: underline;
          cursor: pointer;
          @include mobile {
            font-size: 12px;
          }
        }

        .item__btn {
          @include rect-s(28px, 28px, $theme-color-3, 8px);
          @include center;
          cursor: pointer;
          background: url("../assets/eye_off.svg") no-repeat;
          background-size: cover;

          &.toggleSetup {
            box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15),
              2px 2px 4px 0 #ffffff;
            background: url("../assets/eye_on.svg") no-repeat;
            background-size: cover;
          }

          @include mobile {
            width: 24px;
            height: 24px;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          .item__btn {
            box-shadow: none;
            cursor: auto;
          }
        }
      }

      &.phase-list {
        margin-top: 18px;

        .setup__item {
          .item__name {
            flex: 1;
          }

          .item__num {
            flex: 1;
            padding-left: 32px;
          }
        }
      }
    }
  }

  //容許度
  .tolerance {
    padding: 24px 17px 34px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 14px));
    @include rect-inset(90%, calc(100% - 32px), $theme-color-3, 40px);
    @include align(flex-start, center);
    flex-direction: column;

    @include mobile {
      width: 90%;
    }

    .tolerance__title {
      font-size: 20px;
      font-weight: bold;
      color: $theme-color-1;
      @include mobile {
        font-size: 16px;
      }
    }

    .tolerance__notice {
      margin-top: 10px;
      font-size: 16px;
      color: $secondary-color-3;
      @include mobile {
        font-size: 12px;
      }
    }

    .tolerance__box {
      @include rect-inset(100%, 64px, $theme-color-3, 12px);
      margin-top: 24px;
      padding: 0 24px;
      @include align(flex-start, center);
      @include mobile {
        height: 50px;
      }

      .item {
        font-size: 18px;
        color: $secondary-color-3;
        width: 36px;
        height: 48px;
        @include center;
        cursor: pointer;

        &.clickTolerance {
          width: 48px;
          height: 48px;
          background-color: $theme-color-1;
          border-radius: 50%;
          color: #ffffff;
          @include mobile {
            width: 32px;
            height: 32px;
          }
        }

        @include mobile {
          font-size: 14px;
          width: 30px;
          height: 32px;
        }
      }
    }
  }
}
//電腦版的setup面板
.dialog__overlay {
  @include mobile {
    display: none;
  }
}
.dialog__overlay .setup {
  position: relative;
  left: 0;
  bottom: 0;
  @include rect(480px, 560px, $theme-color-3, 60px);
  padding-top: 56px;
  display: block;

  &::before {
    content: "";
    display: none;
  }
  .setup__close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 48px;
    cursor: pointer;
  }

  .setup__scroll {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 8px;
    animation: flicker 1s linear infinite both alternate;
  }

  @keyframes flicker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .setup__wrap {
    position: relative;
    width: 100%;
    height: 456px;
    padding: 14px 50px 32px 50px;
    &.activeTolerance {
      overflow-y: hidden;
    }
  }

  //容許度
  .tolerance {
    padding: 12px 17px 48px;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, 0);
    @include rect-inset(432px, 432px, $theme-color-3, 40px);
    @include align(center, center);
    flex-direction: column;

    .tolerance__close {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }

    .tolerance__title {
      font-size: 20px;
      font-weight: bold;
      color: $theme-color-1;
      @include mobile {
        font-size: 16px;
      }
    }

    .tolerance__notice {
      margin-top: 10px;
      font-size: 16px;
      color: $secondary-color-3;
      @include mobile {
        font-size: 12px;
      }
    }

    .tolerance__box {
      @include rect-inset(100%, 64px, $theme-color-3, 12px);
      margin-top: 24px;
      padding: 0 24px;
      @include align(flex-start, center);
      @include mobile {
        height: 50px;
      }

      .item {
        font-size: 18px;
        color: $secondary-color-3;
        width: 36px;
        height: 48px;
        @include center;
        cursor: pointer;

        &.clickTolerance {
          width: 48px;
          height: 48px;
          background-color: $theme-color-1;
          border-radius: 50%;
          color: #ffffff;
          @include mobile {
            width: 32px;
            height: 32px;
          }
        }

        @include mobile {
          font-size: 14px;
          width: 30px;
          height: 32px;
        }
      }
    }
  }
}
</style>

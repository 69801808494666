<template>
  <!-- 開發中 -->
  <transition name="fade">
    <FixedPopup v-show="noticePopup">
      <div class="imageNotice__box">
        <img class="imageNotice__img" :src="imgList[imageIdx]" alt />
        <div class="imageNotice__title" v-html="title"></div>
        <div class="imageNotice__subtitle" v-html="desc"></div>
        <div class="imageNotice__btn" @click="ok()">{{ okText }}</div>
        <div class="imageNotice__textBtn" @click="cancel()" v-if="cancelText">
          {{ cancelText }}
        </div>
      </div>
    </FixedPopup>
  </transition>
</template>

<script>
import FixedPopup from "@/components/Fixed_popup.vue";
import Vue from "vue";

let imgList = [
  require("@/assets/月亮日記/這天寫過了.svg"),
  require("@/assets/develop.svg"),
];

export default {
  components: { FixedPopup },
  data() {
    return {
      imgList,
      noticePopup: false,
      imageIdx: 0,
      okText: "我瞭解了",
      okEvent: null,
      cancelText: "取消",
      cancelEvent: null,
      title: "",
      desc: "",
    };
  },
  created() {
    Vue.prototype.$imageNotice = (arg) => {
      this.imageIdx = arg.imageIdx || 0;
      this.title = arg.title || "";
      this.desc = arg.desc || "";
      this.okText = arg.okText || "我瞭解了";
      this.okEvent = arg.okEvent || null;
      this.cancelText = arg.cancelText;
      this.cancelEvent = arg.cancelEvent || null;
      this.noticePopup = true;
    };
  },
  methods: {
    cancel() {
      this.cancelEvent ? this.cancelEvent() : () => {};
      this.noticePopup = false;
      setTimeout(() => {
        this.init();
      }, 1000);
    },
    ok() {
      this.okEvent ? this.okEvent() : () => {};
      this.noticePopup = false;
      setTimeout(() => {
        this.init();
      }, 1000);
    },
    init() {
      this.noticePopup = false;
      this.$nextTick(() => {
        this.imageIdx = 0;
        this.title = "";
        this.desc = "";
        this.okText = "我瞭解了";
        this.okEvent = null;
        this.cancelText = "取消";
        this.cancelEvent = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.imageNotice__box {
  z-index: 1050;
  margin: auto;
  @include rect(520px, auto, $theme-color-3, 100px);
  @include center;
  flex-direction: column;
  padding: 64px 36px 40px;
  transition: 0.5s;
  @include mobile() {
    @include rect(326px, auto, $theme-color-3, 110px);
    margin: 0 24px;
    padding: 52px 40px 40px;
  }
  .imageNotice__img {
    height: 272px;
    object-fit: contain;
    margin: 0 auto;
    @include mobile() {
      height: 210px;
    }
  }
  .imageNotice__title {
    font-size: 38px;
    font-weight: 700;
    line-height: 58px;
    text-align: center;
    margin-top: 24px;
    color: $secondary-color-1;
    @include mobile() {
      font-size: 28px;
      line-height: 58px;
      margin-top: 20px;
    }
  }
  .imageNotice__subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-top: 16px;
    color: #9b9b9b;
    @include mobile() {
      font-size: 14px;
      line-height: 24px;
      margin-top: 12px;
    }
  }
  .imageNotice__btn {
    @include btn(262px, 44px, $theme-color-1, $theme-color-3, 20px);
    margin-top: 40px;
    transition: 0.2s;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    @include mobile() {
      @include btn(234px, 40px, $theme-color-1, $theme-color-3, 14px);
      margin-top: 52px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    &:hover {
      opacity: 0.7;
    }
    &:active {
      background-color: $secondary-color-1;
    }
  }
  .imageNotice__textBtn {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: $theme-color-1;
    margin-top: 16px;
    cursor: pointer;
    @include mobile() {
      font-size: 14px;
      line-height: 14px;
      margin-top: 20px;
    }
  }
}
</style>

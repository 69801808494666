export default {
  namespaced: false,
  state: () => ({
    MoondayEdit: {
      MoonNoteID: "",
      Constellation: "",
      Content: "",
      Moods: "",
      MoodContent: "",
      Date: "",
      Time: "",
    },
    showAlreadyWrite: false,
    moondayFilterTarget: null,
    todayIsNewMoon: null,
    showNewMoonNotice: true,
  }),
  getters: {
    MoondayEdit: (state) => {
      return state.MoondayEdit;
    },
    showAlreadyWrite: (state) => {
      return state.showAlreadyWrite;
    },
    moondayFilterTarget: (state) => {
      return state.moondayFilterTarget;
    },
    todayIsNewMoon: (state) => {
      return state.todayIsNewMoon;
    },
    showNewMoonNotice: (state) => {
      return state.showNewMoonNotice;
    },
  },
  mutations: {
    setMoondayEdit(state, payload) {
      state.MoondayEdit = {
        ...state.MoondayEdit,
        ...payload,
      };
    },
    setShowAlreadyWrite(state, payload) {
      state.showAlreadyWrite = payload;
    },
    setMoondayFilterTarget(state, payload) {
      state.moondayFilterTarget = payload;
    },
    setTodayIsNewMoon(state, payload) {
      state.todayIsNewMoon = payload;
    },
    setShowNewMoonNotice(state, payload) {
      state.showNewMoonNotice = payload;
    },
  },

  actions: {},
};

<template>
  <div class="MoonDayCTAWrap">
    <div class="MoonDayCTA">
      <!-- 推運版型 -->
      <template v-if="mode == 'timeLineMode'">
        <div class="MoonDayCTA__userInfo">
          <!-- 左邊按鈕 -->
          <div class="" @click.stop="userInfoOpen = !userInfoOpen">
            <div>
              {{ $NameFormat(nowUserData.Name) }}
              <span> &ensp;(內圈)</span>
            </div>
          </div>
          <!-- 分隔星星 -->
          <div>
            <img src="@/assets/分隔星星.svg" alt="" />
          </div>
          <!-- 右邊按鈕 -->
          <div class="" @click.stop="userInfoOpen = !userInfoOpen">
            <div>推運 (外圈)</div>
            <div class="ml_3 pt_1">
              <img
                src="@/assets/icon_downward.svg"
                :style="userInfoOpen ? 'transform:rotate(180deg)' : ''"
              />
            </div>
          </div>
        </div>
        <div class="MoonDayCTA__userDetail mb_10" v-if="userInfoOpen">
          <!-- 左邊(本命) -->
          <div class="MoonDayCTA__userDetail__item">
            <div class="MoonDayCTA__userDetail__place mt_5">
              <img src="@/assets/map-pin.svg" alt="" />
              {{ `${nowUserData.BirthCountry}  ${nowUserData.BirthCity}` }}
            </div>
            <div class="MoonDayCTA__userDetail__birth mt_1">
              <template>
                {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }}
                <span
                  class="MoonDayCTA__userDetail__birth--space mx_4"
                  v-if="!data2"
                >
                  |
                </span>
                <br v-else />
                {{
                  `${deFormat(nowUserData.Longitude)}, 
                 ${deFormat(nowUserData.Latitude)}`
                }}
                <img
                  src="@/assets/日光節約icon.svg"
                  v-if="nowUserData.IsDaylight"
                />
              </template>
            </div>
          </div>
          <!-- 右邊(推運or另一個人) -->
          <div
            class="MoonDayCTA__userDetail__item MoonDayCTA__userDetail__item--right"
            v-if="data2"
          >
            <div
              class="MoonDayCTA__userDetail__place mt_5"
              v-if="!nowTimeNowPlaceIng"
            >
              <img src="@/assets/map-pin.svg" alt="" />
              {{ `${nowUserData.BirthCountry}  ${nowUserData.BirthCity}` }}
            </div>
            <div v-if="nowTimeNowPlaceIng" class="mt_5">當前地點</div>
            <div class="MoonDayCTA__userDetail__birth mt_1">
              <template>
                <!-- {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }} -->
                {{ moment(new Date(format_time)).format("YYYY/MM/DD,HH:mm") }}
                <span
                  class="MoonDayCTA__userDetail__birth--space mx_4"
                  v-if="!data2"
                >
                  |
                </span>
                <br v-else />
                {{ nowPlace }}
              </template>
            </div>
          </div>
        </div>
      </template>
      <!-- 天象版型 -->
      <template v-if="mode == 'astrometryMode'">
        <div class="MoonDayCTA__astrometry">
          <!-- 左邊按鈕 -->
          <div
            class="MoonDayCTA__astrometry__btn"
            @click.stop="userInfoOpen = !userInfoOpen"
          >
            <div>
              {{ $NameFormat(nowUserData.Name) }}
            </div>
            <div class="ml_3 pt_1">
              <img
                src="@/assets/icon_downward.svg"
                :style="userInfoOpen ? 'transform:rotate(180deg)' : ''"
              />
            </div>
          </div>
          <!-- //此時此地按鈕 -->
          <div
            class="userDesktopInfo__btn"
            :class="{ nowTimeNowPlaceIng: nowTimeNowPlaceIng }"
            @click.stop="$emit('toNowTimeNowPlace')"
          >
            此時此地
          </div>
        </div>
        <div class="MoonDayCTA__astrometryDetail" v-show="userInfoOpen">
          <div class="MoonDayCTA__astrometryDetail__place">
            <img src="@/assets/map-pin.svg" alt="" />
            {{ `${nowUserData.BirthCountry}  ${nowUserData.BirthCity}` }}
          </div>
          <div class="MoonDayCTA__astrometryDetail__birth">
            {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }}
            <br />
            {{
              `${deFormat(nowUserData.Longitude)}, 
                 ${deFormat(nowUserData.Latitude)}`
            }}
            <img
              src="@/assets/日光節約icon.svg"
              v-if="nowUserData.IsDaylight"
            />
          </div>
        </div>
      </template>
      <div class="MoonDayCTA__line"></div>
      <!-- 月亮日記的進入點就是這邊 -->
      <div class="MoonDayCTA__main mt_6 pb_6" v-if="!EnabledMoonNote">
        新功能<img src="@/assets/moon.svg" alt="" />
        <span>月亮日記</span>
        <div class="toUnlock_cta" @click="toStore()">
          <img src="@/assets/locked.svg" alt="" />
          點此解鎖
        </div>
      </div>
      <div
        class="MoonDayCTA__main mt_6 pb_6"
        v-else-if="moondayObj.Constellation"
      >
        <img src="@/assets/moon.svg" alt="" />
        <span>月亮{{ moondayObj.Constellation }}日</span>
        ，記錄當下的感受吧:)
        <div class="MoonDayCTA__main__toNote" @click="addMoonNoteHandler()">
          <img src="@/assets/icon-edit.svg" alt="" />
        </div>
      </div>
      <div class="MoonDayCTA__main mt_6 pb_6" v-else>讀取中...</div>
    </div>
  </div>
</template>

<script>
import phaseMixin from "@/mixin/phaseMixin";
import moment from "moment";
export default {
  mixins: [phaseMixin],
  props: [
    "data2",
    "nowPlace",
    "nowTimeNowPlaceIng",
    "mode",
    "format_time",
    "moondayObj",
  ],
  data() {
    return {
      userInfoOpen: false,
    };
  },
  computed: {
    nowUserData() {
      return this.$store.state.nowUserData; //星盤設置設定檔
    },
    EnabledMoonNote() {
      return this.$store.state.userData.EnabledMoonNote;
    },
  },
  methods: {
    moment,
    async addMoonNoteHandler() {
      let astro = this.$Astro.find(
        (item) => item.cn === this.moondayObj.Constellation
      );
      let res = await this.$API.GET_MoonDayNoteCheck({
        Date: moment(new Date(this.format_time)).format("YYYY-MM-DD"),
        Constellation: astro.en,
      });
      if (res.data.Data) {
        this.$store.commit("setShowAlreadyWrite", true);
      } else {
        this.$store.commit("setMoondayEdit", {
          MoonNoteID: "",
          Constellation_CN: this.moondayObj.Constellation,
          Constellation: astro.en,
          Content: "",
          MoodContent: "",
          Date: moment(new Date(this.format_time)).format("YYYY-MM-DD"),
          Time: moment(new Date(this.format_time)).format("HH:mm"),
        });
        this.$router.push("/moonday-create");
      }
    },
    toStore() {
      let productId = this.$store.getters.productListMap["MoonNote"];
      this.$router.push(`/store/detail/${productId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.MoonDayCTAWrap {
  width: 100%;
  padding: 0 20px;
}
.MoonDayCTA {
  @include rect(100%, auto, #f0f2f4, 20px);
  padding: 0 16px;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1), -2px -2px 8px 0px #fff,
    -4px -4px 12px 0px #fff;

  // 推運版型
  &__userInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #868686;
    font-size: 15px;
    & > div {
      display: flex;
      align-items: center;
      & > div {
        margin-left: 8px;
      }
    }
  }
  &__userDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: #868686;
    text-align: center;
    font-size: 14px;
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &--right {
        align-items: center;
      }
      &__place {
        display: flex;
        align-items: center;
        color: #868686;
        &--space {
          margin: 0 4px;
        }
      }
      &__birth {
        color: #868686;
        &--space {
          margin: 0 4px;
        }
      }
    }
  }
  // 天象版型
  &__astrometry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    &__btn {
      display: flex;
      align-items: center;
    }
  }
  &__astrometryDetail {
    margin-top: -5px;
    padding-bottom: 12px;
    color: #868686;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  // 結束
  &__line {
    height: 1px;
    background: #f0f2f4;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08) inset, 3px 3px 4px 0px #fff;
  }
  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #868686;
    font-size: 14px;
    font-weight: 400;
    span {
      color: #ab956c;
    }
    &__toNote {
      display: flex;
      align-items: center;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.toUnlock_cta {
  @include btn(116px, 35px, $theme-color-2, #fff);
  font-weight: 500;
  margin-left: 20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  @include mobile {
    width: 94px;
    height: 30px;
    margin-left: 12px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.nowUserData
        ? _c("section", { staticClass: "row-wrap" }, [
            _c("div", { staticClass: "detail" }, [
              _c("div", { staticClass: "detail__tab" }, [
                _c(
                  "div",
                  {
                    staticClass: "detail__tab__item",
                    class: {
                      activeDetailTab: _vm.activeDetailTab == "interpret"
                    },
                    on: {
                      click: function($event) {
                        _vm.activeDetailTab = "interpret"
                      }
                    }
                  },
                  [_vm._v("\n          星盤解讀\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "detail__tab__item",
                    class: { activeDetailTab: _vm.activeDetailTab == "friend" },
                    on: {
                      click: function($event) {
                        _vm.activeDetailTab = "friend"
                      }
                    }
                  },
                  [_vm._v("\n          好友選擇\n        ")]
                )
              ]),
              _vm.activeDetailTab == "interpret"
                ? _c("div", { staticClass: "pan_interpret" })
                : _vm._e(),
              _vm.activeDetailTab == "friend"
                ? _c("div", { staticClass: "pan_friendList" }, [
                    _c("div", { staticClass: "btn__wrap" }, [
                      _c(
                        "button",
                        {
                          staticClass: "user__btn",
                          on: {
                            click: function($event) {
                              return _vm.changeData()
                            }
                          }
                        },
                        [_vm._v("\n            取得合盤(選兩筆)\n          ")]
                      )
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.friendList, function(set, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "friend_set" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(set.Title) +
                                "\n            "
                            ),
                            _c(
                              "ul",
                              _vm._l(set.AstrolabeFiles, function(friend) {
                                return _c(
                                  "li",
                                  {
                                    key: friend.AstrolabeFileId,
                                    staticClass: "friend_item"
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        id: friend.AstrolabeFileId
                                      },
                                      domProps: {
                                        checked:
                                          _vm.friendListCheck.indexOf(
                                            "" + friend.AstrolabeFileId
                                          ) > -1
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.selectData(
                                            friend.AstrolabeFileId
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: friend.AstrolabeFileId }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(friend.Name) +
                                            " - " +
                                            _vm._s(friend.BirthDay) +
                                            " -\n                  " +
                                            _vm._s(friend.City)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.Data2
                  ? _c("div", { staticClass: "user" }, [
                      _c("div", { staticClass: "user__info" }, [
                        _c("img", { attrs: { src: _vm.Data.ProfilePhotoSrc } }),
                        _c("div", { staticClass: "user__info__name" }, [
                          _vm._v(_vm._s(_vm.Data.Name))
                        ])
                      ]),
                      _vm._m(0),
                      _c("div", { staticClass: "user__info" }, [
                        _c("img", {
                          attrs: { src: _vm.Data2.ProfilePhotoSrc }
                        }),
                        _c("div", { staticClass: "user__info__name" }, [
                          _vm._v(_vm._s(_vm.Data2.Name))
                        ])
                      ])
                    ])
                  : _c("div", { staticClass: "user" }, [
                      _c("div", { staticClass: "user__info" }, [
                        _c("img", {
                          attrs: { src: _vm.nowUserData.ProfilePhotoSrc }
                        }),
                        _c("div", { staticClass: "user__info__name" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$NameFormat(_vm.nowUserData.Name)) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._m(1),
                      _c("div", { staticClass: "user__info" }, [
                        _c("img", {
                          attrs: { src: _vm.nowUserData2.ProfilePhotoSrc }
                        }),
                        _c("div", { staticClass: "user__info__name" }, [
                          _vm._v(_vm._s(_vm.nowUserData2.Name))
                        ])
                      ])
                    ]),
                _c("v-spacer"),
                _c("ul", { staticClass: "pan_type" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.showType == "type1" },
                      on: {
                        click: function($event) {
                          return _vm.changeType1()
                        }
                      }
                    },
                    [_vm._v("\n          比較 1\n        ")]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.showType == "type2" },
                      on: {
                        click: function($event) {
                          return _vm.changeType2()
                        }
                      }
                    },
                    [_vm._v("\n          比較 2\n        ")]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.showType == "type3" },
                      on: {
                        click: function($event) {
                          return _vm.changeType3()
                        }
                      }
                    },
                    [_vm._v("\n          組合\n        ")]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "astrolabe" },
                  [
                    _c("div", { staticClass: "astrolabe__wrap" }, [
                      _c(
                        "div",
                        { staticClass: "swiper" },
                        [
                          _c(
                            "swiper",
                            {
                              attrs: { id: "SW", options: _vm.swiperAstrolabe }
                            },
                            _vm._l(_vm.style, function(item) {
                              return _c(
                                "swiper-slide",
                                {
                                  key: item.id,
                                  staticClass: "astrolabe__item"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "astrolabe__bg",
                                      attrs: { id: "astrolabe__bg" }
                                    },
                                    [
                                      _vm.dataChange
                                        ? _c("canvasAstrolabe", {
                                            staticClass: "canvas-pan",
                                            attrs: {
                                              width: _vm.paramPan.width,
                                              configItem: _vm.panConfigItem,
                                              panStyle: item.name,
                                              type: _vm.paramPan.type,
                                              data1: _vm.Data.AstralInfo
                                                ? _vm.Data.AstralInfo
                                                    .AstralRadians
                                                : _vm.Data,
                                              data2: _vm.Data2
                                                ? _vm.Data2.AstralInfo
                                                    .AstralRadians
                                                : _vm.Data2
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "user__info__birth-m hidden-pad-up"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.nowUserData.BirthDay) +
                                  " " +
                                  _vm._s(_vm.nowUserData.BirthTime) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "switch" }, [
                            _c("div", {
                              staticClass: "swiper-button-prev",
                              attrs: { slot: "button-prev" },
                              slot: "button-prev"
                            }),
                            _c("div", {
                              staticClass: "swiper-pagination",
                              attrs: { slot: "pagination" },
                              slot: "pagination"
                            }),
                            _c("div", {
                              staticClass: "swiper-button-next",
                              attrs: { slot: "button-next" },
                              slot: "button-next"
                            })
                          ])
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "astrolabe__setting",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openSetup()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icon_setting.svg"),
                            alt: ""
                          }
                        })
                      ]
                    ),
                    _c("SetAstrolabe", {
                      attrs: { activeSetup: _vm.activeSetup },
                      on: {
                        close: function($event) {
                          _vm.activeSetup = false
                        },
                        dataChange: function($event) {
                          return _vm.dataChangeHandler($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("影響")]), _c("span", [_vm._v("→")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("組合")]), _c("span", [_vm._v("+")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
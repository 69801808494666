var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.nowUserData && _vm.nowUserAstralInfo
        ? _c("section", { staticClass: "row-wrap custom-container" }, [
            _c("div", { staticClass: "detail" }, [
              _c("div", { staticClass: "detail__tab" }, [
                _c(
                  "div",
                  {
                    staticClass: "detail__tab__item",
                    class: {
                      activeDetailTab: _vm.activeDetailTab == "interpret"
                    },
                    on: {
                      click: function($event) {
                        _vm.activeDetailTab = "interpret"
                      }
                    }
                  },
                  [_vm._v("\n          星盤解讀\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "detail__tab__item",
                    class: { activeDetailTab: _vm.activeDetailTab == "params" },
                    on: {
                      click: function($event) {
                        _vm.activeDetailTab = "params"
                      }
                    }
                  },
                  [_vm._v("\n          詳細參數\n        ")]
                )
              ]),
              _vm.activeDetailTab == "interpret"
                ? _c("div", { staticClass: "interpret" }, [
                    _c(
                      "div",
                      { staticClass: "interpret__tab" },
                      [
                        _c("transition", { attrs: { name: "fade" } }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.interpretTabNotice == true,
                                  expression: "interpretTabNotice == true"
                                }
                              ],
                              staticClass: "interpret__notice"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/icon_swipe.svg"),
                                  alt: ""
                                }
                              }),
                              _c("span", [_vm._v("左右滑動選擇解讀")])
                            ]
                          )
                        ]),
                        _c(
                          "swiper",
                          {
                            staticStyle: { padding: "5px 5px" },
                            attrs: { id: "sw", options: _vm.swiperInterpret }
                          },
                          _vm._l(_vm.interpretData, function(astro) {
                            return _c(
                              "swiper-slide",
                              {
                                key: astro.id,
                                staticClass: "interpret__tab__item",
                                class: {
                                  activeInterpretTab:
                                    astro.name === _vm.NowInterpret.name
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.changeInterpret(astro.name)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "iconfont interpret__icon" },
                                  [_vm._v(_vm._s(astro.img))]
                                ),
                                _c("span", { staticClass: "interpret__text" }, [
                                  _vm._v(_vm._s(astro.name))
                                ])
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "interpret__star",
                        class: { openStar: _vm.openStar },
                        on: {
                          click: function($event) {
                            _vm.openStar = !_vm.openStar
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "interpret__star__title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.NowInterpret.name) +
                              "\n            "
                          ),
                          _c("img", {
                            staticClass: "interpret__star__img",
                            attrs: { src: _vm.NowInterpret.img1, alt: "" }
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.NowInterpret.Title2) +
                              "\n            "
                          ),
                          _c(
                            "div",
                            { staticClass: "interpret__star__degree" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.NowInterpret.Angle1) +
                                  "\n              "
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  staticStyle: { transform: "translateX(-1px)" }
                                },
                                [_vm._v(_vm._s(_vm.NowInterpret.Angle2))]
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "interpret__star__openText" },
                          [_vm._v("點擊展開解讀")]
                        ),
                        _c("img", {
                          staticClass: "interpret__star__openImg",
                          attrs: { src: require("@/assets/icon_downward.svg") }
                        }),
                        _c("div", { staticClass: "interpret__line__new" }),
                        _c(
                          "div",
                          { staticClass: "interpret__star__subtitle" },
                          [
                            _c("span", { staticClass: "subtitle__text" }, [
                              _vm._v(_vm._s(_vm.NowInterpret.SubTitle))
                            ])
                          ]
                        ),
                        _c("div", {
                          staticClass: "interpret__star__content",
                          domProps: {
                            innerHTML: _vm._s(_vm.NowInterpret.Content)
                          }
                        })
                      ]
                    ),
                    _vm.NowInterpret.HouseContent
                      ? _c(
                          "div",
                          {
                            staticClass: "interpret__house",
                            class: { openHouse: _vm.openHouse },
                            on: {
                              click: function($event) {
                                _vm.openHouse = !_vm.openHouse
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "interpret__house__title" },
                              [_vm._v("宮位解讀")]
                            ),
                            _c(
                              "div",
                              { staticClass: "interpret__house__house" },
                              [_vm._v(_vm._s(_vm.NowInterpret.House))]
                            ),
                            _vm.$store.state.nowUserData.IsUnlockBC
                              ? _c("div", [
                                  _c("div", {
                                    staticClass: "interpret__house__subtitle",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.NowInterpret.HouseTitle
                                      )
                                    }
                                  }),
                                  _c("img", {
                                    staticClass: "interpret__house__img",
                                    attrs: {
                                      src: require("@/assets/icon_downward.svg")
                                    }
                                  }),
                                  _c("div", {
                                    staticClass: "interpret__house__content",
                                    class: {
                                      "text-center":
                                        _vm.NowInterpret.name == "上升"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.NowInterpret.HouseContent
                                      )
                                    }
                                  })
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "toUnlock_cta",
                                      on: {
                                        click: function($event) {
                                          return _vm.toStore("BC")
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../assets/locked.svg"),
                                          alt: ""
                                        }
                                      }),
                                      _vm._v(
                                        "\n              點此解鎖\n            "
                                      )
                                    ]
                                  )
                                ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.activeDetailTab == "params"
                ? _c("div", { staticClass: "params" }, [
                    _c(
                      "div",
                      { staticClass: "params__tab" },
                      _vm._l(["星位", "宮位", "相位", "統計"], function(type) {
                        return _c(
                          "div",
                          {
                            key: type,
                            staticClass: "params__tab_item mt-4",
                            class: { active: _vm.activeParamsTab === type },
                            on: {
                              click: function($event) {
                                return _vm.switchTab(type)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(type) + "\n          "
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "params__content" }, [
                      _vm.activeParamsTab == "星位"
                        ? _c(
                            "table",
                            { staticClass: "table planet-table" },
                            [
                              _vm._m(0),
                              _vm._l(_vm.constellationNodes, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "table__content" },
                                  [
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "item__name__img iconfont",
                                          style: {
                                            color:
                                              item.Astral.indexOf("王") > -1
                                                ? "#e99a00"
                                                : "#27aba3"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.img))]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.Astral) +
                                          "\n              "
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.Constellation) + "座")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.Angle) +
                                          "\n                "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "retrograde" },
                                        [_vm._v(_vm._s(item.Retrograde))]
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.House) +
                                          "\n              "
                                      )
                                    ])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.activeParamsTab == "宮位"
                        ? _c(
                            "table",
                            { staticClass: "table palace-table" },
                            [
                              _vm._m(1),
                              _vm._l(_vm.palaces, function(item, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "table__content" },
                                  [
                                    _c(
                                      "td",
                                      { staticStyle: { "font-size": "12px" } },
                                      [_vm._v(_vm._s(item.House))]
                                    ),
                                    _c("td", [
                                      _vm._v(_vm._s(item.Constellation))
                                    ]),
                                    _c("td", [_vm._v(_vm._s(item.MainAstral))]),
                                    _c("td", [_vm._v(_vm._s(item.FlyInto1))]),
                                    _c("td", [_vm._v(_vm._s(item.FlyInto2))])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.activeParamsTab == "相位"
                        ? _c(
                            "table",
                            { staticClass: "phase-table" },
                            [
                              _c(
                                "tr",
                                { staticClass: "table__title" },
                                [
                                  _c("td"),
                                  _vm._l(_vm.phases1, function(Rowitem, index) {
                                    return _c("td", { key: index }, [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.phases[index]))
                                      ])
                                    ])
                                  })
                                ],
                                2
                              ),
                              _vm._l(_vm.phases1, function(RowItem, index) {
                                return _c(
                                  "tr",
                                  { key: index, staticClass: "table__content" },
                                  [
                                    _c("td", [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.phases[index]))
                                      ])
                                    ]),
                                    _vm._l(RowItem, function(ColItem, index1) {
                                      return _c(
                                        "td",
                                        {
                                          key: index1,
                                          class: _vm.phases2[index][index1]
                                        },
                                        [
                                          _vm.phaseIcon[ColItem]
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    tabindex:
                                                      index + "_" + index1
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showId =
                                                        index + "_" + index1
                                                    },
                                                    blur: function($event) {
                                                      _vm.showId = ""
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        _vm.phaseIcon[ColItem]
                                                          .icon,
                                                      alt: ""
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "phaseMean",
                                                      class: {
                                                        show:
                                                          _vm.showId ==
                                                          index + "_" + index1
                                                      },
                                                      attrs: {
                                                        id: index + "_" + index1
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.phaseIcon[
                                                              ColItem
                                                            ].deg
                                                          ) +
                                                          "度\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.activeParamsTab == "相位"
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "table palace-table introduction mt_20",
                              staticStyle: { "border-radius": "0px" }
                            },
                            [
                              _vm._m(2),
                              _vm._m(3),
                              _vm._m(4),
                              _vm._m(5),
                              _vm._m(6),
                              _vm._m(7),
                              _vm._m(8),
                              _vm._m(9)
                            ]
                          )
                        : _vm._e(),
                      _vm.activeParamsTab == "統計"
                        ? _c("div", [
                            _c(
                              "table",
                              { staticClass: "table pattern-table" },
                              [
                                _vm._m(10),
                                _vm._l(_vm.P, function(item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.Title,
                                      staticClass: "table__content"
                                    },
                                    [
                                      _c(
                                        "td",
                                        { staticStyle: { width: "25%" } },
                                        [_vm._v(_vm._s(item.Title))]
                                      ),
                                      _c(
                                        "td",
                                        _vm._l(
                                          item.Content.split("/"),
                                          function(item1, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item1) +
                                                  "\n                    "
                                              ),
                                              _c("br")
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _c(
                              "table",
                              { staticClass: "table stats-table" },
                              [
                                _vm._m(11),
                                _vm._l(_vm.Y, function(item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.Title,
                                      staticClass: "table__content"
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(item.Title))]),
                                      _c(
                                        "td",
                                        _vm._l(
                                          item.Content.split("/"),
                                          function(item1, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(_vm._s(item1))
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _c(
                              "table",
                              { staticClass: "table stats-table" },
                              [
                                _vm._m(12),
                                _vm._l(_vm.T, function(item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.Title,
                                      staticClass: "table__content"
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(item.Title))]),
                                      _c(
                                        "td",
                                        _vm._l(
                                          item.Content.split("/"),
                                          function(item1, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(_vm._s(item1))
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _c(
                              "table",
                              { staticClass: "table stats-table" },
                              [
                                _vm._m(13),
                                _vm._l(_vm.Q, function(item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.Title,
                                      staticClass: "table__content"
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(item.Title))]),
                                      _c(
                                        "td",
                                        _vm._l(
                                          item.Content.split("/"),
                                          function(item1, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(_vm._s(item1))
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ]),
            _vm.nowUserData
              ? _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "contentUser hidden-pad-down" }, [
                    _c(
                      "div",
                      { staticClass: "user" },
                      [
                        _c(
                          "div",
                          { staticClass: "user__photo" },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "user__photo__img",
                                attrs: { size: "58" }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.nowUserData.ProfilePhotoSrc
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "user__info" }, [
                          _c("div", { staticClass: "user__info__name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$NameFormat(_vm.nowUserData.Name)) +
                                "\n            "
                            )
                          ]),
                          _c("div", { staticClass: "user__info__birth" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.nowUserData.BirthDay +
                                    ", " +
                                    _vm.nowUserData.BirthTime
                                ) +
                                "\n              "
                            ),
                            _c(
                              "span",
                              { staticClass: "user__info__birth--space mx_4" },
                              [_vm._v(" | ")]
                            ),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.deFormat(_vm.nowUserData.Longitude) +
                                    ", \n               " +
                                    _vm.deFormat(_vm.nowUserData.Latitude)
                                ) +
                                "\n              "
                            ),
                            _vm.nowUserData.IsDaylight
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/日光節約icon.svg")
                                  }
                                })
                              : _vm._e()
                          ])
                        ]),
                        _c("v-spacer"),
                        _c("div", { staticClass: "user__change" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/icon_change.svg"),
                              alt: ""
                            },
                            on: { click: _vm.toFriend }
                          })
                        ])
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "astrolabe" },
                    [
                      _c("div", { staticClass: "userDesktopInfo mb_16" }, [
                        _c(
                          "div",
                          { staticClass: "userDesktopInfo__btnGroup" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "userDesktopInfo__btn",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.userDesktopInfoOpen = !_vm.userDesktopInfoOpen
                                  }
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$NameFormat(_vm.nowUserData.Name)
                                    )
                                  )
                                ]),
                                _c("div", { staticClass: "ml_3 pt_1" }, [
                                  _c("img", {
                                    style: _vm.userDesktopInfoOpen
                                      ? "transform:rotate(180deg)"
                                      : "",
                                    attrs: {
                                      src: require("@/assets/icon_downward.svg")
                                    }
                                  })
                                ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.userDesktopInfoOpen,
                                expression: "userDesktopInfoOpen"
                              }
                            ],
                            staticClass: "pt_4"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "userDesktopInfo__place mt_5" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/map-pin.svg"),
                                    alt: ""
                                  }
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.nowUserData.BirthCountry +
                                        "  " +
                                        _vm.nowUserData.BirthCity
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "userDesktopInfo__birth mt_1" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.nowUserData.BirthDay +
                                        ", " +
                                        _vm.nowUserData.BirthTime
                                    ) +
                                    "\n              "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "userDesktopInfo__birth--space mx_4"
                                  },
                                  [_vm._v(" | ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.deFormat(_vm.nowUserData.Longitude) +
                                        ", \n               " +
                                        _vm.deFormat(_vm.nowUserData.Latitude)
                                    ) +
                                    "\n              "
                                ),
                                _vm.nowUserData.IsDaylight
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/日光節約icon.svg")
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "astrolabe__wrap" }, [
                        _c(
                          "div",
                          { staticClass: "swiper" },
                          [
                            _c(
                              "swiper",
                              {
                                attrs: {
                                  id: "SW",
                                  options: _vm.swiperAstrolabe
                                }
                              },
                              _vm._l(_vm.style, function(item, index) {
                                return _c(
                                  "swiper-slide",
                                  {
                                    key: item.id,
                                    staticClass: "astrolabe__item"
                                  },
                                  [
                                    _vm.canshow[index]
                                      ? _c(
                                          "div",
                                          { staticClass: "toast toast-s" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.displayName) +
                                                "版\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "astrolabe__bg",
                                        attrs: { id: "astrolabe__bg" }
                                      },
                                      [
                                        _vm.dataChange
                                          ? _c("canvasAstrolabe", {
                                              staticClass: "canvas-pan",
                                              attrs: {
                                                role: "Astrolabe",
                                                width: _vm.paramPan.width,
                                                configItem: _vm.panConfigItem,
                                                panStyle: item.name,
                                                type: _vm.paramPan.type,
                                                data1:
                                                  _vm.nowUserAstralInfo
                                                    .AstralRadians,
                                                data2: _vm.paramPan.data2,
                                                Ret1:
                                                  _vm.nowUserAstralInfo
                                                    .RetAstralSet
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              1
                            ),
                            _c("div", { staticClass: "switch" }, [
                              _c("div", {
                                staticClass: "swiper-button-prev",
                                attrs: { slot: "button-prev" },
                                slot: "button-prev"
                              }),
                              _c("div", {
                                staticClass: "swiper-pagination",
                                attrs: { slot: "pagination" },
                                slot: "pagination"
                              }),
                              _c("div", {
                                staticClass: "swiper-button-next",
                                attrs: { slot: "button-next" },
                                slot: "button-next"
                              })
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "astrolabe__setting",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openSetup()
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/icon_setting.svg"),
                                alt: ""
                              }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "user__change-m hidden-pad-up",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toFriend()
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/icon_change.svg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      ]),
                      _c("SetAstrolabe", {
                        attrs: {
                          role: "Astrolabe",
                          activeSetup: _vm.activeSetup,
                          phasePriority: true
                        },
                        on: {
                          close: function($event) {
                            _vm.activeSetup = false
                          },
                          dataChange: function($event) {
                            return _vm.dataChangeHandler($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showSwitch
          ? _c("div", { staticClass: "toast2" }, [
              _vm._v("已切換成" + _vm._s(_vm.switchName) + "的星盤")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("星體")])]),
      _c("td", [_c("span", [_vm._v("落入星座")])]),
      _c("td", [_c("span", [_vm._v("度數")])]),
      _c("td", { attrs: { colspan: "2" } }, [_c("span", [_vm._v("落入宮位")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("宮位")])]),
      _c("td", [_c("span", [_vm._v("星座")])]),
      _c("td", [_c("span", [_vm._v("宮位守護星")])]),
      _c("td", { attrs: { colspan: "2" } }, [_c("span", [_vm._v("飛入")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("線條顏色")])]),
      _c("td", [_c("span", [_vm._v("符號")])]),
      _c("td", [_c("span", [_vm._v("角度")])]),
      _c("td", [_c("span", [_vm._v("相位")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#ffd306" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/合相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("0°")]),
      _c("td", [_vm._v("合相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#f00" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/對分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("180°")]),
      _c("td", [_vm._v("對分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#00f" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/三分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("120°")]),
      _c("td", [_vm._v("三分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#f00" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/四分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("90°")]),
      _c("td", [_vm._v("四分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#00f" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/六分相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("60°")]),
      _c("td", [_vm._v("六分相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#5def60" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/八分之三相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("135°")]),
      _c("td", [_vm._v("八分之三相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__content" }, [
      _c("td", [
        _c("div", {
          staticClass: "introduction__line",
          staticStyle: { "background-color": "#625b57" }
        })
      ]),
      _c("td", [
        _c("img", {
          attrs: { src: require("@/assets/相位icon/十二分之五相.svg"), alt: "" }
        })
      ]),
      _c("td", [_vm._v("150°")]),
      _c("td", [_vm._v("十二分之五相")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", [_c("span", [_vm._v("格局")])]),
      _c("td", [_c("span", [_vm._v("詳情")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", { staticStyle: { width: "25%" } }, [
        _c("span", [_vm._v("陰陽性")])
      ]),
      _c("td", [_c("span", [_vm._v("包含星體")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", { staticStyle: { width: "25%" } }, [
        _c("span", [_vm._v("三大模式")])
      ]),
      _c("td", [_c("span", [_vm._v("包含星體")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "table__title" }, [
      _c("td", { staticStyle: { width: "25%" } }, [
        _c("span", [_vm._v("四大元素")])
      ]),
      _c("td", [_c("span", [_vm._v("包含星體")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./NewMoonCTA.vue?vue&type=template&id=99dafdb6&"
import script from "./NewMoonCTA.vue?vue&type=script&lang=js&"
export * from "./NewMoonCTA.vue?vue&type=script&lang=js&"
import style0 from "./NewMoonCTA.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/joe/Desktop/DaMou-Frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('99dafdb6')) {
      api.createRecord('99dafdb6', component.options)
    } else {
      api.reload('99dafdb6', component.options)
    }
    module.hot.accept("./NewMoonCTA.vue?vue&type=template&id=99dafdb6&", function () {
      api.rerender('99dafdb6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/月亮日記/NewMoonCTA.vue"
export default component.exports
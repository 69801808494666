module.exports = [
  {
    imageUrl: require("@/assets/Line/astral/astral_1.svg"),
    name: "太陽",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_2.svg"),
    name: "月亮",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_3.svg"),
    name: "水星",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_4.svg"),
    name: "金星",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_5.svg"),
    name: "火星",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_6.svg"),
    name: "木星",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_7.svg"),
    name: "土星",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_8.svg"),
    name: "天王",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_9.svg"),
    name: "海王",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_10.svg"),
    name: "冥王",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_11.svg"),
    name: "上升",
    img: "",
  },
  {
    imageUrl: require("@/assets/Line/astral/astral_12.svg"),
    name: "天頂",
    img: "",
  },
];

<template>
  <div class="interpret">
    <div class="interpret__tab">
      <transition name="fade">
        <div class="interpret__notice" v-show="interpretTabNotice == true">
          <img src="@/assets/icon_swipe.svg" alt />
          <span>左右滑動選擇解讀</span>
        </div>
      </transition>
      <!-- swiper -->
      <swiper id="sw" :options="swiperInterpret" style="padding: 5px 5px">
        <!-- 順序: 上升、太陽、月亮、水星、金星、火星、木星、土星、天王星、海王星、冥王星、天頂 -->
        <swiper-slide
          v-for="(astro, index) in interpretData"
          :key="astro.key"
          class="interpret__tab__item"
          @click.native="changeInterpret(index)"
          :class="{
            activeInterpretTab: index == NowInterpretIndex,
          }"
        >
          <span class="iconfont interpret__icon">{{ astro.img }}</span>
          <span class="interpret__text">{{ astro.name }}</span>
        </swiper-slide>
      </swiper>
      <!-- swiper end -->
    </div>
    <!-- 星座解讀 -->
    <div
      class="interpret__star"
      @click="openStar = !openStar"
      :class="{ openStar: openStar }"
    >
      <div class="interpret__star__title">
        <img
          class="interpret__star__img"
          :src="interpretData[NowInterpretIndex].imgBig"
          alt
        />
        {{ interpretData[NowInterpretIndex].name }}
        <div
          class="interpret__star__degree"
          v-show="interpretData[NowInterpretIndex].IsRetAstral"
        >
          ℞逆行
        </div>
      </div>
      <div class="interpret__star__openText">點擊展開解讀</div>
      <img class="interpret__star__openImg" src="@/assets/icon_downward.svg" />
      <div class="interpret__line__new"></div>
      <div class="interpret__star__subtitle">
        <span class="subtitle__text">{{
          interpretData[NowInterpretIndex].Title
        }}</span>
      </div>
      <div class="interpret__star__content">
        {{ interpretData[NowInterpretIndex].Content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["TcAstralInfo", "BcAstralInfo"],
  data() {
    return {
      interpretTabNotice: true,
      openStar: true,
      NowInterpretIndex: 0,
      interpretData: [
        {
          img: "",
          name: "太陽",
          Astral: "Sun",
          imgBig: require("@/assets/推運星盤/太陽.svg"),
          key: "Sun",
        },
        {
          img: "",
          name: "月亮",
          Astral: "Moon",
          imgBig: require("@/assets/推運星盤/月亮.svg"),
          key: "Moon",
        },
        {
          img: "",
          name: "水星",
          Astral: "Mercury",
          imgBig: require("@/assets/推運星盤/水星.png"),
          key: "Mercury",
        },
        {
          img: "",
          name: "金星",
          Astral: "Venus",
          imgBig: require("@/assets/推運星盤/金星.png"),
          key: "Venus",
        },
        {
          img: "",
          name: "火星",
          Astral: "Mars",
          imgBig: require("@/assets/推運星盤/火星.svg"),
          key: "Mars",
        },
        {
          img: "",
          name: "木星",
          Astral: "Jupiter",
          imgBig: require("@/assets/推運星盤/木星.svg"),
          key: "require",
        },
        {
          img: "",
          name: "土星",
          Astral: "Saturn",
          imgBig: require("@/assets/推運星盤/土星.svg"),
          key: "Saturn",
        },
        {
          img: "",
          name: "天王",
          Astral: "Uranus",
          imgBig: require("@/assets/推運星盤/天王.svg"),
          key: "Uranus",
        },
        {
          img: "",
          name: "海王",
          Astral: "Neptune",
          imgBig: require("@/assets/推運星盤/海王.svg"),

          key: "Neptune",
        },
        {
          img: "",
          name: "冥王",
          Astral: "Pluto",
          imgBig: require("@/assets/推運星盤/冥王.svg"),
          key: "Pluto",
        },
      ],
      //解讀Swiper
      swiperInterpret: {
        slidesPerView: "auto",
        spaceBetween: 12,
        freeMode: true,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.interpretTabNotice = true;
      setTimeout(() => {
        this.interpretTabNotice = false;
      }, 3000);
    }, 500);
  },
  methods: {
    changeInterpret(index) {
      this.NowInterpretIndex = index;
    },
    getInterpretations() {
      this.$API
        .POST_LcInterpretations({
          TcAstralInfo: this.TcAstralInfo,
          BcAstralInfo: this.BcAstralInfo,
        })
        .then((res) => {
          this.interpretData = this.interpretData.map((item) => {
            let InterpretationsObj = res.Data.find((obj) => {
              return obj.Astral === item.Astral;
            });
            if (InterpretationsObj) {
              return {
                ...item,
                ...InterpretationsObj,
                key: new Date().toISOString() + item.name,
              };
            }
          });
          this.$forceUpdate();
        });
    },
  },
  watch: {
    TcAstralInfo: {
      handler() {
        this.getInterpretations();
      },
      immediate: true,
    },
    BcAstralInfo() {
      this.getInterpretations();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.interpret {
  width: 100%;

  .interpret__tab {
    position: relative;
    margin-top: 24px;
    @include align(flex-start, center);

    .interpret__notice {
      @include center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      background-color: rgba(240, 242, 244, 0.8);
      img {
        position: relative;
        width: 54px;
        height: 54px;
        animation: swipe 0.5s linear infinite both alternate;
        @keyframes swipe {
          from {
            left: 0px;
          }
          to {
            left: -30px;
          }
        }
        @include mobile {
          width: 32px;
          height: 32px;
        }
      }
      span {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: $theme-color-2;
        letter-spacing: 0.56px;
        @include mobile {
          font-size: 12px;
          letter-spacing: 0.48px;
        }
      }
    }

    .interpret__tab__item {
      @include rect(48px, 72px, $theme-color-3, 24px);
      @include center;
      flex-direction: column;
      box-shadow: -1px -1px 4px 0 #ffffff, 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
      // transition: 0.2s;
      cursor: pointer;

      .interpret__icon {
        font-size: 18px;
        color: #27aba3;
      }

      .interpret__text {
        writing-mode: vertical-rl;
        color: $secondary-color-3;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.56px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        .interpret__icon {
          color: #e99a00;
        }
      }

      &:hover,
      &.activeInterpretTab {
        background-color: $theme-color-1;

        .interpret__icon {
          color: #ffffff;
        }

        .interpret__text {
          color: #ffffff;
        }
      }
    }
  }

  .interpret__star {
    margin: 32px 0;
    padding: 18px 32px 28px;
    @include rect(100%, auto, $theme-color-3, 20px);
    @include align(flex-start, center);
    flex-direction: column;
    position: relative;
    cursor: pointer;
    @include mobile {
      padding-right: 20px;
      padding-left: 20px;
    }
    &__openText {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.08em;
      color: #9b9b9b;
      opacity: 1;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 12px;
    }
    //打開星座解讀
    &.openStar {
      .interpret__star__openText {
        opacity: 0;
      }
      .interpret__line__new {
        opacity: 1;
        max-height: 1000px;
        margin: 32px 0;
        @include pad {
          margin: 28px 0;
        }
      }
      .interpret__star__content {
        max-height: 1000px;
        opacity: 1;
      }
      .interpret__star__subtitle {
        max-height: 1000px;
        opacity: 1;
        margin-bottom: 16px;
        @include pad {
          margin-bottom: 14px;
        }
      }
      .interpret__star__openImg {
        transform: rotate(180deg);
      }
    }
    .interpret__star__openImg {
      position: absolute;
      right: 12px;
      bottom: 12px;
    }
    .interpret__star__title {
      position: relative;
      @include center;
      @include text-2;
      width: 100%;

      .interpret__star__img {
        width: 64px;
        height: 64px;
        margin: 0 8px;
        @include mobile {
          width: 56px;
          height: 56px;
        }
      }

      .interpret__star__degree {
        writing-mode: vertical-lr;
        @include center;
        flex-direction: column;
        position: absolute;
        right: 0;
        width: 36px;
        height: 60px;
        border-radius: 6px;
        box-shadow: inset 4px 4px 10px 0 rgba(0, 0, 0, 0.08),
          2px 2px 4px 0 #ffffff;
        background-color: $theme-color-3;
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.56px;
        color: rgba($color: $secondary-color-1, $alpha: 0.6);
        text-align: end;
        font-weight: 400;
        @include mobile {
          width: 28px;
          height: 48px;
          font-size: 10px;
        }
      }
    }

    .interpret__star__house {
      @include center;
      width: 120px;
      height: 24px;
      background-color: $theme-color-1;
      color: $theme-color-3;
      margin: 10px 0 24px 0;
    }
    .interpret__line__new {
      margin: 0;
      width: 100%;
      height: 3px;
      box-shadow: inset 4px 4px 10px 0 rgba(0, 0, 0, 0.08),
        3px 3px 4px 0 #ffffff;
      background-color: #f0f2f4;
      max-height: 0;
      opacity: 0;
      transition: 0.5s;
    }

    .interpret__star__subtitle {
      margin-bottom: 0;
      width: 100%;
      max-height: 0;
      opacity: 0;
      transition: 0.5s;

      @include center;
      .subtitle__text {
        color: $theme-color-2;
        font-size: 16px;
        margin: 0 10px;
        @include pad {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .interpret__star__content {
      @include text-3("#292d2d");
      font-size: 16px;
      margin-bottom: 30px;
      line-height: 30px !important;
      transition: 0.5s;
      max-height: 0;
      opacity: 0;
      /* margin-top: 12px; */
      margin: 12px;
      @include pad {
        font-size: 14px;
        line-height: 24px !important;
      }
    }
  }

  .interpret__house {
    position: relative;
    padding: 28px 32px;
    @include rect(100%, auto, $theme-color-3, 20px);
    @include align(flex-start, center);
    min-height: 120px;
    flex-direction: column;
    cursor: pointer;
    @include mobile {
      padding-right: 20px;
      padding-left: 20px;
    }

    .interpret__house__title {
      @include text-2;
    }

    .interpret__house__house {
      position: absolute;
      top: 12px;
      right: 37px;
      width: 36px;
      height: 60px;
      @include center;
      color: $theme-color-1;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      writing-mode: vertical-lr;
      box-shadow: 2px 2px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
    }
    .interpret__house__subtitle {
      width: 100%;
      transition: 0.5s;

      @include center;
      color: $theme-color-2;
      font-size: 16px;
      margin: 24px 0 0;
      @include pad {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .interpret__house__img {
      position: absolute;
      right: 12px;
      bottom: 12px;
      transition: transform 0.5s;
    }

    .interpret__house__content {
      @include text-3("#292d2d");
      overflow: hidden;
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0;
      transition: max-height 0.5s 0.1s, opacity 0.5s, margin-top 0.5s;
    }
    //還沒解鎖
    .toUnlock_cta {
      margin-top: 26px;
      @include btn(268px, 56px, $theme-color-2, #fff);
    }
    //打開宮位解讀
    &.openHouse {
      .interpret__house__img {
        transform: rotate(180deg);
      }

      .interpret__house__content {
        max-height: 1000px;
        margin-top: 18px;
        margin-bottom: 30px;
        opacity: 1;
      }
    }
  }
}
</style>

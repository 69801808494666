export default {
  namespaced: true,
  state: () => ({
    mainPlanetList: [
      {
        imageUrl: require("@/assets/Line/astral/astral_1.svg"),
        name: "太陽",
        img: "",
        toggleImg: require("@/assets/Line/disable-eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 15,
        default: 15,
        strict: 15,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_2.svg"),
        name: "月亮",
        img: "",
        toggleImg: require("@/assets/Line/disable-eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 12,
        default: 12,
        strict: 12,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_3.svg"),
        name: "水星",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 7,
        default: 7,
        strict: 7,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_4.svg"),
        name: "金星",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 7,
        default: 7,
        strict: 7,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_5.svg"),
        name: "火星",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 7, //8
        default: 7,
        strict: 7,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_6.svg"),
        name: "木星",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 5, //9
        default: 5,
        strict: 5,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_7.svg"),
        name: "土星",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 5, //9
        default: 5,
        strict: 5,
      },
      {
        name: "合相(0度)",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 7,
        default: 7,
        strict: 4,
      },
      {
        name: "四分相(90度)",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 6,
        default: 6,
        strict: 3,
      },
      {
        name: "三分相(120度)",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 5, //6
        default: 5,
        strict: 3,
      },
      {
        name: "對分相(180度)",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 5, //6
        default: 5,
        strict: 3,
      },
      {
        name: "六分相(60度)",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
        range: 15,
        val: 5,
        default: 5,
        strict: 2,
      },
      {
        name: "半六分相(30度)",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
        range: 2,
        val: 1.5,
        default: 1.5,
        strict: 1,
      },
      {
        name: "半四分相(45度)",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
        range: 2,
        val: 1.5,
        default: 1.5,
        strict: 1,
      },
      {
        name: "五分相(72度)",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
        range: 2,
        val: 1.5,
        default: 1.5,
        strict: 1.5,
      },
      {
        name: "八分之三相(135度)",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
        range: 2,
        val: 1.5,
        default: 1.5,
        strict: 1.5,
      },
      {
        name: "補五分相(144度)",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
        range: 2,
        val: 1.5,
        default: 1.5,
        strict: 1.5,
      },
      {
        name: "十二分之五相(150度)",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
        range: 2,
        val: 1.5,
        default: 1.5,
        strict: 1.5,
      },
    ],
    planetList: [
      {
        imageUrl: require("@/assets/Line/astral/astral_8.svg"),
        name: "天王",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_9.svg"),
        name: "海王",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_10.svg"),
        name: "冥王",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_11.svg"),
        name: "上升",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_12.svg"),
        name: "天頂",
        img: "",
        toggleImg: require("@/assets/Line/eye.svg"),
        toggleSelect: true,
        defaultSelect: true,
        strictSelect: true,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_13.svg"),
        name: "凱龍",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: true,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_14.svg"),
        name: "穀神",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_15.svg"),
        name: "智神",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_16.svg"),
        name: "婚神",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_17.svg"),
        name: "灶神",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_18.svg"),
        name: "北交",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_19.svg"),
        name: "南交",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_20.svg"),
        name: "莉莉絲",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_21.svg"),
        name: "福點",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_22.svg"),
        name: "宿命",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_23.svg"),
        name: "東昇",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_24.svg"),
        name: "下降",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
      {
        imageUrl: require("@/assets/Line/astral/astral_25.svg"),
        name: "天底",
        img: "",
        toggleImg: require("@/assets/Line/closed-eye.svg"),
        toggleSelect: false,
        defaultSelect: false,
        strictSelect: false,
      },
    ],
  }),
  mutations: {
    setPanConfig(state, panConfig) {
      for (let item in panConfig) {
        state.panConfig[item] = panConfig[item];
      }
    },
    setMainPlanetList(state, data) {
      state.mainPlanetList = data;
    },
    resetSetting(state) {
      //回到預設值(JOE 2022/04/07新增)
      state.mainPlanetList = state.mainPlanetList.map((item) => {
        item.val = item.default;
        item.toggleSelect = item.defaultSelect
        return item;
      });
      state.planetList = state.planetList.map((item) => {
        item.toggleSelect = item.defaultSelect
        return item;
      });
    },
    resetSettingStrict(state) {
      //變成嚴格模式(JOE 2022/04/17新增)
      state.mainPlanetList = state.mainPlanetList.map((item) => {
        item.val = item.strict;
        item.toggleSelect = item.strictSelect;
        return item;
      });
      state.planetList = state.planetList.map((item) => {
        item.toggleSelect = item.strictSelect;
        return item;
      });
    },
  },

  actions: {
    setPanConfig({ commit }, panConfig) {
      commit("setPanConfig", panConfig);
    },
    setMainPlanetList({ commit }, data) {
      commit("setMainPlanetList", data);
    },
  },
};

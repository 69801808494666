var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FixedPopup", { class: { onFocus: _vm.onFocus } }, [
    _vm.nowStep == "0"
      ? _c("div", { staticClass: "stepBox step0Box" }, [
          _c("div", { staticClass: "stepBox__pic" }, [
            _c("img", {
              attrs: { src: require("@/assets/插圖/s1.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "stepBox__content" }, [
            _c("h2", [_vm._v("想進行帳號歸戶嗎？")]),
            _c("div", { staticClass: "stepBox__content__desc" }, [
              _vm._v(
                "\n        曾經登入發現「已解鎖內容」需再次付費，可能是過去「使用不同帳號登入」所導致。您可以透過「帳號歸戶」來整合管理所有帳戶星盤與訂單，或是「維持原樣」各別管理不同帳號。\n      "
              )
            ]),
            _c("label", { staticClass: "checkbox-pri" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.setNoNeedToRemind,
                    expression: "setNoNeedToRemind"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.setNoNeedToRemind)
                    ? _vm._i(_vm.setNoNeedToRemind, null) > -1
                    : _vm.setNoNeedToRemind
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.setNoNeedToRemind,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.setNoNeedToRemind = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.setNoNeedToRemind = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.setNoNeedToRemind = $$c
                    }
                  }
                }
              }),
              _c("span", { staticClass: "checkmark" }),
              _vm._v("\n        不再提醒\n      ")
            ])
          ]),
          _c("div", { staticClass: "stepBox__footer" }, [
            _c(
              "div",
              {
                staticClass: "step0Box__skip",
                on: {
                  click: function($event) {
                    return _vm.nextTime()
                  }
                }
              },
              [_vm._v("下次再說")]
            ),
            _c(
              "div",
              {
                staticClass: "step0Box__start",
                on: {
                  click: function($event) {
                    _vm.nowStep = 1
                  }
                }
              },
              [_vm._v("帳號歸戶")]
            )
          ]),
          _c("div", { staticClass: "step0Box__swapAccount" }, [
            _vm._v("\n      想使用其他帳號登入嗎?\n      "),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.swapAccount()
                  }
                }
              },
              [_vm._v("改用其他帳號")]
            )
          ])
        ])
      : _vm._e(),
    _vm.nowStep == "1"
      ? _c("div", { staticClass: "stepBox step1Box" }, [
          _c("div", { staticClass: "stepBox__pic" }, [
            _c("img", {
              attrs: { src: require("@/assets/插圖/s1.png"), alt: "" }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "stepBox__close",
              on: {
                click: function($event) {
                  return _vm.nextTime()
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/icon_menu-close-dark.svg") }
              })
            ]
          ),
          _c("div", { staticClass: "stepBox__content" }, [
            _c("h2", [_vm._v("帳號歸戶設定")]),
            _c("div", { staticClass: "stepBox__content__desc" }, [
              _vm._v(
                "\n        若曾透過 Line、Facebook 或 Google\n        註冊過帳號，「帳號歸戶」可以將您各帳號的好友與星盤合併在一起。\n      "
              )
            ]),
            _c("div", { staticClass: "step1Box__form" }, [
              _c("div", { staticClass: "step1Box__form__input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.recipientMail,
                      expression: "recipientMail",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    type: "email",
                    id: "email",
                    placeholder: "輸入 Email 來歸戶帳號"
                  },
                  domProps: { value: _vm.recipientMail },
                  on: {
                    hover: function($event) {
                      _vm.onFocus = true
                    },
                    blur: [
                      function($event) {
                        _vm.onFocus = false
                        _vm.emailFormatError = false
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.recipientMail = $event.target.value.trim()
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "step1Box__form__submit",
                  class: { disable: _vm.waiting || !_vm.recipientMail },
                  on: {
                    click: function($event) {
                      return _vm.step1__sendEmail()
                    }
                  }
                },
                [_vm._v("\n          驗證\n        ")]
              )
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.emailFormatError,
                    expression: "emailFormatError"
                  }
                ],
                staticClass: "inputError"
              },
              [_vm._v("Email格式有誤!")]
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.emailSendFrequent,
                    expression: "emailSendFrequent"
                  }
                ],
                staticClass: "inputError"
              },
              [
                _vm._v(
                  "\n        您的信件驗證已達本日上限，請確認您輸入的信箱是否正確或是檢查驗證信是否誤判為垃圾郵件，謝謝！\n      "
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm.nowStep == "2"
      ? _c("div", { staticClass: "stepBox step2Box" }, [
          _c("div", { staticClass: "stepBox__pic" }, [
            _c("img", {
              attrs: { src: require("@/assets/插圖/develop.svg"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "stepBox__content" }, [
            _c("h2", [_vm._v("信箱驗證")]),
            _c("div", { staticClass: "stepBox__content__desc" }, [
              _vm._v("\n        驗證碼已寄至您的信箱，請輸入驗證碼\n      ")
            ]),
            _c("div", { staticClass: "step2Box__form" }, [
              _c(
                "div",
                {
                  staticClass: "verification-code-input",
                  class: { captchaError: _vm.captchaError }
                },
                [
                  _c(
                    "div",
                    { staticClass: "verification-code-input-group" },
                    _vm._l(_vm.inputs, function(input, index) {
                      return _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: input.value,
                            expression: "input.value"
                          }
                        ],
                        key: index,
                        ref: "input" + index,
                        refInFor: true,
                        class: { active: input.active },
                        attrs: {
                          type: "tel",
                          maxlength: "1",
                          autocomplete: "off",
                          placeholder: "●"
                        },
                        domProps: { value: input.value },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(input, "value", $event.target.value)
                            },
                            function($event) {
                              return _vm.handleInput(index)
                            }
                          ],
                          keydown: function($event) {
                            return _vm.handleKeyDown(index, $event)
                          },
                          focus: function($event) {
                            _vm.onFocus = true
                          },
                          blur: function($event) {
                            _vm.onFocus = false
                          }
                        }
                      })
                    }),
                    0
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.captchaError,
                          expression: "captchaError"
                        }
                      ],
                      staticClass: "inputError"
                    },
                    [_vm._v("請輸入正確的驗證碼")]
                  )
                ]
              )
            ]),
            _c("p", { staticClass: "step2Box__resend" }, [
              _c("span", { staticClass: "step2Box__resend__time" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.block_countdown + "s") +
                    "\n        "
                )
              ]),
              _vm._v("\n        後將可以重新發驗證碼\n        "),
              _c(
                "span",
                {
                  staticClass: "step2Box__resend__btn",
                  class: { active: _vm.block_CanSend },
                  on: {
                    click: function($event) {
                      return _vm.step1__sendEmail()
                    }
                  }
                },
                [_vm._v("發送驗證碼")]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "step2Box__submit",
              class: { notReady: _vm.inputCaptcha.length !== 6 },
              on: {
                click: function($event) {
                  return _vm.importToAccount()
                }
              }
            },
            [_vm._v("\n      送出\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "step2Box__back",
              on: {
                click: function($event) {
                  return _vm.backStep1()
                }
              }
            },
            [_vm._v("回上一頁")]
          )
        ])
      : _vm._e(),
    _vm.nowStep == "3"
      ? _c("div", { staticClass: "step3Box" }, [
          _c("img", {
            staticClass: "step3Box__img",
            attrs: { src: require("@/assets/ok.svg"), alt: "" }
          }),
          _c("div", { staticClass: "step3Box__title" }, [
            _vm._v("信箱已驗證成功，謝謝您！")
          ]),
          _c(
            "div",
            {
              staticClass: "step3Box__close",
              on: {
                click: function($event) {
                  _vm.nowStep = "4"
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/close.svg"), alt: "" }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm.nowStep == "4"
      ? _c("div", { staticClass: "stepBox step4Box" }, [
          _c("div", { staticClass: "stepBox__pic" }, [
            _c("img", {
              attrs: { src: require("@/assets/插圖/s1.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "stepBox__content" }, [
            _c("h2", [_vm._v("帳號歸戶成功")]),
            _c("div", { staticClass: "stepBox__content__desc" }, [
              _vm._v(
                "\n        已成功將您的\n        " +
                  _vm._s(_vm.sccessPlatforms.join("、")) +
                  "\n        整合在同一個帳號下。未來您可以自由地透過 Facebook、Line、Google\n        或信箱來登入帳號。\n        "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                "點擊「前往好友列表」，您可以看到合併後的好友名單喔~\n      "
              )
            ])
          ]),
          _c("div", { staticClass: "step4Box__btnGroup" }, [
            _c(
              "div",
              {
                staticClass: "step4Box__btnGroup__toFriend",
                on: {
                  click: function($event) {
                    return _vm.toFriend()
                  }
                }
              },
              [_vm._v("\n        前往好友列表\n      ")]
            ),
            _c(
              "div",
              {
                staticClass: "step4Box__btnGroup__toImportList",
                on: {
                  click: function($event) {
                    return _vm.toImportList()
                  }
                }
              },
              [_vm._v("\n        查看帳號歸戶\n      ")]
            )
          ])
        ])
      : _vm._e(),
    _vm.nowStep == "5"
      ? _c("div", { staticClass: "stepBox step5Box" }, [
          _c("div", { staticClass: "stepBox__pic" }, [
            _c("img", {
              staticClass: "hidden-mobile-down",
              attrs: { src: require("@/assets/歸戶提示.png"), alt: "" }
            }),
            _c("img", {
              staticClass: "hidden-mobile-up",
              attrs: { src: require("@/assets/歸戶提示_m.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "stepBox__content" }, [
            _c("h2", [
              _vm._v("\n        未來仍可從個人帳號中\n        "),
              _c("br"),
              _vm._v("設定「帳號歸戶」喔\n      ")
            ]),
            _c("div", { staticClass: "stepBox__content__desc" }, [
              _vm._v(
                "\n        未來若想設定「帳號歸戶」，您仍可以透過點擊個人帳號的選單，來設定「帳號歸戶」。\n      "
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "step5Box__btn",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("了解")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "intro custom-container-xl" }, [
        _c("div", { staticClass: "intro__img" }, [
          _c("img", {
            attrs: { src: _vm.carousel[_vm.carouselIndex].img, alt: "唐老師" }
          })
        ]),
        _c("div", { staticClass: "intro__content" }, [
          _c("div", { staticClass: "content__box" }, [
            _c("div", { staticClass: "content__title" }, [
              _c("h1", {
                domProps: {
                  innerHTML: _vm._s(_vm.carousel[_vm.carouselIndex].title)
                }
              })
            ]),
            _c("div", {
              staticClass: "content__sub hidden-pad-down",
              domProps: {
                innerHTML: _vm._s(_vm.carousel[_vm.carouselIndex].subtitle)
              }
            }),
            _c("div", { staticClass: "content__line hidden-pad-down" }),
            _c("div", {
              staticClass: "content__slogan",
              domProps: {
                innerHTML: _vm._s(_vm.carousel[_vm.carouselIndex].slogan)
              }
            }),
            _c(
              "div",
              {
                staticClass: "content__btn",
                on: {
                  click: function($event) {
                    return _vm.toPan(
                      _vm.carousel[_vm.carouselIndex].buttonEventId
                    )
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.carousel[_vm.carouselIndex].buttonText) +
                    "\n        "
                )
              ]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "carousel__group" },
        _vm._l(_vm.carousel, function(item, index) {
          return _c("div", {
            key: index,
            staticClass: "carousel__group__bullet",
            class: { active: _vm.carouselIndex === index },
            on: {
              click: function($event) {
                _vm.carouselIndex = index
              }
            }
          })
        }),
        0
      ),
      _c("div", { staticClass: "color-block" }),
      _c("section", { staticClass: "about" }, [
        _c(
          "div",
          { staticClass: "about_box" },
          [
            _c("v-col", { staticClass: "about_title", attrs: { md: "6" } }, [
              _c("h2", [_vm._v("About  Us")]),
              _c("h2", [_c("p", [_vm._v("品牌故事")])])
            ]),
            _c("v-col", { staticClass: "about_img" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/Index/aboutUs.jpg"),
                  alt: "關於我們"
                }
              })
            ]),
            _c("v-col", { staticClass: "about_context", attrs: { md: "6" } }, [
              _c("span", [_vm._v("唐綺陽 / 華人界最具影響力的占星師")]),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n        最優質Premium的星座專家，始終堅持人文與清流路線，致力於占星知識的推廣與運用，期待每個人都可以受惠這門超越科學的學問，找到自己最好的存在方式。\n      "
              )
            ]),
            _c("Social", {
              staticClass: "about_social_group",
              on: {
                weChat: function($event) {
                  _vm.weChat = true
                }
              }
            })
          ],
          1
        ),
        _vm._m(0)
      ]),
      _c(
        "section",
        { staticClass: "slide custom-container-xl hidden-laptop-down" },
        [
          _c(
            "div",
            {
              staticClass: "slide__arrow",
              class: { disable: _vm.nowTab == 0 },
              on: { click: _vm.tabPrev }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/icon_prev2.svg"), alt: "icon" }
              })
            ]
          ),
          _c("div", { staticClass: "slide__wrap" }, [
            _c("div", { staticClass: "slide__box" }, [
              _c(
                "div",
                { staticClass: "slide__item", class: { fix: _vm.nowTab == 0 } },
                [
                  _c("img", {
                    attrs: { src: _vm.tab[_vm.nowTab].img1, alt: "icon" }
                  })
                ]
              ),
              _c("div", { staticClass: "slide__current" }, [
                _c("div", { staticClass: "current__bg" }, [
                  _c("img", {
                    attrs: { src: _vm.tab[_vm.nowTab].img, alt: "icon圖片" }
                  })
                ])
              ]),
              _c("div", { staticClass: "slide__word" }, [
                _c(
                  "div",
                  {
                    staticClass: "slide__title",
                    class: { nextSlideTitle: _vm.nowTab == 1 }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.tab[_vm.nowTab].title) +
                        "\n          "
                    )
                  ]
                ),
                _c("div", { staticClass: "slide__subtitle" }, [
                  _vm._v(_vm._s(_vm.tab[_vm.nowTab].subtitle))
                ]),
                _c("div", { staticClass: "slide__content" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.tab[_vm.nowTab].content)
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.tab[_vm.nowTab].content2) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "slide__num" }, [
              _c("span", [_vm._v("0" + _vm._s(_vm.nowTab + 1))]),
              _vm._v("/ 0" + _vm._s(_vm.tab.length) + "\n      ")
            ]),
            _c("div", { staticClass: "slide__bar" }, [
              _c("div", {
                staticClass: "bar",
                style:
                  "left:" +
                  (100 / _vm.tab.length) * _vm.nowTab +
                  "%;width:" +
                  100 / _vm.tab.length +
                  "%"
              })
            ]),
            _c(
              "div",
              {
                staticClass: "slide__btn",
                on: {
                  click: function($event) {
                    return _vm.toPan(_vm.nowTab)
                  }
                }
              },
              [_vm._v("前往體驗")]
            )
          ]),
          _c(
            "div",
            {
              staticClass: "slide__arrow",
              class: { disable: _vm.nowTab == 1 }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/icon_next2.svg"), alt: "icon" },
                on: { click: _vm.tabNext }
              })
            ]
          )
        ]
      ),
      _c(
        "section",
        { staticClass: "slide-mobile custom-container hidden-laptop-up" },
        [
          _c("div", { staticClass: "slide__box" }, [
            _c(
              "div",
              {
                staticClass: "slide__arrow",
                class: { disable: _vm.nowTab == 0 },
                on: { click: _vm.tabPrev }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icon_prev2.svg"),
                    alt: "icon"
                  }
                })
              ]
            ),
            _c("div", { staticClass: "slide__current" }, [
              _c("div", { staticClass: "current__bg" }, [
                _c("img", {
                  attrs: { src: _vm.tab[_vm.nowTab].img, alt: "icon" }
                })
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "slide__arrow",
                class: { disable: _vm.nowTab == 1 },
                on: { click: _vm.tabNext }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icon_next2.svg"),
                    alt: "icon"
                  }
                })
              ]
            )
          ]),
          _c("div", { staticClass: "slide__num" }, [
            _c("span", [_vm._v("0" + _vm._s(_vm.nowTab + 1))]),
            _vm._v("/ 0" + _vm._s(_vm.tab.length) + "\n    ")
          ]),
          _c("div", { staticClass: "slide__bar" }, [
            _c("div", {
              staticClass: "bar",
              style:
                "left:" +
                (100 / _vm.tab.length) * _vm.nowTab +
                "%;width:" +
                100 / _vm.tab.length +
                "%"
            })
          ]),
          _c("div", { staticClass: "slide__word" }, [
            _c("div", { staticClass: "slide__title" }, [
              _vm._v(_vm._s(_vm.tab[_vm.nowTab].title))
            ]),
            _c("div", { staticClass: "slide__subtitle" }, [
              _vm._v(_vm._s(_vm.tab[_vm.nowTab].subtitle))
            ]),
            _c("div", { staticClass: "slide__content" }, [
              _vm._v("\n        " + _vm._s(_vm.tab[_vm.nowTab].content)),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n        " + _vm._s(_vm.tab[_vm.nowTab].content2) + "\n      "
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "slide__btn",
              on: {
                click: function($event) {
                  return _vm.toPan(_vm.nowTab)
                }
              }
            },
            [_vm._v("前往體驗")]
          )
        ]
      ),
      _c("section", { staticClass: "personal" }, [
        _c("img", {
          staticClass: "personal__title",
          attrs: { src: require("@/assets/personal-title.svg"), alt: "" }
        }),
        _c("div", { staticClass: "personal__bg" }),
        _c("div", { staticClass: "personal__wrap custom-container-xl" }, [
          _vm._m(1),
          _c("div", { staticClass: "section__word personal__word" }, [
            _vm._m(2),
            _vm._m(3),
            _c("div", { staticClass: "section__content" }, [
              _vm._v(
                "\n          30歲看上升？愛情看金星？又跟十二宮位有什麼關係？個人星盤帶你探索自己！\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "section__btn",
                on: {
                  click: function($event) {
                    return _vm.toPan(0)
                  }
                }
              },
              [_vm._v("了解更多")]
            )
          ])
        ])
      ]),
      _c("section", { staticClass: "composite" }, [
        _c("img", {
          staticClass: "composite__title",
          attrs: { src: require("@/assets/transit-title.svg"), alt: "推運功能" }
        }),
        _c("div", { staticClass: "composite__wrap custom-container-xl" }, [
          _c("div", { staticClass: "section__word composite__word" }, [
            _vm._m(4),
            _vm._m(5),
            _c("div", { staticClass: "section__content" }, [
              _vm._v(
                "\n          推運功能已經推出，請關注唐綺陽官方專屬星盤以獲取最新消息！\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "section__btn",
                on: {
                  click: function($event) {
                    return _vm.toPan(2)
                  }
                }
              },
              [_vm._v("了解更多")]
            )
          ]),
          _vm._m(6)
        ])
      ]),
      _c("section", { staticClass: "personal landpageAnit" }, [
        _c("img", {
          staticClass: "personal__title",
          attrs: { src: require("@/assets/composite-title2.svg"), alt: "" }
        }),
        _c("div", { staticClass: "personal__bg" }),
        _c("div", { staticClass: "personal__wrap custom-container-xl" }, [
          _vm._m(7),
          _c("div", { staticClass: "section__word personal__word" }, [
            _vm._m(8),
            _vm._m(9),
            _c("div", { staticClass: "section__content" }, [
              _vm._v(
                "\n          雙人合盤即將推出，請關注唐綺陽官方專屬星盤以獲取最新消息！\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "section__btn",
                on: {
                  click: function($event) {
                    return _vm.toPan(3)
                  }
                }
              },
              [_vm._v("了解更多")]
            )
          ])
        ])
      ]),
      _c("section", { staticClass: "subscribe custom-container" }, [
        _vm._m(10),
        _c("div", { staticClass: "section__word subscribe__word" }, [
          _c("div", { staticClass: "section__title" }, [_vm._v("Subscribe")]),
          _c("div", { staticClass: "section__subtitle" }, [
            _vm._v("加入我們的官方帳號")
          ]),
          _c("div", { staticClass: "section__content" }, [
            _vm._v(
              "\n        這是唐綺陽的Line官方帳號，你不一定因為是「粉絲」才加入，只要跟我一樣迷戀占星、又想感覺占星的神奇，都歡迎你來加入我的Line！\n      "
            )
          ]),
          _c(
            "div",
            { staticClass: "section__btn", on: { click: _vm.toLIne } },
            [
              _c("img", {
                attrs: { src: require("@/assets/icon_line.svg"), alt: "icon" }
              }),
              _vm._v("\n        立即加入\n        ")
            ]
          )
        ])
      ]),
      _vm._m(11),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.developing
          ? _c(
              "div",
              {
                staticClass: "popup__overlay",
                class: { scaleBox: _vm.developing },
                on: {
                  click: function($event) {
                    _vm.developing = false
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup__box",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.developing = true
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "popup__img",
                      attrs: {
                        src: require("@/assets/develop.svg"),
                        alt: "開發中"
                      }
                    }),
                    _c("div", { staticClass: "popup__title" }, [
                      _vm._v("Developing")
                    ]),
                    _c("div", { staticClass: "popup__subtitle" }, [
                      _vm._v(
                        "\n          此星球還在開發中，先去別的星球看看吧！\n        "
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "popup__btn",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.developing = false
                          }
                        }
                      },
                      [_vm._v("返回")]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.loginFirst
          ? _c(
              "div",
              {
                staticClass: "popup__overlay",
                class: { scaleBox: _vm.loginFirst },
                on: {
                  click: function($event) {
                    _vm.loginFirst = false
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup__box",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.loginFirst = true
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "popup__img",
                      attrs: {
                        src: require("@/assets/develop.svg"),
                        alt: "開發中"
                      }
                    }),
                    _c("div", { staticClass: "popup__title" }, [
                      _vm._v("Please Login")
                    ]),
                    _c("div", { staticClass: "popup__subtitle" }, [
                      _vm._v("請先登入!")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "popup__btn",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.loginFirst = false
                          }
                        }
                      },
                      [_vm._v("返回")]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.weChat
          ? _c(
              "div",
              {
                staticClass: "popup__overlay",
                on: {
                  click: function($event) {
                    _vm.weChat = false
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup__box",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.weChat = true
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://weixin.qq.com/r/eTj-5zDE_G2HrXSE921r",
                          target: "_blank"
                        }
                      },
                      [
                        _c("div", { staticClass: "popup__weChat_imgbox" }, [
                          _c("img", {
                            staticClass: "popup__img",
                            attrs: {
                              src: require("../assets/Index/QRcode(wechat) 1@2x.jpg"),
                              alt: "QRcode"
                            }
                          })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "popup__title" }, [
                      _vm._v("Wechat")
                    ]),
                    _c("div", { staticClass: "popup__subtitle" }, [
                      _vm._v(
                        "\n          掃描或點擊二維碼，立即關注唐綺陽看星星！\n        "
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "popup__btn",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.weChat = false
                          }
                        }
                      },
                      [_vm._v("返回")]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about_scroll" }, [
      _c("img", {
        attrs: { src: require("../assets/Index/JESSE TANG.svg"), alt: "跑馬燈" }
      }),
      _c("img", {
        attrs: { src: require("../assets/Index/JESSE TANG.svg"), alt: "跑馬燈" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "personal__visual" }, [
      _c("img", {
        staticClass: "personal__img",
        attrs: { src: require("@/assets/personal-bg2.svg"), alt: "個人星盤" }
      }),
      _c("img", {
        staticClass: "personal__inside",
        attrs: { src: require("@/assets/personal-inside.svg"), alt: "個人星盤" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__title" }, [
      _c("h2", [_vm._v("Personal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__subtitle" }, [
      _c("h2", [_vm._v("個人星盤")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__title" }, [
      _c("h2", [_vm._v("Transit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__subtitle" }, [
      _c("h2", [_vm._v("推運功能")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "composite__visual" }, [
      _c("img", {
        staticClass: "composite__img",
        attrs: { src: require("@/assets/transit-bg.svg"), alt: "推運功能" }
      }),
      _c("img", {
        staticClass: "landpageAnit-transit",
        attrs: { src: require("@/assets/transit-inside.svg"), alt: "推運功能" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "personal__visual" }, [
      _c("img", {
        staticClass: "personal__img",
        staticStyle: { transform: "rotateY(180deg)" },
        attrs: { src: require("@/assets/composite-bg2.svg"), alt: "雙人合盤" }
      }),
      _c("img", {
        staticClass: "landpageAnit-composit",
        attrs: {
          src: require("@/assets/composite-inside.svg"),
          alt: "雙人合盤"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__title" }, [
      _c("h2", [_vm._v("Synastry")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section__subtitle" }, [
      _c("h2", [_vm._v("雙人合盤")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subscribe__visual" }, [
      _c("img", {
        attrs: {
          src: require("../assets/Index/iphone_mockup.png"),
          alt: "訂閱我們"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "more" }, [
      _c("div", { staticClass: "more_header custom-container" }, [
        _c("div", { staticClass: "more__title" }, [_vm._v("More")]),
        _c("div", { staticClass: "more__subtitle" }, [_vm._v("更多資訊")])
      ]),
      _c("div", { staticClass: "media" }, [
        _c("div", { staticClass: "media__row custom-container" }, [
          _c(
            "a",
            {
              staticClass: "media__box",
              attrs: {
                href: "https://www.facebook.com/JesseTang11/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/media_fb.svg"), alt: "icon" }
              })
            ]
          ),
          _c(
            "a",
            {
              staticClass: "media__box",
              attrs: {
                href: "https://www.instagram.com/jessetang11/?hl=zh-tw",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/media_ig.svg"), alt: "icon" }
              })
            ]
          ),
          _c(
            "a",
            {
              staticClass: "media__box",
              attrs: {
                href:
                  "https://www.youtube.com/channel/UCK7LdglLCApOTaylxX8hW2Q",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/media_yt.svg"), alt: "icon" }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
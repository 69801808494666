var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.noticePopup,
            expression: "noticePopup"
          }
        ],
        staticClass: "noticePopupContainer"
      },
      [
        _c("div", { staticClass: "noticePopup" }, [
          _c("div", { staticClass: "noticePopup__title" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
          ]),
          _c("div", { staticClass: "noticePopup__content" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
          ]),
          _c("div", { staticClass: "noticePopup__btn" }, [
            _c(
              "div",
              {
                staticClass: "noticePopup__btn__cancel",
                on: { click: _vm.cancel }
              },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "noticePopup__btn__ok", on: { click: _vm.ok } },
              [_vm._v("\n          " + _vm._s(_vm.okText) + "\n        ")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
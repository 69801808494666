var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MoonDayCTAWrap" }, [
    _c(
      "div",
      { staticClass: "MoonDayCTA" },
      [
        _vm.mode == "timeLineMode"
          ? [
              _c("div", { staticClass: "MoonDayCTA__userInfo" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.userInfoOpen = !_vm.userInfoOpen
                      }
                    }
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$NameFormat(_vm.nowUserData.Name)) +
                          "\n            "
                      ),
                      _c("span", [_vm._v("  (內圈)")])
                    ])
                  ]
                ),
                _vm._m(0),
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.userInfoOpen = !_vm.userInfoOpen
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("推運 (外圈)")]),
                    _c("div", { staticClass: "ml_3 pt_1" }, [
                      _c("img", {
                        style: _vm.userInfoOpen
                          ? "transform:rotate(180deg)"
                          : "",
                        attrs: { src: require("@/assets/icon_downward.svg") }
                      })
                    ])
                  ]
                )
              ]),
              _vm.userInfoOpen
                ? _c("div", { staticClass: "MoonDayCTA__userDetail mb_10" }, [
                    _c("div", { staticClass: "MoonDayCTA__userDetail__item" }, [
                      _c(
                        "div",
                        { staticClass: "MoonDayCTA__userDetail__place mt_5" },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/map-pin.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.nowUserData.BirthCountry +
                                  "  " +
                                  _vm.nowUserData.BirthCity
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "MoonDayCTA__userDetail__birth mt_1" },
                        [
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.nowUserData.BirthDay +
                                    ", " +
                                    _vm.nowUserData.BirthTime
                                ) +
                                "\n              "
                            ),
                            !_vm.data2
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "MoonDayCTA__userDetail__birth--space mx_4"
                                  },
                                  [
                                    _vm._v(
                                      "\n                |\n              "
                                    )
                                  ]
                                )
                              : _c("br"),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.deFormat(_vm.nowUserData.Longitude) +
                                    ", \n               " +
                                    _vm.deFormat(_vm.nowUserData.Latitude)
                                ) +
                                "\n              "
                            ),
                            _vm.nowUserData.IsDaylight
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/日光節約icon.svg")
                                  }
                                })
                              : _vm._e()
                          ]
                        ],
                        2
                      )
                    ]),
                    _vm.data2
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "MoonDayCTA__userDetail__item MoonDayCTA__userDetail__item--right"
                          },
                          [
                            !_vm.nowTimeNowPlaceIng
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "MoonDayCTA__userDetail__place mt_5"
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/map-pin.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.nowUserData.BirthCountry +
                                            "  " +
                                            _vm.nowUserData.BirthCity
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.nowTimeNowPlaceIng
                              ? _c("div", { staticClass: "mt_5" }, [
                                  _vm._v("當前地點")
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "MoonDayCTA__userDetail__birth mt_1"
                              },
                              [
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm
                                          .moment(new Date(_vm.format_time))
                                          .format("YYYY/MM/DD,HH:mm")
                                      ) +
                                      "\n              "
                                  ),
                                  !_vm.data2
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "MoonDayCTA__userDetail__birth--space mx_4"
                                        },
                                        [
                                          _vm._v(
                                            "\n                |\n              "
                                          )
                                        ]
                                      )
                                    : _c("br"),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.nowPlace) +
                                      "\n            "
                                  )
                                ]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          : _vm._e(),
        _vm.mode == "astrometryMode"
          ? [
              _c("div", { staticClass: "MoonDayCTA__astrometry" }, [
                _c(
                  "div",
                  {
                    staticClass: "MoonDayCTA__astrometry__btn",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.userInfoOpen = !_vm.userInfoOpen
                      }
                    }
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$NameFormat(_vm.nowUserData.Name)) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "ml_3 pt_1" }, [
                      _c("img", {
                        style: _vm.userInfoOpen
                          ? "transform:rotate(180deg)"
                          : "",
                        attrs: { src: require("@/assets/icon_downward.svg") }
                      })
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "userDesktopInfo__btn",
                    class: { nowTimeNowPlaceIng: _vm.nowTimeNowPlaceIng },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("toNowTimeNowPlace")
                      }
                    }
                  },
                  [_vm._v("\n          此時此地\n        ")]
                )
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userInfoOpen,
                      expression: "userInfoOpen"
                    }
                  ],
                  staticClass: "MoonDayCTA__astrometryDetail"
                },
                [
                  _c(
                    "div",
                    { staticClass: "MoonDayCTA__astrometryDetail__place" },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/map-pin.svg"), alt: "" }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.nowUserData.BirthCountry +
                              "  " +
                              _vm.nowUserData.BirthCity
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "MoonDayCTA__astrometryDetail__birth" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.nowUserData.BirthDay +
                              ", " +
                              _vm.nowUserData.BirthTime
                          ) +
                          "\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.deFormat(_vm.nowUserData.Longitude) +
                              ", \n               " +
                              _vm.deFormat(_vm.nowUserData.Latitude)
                          ) +
                          "\n          "
                      ),
                      _vm.nowUserData.IsDaylight
                        ? _c("img", {
                            attrs: { src: require("@/assets/日光節約icon.svg") }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          : _vm._e(),
        _c("div", { staticClass: "MoonDayCTA__line" }),
        !_vm.EnabledMoonNote
          ? _c("div", { staticClass: "MoonDayCTA__main mt_6 pb_6" }, [
              _vm._v("\n      新功能"),
              _c("img", {
                attrs: { src: require("@/assets/moon.svg"), alt: "" }
              }),
              _c("span", [_vm._v("月亮日記")]),
              _c(
                "div",
                {
                  staticClass: "toUnlock_cta",
                  on: {
                    click: function($event) {
                      return _vm.toStore()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/locked.svg"), alt: "" }
                  }),
                  _vm._v("\n        點此解鎖\n      ")
                ]
              )
            ])
          : _vm.moondayObj.Constellation
          ? _c("div", { staticClass: "MoonDayCTA__main mt_6 pb_6" }, [
              _c("img", {
                attrs: { src: require("@/assets/moon.svg"), alt: "" }
              }),
              _c("span", [
                _vm._v("月亮" + _vm._s(_vm.moondayObj.Constellation) + "日")
              ]),
              _vm._v("\n      ，記錄當下的感受吧:)\n      "),
              _c(
                "div",
                {
                  staticClass: "MoonDayCTA__main__toNote",
                  on: {
                    click: function($event) {
                      return _vm.addMoonNoteHandler()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/icon-edit.svg"), alt: "" }
                  })
                ]
              )
            ])
          : _c("div", { staticClass: "MoonDayCTA__main mt_6 pb_6" }, [
              _vm._v("讀取中...")
            ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/分隔星星.svg"), alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("div", { class: ["notice", { active: _vm.notice }] }, [
        _vm._v("已複製到剪貼簿")
      ]),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "swiper",
            {
              staticClass: "popup__overlay",
              attrs: { id: "sw", options: _vm.swiper }
            },
            [
              _c("swiper-slide", { class: { scaleBox: false } }, [
                _c("div", { staticClass: "popup__box_error" }, [
                  _c("div", { staticClass: "inner_box" }, [
                    _c("img", {
                      staticClass: "ml-4",
                      attrs: { src: require("../assets/LoginError/copy.svg") }
                    }),
                    _c("img", {
                      staticClass: "close",
                      attrs: {
                        src: require("../assets/LoginError/icon_menu_close.svg")
                      },
                      on: { click: _vm.close }
                    }),
                    _c("div", { staticClass: "copy_link" }, [
                      _c("input", {
                        attrs: { type: "text", readonly: "", id: "txt_output" },
                        domProps: { value: _vm.link }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "copy_btn",
                          on: { click: _vm.copyLink }
                        },
                        [_vm._v("複製")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "popup__title" }, [
                    _vm._v("複製連結")
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      "\n            點選上方的「複製」，將唐綺陽星盤網址複製起來。\n          "
                    )
                  ]),
                  _c("div", { staticClass: "step" }, [
                    _c("div", { staticClass: "step_item active" }),
                    _c("div", { staticClass: "step_item" }),
                    _c("div", { staticClass: "step_item" })
                  ])
                ])
              ]),
              _c("swiper-slide", { class: { scaleBox: false } }, [
                _c("div", { staticClass: "popup__box_error" }, [
                  _c("div", { staticClass: "inner_box" }, [
                    _c("img", {
                      attrs: {
                        src: require("../assets/LoginError/openbrowser.png")
                      }
                    }),
                    _c("img", {
                      staticClass: "close",
                      attrs: {
                        src: require("../assets/LoginError/icon_menu_close.svg")
                      },
                      on: { click: _vm.close }
                    })
                  ]),
                  _c("div", { staticClass: "popup__title" }, [
                    _vm._v("打開瀏覽器")
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      "\n            開啟您的瀏覽器，例如「Safari」或「Chrome」。\n          "
                    )
                  ]),
                  _c("div", { staticClass: "step" }, [
                    _c("div", { staticClass: "step_item" }),
                    _c("div", { staticClass: "step_item active" }),
                    _c("div", { staticClass: "step_item" })
                  ])
                ])
              ]),
              _c("swiper-slide", { class: { scaleBox: false } }, [
                _c("div", { staticClass: "popup__box_error" }, [
                  _c("div", { staticClass: "inner_box" }, [
                    _c("img", {
                      attrs: {
                        src: require("../assets/LoginError/login.svg"),
                        alt: ""
                      }
                    }),
                    _c("img", {
                      staticClass: "close",
                      attrs: {
                        src: require("../assets/LoginError/icon_menu_close.svg")
                      },
                      on: { click: _vm.close }
                    })
                  ]),
                  _c("div", { staticClass: "popup__title" }, [
                    _vm._v("進行登入")
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      "\n            「貼上連結」並前往，就可以進行登入開始探索囉！\n          "
                    )
                  ]),
                  _c("div", { staticClass: "step" }, [
                    _c("div", { staticClass: "step_item" }),
                    _c("div", { staticClass: "step_item" }),
                    _c("div", { staticClass: "step_item active" })
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!-- 開發中 -->
  <transition name="fade">
    <Fixed_popup v-show="showAlreadyWrite">
      <div class="alreadyWrite__box">
        <img
          class="alreadyWrite__img"
          src="@/assets/月亮日記/這天寫過了.svg"
          alt
        />
        <div class="alreadyWrite__title">你今天已寫過<br />月亮日記囉</div>
        <div class="alreadyWrite__btn" @click="toNote()">返回調整時間</div>
      </div>
    </Fixed_popup>
  </transition>
</template>

<script>
import Fixed_popup from "@/components/Fixed_popup.vue";
export default {
  components: { Fixed_popup },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    showAlreadyWrite() {
      return this.$store.getters.showAlreadyWrite;
    },
  },
  methods: {
    toNote() {
      this.$store.commit("setShowAlreadyWrite", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.alreadyWrite__box {
  z-index: 1050;
  margin: auto;
  @include rect(520px, 511px, $theme-color-3, 50px);
  @include center;
  flex-direction: column;
  padding: 28px 0 40px;
  transition: 0.5s;
  @include mobile() {
    @include rect(326px, auto, $theme-color-3, 110px);
    margin: 0 24px;
  }
  .alreadyWrite__img {
    height: 220px;
    width: 220px;
    object-fit: cover;
    margin: 0 auto;
    @include mobile() {
      height: 200px;
      width: 200px;
    }
  }
  .alreadyWrite__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    margin-top: 24px;
    color: $secondary-color-1;
    @include mobile() {
      font-size: 20px;
      line-height: 24px;
      margin-top: 20px;
    }
  }
  .alreadyWrite__btn {
    @include btn(245px, 44px, $theme-color-1, $theme-color-3, 20px);
    margin-top: 40px;
    transition: 0.2s;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    @include mobile() {
      @include btn(234px, 40px, $theme-color-1, $theme-color-3, 14px);
      margin-top: 52px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    &:hover {
      opacity: 0.7;
    }
    &:active {
      background-color: $secondary-color-1;
    }
  }
  .alreadyWrite__textBtn {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: $theme-color-1;
    margin-top: 16px;
    cursor: pointer;
    @include mobile() {
      font-size: 14px;
      line-height: 14px;
      margin-top: 20px;
    }
  }
}
</style>

<template>
  <div class="wrap">
    <div :class="['notice', { active: notice }]">已複製到剪貼簿</div>
    <transition name="fade">
      <swiper id="sw" :options="swiper" class="popup__overlay">
        <swiper-slide :class="{ scaleBox: false }">
          <div class="popup__box_error">
            <div class="inner_box">
              <img src="../assets/LoginError/copy.svg" class="ml-4" />
              <img
                @click="close"
                src="../assets/LoginError/icon_menu_close.svg"
                class="close"
              />
              <div class="copy_link">
                <input type="text" :value="link" readonly id="txt_output" />
                <div class="copy_btn" @click="copyLink">複製</div>
              </div>
            </div>
            <div class="popup__title">複製連結</div>
            <div class="content">
              點選上方的「複製」，將唐綺陽星盤網址複製起來。
            </div>
            <div class="step">
              <div class="step_item active"></div>
              <div class="step_item"></div>
              <div class="step_item"></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide :class="{ scaleBox: false }">
          <div class="popup__box_error">
            <div class="inner_box">
              <img src="../assets/LoginError/openbrowser.png" />
              <img
                @click="close"
                src="../assets/LoginError/icon_menu_close.svg"
                class="close"
              />
            </div>
            <div class="popup__title">打開瀏覽器</div>
            <div class="content">
              開啟您的瀏覽器，例如「Safari」或「Chrome」。
            </div>
            <div class="step">
              <div class="step_item"></div>
              <div class="step_item active"></div>
              <div class="step_item"></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide :class="{ scaleBox: false }">
          <div class="popup__box_error">
            <div class="inner_box">
              <img src="../assets/LoginError/login.svg" alt="" />
              <img
                @click="close"
                src="../assets/LoginError/icon_menu_close.svg"
                class="close"
              />
            </div>
            <div class="popup__title">進行登入</div>
            <div class="content">
              「貼上連結」並前往，就可以進行登入開始探索囉！
            </div>
            <div class="step">
              <div class="step_item"></div>
              <div class="step_item"></div>
              <div class="step_item active"></div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiper: {
        slidesPerView: 1,
        spaceBetween: 0,
        initialSlide: 0,
        grabCursor: true,
        centerInsufficientSlides: true,
        speed: 100,
        effect: "fade",
        // virtualTranslate:true,
        fadeEffect: {
          crossFade: true,
        },
      },
      link: "https://astrolabe.astroinfo.com.tw/",
      notice: false,
    };
  },
  methods: {
    copyLink() {
      var copyText = document.getElementById("txt_output");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      if (!this.notice) {
        this.notice = true;
        setTimeout(() => {
          this.notice = false;
        }, 2000);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_mixin.scss";
.wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1002;
  @include center;
}
#sw {
  width: 100%;
  box-sizing: border-box;
}

.popup__overlay {
  @include overlay;
  background-color: rgba(240, 242, 244, 1);
  &.scaleBox .popup__box {
    animation: scaleBox 0.25s ease-in-out forwards;
  }
  @keyframes scaleBox {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
}
.swiper-slide {
  @include center;
}
.popup__box_error {
  z-index: 1052;
  // margin:83px auto;
  @include rect(326px, 440px, $theme-color-3, 110px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 39px;
  transition: 0.5s;
  box-sizing: border-box;
}

.inner_box {
  width: 240px;
  height: 200px;
  // background-color: #f0a;
  margin-bottom: 45px;
  position: relative;
  .close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -7px;
    top: -7px;
    z-index: 102;
  }
  .ml-4 {
    margin-left: 13px;
  }
  .copy_link {
    display: flex;
    position: absolute;
    bottom: 0;
    left: -20px;
    input {
      width: 200px;
      height: 40px;
      border-radius: 20px;
      box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
      background-color: #f0f2f4;
      font-size: 14px;
      line-height: 1;
      text-align: left;
      color: #868686;
      padding: 14px;
      text-overflow: ellipsis;
      display: -webkit-box;
      margin-right: 8px;
      &:active,
      &:focus {
        outline: none;
        border: none;
      }
    }
    .copy_btn {
      width: 64px;
      height: 40px;
      border-radius: 20px;
      background-color: #798da5;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.56px;
      text-align: center;
      color: #f0f2f4;
      font-weight: 400;
      @include center;
    }
  }
}
.popup__title {
  font-family: "Noto Sans TC", cursive;
  color: $secondary-color-1;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 1.6px;
  text-align: center;
  font-weight: 700;
}
.content {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.56px;
  text-align: center;
  color: #868686;
  margin-top: 16px;
}

.step {
  display: flex;
  margin-top: 21px;
  .step_item {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    box-shadow: -1px -1px 4px 0 #ffffff, -1px -1px 4px 0 #ffffff,
      2px 2px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: #f0f2f4;
    margin: 4px;
    &.active {
      width: 32px;
      box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
    }
  }
}
.notice {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc((100vh - 440px) / 6);
  z-index: 1001;
  width: 192px;
  height: 40px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border: solid 0.8px #d9d9d9;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.56px;
  @include center;
  color: #292929a6;
  opacity: 0;
  transition: 0.5s;
  &.active {
    opacity: 1;
  }
}
</style>

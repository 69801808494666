import Base from "./_base";
import MemberAPI from "./module/Member";
import CalculateAPI from "./module/Calculate";
import GatewayAPI from "./module/Geteway";
import ProductAPI from "./module/Product";
import Other from "./module/Other";

let { Encrypt, Decrypt, ErrorHandle } = Base;

export default {
  ...MemberAPI,
  ...Other,
  ...CalculateAPI,
  ...GatewayAPI,
  ...ProductAPI,
  Encrypt,
  Decrypt,
  ErrorHandle,
};

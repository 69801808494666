<template>
  <div class="params">
    <div class="params__tab">
      <div
        class="params__tab_item"
        :class="{ active: activeParamsTab === type }"
        v-for="type in ['星位', '宮位', '相位', '統計']"
        :key="type"
        @click="switchTab(type)"
      >
        {{ type }}
      </div>
    </div>
    <div class="params__content">
      <!--1.星位-->
      <table class="table planet-table" v-if="activeParamsTab == '星位'">
        <tr class="table__title">
          <td><span>星體</span></td>
          <td><span>落入星座</span></td>
          <td><span>度數</span></td>
          <td colspan="2"><span>落入宮位</span></td>
        </tr>
        <tr
          v-for="(item, index) in constellationNodes"
          class="table__content"
          :key="index"
        >
          <td>
            <!-- <img :src="item.img" alt /> -->
            <span
              class="item__name__img iconfont"
              :style="{
                color: item.Astral.indexOf('王') > -1 ? '#e99a00' : '#27aba3',
              }"
              >{{ item.img }}</span
            >
            {{ item.Astral }}
          </td>
          <td>{{ item.Constellation }}座</td>
          <td>
            {{ item.Angle }}
            <span class="retrograde">{{ item.Retrograde }}</span>
          </td>
          <td>
            {{ item.House }}
          </td>
        </tr>
      </table>
      <!--2.宮位-->
      <table class="table palace-table" v-if="activeParamsTab == '宮位'">
        <tr class="table__title">
          <td><span>宮位</span></td>
          <td><span>星座</span></td>
          <td><span>宮位守護星</span></td>
          <td colspan="2"><span>飛入</span></td>
        </tr>
        <tr
          v-for="(item, index) in palaces"
          class="table__content"
          :key="index"
        >
          <td style="font-size: 12px">{{ item.House }}</td>
          <td>{{ item.Constellation }}</td>
          <td>{{ item.MainAstral }}</td>
          <td>{{ item.FlyInto1 }}</td>
          <td>{{ item.FlyInto2 }}</td>
        </tr>
      </table>
      <!--3.相位-->
      <table class="phase-table" v-if="activeParamsTab == '相位'">
        <!--標題-->
        <tr class="table__title">
          <td></td>
          <td v-for="(Rowitem, index) in phases1" :key="index">
            <div>{{ phases[index] }}</div>
          </td>
        </tr>
        <tr
          v-for="(RowItem, index) in phases1"
          class="table__content"
          :key="index"
        >
          <td>
            <div>{{ phases[index] }}</div>
          </td>
          <td
            v-for="(ColItem, index1) in RowItem"
            :class="phases2[index][index1]"
            :key="index1"
            class=""
          >
            <div
              v-if="phaseIcon[ColItem]"
              :tabindex="`${index}_${index1}`"
              @click="showId = `${index}_${index1}`"
              @blur="showId = ''"
              style="cursor: pointer"
            >
              <img :src="phaseIcon[ColItem].icon" alt="" />
              <div
                class="phaseMean"
                :id="`${index}_${index1}`"
                :class="{ show: showId == `${index}_${index1}` }"
              >
                {{ phaseIcon[ColItem].deg }}度
              </div>
            </div>
          </td>
        </tr>
      </table>
      <!--3-1.相位中的科普-->
      <table
        class="table palace-table introduction mt_20"
        style="border-radius: 0px"
        v-if="activeParamsTab == '相位'"
      >
        <tr class="table__title">
          <td><span>線條顏色</span></td>
          <td><span>符號</span></td>
          <td><span>角度</span></td>
          <td><span>相位</span></td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #ffd306"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/合相.svg" alt="" />
          </td>
          <td>0°</td>
          <td>合相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #f00"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/對分相.svg" alt="" />
          </td>
          <td>180°</td>
          <td>對分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #00f"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/三分相.svg" alt="" />
          </td>
          <td>120°</td>
          <td>三分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #f00"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/四分相.svg" alt="" />
          </td>
          <td>90°</td>
          <td>四分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #00f"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/六分相.svg" alt="" />
          </td>
          <td>60°</td>
          <td>六分相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #5def60"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/八分之三相.svg" alt="" />
          </td>
          <td>135°</td>
          <td>八分之三相</td>
        </tr>
        <tr class="table__content">
          <td>
            <div
              class="introduction__line"
              style="background-color: #625b57"
            ></div>
          </td>
          <td>
            <img src="../../assets/相位icon/十二分之五相.svg" alt="" />
          </td>
          <td>150°</td>
          <td>十二分之五相</td>
        </tr>
        <!-- <div class="introduction">
          <div class="introduction__title">相位線條介紹</div>
          <div class="introduction__content">
           <p style="color:#007500">——— 120°</p>
           <p style="color:#0066cc">——— 60°</p>
           <p style="color:#EA0000">——— 90°</p>
           <p style="color:#0000c6">——— 180°</p>
          </div>
        </div> -->
      </table>
      <!--4.統計-->
      <div v-if="activeParamsTab == '統計'">
        <!--格局-->
        <table class="table pattern-table">
          <tr class="table__title">
            <td><span>格局</span></td>
            <td><span>詳情</span></td>
          </tr>
          <!--(測試)-->
          <tr class="table__content" v-for="item in P" :key="item.Title">
            <td style="width: 25%">{{ item.Title }}</td>
            <td>
              <span
                v-for="(item1, index) in item.Content.split('/')"
                :key="index"
              >
                {{ item1 }}
                <br />
              </span>
            </td>
          </tr>
        </table>
        <!--共有三個: 陰陽姓、三大模式、四大元素-->
        <table class="table stats-table">
          <tr class="table__title">
            <td style="width: 25%"><span>陰陽性</span></td>
            <td><span>包含星體</span></td>
          </tr>
          <!--(測試)-->
          <tr class="table__content" v-for="item in Y" :key="item.Title">
            <td>{{ item.Title }}</td>
            <td>
              <span
                v-for="(item1, index) in item.Content.split('/')"
                :key="index"
                >{{ item1 }}</span
              >
            </td>
          </tr>
        </table>
        <table class="table stats-table">
          <tr class="table__title">
            <td style="width: 25%"><span>三大模式</span></td>
            <td><span>包含星體</span></td>
          </tr>
          <!--(測試)-->
          <tr class="table__content" v-for="item in T" :key="item.Title">
            <td>{{ item.Title }}</td>
            <td>
              <span
                v-for="(item1, index) in item.Content.split('/')"
                :key="index"
                >{{ item1 }}</span
              >
            </td>
          </tr>
        </table>
        <table class="table stats-table">
          <tr class="table__title">
            <td style="width: 25%"><span>四大元素</span></td>
            <td><span>包含星體</span></td>
          </tr>
          <!--(測試)-->
          <tr class="table__content" v-for="item in Q" :key="item.Title">
            <td>{{ item.Title }}</td>
            <td>
              <span
                v-for="(item1, index) in item.Content.split('/')"
                :key="index"
                >{{ item1 }}</span
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import phaseMixin from "@/mixin/phaseMixin";
export default {
  props: ["TcAstralInfo", "AstrolabeType", "role", "moondayObj"],
  mixins: [phaseMixin],
  data() {
    return {
      showId: "",
      activeSelect: false,
      NowInterpret: [{ name: "" }],
      openHouse: false,
      activeParamsTab: "星位",
      planets: require("@/js/planets"),
      planets2: require("@/js/planets2"),
      palaces: null,
      AspectLists: [], //行星相位
      P: [],
      Q: [],
      T: [],
      Y: [],
      constellationNodes: [],
      ConstellationLists: [], //星位
      phases: [
        "日",
        "月",
        "水",
        "金",
        "火",
        "木",
        "土",
        "天",
        "海",
        "冥",
        "升",
        "頂",
      ],
      phases1: [],
      phases2: [],
    };
  },
  mounted() {
    this.getParams();
  },
  computed: {
    DetailNeed() {
      return this.$store.state[this.role].mainPlanetList.concat(
        this.$store.state[this.role].planetList
      );
    },
  },
  methods: {
    switchTab(type) {
      this.activeParamsTab = type || "星位";
    },
    //取得參數(需呼叫因為要刷新詳細參數內容、表格)
    getParams() {
      let DetailNeed = this.$store.state[this.role].mainPlanetList.concat(
        this.$store.state[this.role].planetList
      );
      let detailNeed_fixed = [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];

      this.$API
        .Get_AstrolabeParams({
          SetupConfigs: detailNeed_fixed,
          AstralInfo: this.TcAstralInfo,
          AstrolabeType: this.AstrolabeType,
        })
        .then((data) => {
          data = data.Data;
          this.$emit("update:moondayObj", data.ConstellationNodes[1]);
          //星位
          this.constellationNodes = data.ConstellationNodes;
          this.constellationNodes.forEach((item) => {
            let planet = this.planets.find(
              (planet) => planet.name == item.Astral
            );
            let planets2 = this.planets2.find(
              (planet) => planet.name == item.Astral
            );
            if (planet) {
              item.img = planet.img;
              item.imageUrl = planet.imageUrl;
            }
            if (planets2) {
              item.img = planets2.img;
              item.imageUrl = planets2.imageUrl;
            }
          });

          for (let i = 0; i < this.planets.length; i++) {
            if (
              this.planets[i].star === "" ||
              this.planets[i].star === null ||
              this.planets[i].star === undefined
            ) {
              this.planets[i].show = false;
            } else {
              this.planets[i].star = this.planets[i].star.slice(0, 2) + "座";
              this.planets[i].show = true;
            }
          }

          this.palaces = data.HouseNodes;

          // //相位
          this.phases1 = [];
          for (let i = 0; i < data.PhaseNodes.length; i++) {
            this.phases1.push(data.PhaseNodes[i].Phases);
          }
          //參考索引值
          let CssArr = [
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", ""],
          ];
          for (let i = 0; i < this.phases1.length; i++) {
            for (let j = 0; j < this.phases1[i].length; j++) {
              if (this.phases1[i][j] === "合") {
                CssArr[i][j] = "phase-neutral";
              } else if (
                this.phases1[i][j] === "沖" ||
                this.phases1[i][j] === "刑" ||
                this.phases1[i][j] === "梅"
              ) {
                CssArr[i][j] = "phase-ominous";
              } else if (
                this.phases1[i][j] === "拱" ||
                this.phases1[i][j] === "六"
              ) {
                CssArr[i][j] = "phase-lucky";
              } else {
                CssArr[i][j] = " ";
              }
            }
          }
          this.phases2 = CssArr;

          // //統計
          this.P = data.StatisticsNode.P_Notes;
          this.Q = data.StatisticsNode.Q_Nodes;
          this.T = data.StatisticsNode.T_Nodes;
          this.Y = data.StatisticsNode.Y_Nodes;
        });
    },
    getPlantImg(name) {
      let target = this.planets.find((item) => item.name === name);
      return target.img;
    },
  },
  watch: {
    TcAstralInfo() {
      this.getParams();
    },
    DetailNeed: {
      handler() {
        this.getParams();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";
@import "@/scss/Astrolabe.scss";
.introduction {
  &__line {
    margin: 0 auto;
    width: 90%;
    height: 4px;
  }
}
</style>

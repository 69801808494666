var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("canvas", { ref: "astrolabe", staticClass: "canvasShadow" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.noticePopup,
          expression: "noticePopup"
        }
      ],
      staticClass: "noticePopupContainer"
    },
    [
      _vm.IsUnlockBC
        ? _c("div", { staticClass: "noticePopup" }, [
            _vm._m(0),
            _c("div", { staticClass: "noticePopup__content" }, [
              _c("div", { staticClass: "noticePopup__content__icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "16",
                      height: "16",
                      viewBox: "0 0 16 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z",
                        fill: "#E2562C"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M6.80013 3.2L6.30493 3.7704L7.10493 9.3704L7.60093 9.8H8.40093L8.89533 9.3704L9.69533 3.7704L9.20013 3.2H6.80013ZM7.40013 11.2L6.90013 11.7V12.1L7.40013 12.6H8.60013L9.10013 12.1V11.7L8.60013 11.2H7.40013Z",
                        fill: "white"
                      }
                    })
                  ]
                )
              ]),
              _c("div", [
                _vm._v(
                  "\n        原本的宮位解讀將因修改資料後不再適用，需重新購買宮位解讀來進行星盤分析。\n      "
                )
              ])
            ]),
            _c("div", { staticClass: "noticePopup__checkbox" }, [
              _c("label", { attrs: { for: "isOk" } }, [
                _c(
                  "div",
                  { staticClass: "checkbox", class: { active: _vm.isOk } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isOk,
                          expression: "isOk"
                        }
                      ],
                      attrs: { type: "checkbox", id: "isOk" },
                      domProps: {
                        checked: Array.isArray(_vm.isOk)
                          ? _vm._i(_vm.isOk, null) > -1
                          : _vm.isOk
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.isOk,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.isOk = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isOk = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isOk = $$c
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "checkmark" })
                  ]
                ),
                _c("div", [
                  _vm._v("我已了解修改資料後，需重新購買宮位解讀的說明")
                ])
              ])
            ]),
            _c("div", { staticClass: "noticePopup__btn" }, [
              _c(
                "div",
                {
                  staticClass: "noticePopup__btn__cancel",
                  on: { click: _vm.cancel }
                },
                [_vm._v("取消修改")]
              ),
              _c(
                "div",
                {
                  staticClass: "noticePopup__btn__ok",
                  class: { disabled: !_vm.isOk },
                  on: { click: _vm.save }
                },
                [_vm._v("\n        確認儲存\n      ")]
              )
            ])
          ])
        : _vm._e(),
      !_vm.IsUnlockBC
        ? _c("div", { staticClass: "noticePopup" }, [
            _c("div", { staticClass: "noticePopup__title" }, [
              _vm._v("修改帳戶資料")
            ]),
            _c("div", { staticClass: "noticePopup__subtitle mt_8" }, [
              _vm._v("確定要修改帳戶資料嗎？")
            ]),
            _c("div", { staticClass: "noticePopup__btn" }, [
              _c(
                "div",
                {
                  staticClass: "noticePopup__btn__cancel",
                  on: { click: _vm.cancel }
                },
                [_vm._v("取消修改")]
              ),
              _c(
                "div",
                {
                  staticClass: "noticePopup__btn__ok",
                  on: { click: _vm.save }
                },
                [_vm._v("確認儲存")]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "noticePopup__title" }, [
      _vm._v("\n      資料修改後"),
      _c("br"),
      _vm._v("\n      需重新購買宮位解讀\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="content" v-if="nowUserData">
    <!-- //使用者資訊電腦版 -->
    <div class="contentUser hidden-pad-down">
      <div class="user">
        <div class="user__photo">
          <v-avatar size="58" class="user__photo__img">
            <img
              :src="nowUserData.ProfilePhotoSrc"
              v-if="!nowTimeNowPlaceIng"
            />
            <img src="@/assets/推運星盤/nowTimeNowPlace.png" v-else />
          </v-avatar>
        </div>
        <div class="user__info">
          <div class="user__info__name">
            {{
              nowTimeNowPlaceIng ? "此時此地" : $NameFormat(nowUserData.Name)
            }}
          </div>
          <div class="user__info__birth">
            <!-- 本命 -->
            <template v-if="!showNowTime">
              {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }}
              <span class="user__info__birth--space mx_4"> | </span>
              {{
                `${deFormat(nowUserData.Longitude)}, 
                 ${deFormat(nowUserData.Latitude)}`
              }}
              <img
                src="@/assets/日光節約icon.svg"
                v-if="nowUserData.IsDaylight"
              />
            </template>
            <!-- 天象此時此刻 -->
            <template v-else-if="nowTimeNowPlaceIng">
              {{ moment(new Date(format_time)).format("YYYY/MM/DD, HH:mm") }}
              <span class="user__info__birth--space mx_4"> | </span>
              {{ nowPlace }}
            </template>
            <!-- 天象預設 -->
            <template v-else>
              <img src="@/assets/newAstrolabe/time.svg" alt="" />
              當前時間：{{
                moment(new Date(format_time)).format("YYYY/MM/DD, HH:mm")
              }}
            </template>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="user__change">
          <img @click="toFriend" src="@/assets/icon_change.svg" alt />
        </div>
      </div>
      <div class="contentUser__line mt_7"></div>
      <!-- 月亮日記的進入點就是這邊 v-if=!EnabledMoonNote -->
      <div class="contentUser__moonday mt_11" v-if="!EnabledMoonNote">
        新功能<img src="@/assets/moon.svg" alt="" />
        <span>月亮日記</span>
        <div class="toUnlock_cta" @click="toStore()">
          <img src="@/assets/locked.svg" alt="" />
          點此解鎖
        </div>
      </div>
      <div
        class="contentUser__moonday mt_11"
        v-else-if="moondayObj.Constellation"
      >
        <img src="@/assets/moon.svg" alt="" />
        <span>月亮{{ moondayObj.Constellation }}日</span>
        ，記錄當下的感受吧:)
        <div class="contentUser__moonday__toNote" @click="addMoonNoteHandler()">
          <img src="@/assets/icon-edit.svg" alt="" />
        </div>
      </div>
      <div class="contentUser__moonday mt_11" v-else>讀取中...</div>
    </div>

    <div class="astrolabe">
      <!-- //使用者資訊（電腦） -->
      <div class="userDesktopInfo mb_16 hidden-pad-down">
        <div
          class="userDesktopInfo__notice"
          v-if="notice__useSunMode && mode == 'timeLineMode'"
        >
          <div class="userDesktopInfo__notice__content">
            <img src="@/assets/提示燈泡.svg" alt="" />
            <span>唐老師的直播內容都是以「太陽運勢」來說明的喔～</span>
          </div>
          <div
            class="userDesktopInfo__notice__action"
            @click="$emit('closeNotice__useSunMode')"
          >
            不再提醒
          </div>
        </div>
        <div class="userDesktopInfo__btnGroup mt_8">
          <!-- 左邊按鈕 -->
          <div
            class="userDesktopInfo__btn"
            @click.stop="userDesktopInfoOpen = !userDesktopInfoOpen"
          >
            <div>
              {{ $NameFormat(nowUserData.Name) }}
              <span v-if="data2"> &ensp;(內圈)</span>
            </div>
            <div class="ml_3 pt_1">
              <img
                src="@/assets/icon_downward.svg"
                :style="userDesktopInfoOpen ? 'transform:rotate(180deg)' : ''"
              />
            </div>
          </div>
          <!-- 右邊按鈕 -->
          <div
            v-if="mode == 'timeLineMode'"
            class="userDesktopInfo__btn"
            @click.stop="userDesktopInfoOpen = !userDesktopInfoOpen"
          >
            <div>推運 (外圈)</div>
            <div class="ml_3 pt_1">
              <img
                src="@/assets/icon_downward.svg"
                :style="userDesktopInfoOpen ? 'transform:rotate(180deg)' : ''"
              />
            </div>
          </div>
          <!-- //此時此地按鈕 -->
          <div
            v-if="nowTimeNowPlaceBtn"
            class="userDesktopInfo__btn"
            :class="{ nowTimeNowPlaceIng: nowTimeNowPlaceIng }"
            @click.stop="$emit('toNowTimeNowPlace')"
          >
            此時此地
          </div>
        </div>

        <div class="userDesktopInfo__content pt_4" v-show="userDesktopInfoOpen">
          <!-- 左邊(本命) -->
          <div class="userDesktopInfo__item">
            <div class="userDesktopInfo__place mt_5">
              <img src="@/assets/map-pin.svg" alt="" />
              {{ `${nowUserData.BirthCountry}  ${nowUserData.BirthCity}` }}
            </div>
            <div class="userDesktopInfo__birth mt_1">
              <template>
                {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }}
                <span class="userDesktopInfo__birth--space mx_4" v-if="!data2">
                  |
                </span>
                <br v-else />
                {{
                  `${deFormat(nowUserData.Longitude)}, 
                 ${deFormat(nowUserData.Latitude)}`
                }}
                <img
                  src="@/assets/日光節約icon.svg"
                  v-if="nowUserData.IsDaylight"
                />
              </template>
            </div>
          </div>
          <!-- 右邊(推運or另一個人) -->
          <div
            class="userDesktopInfo__item userDesktopInfo__item--right"
            v-if="data2"
          >
            <div
              class="userDesktopInfo__place mt_5"
              v-if="mode == 'timeLineMode' && !nowTimeNowPlaceIng"
            >
              <img src="@/assets/map-pin.svg" alt="" />
              {{ `${nowUserData.BirthCountry}  ${nowUserData.BirthCity}` }}
            </div>
            <div v-if="nowTimeNowPlaceIng" class="mt_5">當前地點</div>
            <div class="userDesktopInfo__birth mt_1">
              <template>
                <!-- {{ `${nowUserData.BirthDay}, ${nowUserData.BirthTime}` }} -->
                {{ moment(new Date(format_time)).format("YYYY/MM/DD,HH:mm") }}
                <span class="userDesktopInfo__birth--space mx_4" v-if="!data2">
                  |
                </span>
                <br v-else />
                {{ nowPlace }}
                <!-- //未來合盤需支援日光節約icon -->
                <!-- <img
                  src="@/assets/日光節約icon.svg"
                  v-if="nowUserData.IsDaylight"
                /> -->
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="astrolabe__wrap"
        :class="{ timeLineModeWrap: mode == 'timeLineMode' }"
      >
        <!-- swiper -->
        <div class="swiper">
          <swiper id="SW" :options="swiperAstrolabe">
            <swiper-slide
              class="astrolabe__item"
              v-for="(item, index) in styleArray"
              :key="item.id"
            >
              <!-- 提示框 -->
              <div class="toast toast-s" v-if="canshow[index]">
                {{ item.displayName }}版
              </div>
              <div class="toast toast-s" v-if="canshowMode == '普拉希德'">
                普拉希德宮位
              </div>
              <div class="toast toast-s" v-if="canshowMode == '太陽運勢'">
                太陽運勢宮位
              </div>
              <div class="astrolabe__bg" id="astrolabe__bg">
                <!-- 父組件參數：data1、data2、type，只要參數有更新就重畫 -->
                <canvasAstrolabe
                  v-if="dataChange"
                  class="canvas-pan"
                  :width="paramPan.width - 2"
                  :configItem="panConfigItem"
                  :panStyle="item.name"
                  :type="parseInt(type)"
                  :data1="data1"
                  :data2="data2"
                  :Ret1="Ret1"
                  :role="role"
                ></canvasAstrolabe>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="user__info__birth-m hidden-pad-up"
            v-show="mode == 'astrometryMode' && !nowTimeNowPlaceIng"
          >
            <img src="@/assets/newAstrolabe/time.svg" alt="" />
            當前時間：{{
              moment(new Date(format_time)).format("YYYY/MM/DD,HH:mm")
            }}
          </div>
          <div
            class="user__info__birth-m hidden-pad-up"
            v-show="mode == 'astrometryMode' && nowTimeNowPlaceIng"
          >
            {{ moment(new Date(format_time)).format("YYYY/MM/DD, HH:mm") }}
            <span class="user__info__birth--space mx_4"> | </span>
            {{ nowPlace }}
          </div>
          <div class="switch" v-if="styleArray.length > 1">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>
        </div>
        <!-- swiper end -->
        <!-- 參數設定按鈕(一般) -->
        <div
          class="astrolabe__setting"
          @click.stop="openSetup()"
          v-if="mode != 'timeLineMode'"
        >
          <img src="@/assets/icon_setting.svg" alt />
        </div>
        <!-- 參數設定按鈕(時間軸) -->
        <div class="TC__setting" v-if="mode == 'timeLineMode'">
          <div @click="changeSystem()">
            <img src="@/assets/推運星盤/changeMode.svg" class="mr-1" />
            <span v-show="System == 0">普拉<br />西德</span>
            <span v-show="System == 2">太陽<br />運勢</span>
          </div>
          <div @click.stop="openSetup()">
            <img src="@/assets/icon_setting.svg" alt />
          </div>
        </div>
        <!-- 手機版切換好友 -->
        <div class="user__change-m hidden-pad-up" @click.stop="toFriend()">
          <img src="@/assets/icon_change.svg" alt />
        </div>
      </div>
      <!-- 星盤設置  -->
      <SetAstrolabe
        :activeSetup="activeSetup"
        @close="activeSetup = false"
        @dataChange="dataChangeHandler($event)"
        :role="role"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import SetAstrolabe from "@/components/SetAstrolabe.vue";
import canvasAstrolabe from "@/components/canvas-astrolabe.vue";
import phaseMixin from "@/mixin/phaseMixin";
import moment from "moment";
export default {
  mixins: [phaseMixin],
  props: [
    "data1",
    "data2",
    "type",
    "mode",
    "styleArray",
    "Ret1",
    "role",
    "showNowTime",
    "format_time",
    "nowPlace",
    "nowTimeNowPlaceBtn",
    "nowTimeNowPlaceIng",
    "hiddenuserDesktopInfo",
    "moondayObj",
    "notice__useSunMode",
  ],
  components: {
    canvasAstrolabe,
    SetAstrolabe,
  },
  data() {
    return {
      userDesktopInfoOpen: false,
      System: 2,
      //強迫更新用
      dataChange: true,
      //星盤設置
      activeSetup: false,
      //顯示版本(ex: 專業版、文字版)
      canshow: [false, false, false],
      canshowMode: "",
      //星盤Swiper
      swiperAstrolabe: {
        initialSlide: 1,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "custom-bullet",
          bulletActiveClass: "custom-bullet-active",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swiper = document.getElementById("SW").swiper;
            let i = swiper.activeIndex;
            this.changeShow(i);
          },
        },
      },
      //星盤所需資料
      paramPan: {
        width: 520,
        maxWidth: 600,
        panStyle: "A32",
      },
    };
  },
  computed: {
    panConfigItem() {
      //七個主要星
      let main = this.$store.state[this.role].mainPlanetList.slice(0, 7);
      //其他星
      let planet = this.$store.state[this.role].planetList;
      return [
        ...main.map((item) => (item.toggleSelect ? 1 : 0)), //星盤設置設定檔
        ...planet.map((item) => (item.toggleSelect ? 1 : 0)),
      ];
    },
    nowUserData() {
      return this.$store.state.nowUserData; //星盤設置設定檔
    },
    EnabledMoonNote() {
      return this.$store.state.userData.EnabledMoonNote;
    },
  },
  created() {
    window.addEventListener("resize", this.resize);

    if (window.innerWidth < 1263) {
      this.paramPan.width = 760 - 4;
    }
    if (window.innerWidth < 860) {
      this.paramPan.width = window.innerWidth - 4;
    }
    if (window.innerWidth < 768) {
      this.paramPan.width = window.innerWidth - 12;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    moment,
    dataChangeHandler($event) {
      this.dataChange = $event;
    },
    openSetup() {
      this.activeSetup = !this.activeSetup;
    },
    toFriend() {
      this.$router.push(`/friendList?mode=${this.mode}`);
    },
    resize() {
      let width = document.getElementById("astrolabe__bg").clientWidth;
      this.paramPan.width = width;
    },
    changeShow(index) {
      this.canshow = [false, false, false];
      this.canshow[index] = true;
      setTimeout(() => {
        this.canshow = [false, false, false];
      }, 800);
    },
    changeSystem() {
      this.System = this.System == 2 ? 0 : 2;
      this.$emit("changeSystem", this.System == 2 ? 2 : 0);
      this.canshowMode = this.System == 2 ? "太陽運勢" : "普拉希德";
      if (this.System == 2) {
        this.$store.state.TimeLine.planetList[3].toggleSelect = false;
        this.$store.state.TimeLine.planetList[4].toggleSelect = false;
      } else {
        this.$store.state.TimeLine.planetList[3].toggleSelect = true;
        this.$store.state.TimeLine.planetList[4].toggleSelect = true;
      }
      setTimeout(() => {
        this.canshowMode = "";
      }, 1200);
    },
    async addMoonNoteHandler() {
      let astro = this.$Astro.find(
        (item) => item.cn === this.moondayObj.Constellation
      );
      let res = await this.$API.GET_MoonDayNoteCheck({
        Date: moment(new Date(this.format_time)).format("YYYY-MM-DD"),
        Constellation: astro.en,
      });
      if (res.data.Data) {
        this.$store.commit("setShowAlreadyWrite", true);
      } else {
        this.$store.commit("setMoondayEdit", {
          MoonNoteID: "",
          Constellation_CN: this.moondayObj.Constellation,
          Constellation: astro.en,
          Content: "",
          MoodContent: "",
          Date: moment(new Date(this.format_time)).format("YYYY-MM-DD"),
          Time: moment(new Date(this.format_time)).format("HH:mm"),
        });
        this.$router.push("/moonday-create");
      }
    },
    toStore() {
      let productId = this.$store.getters.productListMap["MoonNote"];
      this.$router.push(`/store/detail/${productId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Astrolabe.scss";
@import "@/scss/toast.scss";
.timeLineModeWrap {
  padding-top: 12px;
  @include pad {
    padding-top: 24px;
  }
}
.TC__setting {
  position: absolute;
  left: 14px;
  top: 0;
  z-index: 10;
  height: 40px;
  width: 116px;
  background: #f0f2f4;
  box-shadow: 0px 6.6px 16.5px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  display: flex;
  color: #868686;
  font-size: 13px;
  padding: 5px 0;
  & > div:first-of-type {
    width: 60%;
    border-right: 1px solid #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include pad {
      width: 65%;
    }
  }
  & > div:last-of-type {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @include pad {
    height: 36px;
    width: 97px;
    font-size: 12px;
    line-height: 1.2;
    img {
      width: 18px;
    }
  }
}
.toUnlock_cta {
  @include btn(116px, 35px, $theme-color-2, #fff);
  font-weight: 500;
  margin-left: 20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  @include mobile {
    width: 94px;
    height: 30px;
    margin-left: 12px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>

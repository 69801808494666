<template>
  <div>
    <div class="banner">
      <div class="pic">
        <img src="../../assets/占星小鋪/shopping.svg" alt="" />
      </div>
      <div class="banner_title">The Astrology Store</div>
      <div class="banner_subtitle">星星雜貨店</div>
      <div class="banner_content">
        解鎖你的星盤，解掉你的憂愁 <br />
        <br />
        人生有煩惱？買酒不如買解鎖
      </div>
    </div>
    <div class="shopping_group" ref="shopping_group">
      <div
        class="shopping_item"
        v-for="(item, index) in productList"
        :key="item.ProductID"
        @click="activeId = index + 1"
      >
        <div class="shopping_title">
          {{ item.Name }}
        </div>
        <div class="shopping_subtilte">
          <img src="../../assets/占星小鋪/subtitleIcon.svg" alt="" />
          適用{{ item.Appliance }}
        </div>
        <div class="shopping_pic">
          <img :src="item.ImageSrc" alt="" />
        </div>
        <div class="shopping_priceBar">
          <div class="shopping_priceBar_price">
            <span>NT.</span>{{ item.Amount }}
          </div>
          <div
            class="shopping_priceBar_btn"
            :class="{ notReady: false }"
            @click="toDetail(item)"
          >
            {{ false ? "即將開放" : "了解更多" }}
          </div>
        </div>
      </div>
    </div>
    <BookChildProductBoxVue
      v-if="childProductBoxData"
      :productList="childProductBoxData"
      @exit="childProductBoxData = null"
    ></BookChildProductBoxVue>
  </div>
</template>

<script>
import BookChildProductBoxVue from "../../components/訂單流程/BookChildProductBox.vue";
export default {
  components: {
    BookChildProductBoxVue,
  },
  data() {
    return {
      scrollTimer: null,
      activeId: 1,
      childProductBoxData: null,
    };
  },
  created() {
    this.$store.dispatch("setLoading", true);
    setTimeout(() => {
      this.$store.dispatch("setLoading", false);
    }, 1000);
  },
  computed: {
    productList() {
      return this.$store.state.productList;
    },
  },
  methods: {
    toDetail(product) {
      if (product.Code == "ProductVoucher_Book2024") {
        //2024解答之書兌換碼
        return (this.childProductBoxData = product.ChildProducts);
      }
      if (product.Code == "Book2024") {
        //2024解答之書
        return this.$router.push("/book2024");
      }
      this.$router.push(`/store/detail/${product.ProductID}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.banner {
  margin-top: -128px;
  background: url("../../assets/占星小鋪/background.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 54.8vw;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14vw 0 0;
  @include pad {
    margin-top: 0;
    padding: 5.3vw 0 0;
  }
  .pic {
    width: 43px;
    @include pad {
      width: 18px;
    }
    img {
      width: 100%;
    }
  }
  .banner_title {
    margin-top: 50px;
    font-family: SoukouMincho;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.015em;
    color: $secondary-color-1;
    @include pad {
      margin-top: 3px;
      font-size: 12px;
      line-height: 1;
    }
  }
  .banner_subtitle {
    margin-top: 12px;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.06em;
    color: #9b9b9b;
    @include pad {
      margin-top: 4px;
      font-size: 12px;
      line-height: 1;
    }
  }
  .banner_content {
    margin-top: 40px;
    max-width: 458px;
    font-family: Redacted Script;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $theme-color-2;
    @include pad {
      margin-top: 12px;
      font-size: 12px;
      line-height: 0.8;
    }
  }
}
.shopping_group {
  width: 992px;
  margin: 0 auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 132px 116px;
  @include pad {
    padding: 28px;
    width: 100%;
    gap: 88px 47px;
  }
}
.shopping_item {
  flex-shrink: 0;
  width: 240px;
  min-height: 405px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  @include pad {
    width: calc(50% - 24px);
    min-height: 188px;
  }
  & * {
    transition: 0.3s;
  }
  .shopping_title {
    font-size: 25px;
    font-weight: 500;
    color: $secondary-color-1;
    @include pad {
      font-size: 18px;
    }
  }
  .shopping_subtilte {
    font-size: 14px;
    line-height: 18px;
    color: $theme-color-2;
    > img {
      height: 12px;
      margin-right: 2px;
    }
  }
  .shopping_pic {
    height: 300px;
    margin-top: 12px;
    flex-shrink: 0;
    flex-grow: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @include pad {
      height: 136px;
    }
  }
  .shopping_priceBar {
    margin-top: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    @include pad {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .shopping_priceBar_price {
      color: $secondary-color-1;
      font-weight: 700;
      vertical-align: baseline;
      font-size: 32px;
      letter-spacing: 0.08em;
      margin-right: 20px;
      @include pad {
        font-size: 32px;
      }
      span {
        font-size: 16px;
        @include pad {
          font-size: 12px;
        }
      }
    }
    .shopping_priceBar_btn {
      @include btn-default(120px, 40px, "Noto Sans TC");
    }
  }
  & > img {
    margin-top: 30px;
    width: 300px;
    height: 320px;
    object-fit: contain;
    transition: 0.3s;
    @include pad {
      width: 120px;
      height: 120px;
    }
  }
}
</style>

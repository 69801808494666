<template>
  <div>
    <!-- section1 開始探索 -->
    <section class="intro custom-container-xl">
      <div class="intro__img">
        <img :src="carousel[carouselIndex].img" alt="唐老師" />
      </div>
      <div class="intro__content">
        <div class="content__box">
          <div class="content__title">
            <h1 v-html="carousel[carouselIndex].title"></h1>
          </div>
          <div
            class="content__sub hidden-pad-down"
            v-html="carousel[carouselIndex].subtitle"
          ></div>
          <div class="content__line hidden-pad-down"></div>
          <div
            class="content__slogan"
            v-html="carousel[carouselIndex].slogan"
          ></div>
          <div
            class="content__btn"
            @click="toPan(carousel[carouselIndex].buttonEventId)"
          >
            {{ carousel[carouselIndex].buttonText }}
          </div>
        </div>
      </div>
    </section>
    <div class="carousel__group">
      <div
        class="carousel__group__bullet"
        v-for="(item, index) in carousel"
        :key="index"
        :class="{ active: carouselIndex === index }"
        @click="carouselIndex = index"
      ></div>
    </div>
    <div class="color-block"></div>
    <!-- section2 about us -->
    <section class="about">
      <div class="about_box">
        <v-col md="6" class="about_title">
          <h2>About&#160; Us</h2>
          <h2><p>品牌故事</p></h2>
        </v-col>
        <v-col class="about_img">
          <img src="../assets/Index/aboutUs.jpg" alt="關於我們" />
        </v-col>
        <v-col md="6" class="about_context">
          <span>唐綺陽 / 華人界最具影響力的占星師</span>
          <br />
          <br />
          最優質Premium的星座專家，始終堅持人文與清流路線，致力於占星知識的推廣與運用，期待每個人都可以受惠這門超越科學的學問，找到自己最好的存在方式。
        </v-col>
        <Social class="about_social_group" @weChat="weChat = true"></Social>
      </div>
      <!-- Jesse跑馬燈 -->
      <div class="about_scroll">
        <img src="../assets/Index/JESSE TANG.svg" alt="跑馬燈" />
        <img src="../assets/Index/JESSE TANG.svg" alt="跑馬燈" />
      </div>
    </section>
    <!-- web版切換 -->
    <section class="slide custom-container-xl hidden-laptop-down">
      <div
        class="slide__arrow"
        @click="tabPrev"
        :class="{ disable: nowTab == 0 }"
      >
        <img src="@/assets/icon_prev2.svg" alt="icon" />
      </div>
      <div class="slide__wrap">
        <div class="slide__box">
          <div class="slide__item" :class="{ fix: nowTab == 0 }">
            <img :src="tab[nowTab].img1" alt="icon" />
          </div>
          <div class="slide__current">
            <div class="current__bg">
              <img :src="tab[nowTab].img" alt="icon圖片" />
            </div>
          </div>
          <div class="slide__word">
            <div class="slide__title" :class="{ nextSlideTitle: nowTab == 1 }">
              {{ tab[nowTab].title }}
            </div>
            <div class="slide__subtitle">{{ tab[nowTab].subtitle }}</div>
            <div class="slide__content">
              {{ tab[nowTab].content }}<br /><br />
              {{ tab[nowTab].content2 }}
            </div>
          </div>
        </div>
        <div class="slide__num">
          <span>0{{ nowTab + 1 }}</span
          >/ 0{{ tab.length }}
        </div>
        <div class="slide__bar">
          <div
            class="bar"
            :style="`left:${(100 / tab.length) * nowTab}%;width:${
              100 / tab.length
            }%`"
          ></div>
        </div>
        <div class="slide__btn" @click="toPan(nowTab)">前往體驗</div>
      </div>
      <div class="slide__arrow" :class="{ disable: nowTab == 1 }">
        <img src="@/assets/icon_next2.svg" @click="tabNext" alt="icon" />
      </div>
    </section>
    <!-- 手機板切換 -->
    <section class="slide-mobile custom-container hidden-laptop-up">
      <div class="slide__box">
        <div
          class="slide__arrow"
          @click="tabPrev"
          :class="{ disable: nowTab == 0 }"
        >
          <img src="@/assets/icon_prev2.svg" alt="icon" />
        </div>
        <div class="slide__current">
          <div class="current__bg">
            <img :src="tab[nowTab].img" alt="icon" />
          </div>
        </div>
        <div
          class="slide__arrow"
          @click="tabNext"
          :class="{ disable: nowTab == 1 }"
        >
          <img src="@/assets/icon_next2.svg" alt="icon" />
        </div>
      </div>
      <div class="slide__num">
        <span>0{{ nowTab + 1 }}</span
        >/ 0{{ tab.length }}
      </div>
      <div class="slide__bar">
        <div
          class="bar"
          :style="`left:${(100 / tab.length) * nowTab}%;width:${
            100 / tab.length
          }%`"
        ></div>
      </div>
      <div class="slide__word">
        <div class="slide__title">{{ tab[nowTab].title }}</div>
        <div class="slide__subtitle">{{ tab[nowTab].subtitle }}</div>
        <div class="slide__content">
          {{ tab[nowTab].content }}<br /><br />
          {{ tab[nowTab].content2 }}
        </div>
      </div>
      <div class="slide__btn" @click="toPan(nowTab)">前往體驗</div>
    </section>

    <section class="personal">
      <img class="personal__title" src="@/assets/personal-title.svg" alt />
      <div class="personal__bg"></div>
      <div class="personal__wrap custom-container-xl">
        <div class="personal__visual">
          <img
            class="personal__img"
            src="@/assets/personal-bg2.svg"
            alt="個人星盤"
          />
          <img
            class="personal__inside"
            src="@/assets/personal-inside.svg"
            alt="個人星盤"
          />
        </div>
        <div class="section__word personal__word">
          <div class="section__title"><h2>Personal</h2></div>
          <div class="section__subtitle"><h2>個人星盤</h2></div>
          <div class="section__content">
            30歲看上升？愛情看金星？又跟十二宮位有什麼關係？個人星盤帶你探索自己！
          </div>
          <div class="section__btn" @click="toPan(0)">了解更多</div>
        </div>
      </div>
    </section>
    <section class="composite">
      <img
        class="composite__title"
        src="@/assets/transit-title.svg"
        alt="推運功能"
      />
      <div class="composite__wrap custom-container-xl">
        <div class="section__word composite__word">
          <div class="section__title"><h2>Transit</h2></div>
          <div class="section__subtitle"><h2>推運功能</h2></div>
          <div class="section__content">
            推運功能已經推出，請關注唐綺陽官方專屬星盤以獲取最新消息！
          </div>
          <div class="section__btn" @click="toPan(2)">了解更多</div>
        </div>
        <div class="composite__visual">
          <img
            class="composite__img"
            src="@/assets/transit-bg.svg"
            alt="推運功能"
          />
          <img
            class="landpageAnit-transit"
            src="@/assets/transit-inside.svg"
            alt="推運功能"
          />
        </div>
      </div>
    </section>
    <section class="personal landpageAnit">
      <img class="personal__title" src="@/assets/composite-title2.svg" alt />
      <div class="personal__bg"></div>
      <div class="personal__wrap custom-container-xl">
        <div class="personal__visual">
          <img
            class="personal__img"
            src="@/assets/composite-bg2.svg"
            alt="雙人合盤"
            style="transform: rotateY(180deg)"
          />
          <img
            class="landpageAnit-composit"
            src="@/assets/composite-inside.svg"
            alt="雙人合盤"
          />
        </div>
        <div class="section__word personal__word">
          <div class="section__title"><h2>Synastry</h2></div>
          <div class="section__subtitle"><h2>雙人合盤</h2></div>
          <div class="section__content">
            雙人合盤即將推出，請關注唐綺陽官方專屬星盤以獲取最新消息！
          </div>
          <div class="section__btn" @click="toPan(3)">了解更多</div>
        </div>
      </div>
    </section>
    <section class="subscribe custom-container">
      <div class="subscribe__visual">
        <img src="../assets/Index/iphone_mockup.png" alt="訂閱我們" />
      </div>

      <div class="section__word subscribe__word">
        <div class="section__title">Subscribe</div>
        <div class="section__subtitle">加入我們的官方帳號</div>
        <div class="section__content">
          這是唐綺陽的Line官方帳號，你不一定因為是「粉絲」才加入，只要跟我一樣迷戀占星、又想感覺占星的神奇，都歡迎你來加入我的Line！
        </div>

        <div class="section__btn" @click="toLIne">
          <!-- <a href="https://lin.ee/2ydPGI4uU"> -->
          <img src="@/assets/icon_line.svg" alt="icon" />
          立即加入
          <!-- </a> -->
        </div>
      </div>
    </section>
    <section class="more">
      <div class="more_header custom-container">
        <div class="more__title">More</div>
        <div class="more__subtitle">更多資訊</div>
      </div>
      <div class="media">
        <div class="media__row custom-container">
          <a
            href="https://www.facebook.com/JesseTang11/"
            target="_blank"
            class="media__box"
          >
            <img src="@/assets/media_fb.svg" alt="icon" />
          </a>
          <a
            href="https://www.instagram.com/jessetang11/?hl=zh-tw"
            target="_blank"
            class="media__box"
          >
            <img src="@/assets/media_ig.svg" alt="icon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCK7LdglLCApOTaylxX8hW2Q"
            target="_blank"
            class="media__box"
          >
            <img src="@/assets/media_yt.svg" alt="icon" />
          </a>
        </div>
      </div>
    </section>
    <!-- 開發中 -->
    <transition name="fade">
      <div
        class="popup__overlay"
        :class="{ scaleBox: developing }"
        v-if="developing"
        @click="developing = false"
      >
        <div class="popup__box" @click.stop="developing = true">
          <img class="popup__img" src="@/assets/develop.svg" alt="開發中" />
          <div class="popup__title">Developing</div>
          <div class="popup__subtitle">
            此星球還在開發中，先去別的星球看看吧！
          </div>
          <div class="popup__btn" @click.stop="developing = false">返回</div>
        </div>
      </div>
    </transition>
    <!-- 登入提示 -->
    <transition name="fade">
      <div
        class="popup__overlay"
        :class="{ scaleBox: loginFirst }"
        v-if="loginFirst"
        @click="loginFirst = false"
      >
        <div class="popup__box" @click.stop="loginFirst = true">
          <img class="popup__img" src="@/assets/develop.svg" alt="開發中" />
          <div class="popup__title">Please Login</div>
          <div class="popup__subtitle">請先登入!</div>
          <div class="popup__btn" @click.stop="loginFirst = false">返回</div>
        </div>
      </div>
    </transition>
    <!-- 維信彈跳視窗 -->
    <transition name="fade">
      <div class="popup__overlay" v-if="weChat" @click="weChat = false">
        <div class="popup__box" @click.stop="weChat = true">
          <a href="http://weixin.qq.com/r/eTj-5zDE_G2HrXSE921r" target="_blank">
            <div class="popup__weChat_imgbox">
              <img
                src="../assets/Index/QRcode(wechat) 1@2x.jpg"
                alt="QRcode"
                class="popup__img"
              />
            </div>
          </a>
          <div class="popup__title">Wechat</div>
          <div class="popup__subtitle">
            掃描或點擊二維碼，立即關注唐綺陽看星星！
          </div>
          <div class="popup__btn" @click.stop="weChat = false">返回</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Social from "@/components/Social_group.vue";

export default {
  components: {
    Social,
  },

  data() {
    return {
      weChat: false,
      developing: false,
      loginFirst: false,
      tab: [
        {
          title: "Personal",
          subtitle: "個人星盤",
          content:
            "學占星，讓你更認識自己。而星盤，就像人生地圖，描述著我們會用什麼方式前進，以及想走往哪個目的地。",
          content2: "一起打開星盤，導讀你的人生課題！",
          img: require("@/assets/personal.svg"),
          img1: require("@/assets/composite.svg"),
          path: "/astrolabe",
        },
        {
          title: "Celestial",
          subtitle: "天象星盤",
          content: "天象星盤已經推出，請關注唐綺陽官方專屬星盤以獲取最新消息！",
          content2: "",
          img: require("@/assets/celestial.svg"),
          img1: require("@/assets/personal.svg"),
          path: "/astrometry",
        },
        {
          title: "Transit",
          subtitle: "推運功能",
          content: "推運功能已經推出，請關注唐綺陽官方專屬星盤以獲取最新消息！",
          content2: "",
          img: require("@/assets/transit.svg"),
          img1: require("@/assets/celestial.svg"),
          path: "/timelineAstrolabe",
        },
        {
          title: "Synastry",
          subtitle: "雙人合盤",
          content: "雙人合盤即將推出，請關注唐綺陽官方專屬星盤以獲取最新消息！",
          content2: "",
          img: require("@/assets/composite.svg"),
          img1: require("@/assets/transit.svg"),
          path: "",
        },
      ],
      carousel: [
        // $隱藏
        {
          title: `唐綺陽<br class="hidden-laptop-down" />官方專屬星盤`,
          subtitle: "Astrology Forecast",
          slogan: "打開專屬星盤 導讀你的人生課題",
          img: require("@/assets/Index/KV.jpg"),
          buttonText: "開始探索",
          buttonEventId: 0,
        },
        {
          title: `2024 唐綺陽<br class="hidden-laptop-down" />解答之書`,
          subtitle: "Jesse Tang",
          slogan: "2024 全新的你，已經在你身上了",
          img: require("@/assets/運勢書共用/book2024_banner.png"),
          buttonText: "了解更多",
          buttonEventId: "book2024",
        },
      ],
      carouselIndex: 0,
      nowTab: 0,
    };
  },
  created() {
    setInterval(() => {
      if (this.carouselIndex === this.carousel.length - 1) {
        this.carouselIndex = 0;
      } else {
        this.carouselIndex++;
      }
    }, 10000);
  },
  methods: {
    toPan(type) {
      if (type === "book2024") {
        return this.$router.push({ path: "/book2024" });
      }
      if (this.tab[type].path != "") {
        if (window.localStorage.getItem("login_status")) {
          this.$router.push({ path: this.tab[type].path });
        } else {
          this.$store.state.LoginOutSide = true;
        }
      } else {
        this.$store.state.developing = true;
      }
    },
    tabNext() {
      if (this.nowTab !== this.tab.length - 1) {
        this.nowTab = this.nowTab + 1;
      }
    },
    tabPrev() {
      if (this.nowTab !== 0) {
        this.nowTab = this.nowTab - 1;
      }
    },
    toLIne() {
      window.location.href = "https://lin.ee/2ydPGI4uU";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/popup.scss";

.intro {
  @include align(space-between, center);
  padding-top: 64px;
  padding-bottom: 72px;
  @include laptop {
    flex-direction: column;
    padding-bottom: 36px;
  }
  @include pad {
    padding-top: 0px;
  }

  .intro__img {
    @include align(flex-end, flex-end);
    width: 55%;
    @include laptop {
      width: 740px;
      @include align(center, center);
    }
    @include pad {
      width: 70%;
      @include align(center, center);
    }
    @include mobile {
      width: 100%;
      @include align(center, center);
    }
    > img {
      width: 536px;
      box-shadow: -112px -56px 0 0 $special_bg_color2;
      @include laptop {
        width: 70%;
        transform: translateX(-28px);
        box-shadow: 56px 28px 0 0 $special_bg_color2;
      }
      @include pad {
        width: 70%;
        transform: translateX(-28px);
        box-shadow: 56px 28px 0 0 $special_bg_color2;
      }
      @include mobile {
        width: 234px;
        transform: translateX(-28px);
        box-shadow: 56px 28px 0 0 $special_bg_color2;
      }
    }
  }

  .intro__content {
    @include align(flex-start, center);
    width: 45%;
    height: 100%;
    @include laptop {
      @include align(center, center);
      width: 100%;
    }

    .content__box {
      width: 381px;
      margin-left: 80px;
      @include laptop {
        margin-left: 0px;
        margin-top: 56px;
        @include center;
        flex-direction: column;
      }
      @include mobile {
        width: 100%;
        margin-top: 40px;
      }

      .content__title {
        font-family: $tw-font;
        font-size: 62px;
        font-weight: 900;
        line-height: 1.1;
        letter-spacing: 1.24px;
        color: $secondary-color-1;
        @include laptop {
          text-align: center;
        }
        @include mobile {
          font-size: 32px;
          letter-spacing: normal;
        }
      }

      .content__sub {
        font-family: "Yeseva One", cursive;
        font-size: 20px;
        letter-spacing: 2.8px;
        color: $theme-color-2;
        margin-top: 24px;
      }

      .content__line {
        width: 100%;
        height: 1.5px;
        background-color: $secondary-color-2;
        margin-top: 48px;
      }

      .content__slogan {
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 1.6px;
        color: $secondary-color-3;
        margin-top: 32px;
        @include mobile {
          font-size: 14px;
          line-height: 1;
          letter-spacing: 0.56px;
          margin-top: 26px;
        }
      }

      .content__btn {
        @include btn-default(368px, 80px, 24px);
        margin-top: 56px;
        @include mobile {
          @include btn-default(84%, 48px, 16px);
          margin-top: 40px;
        }
      }
    }
  }
}

.color-block {
  width: 100%;
  height: 96px;
  background-color: rgba($color: $secondary-color-2, $alpha: 0.4);
}

.section__word {
  @include laptop {
    @include center;
    flex-direction: column;
    text-align: center;
  }
  @include pad {
    width: 92%;
  }
  @include mobile {
    padding: 0 8px;
  }

  .section__title {
    @include title;
  }

  .section__subtitle {
    margin-top: 16px;
    @include subtitle;
    @include mobile {
      margin-top: 8px;
    }
  }

  .section__content {
    margin-top: 40px;
    width: 340px;
    @include content;
    @include laptop {
      text-align: center;
    }
    @include pad {
      width: 100% !important;
    }
    @include mobile {
      margin-top: 24px;
    }
  }

  .section__btn {
    margin-top: 50px;
    @include btn-default(232px, 56px, 20px);

    img {
      margin-right: 10px;
    }

    @include pad {
      width: 84%;
    }
    @include mobile {
      margin-top: 24px;
      height: 48px;
      font-size: 16px;
    }
  }
}

.about {
  height: 1024px;
  position: relative;
  background-color: #ebedef;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // @include align(center, center);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 15%;
    opacity: 0.4;
    background-color: $theme-color-1;
  }
  @include pad {
    height: 768px;
  }
  @include mobile {
    height: 667px;
  }

  .about_box {
    z-index: 1;
    width: calc(100% - 66px);
    height: 697px;
    position: absolute;
    top: 128px;
    right: 0;
    left: 66px;
    box-sizing: border-box;
    background-color: $theme-color-3;
    @media (min-width: 1781px) {
      padding-left: 8.5vw;
    }
    @include pad {
      width: calc(100% - 32px);
      height: 600px;
      margin: 0;
      top: 80px;
      height: 580px;
      right: 0;
      left: 16px;
    }
    @include mobile {
      width: calc(100% - 32px);
      height: 560px;
      margin: 0;
      top: 35px;
      right: 16px;
      left: 16px;
    }

    .about_title {
      // margin-left: 142px;
      margin-left: 10vw;
      margin-top: 82px;
      padding: 12px 32px;
      h2 {
        font-family: "Yeseva One", cursive;
        font-size: 107px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2.2px;
        color: $secondary-color-1;
        white-space: nowrap;
      }
      p {
        font-family: "Noto Sans TC";
        font-size: 24px;
        margin-bottom: 0;
        color: $theme-color-2;
      }
      @include pad {
        margin: 24px auto 0;
        padding: 0;
        text-align: center;
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
          line-height: 2;
        }
      }
    }

    .about_img {
      padding: 0;
      position: absolute;
      left: 45vw;
      top: 48px;
      z-index: -1;
      @include align(flex-start, center);
      @include pad {
        z-index: 5;
        position: relative;
        left: 0;
        top: 0;
        padding: 12px 26px;
      }
      @include mobile {
        height: 270px;
        object-fit: cover;
        overflow: hidden;
      }
      img {
        width: 548px;
        margin-right: auto;
        object-fit: cover;
        @include laptop {
          width: 40vw;
        }
        @include pad {
          width: 30vw;
          margin: 0 auto;
        }
        @include mobile {
          width: 100%;
          max-width: 292px;
        }
      }
    }

    .about_context {
      width: 436px;
      height: 228px;
      padding: 20px;
      margin-left: 12.5vw;
      margin-top: 28px;
      font-family: $tw_font;
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: 1.6px;
      color: $secondary-color-3;
      border-left: solid 1px $theme-color-2;
      font-weight: 400;
      > span {
        display: inline-block;
        width: 100%;
        font-weight: 500;
        @include pad {
          text-align: center;
        }
      }
      @include pad {
        font-size: 14px;
        border: none;
        line-height: 1.57;
        letter-spacing: 0.56px;
        margin: 16px auto 0;
        width: auto;
        height: auto;
        padding: 0 84px 0 90px;
      }
      @include mobile {
        padding: 0 24px 0 30px;
      }
    }

    .about_social_group {
      width: 344px;
      position: absolute;
      bottom: 24px;
      left: 50vw;
      z-index: 5;
      margin-left: 124px;
      @include laptop {
        margin-left: 0;
      }
      @include pad {
        width: 288px;
        position: relative;
        left: 0;
        top: 0;
        padding: 32px 26px;
        margin: 0 auto;
      }
      @include mobile {
        width: 248px;
        position: relative;
        left: 0;
        top: 0;
        padding: 8px 26px 16px;
        margin: 0 auto;
      }
    }
  }

  .about_scroll {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    width: 200vw;
    justify-content: space-around;
    animation: scroll 20s linear infinite;
    z-index: 21;
    @media (min-width: 960px) {
      margin-bottom: 64px;
    }
    > img {
      width: 1212px;
      opacity: 0.2;
      padding: 0;
      @include laptop {
        width: 90vw;
        margin: 0 40px;
      }
      @include mobile {
        width: 360px;
        height: 28.2px;
        margin: 0 40px;
      }
    }
  }
  @keyframes scroll {
    from {
      transform: translateX(50vw);
    }
    to {
      transform: translateX(-50vw);
    }
  }
}

.slide {
  padding: 50px 16px 24px;
  @include align(space-between, center);

  .slide__arrow {
    &.disable {
      opacity: 0.5;
    }
    cursor: pointer;
  }

  .slide__wrap {
    @include center;
    flex-direction: column;
    width: 1024px;
    max-width: 1024px;

    .slide__box {
      @include center;
      width: 100%;

      .slide__item {
        // width: 24%;
        width: 238px;
        margin-top: 32px;
        object-fit: contain;
        flex-shrink: 0;

        img {
          width: 100%;
        }
        &.fix {
          padding: 30px;
        }
      }

      .slide__current {
        margin: 56px 52px;
        flex-shrink: 0;
        @include rect(42%, 615px, $theme-color-3, 0);
        @include center;

        .current__bg {
          @include rect-inset(94%, 590px, $theme-color-3, 0);
          @include center;

          img {
            width: 89%;
            height: 70%;
          }
        }
      }
    }

    .slide__num {
      margin-top: 30px;
      margin-bottom: 16px;
      @include center;
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: 1.6px;
      color: rgba($color: $secondary-color-3, $alpha: 0.5);

      span {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 3.2px;
        color: rgba($color: $secondary-color-3, $alpha: 1);
        margin-right: 16px;
      }
    }

    .slide__bar {
      position: relative;
      width: 100%;
      height: 8px;
      background-color: #d9d9d9;

      .bar {
        position: absolute;
        height: 8px;
        background-color: #9b9b9b;
        left: 0%;
        transition: 0.2s;
      }
    }

    .slide__word {
      width: 290px;

      .slide__title {
        @include title;
        font-size: 50px;

        &.nextSlideTitle {
          letter-spacing: 0;
          font-size: 44px;
        }
      }

      .slide__subtitle {
        @include subtitle;
      }

      .slide__content {
        margin-top: 32px;
        width: 100%;
        @include content;
      }
    }

    .slide__btn {
      margin-top: 72px;
      @include btn-default(368px, 80px, 24px);
    }
  }
}

// mobile
.slide-mobile {
  padding-top: 40px;
  @include center;
  flex-direction: column;

  .slide__box {
    @include align(space-between, center);
    @include pad {
      width: 100%;
    }

    .slide__arrow {
      cursor: pointer;
      &.disable {
        opacity: 0.5;
      }
      @include mobile {
        width: 32px;
        img {
          width: 100%;
        }
      }
    }

    .slide__current {
      margin: 0 56px;
      @include rect(420px, 615px, $theme-color-3, 0);
      @include center;

      @include pad {
        width: 55%;
        height: 425px;
      }
      @include mobile {
        width: 60%;
        margin: 0 16px;
        height: 300px;
      }

      .current__bg {
        @include rect-inset(94%, 590px, $theme-color-3, 0);
        @include center;

        @include pad {
          height: 400px;
        }
        @include mobile {
          height: 275px;
        }

        img {
          width: 89%;
          height: 89%;
        }
      }
    }
  }

  .slide__num {
    margin-top: 30px;
    margin-bottom: 16px;
    @include center;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 1.6px;
    color: rgba($color: $secondary-color-3, $alpha: 0.5);

    span {
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 3.2px;
      color: rgba($color: $secondary-color-3, $alpha: 1);
      margin-right: 16px;
    }

    @include mobile {
      margin-top: 24px;
      font-size: 14px;
      span {
        font-size: 24px;
      }
    }
  }

  .slide__bar {
    position: relative;
    width: 80%;
    height: 8px;
    background-color: #d9d9d9;
    @include pad {
      width: 92%;
    }

    .bar {
      position: absolute;
      width: 50%;
      height: 8px;
      background-color: #9b9b9b;
      left: 0%;
      transition: 0.2s;

      &.nextSlide {
        left: 50%;
      }
    }
  }

  .slide__word {
    margin-top: 32px;
    @include center;
    flex-direction: column;
    @include pad {
      width: 92%;
    }

    .slide__title {
      @include title;
      font-size: 50px;
    }

    .slide__subtitle {
      margin-top: 8px;
      @include subtitle;
    }

    .slide__content {
      margin-top: 32px;
      width: 336px;
      @include content;
      text-align: center;
      @include pad {
        width: 100%;
      }
      @include mobile {
        margin-top: 24px;
      }
    }
  }

  .slide__btn {
    margin-top: 50px;
    @include btn-default(368px, 80px, 24px);
    @include pad {
      width: 92%;
    }
    @include mobile {
      margin-top: 24px;
      height: 48px;
      font-size: 16px;
      width: 84%;
    }
  }
}

//
.personal {
  position: relative;
  height: 1000px;
  @include align(flex-start, center);
  margin-top: 50px;
  margin-bottom: 50px;
  @include laptop {
    height: 1100px;
  }
  @include pad {
    height: 1200px;
  }
  @include mobile {
    height: 900px;
  }
  .personal__title {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 50%;
    transform: translate(0, calc(-50% - 40px));
    width: 8.8%;
    max-width: 128px;
    @include laptop {
      top: 30%;
    }
    @include pad {
      width: 14.6%;
      top: 34%;
    }
    @include mobile {
      top: 37%;
    }
  }
  .personal__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -100px;
    width: 120%;
    height: 1000px;
    background-color: rgba($color: $secondary-color-2, $alpha: 0.2);
    transform: rotate(-5deg);
    @include laptop {
      height: 1100px;
    }
    @include pad {
      height: 1200px;
      left: -50px;
    }
    @include mobile {
      height: 900px;
      width: 130%;
      left: -50px;
    }
  }

  .personal__wrap {
    position: relative;
    z-index: 2;
    @include align(flex-start, center);
    width: 1472px;
    padding: 0 0 0 43px;
    @include laptop {
      flex-direction: column;
      padding: 0;
    }
    .personal__word {
      margin-left: 50px;
      @include laptop {
        margin-left: 0;
        margin-top: 32px;
      }
    }

    .personal__visual {
      position: relative;
      width: 56%;
      @include pad {
        width: 100%;
        padding: 0 45px;
      }
      @include mobile {
        padding: 0 20px;
      }

      .personal__img {
        width: 100%;
      }

      .personal__inside {
        position: absolute;
        width: 47%;
        bottom: 20%;
        right: 25%;
        transition: 0.2s;
      }

      &:hover {
        .personal__inside {
          transform: scale(1.2);
        }
        .landpageAnit-composit {
          transform: scale(1.2) rotateY(180deg);
        }
      }
    }
    ///客製化hover動畫
    //雙人合盤
    .landpageAnit-composit {
      transform: rotateY(180deg);
      width: 63%;
      position: absolute;
      bottom: -16%;
      left: 30%;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
  }
}

.composite {
  position: relative;
  .composite__title {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 50%;
    transform: translate(0, calc(-50% - 70px));
    width: 8.8%;
    max-width: 128px;
    @include laptop {
      top: 30%;
    }
    @include pad {
      width: 14.6%;
    }
    @include mobile {
      top: 32%;
    }
  }
  .composite__wrap {
    padding: 50px 45px 170px 0;
    @include align(flex-end, center);
    @include laptop {
      flex-direction: column;
      padding: 0 0 80px 0;
    }
  }

  .composite__word {
    margin-right: 50px;
    @include laptop {
      margin-right: 0px;
      margin-top: 100px;
      order: 2;
    }
    @include pad {
      margin-top: 140px;
    }
    @include mobile {
      margin-top: 100px;
    }
  }

  .composite__visual {
    position: relative;
    margin-left: 10px;
    width: 51%;
    @include pad {
      width: 100%;
      padding: 0 45px;
    }
    @include mobile {
      padding: 0 20px;
    }

    .composite__img {
      width: 100%;
    }
    &:hover {
      .landpageAnit-transit {
        transform: scale(1.35);
      }
    }
  }
}
///客製化hover動畫
//天象
.landpageAnit-transit {
  width: 33%;
  position: absolute;
  top: 15%;
  left: 12%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.subscribe {
  padding-top: 20px;
  padding-bottom: 60px;
  @include center;
  @include laptop {
    flex-direction: column;
  }

  .subscribe__visual {
    margin-right: 52px;
    width: 60%;

    img {
      width: 100%;
    }

    @include laptop {
      width: 70%;
      margin-right: 0;
    }
    @include pad {
      width: 100%;
    }
  }

  .subscribe__word {
    @include mobile {
      width: 100%;
    }
    .section__content {
      width: 395px;
    }
  }
}

.more {
  margin-top: 50px;
  @include rect(100%, auto, $theme-color-3, 0);
  position: relative;

  &::before {
    content: "";
    @include rect(60px, 60px, $theme-color-3, 0);
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    height: 30px;
    background-color: $theme-color-3;
    position: absolute;
    bottom: -30px;
  }

  .more_header {
    padding: 90px 0 40px;
    position: relative;
    z-index: 2;
    text-align: center;
    background-color: $theme-color-3;
    @include mobile {
      padding: 56px 0 24px;
    }

    .more__title {
      font-family: "Yeseva One", cursive;
      font-size: 70px;
      letter-spacing: 1.4px;
      color: $secondary-color-1;
      @include mobile {
        font-size: 32px;
      }
    }

    .more__subtitle {
      font-size: 24px;
      letter-spacing: 0.96px;
      color: $theme-color-2;
      @include mobile {
        font-size: 16px;
      }
    }
  }

  .media {
    padding: 100px 0;
    background-color: rgba($color: $secondary-color-2, $alpha: 0.2);
    @include center;
    @include pad {
      padding: 20px 0;
    }

    .media__row {
      @include center;

      .media__box {
        display: block;
        width: 320px;
        height: 320px;
        border: solid 15px rgba($color: $secondary-color-2, $alpha: 0.6);
        @include center;
        margin-right: 32px;
        transition: 0.3s;
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          border-color: rgba($color: $theme-color-1, $alpha: 0.7);
        }

        img {
          width: 68%;
        }

        @include laptop {
          width: 28vw;
          height: 28vw;
        }
        @include pad {
          border-width: 4px;
          margin-right: 16px;
          width: 24vw;
          height: 24vw;
        }
      }
    }
  }
}

//輪播圖底下按鈕
.carousel__group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  &__bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;
    transition: 0.3s;
    background-color: #f0f2f4;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25), -1px -1px 4px 0px #fff,
      -1px -1px 4px 0px #fff;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      border-radius: 5px;
      width: 56px;
      height: 10px;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15) inset,
        2px 2px 4px 0px #fff;
    }
  }
}
</style>

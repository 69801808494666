import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import './registerServiceWorker'
import vuetify from "./plugins/vuetify";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Qs from "qs";
import VueMeta from "vue-meta";
import GoogleSignInButton from "vue-google-signin-button-directive";
import CountryCode from "./js/country.json";
import directives from "./js/preventRepeat";
import astro from "@/js/astro.js";
//導入API
import API from "./api/index.js";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(directives);
Vue.config.productionTip = false;
//全域導入API
Vue.prototype.$API = API;
Vue.prototype.Qs = Qs;
Vue.prototype.$CountryCode = CountryCode;
Vue.prototype.$Astro = astro;

Vue.prototype.$NameFormat = function (str, n = 18) {
  let length = 0; // 当前累计长度
  let index = 0; // 截断索引
  let source = str;
  for (let i = 0; i < str.length; i++) {
    if (str[i].match(/[^\x00-\xff]/g)) {
      // 匹配到中文字符
      length += 3;
    } else {
      // 匹配到英文字符
      length += 2;
    }

    if (length > n) {
      index = i;
      break;
    }
  }
  return index > 0 ? str.substring(0, index) + "..." : source;
};

Vue.prototype.$formatNumber = (num) => {
  if (num.length === 4) {
    return num;
  } else if (num.length === 3) {
    return "0" + num;
  } else if (num.length === 2) {
    return "00" + num;
  } else {
    return "000" + num;
  }
};

if (process.env.NODE_ENV == "local" || process.env.NODE_ENV == "dev") {
  Vue.config.devtools = true;
  Vue.config.debug = true;

  console.log("測試中");
} else {
  Vue.config.debug = false;
  Vue.config.devtools = false;
}

let vm = new Vue({
  router,
  store,
  vuetify,
  GoogleSignInButton,
  render: (h) => h(App),
  // pre render配置
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#all");

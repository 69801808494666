var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "footerWrap", class: { bookStyle: _vm.bookStyle } },
    [
      _c("div", { staticClass: "row-wrap custom-container" }, [
        _c("div", { staticClass: "row-left" }, [
          _c("a", { attrs: { href: "mailto:service@astroinfo.com.tw" } }, [
            _c("div", { staticClass: "email" }, [
              _vm.bookStyle
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/運勢書共用/social_email.svg")
                    }
                  })
                : _c("img", {
                    attrs: { src: require("@/assets/icon_email.svg") }
                  })
            ])
          ]),
          _c("div", { staticClass: "links" }, [
            _c("div", { staticClass: "link", on: { click: _vm.toReport } }, [
              _vm._v("問題回報")
            ]),
            _c("div", { staticClass: "link", on: { click: _vm.toPrivacy } }, [
              _vm._v("隱私及服務條款")
            ])
          ])
        ]),
        _c("div", { staticClass: "social" }, [
          _c(
            "a",
            {
              staticClass: "social__btn",
              attrs: {
                href: "https://www.instagram.com/jessetang11/?hl=zh-tw",
                target: "_blank"
              }
            },
            [
              _c("div", { staticClass: "btn__bg" }, [
                _vm.bookStyle
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/social_ig.svg")
                      }
                    })
                  : _c("img", {
                      attrs: { src: require("@/assets/social_ig.svg") }
                    })
              ])
            ]
          ),
          _c(
            "a",
            {
              staticClass: "social__btn",
              attrs: {
                href:
                  "https://liff.line.me/1645278921-kWRPP32q/?accountId=jessetang",
                target: "_blank"
              }
            },
            [
              _c("div", { staticClass: "btn__bg" }, [
                _vm.bookStyle
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/social_line.svg")
                      }
                    })
                  : _c("img", {
                      attrs: { src: require("@/assets/social_line.svg") }
                    })
              ])
            ]
          ),
          _c(
            "a",
            {
              staticClass: "social__btn",
              attrs: {
                href: "https://www.facebook.com/JesseTang11/",
                target: "_blank"
              }
            },
            [
              _c("div", { staticClass: "btn__bg" }, [
                _vm.bookStyle
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/social_fb.svg")
                      }
                    })
                  : _c("img", {
                      attrs: { src: require("@/assets/social_fb.svg") }
                    })
              ])
            ]
          )
        ])
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("span", [_vm._v(" Copyright © 2020 DaMou. All rights reserved. ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
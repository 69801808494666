var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "tabs-m hidden-pad-up" }, [
      _c(
        "div",
        {
          staticClass: "tabs-m__item privacy",
          class: { activeTabM: _vm.activeTab == "privacy" },
          on: {
            click: function($event) {
              return _vm.changeTab("privacy")
            }
          }
        },
        [_vm._v("\n        隱私權政策\n      ")]
      ),
      _c(
        "div",
        {
          staticClass: "tabs-m__item service",
          class: { activeTabM: _vm.activeTab == "service" },
          on: {
            click: function($event) {
              return _vm.changeTab("service")
            }
          }
        },
        [_vm._v("\n        服務條款\n      ")]
      )
    ]),
    _c("div", { staticClass: "block custom-row custom-container" }, [
      _c("div", { staticClass: "tabs hidden-pad-down" }, [
        _c(
          "div",
          {
            staticClass: "tabs__item privacy",
            class: { activeTab: _vm.activeTab == "privacy" },
            on: {
              click: function($event) {
                return _vm.changeTab("privacy")
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "80",
                  height: "80",
                  fill: "none",
                  viewBox: "0 0 80 80"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d:
                      "M17.917 16.667l2.588-2.022L40 19.52l19.495-4.873 2.588 2.02v2.917h4.584l2.083 2.084v35l-1.52 2.005-26.666 7.5h-1.128l-26.667-7.5-1.519-2.005v-35l2.083-2.084h4.584v-2.916zm0 7.083h-2.5v31.338l22.5 6.328v-4.02L19.417 52l-1.5-2V23.75zm24.166 33.646L60.583 52l1.5-2V23.75h2.5v31.338l-22.5 6.328v-4.02zm0-4.34V23.293l15.834-3.958v29.102l-15.834 4.619zm-4.166-29.763v29.763l-15.834-4.618V19.334l15.834 3.958zm-3.321 5.824l-10-2.5.808-3.234 10 2.5-.808 3.234zm-10 6.666l10 2.5.808-3.233-10-2.5-.808 3.233zm10 11.667l-10-2.5.808-3.234 10 2.5-.808 3.234zm20.808-20.833l-10 2.5-.808-3.234 10-2.5.808 3.234zm-10 11.666l10-2.5-.808-3.233-10 2.5.808 3.233zm10 6.667l-10 2.5-.808-3.234 10-2.5.808 3.234z",
                    "clip-rule": "evenodd"
                  }
                })
              ]
            ),
            _c("span", [_vm._v("隱私權政策")])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "tabs__item service",
            class: { activeTab: _vm.activeTab == "service" },
            on: {
              click: function($event) {
                return _vm.changeTab("service")
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "80",
                  height: "80",
                  fill: "none",
                  viewBox: "0 0 80 80"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d:
                      "M22.5 17.5h35v45h-35v-45zm-4.166 49.167V13.333h43.333v53.334H18.333zm10-41.667H35v-3.333h-6.666V25zm23.333 8.333H28.333V30h23.334v3.333zm-23.334 8.334h23.334v-3.334H28.333v3.334zM51.668 50H28.333v-3.333h23.334V50zm-15 8.333h15V55h-15v3.333z",
                    "clip-rule": "evenodd"
                  }
                })
              ]
            ),
            _c("span", [_vm._v("服務條款")])
          ]
        )
      ]),
      _c("div", { staticClass: "content-box" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == "privacy",
                expression: "activeTab == 'privacy'"
              }
            ],
            staticClass: "content"
          },
          [
            _vm._m(1),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            非常歡迎您光臨「唐綺陽官方專屬星盤\n            」（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，請詳讀以下內容以了解當您使用我們的網站及服務，以便您了解我們會如何使用您的個人資料。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("一、隱私權保護政策的適用範圍")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。當您經由本網站所提供的連結，點選進入其他網站時，您必須參考該連結網站中的隱私權保護政策。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("二、個人資料的蒐集、處理及利用方式")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            我們將視業務或活動性質請您提供必要的個人資料，以便完成交易與相關服務。 \n          "
              )
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            請確認您所提供的個人資料真實準確，本公司不會承擔您資料中所提供不準確或不完整資訊所造成之損害或錯誤，此將須自行負責。\n          "
              )
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            如果您拒絕提供個人資料，可能無法充分利用本網站某些服務。 \n          "
              )
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            請妥善保管您的會員帳號及密碼，不要將上述資料提供給任何人或允許任何人以您的個人資料申請或使用帳號、密碼，本公司不會承擔任何不當使用密碼之責任。  \n          "
              )
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            “個人資料”包含您的姓名、住址、電話、生日、電子郵件信箱等，我們非常重視保護個人資料的隱私，您的個人資料不會被出售或轉送給其他人或機構，除非法律上需要。\n          "
              )
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("三、資料之保護")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("四、與第三人共用個人資料之政策")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("五、隱私權保護政策之修正")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("六、您的權利與選擇")
            ]),
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("七、準據法及管轄法院")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            因您使用本網站與行動應用程式所造成之爭議，您同意均以中華民國法律為準據法。除法律另有強制規定者應依其規定外，並應以台灣台北地方法院為第一審管轄法院。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("八、Facebook 資料刪除指示")
            ]),
            _vm._m(5),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("Facebook Data Deletion Instructions")
            ]),
            _vm._m(6),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("九、更新/刪除您的個人資訊")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "若您已註冊並希望從系統中刪除您提供給我們的任何註冊資訊，請透過分頁底部的「聯絡我們」連結與我們聯繫。根據您的需求，我們將盡力於30天內自活動數據庫中刪除您的註冊資訊，並在可行的情況下從我們的備份媒體中刪除您的註冊資訊。\n請注意，永久刪除您的註冊資訊，您的所有個人資料亦從資料庫中清除。這道流程完成後，您再也無法使用您的任一項服務，您的使用者帳號與所有資料均將永久移除，將無法恢復您的帳號，或於日後擷取您的個人資料。若您於日後聯絡本服務支援管道，系統將無法辨認您的帳號與找回已刪除的帳號。"
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == "service",
                expression: "activeTab == 'service'"
              }
            ],
            staticClass: "content"
          },
          [
            _vm._m(7),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("一、會員服務條款")
            ]),
            _vm._m(8),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("二、隱私權保護")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            尊重並依據本網站「隱私權保護聲明」保護您的隱私(請參閱「隱私權保護聲明」條款)。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("三、會員")
            ]),
            _vm._m(9),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("四、使用行為")
            ]),
            _vm._m(10),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("五、本網站權限")
            ]),
            _vm._m(11),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("六、修改權")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            本公司有權暫時或永久修改或中止提供本服務給您，您不得因此要求任何賠償。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("七、智慧財產權的保護")
            ]),
            _c("p", { staticClass: "content_p" }, [
              _vm._v(
                "\n            本網站所使用之軟體或程式與網站上之所有內容，均由本網站或其他權利人依法享有智慧財產權。未經本網站或其他權利人之事前書面同意，請勿擅自使用、修改、重製、公開播送或發表、散布、改作、發行、進行還原工程、解編或反向組譯。倘您欲引用或轉載上開內容，請依法取得本網站或其他權利人之事前書面同意，事後並應以明顯圖字標註資料之來源出處；如有違法情事，應負賠償責任。\n          "
              )
            ]),
            _c("div", { staticClass: "content_subtitle" }, [
              _vm._v("八、準據法與管轄法院")
            ]),
            _vm._m(12)
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header custom-container" }, [
      _c("div", { staticClass: "header__title" }, [_vm._v("隱私及服務條款")]),
      _c("div", { staticClass: "header__line hidden-pad-down" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content_title" }, [
      _vm._v("\n            [\n            "),
      _c("span", { staticClass: "title-space" }, [_vm._v("隱私條款")]),
      _vm._v("]\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "content_table" }, [
      _c("tr", [
        _c("th", [
          _vm._v("您的權利\n                "),
          _c("br"),
          _vm._v("\n                Your Rights\n              ")
        ]),
        _c("th", [
          _vm._v("意指？\n                "),
          _c("br"),
          _vm._v("What do your rights entail? \n              ")
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v("被告知之權利"),
          _c("br"),
          _c("br"),
          _c("span", [_vm._v("Rights to be Informed")])
        ]),
        _c("td", [
          _vm._v(
            "\n                您有權就我們使用您個人資料之方式，以及您的權利，獲取清晰、透明且易於理解的資訊。這正是我們於本政策向您提供該等資訊之原因。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "You have the right to obtain clear, transparent, and easily understandable information about how we use your personal data and your rights. This is precisely why we provide such information to you in this policy. "
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v("請求查詢、閱覽、製給複製本之權利\n                "),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v("Right to Request Access, Inspection, and Copies ")
          ])
        ]),
        _c("td", [
          _vm._v(
            "\n                您有權要求查詢、閱覽由我們持有而與您有關之個人資料，並要求製給複製本（可能需符合一定條件）。"
          ),
          _c("br"),
          _vm._v(
            "\n\n                我們可能會視提供該等資料的行政成本而酌收合理費用。  "
          ),
          _c("br"),
          _vm._v(
            "\n\n                如相關請求明顯缺乏依據、過量或反覆的要求可能不會獲得回覆。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "\n                  You have the right to request access and inspection of personal data held by us that relates to you, as well as to request copies to be made (subject to certain conditions). We may charge a reasonable fee based on the administrative costs associated with providing such data. \n\nRequests that are evidently unfounded, excessive, or repetitive may not receive a response. \n                "
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v("請求補充或更正之權利\n                "),
          _c("br"),
          _c("br"),
          _c("span", [_vm._v("Right to Request Supplement or Correction")])
        ]),
        _c("td", [
          _vm._v(
            "\n                如您的個人資料有誤或過時及(或)出現缺漏，您有權要求更正及(或)補充。\n                "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "\n\n                除您自行輸入資料有誤或購買後調整之商品不在此限，其成立之訂單仍存在效力。\n\n                "
          ),
          _c("br"),
          _c("span", [
            _vm._v(
              "If your personal data is inaccurate, outdated, and/or incomplete, you have the right to request correction and/or supplementation. Except for errors in data input by yourself or adjustments to purchased items after the fact, the orders established remain valid. \n              "
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v("請求刪除／被遺忘之權利\n                "),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v("Right to Request Deletion/Right to Be Forgotten")
          ])
        ]),
        _c("td", [
          _vm._v(
            "\n                在部份情形，您有權要求我們銷毀或刪除您的個人資料。請注意，這並非絕對的權利，因我們可能必須依法規要求或基於其他合法裡由必須保存您的個人資料。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "In certain circumstances, you have the right to request us to destroy or delete your personal data. Please note that this is not an absolute right, as we may be required by law or other legitimate reasons to retain your personal data. \n              "
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v("拒絕直接行銷（包括個人剖析）之權利\n                "),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "\n                  Right to Refuse Direct Marketing (including Profiling) \n                "
            )
          ])
        ]),
        _c("td", [
          _vm._v(
            "您可隨時取消訂閱我們的行銷訊息或要求從相關清單退出。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "\n                  You have the right to unsubscribe from our marketing messages at any time or request to be removed from relevant lists. \n                "
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v(
            "基於同意所進行之資料處理，隨時撤回其同意之權利\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "Right to Withdraw Consent for Data Processing Based on Consent "
            )
          ])
        ]),
        _c("td", [
          _vm._v(
            "\n                如我們處理您的個人資料係基於您的同意，您可以隨時撤回您的同意。撤回同意不會影響在撤回前已經依照您的同意所進行之處理之合法性。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "\n                  If we process your personal data based on your consent, you have the right to withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. \n                "
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v(
            "基於合法利益所進行之資料處理，請求停止之權利\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "Right to Object to Data Processing Based on Legitimate Interests "
            )
          ])
        ]),
        _c("td", [
          _vm._v(
            "\n                如我們處理您的個人資料係基於合法利益，您可以隨時提出異議反對我們處理您的個人資料。這不會影響在提出異議前已經依照進行之資料處理之合法性。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "If we process your personal data based on legitimate interests, you have the right to object to our processing of your personal data at any time. This will not affect the lawfulness of processing based on legitimate interests before the objection is raised."
            )
          ])
        ])
      ]),
      _c("tr", [
        _c("td", [
          _vm._v("關閉Cookies之權利\n                "),
          _c("br"),
          _c("br"),
          _c("span", [_vm._v("Right to Disable Cookies ")])
        ]),
        _c("td", [
          _vm._v(
            "\n                您有權關閉Cookies。網頁瀏覽器通常內建預設接受Cookies，但您可以簡單透過更改您的瀏覽器設定來關閉。但請注意，如您選擇關閉Cookies，可能使您無法充分使用我們的網站／應用程式。\n                "
          ),
          _c("br"),
          _c("br"),
          _c("span", [
            _vm._v(
              "\n                You have the right to disable cookies. Web browsers typically accept cookies by default, but you can easily disable them by adjusting your browser settings. However, please note that choosing to disable cookies may limit your ability to fully utilize our website/application. \n                "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "content_p" }, [
      _vm._v(
        "\n            若您對本隱私權保護政策有上述問題或不同意該等變更或修改，皆可利用本網站「問題回報」與我們聯繫或停止使用本網站服務。\n            "
      ),
      _c("br"),
      _c("br"),
      _vm._v("\n            客服信箱: "),
      _c("a", { attrs: { href: "mailto:damou-service@astrorg.com" } }, [
        _vm._v("damou-service@astrorg.com")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content_p enVersion" }, [
      _vm._v(
        '\n            If you have any questions regarding this Privacy Policy or do not agree with such changes or modifications, you can utilize the "Report an Issue" feature on this website or directly contact us to cease using the website services. \n            '
      ),
      _c("br"),
      _c("br"),
      _vm._v("\n            Customer Service Email: "),
      _c("a", { attrs: { href: "mailto:damou-service@astrorg.com" } }, [
        _vm._v("damou-service@astrorg.com")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "content_p" }, [
      _vm._v(
        "\n            根據 Facebook 政策，我們必須提供「Facebook\n            資料刪除指示說明」，如果您想刪除此平台上的 Facebook\n            登入應用數據，您可以按照以下步驟進行： \n            "
      ),
      _c("ol", [
        _c("li", [
          _vm._v("1.進入您的 Facebook 帳號的「設定和隱私」選單並點擊「設定」")
        ]),
        _c("li", [_vm._v("2.向下滾動並點擊「應用程式和網站」 ")]),
        _c("li", [_vm._v("3.找到並點擊此應用程式")]),
        _c("li", [
          _vm._v("4.點擊「移除」按鈕，即成功地刪除了您的應用程式活動。")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "content_p enVersion" }, [
      _vm._v(
        '\n            According to Facebook\'s policy, we are required to provide "Facebook Data Deletion Instructions."'
      ),
      _c("br"),
      _vm._v(
        "\n             If you wish to delete Facebook login app data on this platform, you can follow these steps: \n            "
      ),
      _c("ol", [
        _c("li", [
          _vm._v(
            '1.Go to the "Settings & Privacy" menu of your Facebook account and click on "Settings."'
          )
        ]),
        _c("li", [_vm._v('2.Scroll down and click on "Apps and Websites."  ')]),
        _c("li", [_vm._v("3.Find and click on this application. ")]),
        _c("li", [
          _vm._v(
            '4.Click the "Remove" button to successfully delete your app activity.'
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content_title" }, [
      _vm._v("\n            [\n            "),
      _c("span", { staticClass: "title-space" }, [_vm._v("服務條款")]),
      _vm._v("]\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "content_p" }, [
      _c("li", [
        _vm._v(
          "\n              1.\n              本會員服務條款所稱之「會員」，為依照本站所定之加入會員程序加入完成並通過認證者。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              2.\n              當您使用本站服務時，即表示您已閱讀、瞭解並同意接受本服務條款的內容與規範，事項及相關法律之規定。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              3.\n              本站保留有審核加入會員資格之權利，另外已加入會員者，本站亦保留有解除其會員資格之權利。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              4.\n              本網站有權於任何時間修改或變更本服務條款之內容，建議您隨時注意該等修改或變更。若您於任何修改或變更後繼續使用本服務，視為您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意本服務條款的內容，或者您所屬的國家或地域排除本服務條款內容之全部或一部時，您應立即停止使用本服務。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              5.\n              如手機載具之手機條碼填寫錯誤或統編輸入錯誤，系統將自動轉存至會員電子發票\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "content_p" }, [
      _c("li", [
        _vm._v(
          "\n              1.\n              使用本站所提供之會員服務時，於加入會員時所登錄之帳號及密碼使用之。\n              其登錄資料請務必提供您本人正確、最新的資料，倘若基本資料有變更時，請不定期更新相關個人資料，確保其正確性。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              2.\n              會員須善盡帳號及密碼的使用與管理之責任，並不得讓與第三人使用。對於使用該會員之帳號及密碼(無關於會員本身或其他人)利用本站服務所造成或衍生之所有行為及結果，會員須自行負擔全部責任。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              3.\n              會員之帳號及密碼遺失，或發現無故遭第三者盜用時，應立即通知本站連絡掛失，因未即時通知，導致本站無法有效防止及修改時，所造成的所有損失，會員應自負全責。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              4.\n              盜用第三者會員之帳號及密碼，導致第三者或本公司遭其他第三人或行政機關之調查或追訴時，第三者會員或本公司有權向您請求損害賠償，包括但不限於訴訟費用、律師費及商譽損失等。\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "content_p" }, [
      _c("li", [
        _vm._v(
          "\n              1.\n              您承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國國籍以外之使用者，並同意遵守所屬國家或地域之法令；對於使用者的一切行為，您須自行負擔全部責任。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              2. 您於使用本站會員服務時應遵守以下限制： a)\n              侵害或毀損本網站或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。\n              b) 使用違反公共秩序或善良風俗或其他不法之文字。 c)\n              強烈政治、宗教色彩的偏激言論。 d)\n              未經本公司許可，不得利用本服務或本網站所提供其他資源，包括但不限於圖文資料庫、編寫製作網頁之軟體等，從事任何商業交易行為，或招攬廣告商或贊助人。\n              e) 偽造訊息來源或以任何方式干擾傳輸來源之認定。\n              f)其他違反本站「會員服務條款」的內容。\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "content_p" }, [
      _c("li", [
        _vm._v(
          "\n              1.\n              本服務所載，或本服務所連結之一切軟體或內容，或本公司之廣告商或合夥人所提供之內容，均受其著作權或其他專有權利或法律所保障。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              2.\n              當您傳輸資料至本公司提供之服務時，您授權並許可本公司得以重製、修飾、改編或以其他形式使用該內容之全部或一部分，及利用該內容製作衍生著作。衍生著作之著作權悉歸本公司所有。且承諾不非法轉供其他直接營利目的或侵害您的權利之使用。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              3.\n              所有網頁之頁面出現之廣告看板與活動訊息，所有權及經營權均為本公司所有，使用者除事先取得本公司同意外，不得自行使用所有訊息。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              4.\n              同時為提供行銷、市場分析、統計或研究、或為提供會員個人化服務或加值服務之目的，會員同意本公司、或本公司之策略合作夥伴，得記錄、保存、並利用會員在本網站所留存或產生之資料及記錄，同時在不揭露各該資料之情形下得公開或使用統計資料。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              5.\n              本服務有時可能會出現中斷或故障等現象，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其它經濟上損失等情形。您於使用本服務時宜自行採取防護措施。本網站對於您因使用（或無法使用）本服務而造成的損害，不負任何賠償責任。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              6.\n              對於您在本站中的所有言論、意見或行為僅代表您個人；不代表本公司的立場，本公司不負任何責任。本公司對於使用者所自稱之身分，不擔保其正確性。\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "content_p" }, [
      _c("li", [
        _vm._v(
          "\n              1.\n              凡因使用本服務所生之爭執，均以台灣臺北地方法院為第一審管轄法院。\n            "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n              2.\n              若因您使用本服務之任何行為，導致本公司遭第三人或行政機關之調查或追訴時，本公司有權向您請求損害賠償，包括但不限於訴訟費用、律師費及商譽損失等。\n            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
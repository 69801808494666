<template>
  <div class="popup-mask">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FixedPopup",
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.popup-mask {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  &.onFocus {
    // @include mobile {
    //  align-items: flex-start !important;
    // }
  }
}
</style>

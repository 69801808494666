<template>
  <FixedPopupVue>
    <div class="bookChildProductBox">
      <div class="bookChildProductBox__title">選擇解答之書-兌換碼</div>
      <div class="bookChildProductBox__list">
        <div
          class="bookChildProductBox__list__item"
          v-for="product in productListShow"
          :key="product.ProductID"
        >
          <div class="bookChildProductBox__list__item__pic">
            <img v-if="product.img" :src="product.img" alt="" />
          </div>
          <div class="bookChildProductBox__list__item__title">
            {{ product.Name }}
          </div>
          <div
            class="bookChildProductBox__list__item__select"
            @click="selectHandler(product)"
          >
            <img src="../../assets/月亮日記/icon-ok.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="bookChildProductBox__closeBtn">
        <img
          src="../../assets/月亮日記/close.svg"
          alt=""
          @click="$emit('exit')"
        />
      </div>
    </div>
  </FixedPopupVue>
</template>

<script>
import FixedPopupVue from "../Fixed_popup.vue";
export default {
  components: {
    FixedPopupVue,
  },
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    productListShow() {
      let astroMap = new Map(this.$Astro.map((astro) => [astro.code, astro]));

      return this.productList.map((product) => {
        const match = astroMap.get(product.Code);
        return match ? { ...product, ...match } : product;
      });
    },
  },
  methods: {
    selectHandler(product) {
      this.$router.push(`/store/detail/${product.ProductID}`);
      this.$emit("exit");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.bookChildProductBox {
  margin: 40px auto;
  padding: 36px 12px;
  width: 520px;
  height: calc(100vh - 80px);
  max-height: 780px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background: var(--vi-white, #f0f2f4);
  box-shadow: 4px 4px 25px 0px rgba(0, 0, 0, 0.3), -4px -4px 10px 0px #fff,
    -4px -4px 16px 0px #fff;
  position: relative;
  @include mobile {
    padding: 32px 8px;
    width: 326px;
    max-width: calc(100vw - 48px);
  }
  &__title {
    color: #17445c;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 1.92px;
    margin-bottom: 32px;
    @include mobile {
      font-size: 20px;
    }
  }
  &__option {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 33px;
    margin-bottom: 32px;
    @include mobile {
      margin-bottom: 16px;
      padding: 0 24px;
    }
    &__status {
      color: #868686;
      font-size: 18px;
      @include mobile {
        font-size: 14px;
      }
    }
    &__clear {
      color: #ab956c;
      font-size: 18px;
      text-decoration: underline;
      cursor: pointer;
      @include mobile {
        font-size: 14px;
      }
    }
  }
  &__list {
    width: 100%;
    height: calc(100vh - 80px - 196px);
    height: 584px;
    overflow: auto;
    @include scroll-primary;
    padding: 0 33px;
    @include mobile {
      margin-bottom: 16px;
      padding: 0 24px;
    }
    &__item {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      &__pic {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
          2px 2px 6px rgba(0, 0, 0, 0.25);
        padding: 8px;
        @include mobile {
          width: 56px;
          height: 56px;
          padding: 4px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__title {
        margin-left: 20px;
        color: #767676;
        font-size: 20px;
        @include mobile {
          margin-left: 16px;
          font-size: 16px;
        }
      }
      &__select {
        margin-left: auto;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
          2px 2px 6px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        padding: 4px;
        @include mobile {
          width: 24px;
          height: 24px;
          padding: 3px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &.active {
          background-color: #798da5;
        }
      }
    }
  }
  &__closeBtn {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
    @include mobile {
      top: 16px;
      right: 12px;
    }
    img {
      width: 40px;
      height: 40px;
      @include mobile {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>

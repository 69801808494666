var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "div",
        { ref: "shopping_group", staticClass: "shopping_group" },
        _vm._l(_vm.productList, function(item, index) {
          return _c(
            "div",
            {
              key: item.ProductID,
              staticClass: "shopping_item",
              on: {
                click: function($event) {
                  _vm.activeId = index + 1
                }
              }
            },
            [
              _c("div", { staticClass: "shopping_title" }, [
                _vm._v("\n        " + _vm._s(item.Name) + "\n      ")
              ]),
              _c("div", { staticClass: "shopping_subtilte" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/占星小鋪/subtitleIcon.svg"),
                    alt: ""
                  }
                }),
                _vm._v("\n        適用" + _vm._s(item.Appliance) + "\n      ")
              ]),
              _c("div", { staticClass: "shopping_pic" }, [
                _c("img", { attrs: { src: item.ImageSrc, alt: "" } })
              ]),
              _c("div", { staticClass: "shopping_priceBar" }, [
                _c("div", { staticClass: "shopping_priceBar_price" }, [
                  _c("span", [_vm._v("NT.")]),
                  _vm._v(_vm._s(item.Amount) + "\n        ")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "shopping_priceBar_btn",
                    class: { notReady: false },
                    on: {
                      click: function($event) {
                        return _vm.toDetail(item)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(false ? "即將開放" : "了解更多") +
                        "\n        "
                    )
                  ]
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm.childProductBoxData
        ? _c("BookChildProductBoxVue", {
            attrs: { productList: _vm.childProductBoxData },
            on: {
              exit: function($event) {
                _vm.childProductBoxData = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "pic" }, [
        _c("img", {
          attrs: { src: require("../../assets/占星小鋪/shopping.svg"), alt: "" }
        })
      ]),
      _c("div", { staticClass: "banner_title" }, [
        _vm._v("The Astrology Store")
      ]),
      _c("div", { staticClass: "banner_subtitle" }, [_vm._v("星星雜貨店")]),
      _c("div", { staticClass: "banner_content" }, [
        _vm._v("\n      解鎖你的星盤，解掉你的憂愁 "),
        _c("br"),
        _c("br"),
        _vm._v("\n      人生有煩惱？買酒不如買解鎖\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
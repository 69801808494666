export default [
  {
    id: "Happy",
    normal_icon: require("@/assets/月亮日記/smile.svg"),
    active_icon: require("@/assets/月亮日記/smile_active.svg"),
    color: "#F7D060",
    label: "開心",
  },
  {
    id: "Calm",
    normal_icon: require("@/assets/月亮日記/peace.svg"),
    active_icon: require("@/assets/月亮日記/peace_active.svg"),
    color: "#5AAC80",
    label: "平靜",
  },
  {
    id: "Frightened",
    normal_icon: require("@/assets/月亮日記/frightened.svg"),
    active_icon: require("@/assets/月亮日記/frightened_active.svg"),
    color: "#A48080",
    label: "驚嚇",
  },
  {
    id: "Sad",
    normal_icon: require("@/assets/月亮日記/cry.svg"),
    active_icon: require("@/assets/月亮日記/cry_active.svg"),
    color: "#6499E9",
    label: "難過",
  },
  {
    id: "Angry",
    normal_icon: require("@/assets/月亮日記/frown.svg"),
    active_icon: require("@/assets/月亮日記/frown_active.svg"),
    color: "#E76161",
    label: "生氣",
  },
];

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c(
        "Fixed_popup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAlreadyWrite,
              expression: "showAlreadyWrite"
            }
          ]
        },
        [
          _c("div", { staticClass: "alreadyWrite__box" }, [
            _c("img", {
              staticClass: "alreadyWrite__img",
              attrs: {
                src: require("@/assets/月亮日記/這天寫過了.svg"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "alreadyWrite__title" }, [
              _vm._v("你今天已寫過"),
              _c("br"),
              _vm._v("月亮日記囉")
            ]),
            _c(
              "div",
              {
                staticClass: "alreadyWrite__btn",
                on: {
                  click: function($event) {
                    return _vm.toNote()
                  }
                }
              },
              [_vm._v("返回調整時間")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <transition name="fade">
    <div class="messagePopupContainer" v-show="messagePopup">
      <div class="messagePopup">
        <div class="messagePopup__title" v-html="title"></div>
        <div class="messagePopup__content">
          <div v-html="content"></div>
        </div>
        <div class="messagePopup__btn">
          <div
            class="mr-4"
            :class="
              isNegative ? 'messagePopup__btn__ok' : 'messagePopup__btn__cancel'
            "
            @click="cancel()"
            v-if="showCancel"
          >
            取消
          </div>
          <div
            :class="
              isNegative ? 'messagePopup__btn__cancel' : 'messagePopup__btn__ok'
            "
            @click="ok"
          >
            {{ okText }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    return {
      messagePopup: false,
      event: null,
      isOk: false,
      title: "",
      content: "",
      okText: "我瞭解了",
      showCancel: false,
      isNegative: false,
    };
  },
  created() {
    Vue.prototype.$messagePopup = (
      title,
      content,
      okText,
      event,
      showCancel = false,
      isNegative = false
    ) => {
      this.title = title;
      this.content = content;
      this.okText = okText;
      this.event = event;
      this.messagePopup = true;
      this.showCancel = showCancel;
      this.isNegative = isNegative;
    };
  },
  methods: {
    init() {
      this.title = "";
      this.content = "";
      this.okText = "";
      this.showCancel = false;
      this.isNegative = false;
    },
    cancel() {
      this.messagePopup = false;
      setTimeout(() => {
        this.init();
      }, 500);
    },
    ok() {
      this.event ? this.event() : () => {};
      this.messagePopup = false;
      setTimeout(() => {
        this.init();
      }, 500);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";
.messagePopupContainer {
  @include overlay;
  width: 100vw;
  height: 100vh;
  background: rgba(240, 242, 244, 0.3);
  backdrop-filter: blur(20px);
  .messagePopup {
    width: 520px;
    padding: 32px 56px;
    background-color: #f0f2f4;
    box-shadow: -4px -4px 12px #ffffff, -2px -2px 8px #ffffff,
      4px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    @include mobile {
      max-width: 326px;
      padding: 24px 39px 28px;
      border-radius: 50px;
    }
    &__title {
      text-align: center;
      letter-spacing: 0.016em;
      font-weight: 700;
      font-size: 28px;
      line-height: 1.5;
      color: #17445c;
      @include mobile {
        font-size: 20px;
        line-height: 30px;
        color: #798da5;
      }
    }
    &__content {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      color: #9b9b9b;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      text-align: center;
      @include mobile {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__btn {
      margin-top: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__ok {
        @include btn-default(172px, 48px, 20px);
        line-height: 30px;
        transition: 0.2s;
        @include mobile {
          @include btn-default(130px, 32px, 14px);
        }
      }
      &__cancel {
        @include btn(172px, 48px, transparent, #9b9b9b, 20px);
        border: 1px solid #9b9b9b;
        line-height: 30px;
        transition: 0.2s;
        @include mobile {
          @include btn(130px, 32px, transparent, #9b9b9b, 14px);
          border: 1px solid #9b9b9b;
        }
      }
    }
  }
}
</style>

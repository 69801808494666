var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FixedPopupVue", [
    _c("div", { staticClass: "bookChildProductBox" }, [
      _c("div", { staticClass: "bookChildProductBox__title" }, [
        _vm._v("選擇解答之書-兌換碼")
      ]),
      _c(
        "div",
        { staticClass: "bookChildProductBox__list" },
        _vm._l(_vm.productListShow, function(product) {
          return _c(
            "div",
            {
              key: product.ProductID,
              staticClass: "bookChildProductBox__list__item"
            },
            [
              _c(
                "div",
                { staticClass: "bookChildProductBox__list__item__pic" },
                [
                  product.img
                    ? _c("img", { attrs: { src: product.img, alt: "" } })
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                { staticClass: "bookChildProductBox__list__item__title" },
                [_vm._v("\n          " + _vm._s(product.Name) + "\n        ")]
              ),
              _c(
                "div",
                {
                  staticClass: "bookChildProductBox__list__item__select",
                  on: {
                    click: function($event) {
                      return _vm.selectHandler(product)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/月亮日記/icon-ok.svg"),
                      alt: ""
                    }
                  })
                ]
              )
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "bookChildProductBox__closeBtn" }, [
        _c("img", {
          attrs: { src: require("../../assets/月亮日記/close.svg"), alt: "" },
          on: {
            click: function($event) {
              return _vm.$emit("exit")
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
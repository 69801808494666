<template>
  <div class="loadingMask">
    <div id="lottie" ref="loading"></div>
  </div>
</template>

<script>
import loadingJson from "../js/loading";
import lottie from "lottie-web";
export default {
  name: "loading",
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    // this.timer = setTimeout(() => {
    //   alert("很抱歉，當前網路不穩定或是服務資源擴增中，請稍後再試！");
    //   this.$API.ErrorHandle("SendReport error", {
    //     Method: "post",
    //     Url: "/NotifyMail/ProblemReturnMail",
    //     Msg: {
    //       Msg:"15s timeout",
    //       message:"408"
    //     },
    //   });
    // }, 15000);
    // console.log('ErrorHandle',this.$API.ErrorHandle)
    this.anim = lottie.loadAnimation({
      container: this.$refs.loading,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loadingJson,
    });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss">
.loadingMask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 999;
}
#lottie {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: block;
  transform: translate3d(0, 0, 0);
}
</style>

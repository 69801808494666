var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navWrap", class: { bookStyle: _vm.bookStyle } },
    [
      _c(
        "nav",
        {
          staticClass: "nav hidden-laptop-down",
          class: {
            scrollDown: _vm.scrollDown,
            scrollFade: _vm.scrollPosition < 60
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "nav__logo",
              on: {
                click: function($event) {
                  return _vm.toHome()
                }
              }
            },
            [
              !_vm.bookStyle
                ? _c("img", {
                    attrs: {
                      src: require("../assets/navbar/logo_new.png"),
                      alt: ""
                    }
                  })
                : _c("img", {
                    attrs: {
                      src: require("../assets/運勢書共用/logo.png"),
                      alt: ""
                    }
                  })
            ]
          ),
          _c(
            "div",
            { staticClass: "nav__menu" },
            [
              _c("div", { staticClass: "nav__menu__list" }, [
                _c(
                  "div",
                  {
                    staticClass: "menu__item hoverMenu mr_24",
                    class: {
                      current:
                        _vm.nowPath.indexOf("/astrolabe") > -1 ||
                        _vm.$route.query.mode == "astrolabeMode" ||
                        _vm.nowPath.indexOf("/astrometry") > -1 ||
                        _vm.$route.query.mode == "astrometryMode" ||
                        _vm.nowPath.indexOf("/timelineAstrolabe") > -1 ||
                        _vm.$route.query.mode == "timeLineMode" ||
                        _vm.nowPath.indexOf("/astroDBL") > -1 ||
                        _vm.nowPath.indexOf("/addDBL") > -1 ||
                        _vm.nowPath.indexOf("/listDBL") > -1
                    },
                    on: {
                      mouseenter: function($event) {
                        _vm.hoverAstroList = true
                      },
                      mouseleave: function($event) {
                        _vm.hoverAstroList = false
                      }
                    }
                  },
                  [
                    _vm._v("\n          星盤列表\n          "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hoverAstroList,
                            expression: "hoverAstroList"
                          }
                        ],
                        staticClass: "nav__hover astroList"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "nav__hover__item",
                            class: {
                              currentHoverMenu:
                                _vm.nowPath.indexOf("/astrolabe") > -1 ||
                                _vm.$route.query.mode == "astrolabeMode"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toAstrolabe()
                              }
                            }
                          },
                          [
                            _vm._m(0),
                            _c(
                              "div",
                              { staticClass: "nav__hover__item__title" },
                              [_vm._v("個人星盤")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "nav__hover__item",
                            class: {
                              currentHoverMenu:
                                _vm.nowPath.indexOf("/astrometry") > -1 ||
                                _vm.$route.query.mode == "astrometryMode"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toAstrometry()
                              }
                            }
                          },
                          [
                            _vm._m(1),
                            _c(
                              "div",
                              { staticClass: "nav__hover__item__title" },
                              [_vm._v("天象星盤")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "nav__hover__item",
                            class: {
                              currentHoverMenu:
                                _vm.nowPath.indexOf("/timelineAstrolabe") >
                                  -1 || _vm.$route.query.mode == "timeLineMode"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toTimelineAstrolabe()
                              }
                            }
                          },
                          [
                            _vm._m(2),
                            _c(
                              "div",
                              { staticClass: "nav__hover__item__title" },
                              [_vm._v("推運功能")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "nav__hover__item",
                            class: {
                              currentHoverMenu:
                                _vm.nowPath.indexOf("/astroDBL") > -1 ||
                                _vm.nowPath.indexOf("/addDBL") > -1 ||
                                _vm.nowPath.indexOf("/listDBL") > -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toAstrolabeDBL()
                              }
                            }
                          },
                          [
                            _vm._m(3),
                            _c(
                              "div",
                              { staticClass: "nav__hover__item__title" },
                              [_vm._v("雙人合盤")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "menu__item mr_24",
                    class: {
                      current: _vm.nowPath.indexOf("/book2025") > -1
                    }
                  },
                  [_vm._v("\n          2025 解答之書\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "menu__item hoverMenu mr_24",
                    on: {
                      mouseenter: function($event) {
                        _vm.hoverBookList = true
                      },
                      mouseleave: function($event) {
                        _vm.hoverBookList = false
                      }
                    }
                  },
                  [
                    _vm._v("\n          各年度解答之書\n          "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hoverBookList,
                            expression: "hoverBookList"
                          }
                        ],
                        staticClass: "nav__hover bookList"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "nav__hover__item",
                            class: {
                              currentHoverMenu:
                                _vm.nowPath.indexOf("/book2024") > -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toBook()
                              }
                            }
                          },
                          [_vm._m(4), _vm._m(5)]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "nav__hover__item",
                            class: {
                              currentHoverMenu:
                                _vm.nowPath.indexOf("/book2025") > -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toBook()
                              }
                            }
                          },
                          [_vm._m(6), _vm._m(7)]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "menu__item mr_24",
                    class: {
                      current: _vm.nowPath.indexOf("/moonday") > -1
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toOther("/moonday-list")
                      }
                    }
                  },
                  [_vm._v("\n          月亮日記\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "menu__item mr_24",
                    class: {
                      current:
                        _vm.nowPath.indexOf("/friendList") > -1 &&
                        _vm.nowPath.indexOf("mode") == -1
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toOther("/friendList")
                      }
                    }
                  },
                  [_vm._v("\n          好友列表\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "menu__item mr_25",
                    class: {
                      current:
                        _vm.nowPath.indexOf("/store") > -1 ||
                        _vm.nowPath.indexOf("/order/") > -1
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toStore()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../assets/navbar/storeIcon.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v("\n          星星雜貨店\n        ")
                  ]
                )
              ]),
              _c("v-spacer"),
              !_vm.afterLogin
                ? _c(
                    "div",
                    {
                      staticClass: "nav__login",
                      on: {
                        click: function($event) {
                          return _vm.activeLoginBox()
                        }
                      }
                    },
                    [_vm._v("\n        登入\n      ")]
                  )
                : _vm._e(),
              _vm.afterLogin
                ? _c(
                    "div",
                    {
                      staticClass: "isLogin",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.openOtherMenu = !_vm.openOtherMenu
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "photo" },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "photo__img",
                              attrs: { size: "45" }
                            },
                            [_c("img", { attrs: { src: _vm.headImage } })]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "other_menu_btn" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "12",
                              height: "8",
                              viewBox: "0 0 12 8",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M5.52344 8L8.44588e-08 0.917434L0.476571 4.8252e-07L11.5235 1.37416e-07L12 0.917434L6.47656 8L5.52344 8Z",
                                fill: _vm.bookStyle ? "#fff" : "#D9D9D9"
                              }
                            })
                          ]
                        )
                      ]),
                      _c("v-slide-y-reverse-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.openOtherMenu,
                                expression: "openOtherMenu"
                              }
                            ],
                            staticClass: "other_menu_box",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return function() {}.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "userName" }, [
                              _vm._v(_vm._s(_vm.userName))
                            ]),
                            _c("div", {
                              staticClass: "other_menu_segmentation"
                            }),
                            _vm._l(_vm.other_menu_items, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.name,
                                  staticClass: "other_menu_item",
                                  class: {
                                    active: _vm.nowPath === item.route
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.toOther(item.route)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "other_menu_item active",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.openOtherMenu = false
                                    _vm.logoutDialog = true
                                  }
                                }
                              },
                              [_vm._v("\n              登出\n            ")]
                            )
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.loginDialog || _vm.LoginOutSide
          ? _c(
              "div",
              {
                staticClass: "login__overlay",
                class: {
                  scaleBox: _vm.loginDialog || _vm.LoginOutSide,
                  FromLineWebApp: _vm.FromLineWebApp
                },
                on: { click: _vm.loginDialogClose }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "login__box",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.loginDialog = true
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "login__img",
                      attrs: { src: require("@/assets/login.svg"), alt: "" }
                    }),
                    _c("div", { staticClass: "login__title" }, [
                      _vm.loginPlatform == "all"
                        ? _c("span", [
                            _vm._v(
                              "\n            使用專屬星盤的服務需先登入哦！\n            "
                            ),
                            _c("br"),
                            _vm._v("你可以使用以下方式登入\n          ")
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n            請先使用 " +
                                _vm._s(_vm.loginPlatform) +
                                " 登入， "
                            ),
                            _c("br"),
                            _vm._v("以方便後續進行帳號歸戶\n          ")
                          ])
                    ]),
                    _c("div", { staticClass: "login__sign" }, [
                      _c("span", { staticClass: "sign__line" }),
                      _c("span", { staticClass: "sign__text" }, [
                        _vm._v("SIGN IN")
                      ]),
                      _c("span", { staticClass: "sign__line" })
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.loginPlatform === "all" ||
                              _vm.loginPlatform == "Line",
                            expression:
                              "loginPlatform === 'all' || loginPlatform == 'Line'"
                          }
                        ],
                        staticClass: "login__btn line",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.lineLogin.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/login_line.png"),
                            alt: ""
                          }
                        }),
                        _vm._v("\n          使用 LINE 登入\n        ")
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.loginPlatform === "all" ||
                              _vm.loginPlatform == "Facebook",
                            expression:
                              "loginPlatform === 'all' || loginPlatform == 'Facebook'"
                          }
                        ],
                        staticClass: "login__btn fb",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.fbLogin.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/login_fb.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v("\n          使用 Facebook 登入\n        ")
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "google-signin-button",
                            rawName: "v-google-signin-button",
                            value: _vm.google.clientId,
                            expression: "google.clientId"
                          },
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.loginPlatform === "all" ||
                              _vm.loginPlatform == "Google",
                            expression:
                              "loginPlatform === 'all' || loginPlatform == 'Google'"
                          }
                        ],
                        staticClass: "login__btn google google-signin-button"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/login_google.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v("\n          使用 Google 登入\n        ")
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loginPlatform == "all",
                            expression: "loginPlatform == 'all'"
                          }
                        ],
                        staticClass: "login__notice"
                      },
                      [
                        _vm._v(
                          "\n          登入代表您同意唐綺陽專屬星盤\n          "
                        ),
                        !_vm.FromLineWebApp
                          ? _c("br", { staticClass: "hidden-laptop-up" })
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "login__privacy",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toPrivacyPolicy()
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: { "text-decoration": "underline" }
                              },
                              [_vm._v("服務")]
                            ),
                            _vm._v("及\n            "),
                            _c(
                              "span",
                              {
                                staticStyle: { "text-decoration": "underline" }
                              },
                              [_vm._v("隱私條款")]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm.FromLineWebApp
                      ? _c(
                          "div",
                          {
                            staticClass: "login__error",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openLoginError.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: { "text-decoration": "underline" }
                              },
                              [_vm._v("無法登入")]
                            ),
                            _vm._v("?\n          使用瀏覽器開啟\n        ")
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c(
        "nav",
        {
          staticClass: "mobile__navbar",
          class: {
            scrollDown: _vm.scrollDown,
            scrollFade: _vm.scrollPosition < 60
          }
        },
        [
          _c("div", { staticClass: "mobile__container hidden-laptop-up" }, [
            _c(
              "div",
              {
                staticClass: "nav__ham",
                on: {
                  click: function($event) {
                    return _vm.activeMenu()
                  }
                }
              },
              [
                _vm.bookStyle
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/運勢書共用/icon_menu.svg")
                      }
                    })
                  : _c("img", {
                      attrs: { src: require("@/assets/icon_menu.svg") }
                    })
              ]
            ),
            _c("div", { staticClass: "nav__nowPageName" }, [
              _vm._v(_vm._s(_vm.nowPageName))
            ]),
            _vm.afterLogin
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.mobileHiddenShopIcon.includes(
                          _vm.nowPageRouteName
                        ),
                        expression:
                          "!mobileHiddenShopIcon.includes(nowPageRouteName)"
                      }
                    ],
                    staticClass: "nav__ctaBtn hidden-laptop-up",
                    on: {
                      click: function($event) {
                        return _vm.toStore()
                      }
                    }
                  },
                  [
                    _vm.bookStyle
                      ? _c("img", {
                          attrs: {
                            src: require("../assets/運勢書共用/shopping-ctaBtn.svg")
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: require("../assets/shopping-ctaBtn.svg")
                          }
                        })
                  ]
                )
              : _vm._e(),
            _vm.afterLogin
              ? _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mobileHiddenShopIcon.includes(
                        _vm.nowPageRouteName
                      ),
                      expression:
                        "mobileHiddenShopIcon.includes(nowPageRouteName)"
                    }
                  ],
                  staticClass: "nav__ctaBtn hidden-laptop-up"
                })
              : _c(
                  "div",
                  {
                    staticClass: "nav__login",
                    on: {
                      click: function($event) {
                        return _vm.activeLoginBox()
                      }
                    }
                  },
                  [_vm._v("登入")]
                )
          ])
        ]
      ),
      _c("div", { staticClass: "nav-space" }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.openMenu,
                expression: "openMenu"
              }
            ],
            staticClass: "mobile__overlay",
            class: { ani: _vm.openMenu },
            on: {
              click: function($event) {
                _vm.openMenu = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "mobile",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.openMenu = true
                  }
                }
              },
              [
                _c("div", { staticClass: "mobile__close" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icon_menu-close.svg"),
                      alt: ""
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.openMenu = false
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "mobile__wrap" }, [
                  _c("div", { staticClass: "mobile__box" }, [
                    _c("div", { staticClass: "mobile__box__header" }, [
                      _vm.afterLogin
                        ? _c(
                            "div",
                            { staticClass: "mobile__photo" },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "mobile__photo__img",
                                  attrs: { size: "68" }
                                },
                                [_c("img", { attrs: { src: _vm.headImage } })]
                              ),
                              _c(
                                "div",
                                { staticClass: "mobile__photo__platform" },
                                [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loginMethods == "Line",
                                        expression: "loginMethods == 'Line'"
                                      }
                                    ],
                                    attrs: {
                                      src: require("@/assets/歸戶平台/LINE.png"),
                                      alt: ""
                                    }
                                  }),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loginMethods === "Facebook",
                                        expression:
                                          "loginMethods === 'Facebook'"
                                      }
                                    ],
                                    attrs: {
                                      src: require("@/assets/歸戶平台/facebook.png"),
                                      alt: ""
                                    }
                                  }),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loginMethods === "Google",
                                        expression: "loginMethods === 'Google'"
                                      }
                                    ],
                                    attrs: {
                                      src: require("@/assets/歸戶平台/GOOGLE.png"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "mobile__name" }, [
                        _c("div", { staticClass: "mobile__name__text" }, [
                          _vm._v(_vm._s(_vm.userName))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mobile__name__btn",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toMember()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  查看個人檔案\n                "
                            )
                          ]
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "mobile__list" },
                      [
                        _c("div", { staticClass: "mobile__item mb-0" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/navbar/star.svg"),
                              alt: ""
                            }
                          }),
                          _c("div", { staticClass: "mobile__text" }, [
                            _c("strong", [_vm._v("星盤")])
                          ])
                        ]),
                        _c("div", { staticClass: "mobile__childList" }, [
                          _c(
                            "div",
                            {
                              staticClass: "mobile__childList__childItem",
                              class: {
                                current: _vm.nowPath.indexOf("/astrolabe") > -1
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toAstrolabe()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  個人星盤\n                "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mobile__childList__childItem",
                              class: {
                                current: _vm.nowPath.indexOf("/astrometry") > -1
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toAstrometry()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  天象星盤\n                "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mobile__childList__childItem",
                              class: {
                                current:
                                  _vm.nowPath.indexOf("/timelineAstrolabe") >
                                    -1 ||
                                  _vm.nowPath.indexOf("timeLineMode") > -1
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toTimelineAstrolabe()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  推運功能\n                "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mobile__childList__childItem",
                              class: {
                                current:
                                  _vm.nowPath.indexOf("/astroDBL") > -1 ||
                                  _vm.nowPath.indexOf("/addDBL") > -1
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toAstrolabeDBL()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  雙人合盤\n                "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mobile__item specialEvent",
                            class: {
                              current: _vm.nowPath.indexOf("/book2024") > -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toBook()
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/navbar/sun.svg"),
                                alt: ""
                              }
                            }),
                            _c("div", { staticClass: "mobile__text" }, [
                              _vm._v("2024 解答之書")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mobile__item",
                            class: {
                              current: _vm.nowPath.indexOf("/moonday") > -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toOther("/moonday-list")
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "mobile__text" }, [
                              _vm._v("月亮日記")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mobile__item",
                            class: {
                              current:
                                _vm.nowPath.indexOf("/friendList") > -1 &&
                                _vm.nowPath.indexOf("mode") == -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toOther("/friendList")
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "mobile__text" }, [
                              _vm._v("好友列表")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mobile__item",
                            class: {
                              current: _vm.nowPath.indexOf("/store") > -1
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toStore()
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "mobile__text" }, [
                              _vm._v("星星雜貨店")
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "hr" }),
                        _vm._l(_vm.other_menu_items, function(item) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.hiddenMobile,
                                  expression: "!item.hiddenMobile"
                                }
                              ],
                              key: item.name,
                              staticClass: "mobile__item",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toOther(item.route)
                                }
                              }
                            },
                            [
                              _c("img", { attrs: { src: item.icon, alt: "" } }),
                              _c("div", { staticClass: "mobile__text" }, [
                                _vm._v(_vm._s(item.name))
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm.afterLogin
                      ? _c(
                          "div",
                          {
                            staticClass: "mobile__logout",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.logoutDialog = true
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/icon_menu-logout.svg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ])
                ]),
                _c("img", {
                  staticClass: "mobile__menu__notice",
                  attrs: { src: require("@/assets/next.png") }
                })
              ]
            )
          ]
        )
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.logoutDialog
          ? _c(
              "div",
              {
                staticClass: "dialog__overlay",
                on: {
                  click: function($event) {
                    _vm.logoutDialog = false
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dialog__box",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.logoutDialog = true
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "dialog__title" }, [
                      _vm._v("登出帳號")
                    ]),
                    _c("div", { staticClass: "dialog__subtitle" }, [
                      _vm._v("確定要登出帳號嗎？")
                    ]),
                    _c("div", { staticClass: "dialog__btn" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn cancel",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.logoutDialog = false
                            }
                          }
                        },
                        [_vm._v("\n            取消\n          ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn confirm",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.logout.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("確認")]
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.developing || _vm.DevelopOutSide,
                expression: "developing || DevelopOutSide"
              }
            ],
            staticClass: "popup__overlay",
            class: { scaleBox: _vm.devNotice || _vm.DevelopOutSide },
            on: { click: _vm.developingClose }
          },
          [
            _c(
              "div",
              {
                staticClass: "popup__box",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.developing = true
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "popup__img",
                  attrs: { src: require("@/assets/develop.svg"), alt: "" }
                }),
                _c("div", { staticClass: "popup__title" }, [
                  _vm._v("Coming Soon")
                ]),
                _c("div", { staticClass: "popup__subtitle" }, [
                  _vm._v("服務即將上線！敬請期待！")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "popup__btn",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.developingClose.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("返回")]
                )
              ]
            )
          ]
        )
      ]),
      _vm.LoginError
        ? _c("LoginErrorDialog", {
            on: {
              close: function($event) {
                return _vm.closeLoginError()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/個人星盤.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/天象星盤.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/推運功能.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/雙人合盤.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/navbar/2024運勢書menu圖.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__title" }, [
      _vm._v("2024"),
      _c("br"),
      _vm._v("解答之書")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/navbar/2025運勢書menu圖.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav__hover__item__title" }, [
      _vm._v("2025"),
      _c("br"),
      _vm._v("解答之書")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="row-wrap custom-container" v-if="ready">
    <div class="params_box" v-if="nowUserData && TcAstralInfo">
      <TimeLine
        :format_time.sync="format_time"
        :defaultTime="format_time"
        :nowTimeNowPlaceIng.sync="nowTimeNowPlaceIng"
      />
      <Params
        :TcAstralInfo="TcAstralInfo"
        :AstrolabeType="'C2'"
        :role="role"
        :moondayObj.sync="moondayObj"
      ></Params>
    </div>
    <MoonDayCTA
      class="hidden-pad-up mt_10 mb_10"
      v-if="TcAstralInfo"
      :data2="TcAstralInfo.AstralRadians"
      @toNowTimeNowPlace="toNowTimeNowPlace"
      :nowTimeNowPlaceIng="nowTimeNowPlaceIng"
      :format_time="format_time"
      mode="astrometryMode"
      :moondayObj="moondayObj"
    />
    <!-- nowUserData.AstralInfo.AstralRadians -->
    <NewAstrolabe
      v-if="TcAstralInfo"
      :data1="TcAstralInfo.AstralRadians"
      :data2="null"
      :type="0"
      :styleArray="styleArray"
      :role="role"
      :Ret1="TcAstralInfo.RetAstralSet"
      :showNowTime="true"
      :format_time="format_time"
      mode="astrometryMode"
      :nowTimeNowPlaceBtn="true"
      @toNowTimeNowPlace="toNowTimeNowPlace"
      :nowTimeNowPlaceIng="nowTimeNowPlaceIng"
      :nowPlace="`${Longitude}, ${Latitude}`"
      :moondayObj="moondayObj"
    >
    </NewAstrolabe>
  </div>
</template>

<script>
// TODO: 子組件事件timeline_update>updateTime>更新format_time>更新Arg>呼叫API>更新星盤
import NewAstrolabe from "@/components/星盤組件/new_astrolabe";
import TimeLine from "@/components/星盤組件/Timeline_bar";
import TaiwanList from "@/js/taiwanList.json";
import moment from "moment";
//詳細參數組件
import Params from "@/components/星盤組件/Params_astrometry.vue";
import MoonDayCTA from "../../components/星盤組件/MoonDayCTA.vue";

export default {
  components: {
    NewAstrolabe,
    TimeLine,
    Params,
    MoonDayCTA,
  },
  data() {
    return {
      TcAstralInfo: null,
      role: "Astrometry",
      format_time: moment().format("YYYY-MM-DD HH:mm"),
      //時間軸資料
      initGeo: false,
      ready: false,
      first: true,
      styleArray: [
        { name: "文字", displayName: "文字", show: false, id: "alert1" },
        { name: "JOE2", displayName: "專業", show: false, id: "alert5" },
      ],
      Latitude: null,
      Longitude: null,
      TimeZone: 8,
      nowTimeNowPlaceIng: false,
      moondayObj: {},
    };
  },
  computed: {
    nowUserData() {
      return this.$store.state.nowUserData;
    },
    findCountryCode() {
      let target = this.nowUserData.BirthCountry;
      let CountryCode = this.$CountryCode.find((item) => {
        return item.ChineseName == target;
      });
      return CountryCode ? CountryCode.Code.split(" ")[0] : "TW";
    },
    Arg() {
      if (this.nowUserData) {
        return {
          AstrolabeType: "SC",
          AstrolabeFileId: this.nowUserData.AstrolabeFileId,
          BirthTime: this.format_time,
          IsDaylight: this.nowUserData.IsDaylight,
          BirthLocation: {
            Place: this.findPlaceEngName(this.nowUserData.BirthPlace),
            Country: this.nowUserData.BirthCountry.split("(")[0],
            State: this.nowUserData.BirthState,
            City: this.nowUserData.BirthCity,
            CountryCode: this.findCountryCode,
            TimeZone: this.TimeZone,
            Latitude: this.Latitude,
            Longitude: this.Longitude,
          },
        };
      }
      return null;
    },
  },
  async created() {
    this.$store.commit("setLoading_force", true);

    if (this.nowUserData) {
      const res = await this.$API.Get_AstrolabeFile(
        this.nowUserData.AstrolabeFileId
      );
      this.$store.commit("set_nowUserData", res.Data);
    } else {
      this.$router.replace("/");
      this.$store.commit("setLoading_force", false);
      return;
    }
  },

  mounted() {
    // this.format_time = `${this.nowUserData.BirthDay} ${this.nowUserData.BirthTime}`;
    this.format_time = moment().format("YYYY/MM/DD HH:mm");
    this.toNowTimeNowPlace();
  },
  methods: {
    toNowTimeNowPlace() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // parseFloat(Longitude).toFixed(2)
            this.Latitude =
              position.coords.latitude > 0
                ? parseFloat(position.coords.latitude).toFixed(2) + "N"
                : parseFloat(position.coords.latitude).toFixed(2) + "S";
            this.Longitude =
              position.coords.longitude > 0
                ? parseFloat(position.coords.longitude).toFixed(2) + "E"
                : parseFloat(position.coords.longitude).toFixed(2) + "W";
            this.TimeZone = 0 - new Date().getTimezoneOffset() / 60;
            this.format_time = moment().format("YYYY/MM/DD HH:mm");
            this.nowTimeNowPlaceIng = true;
            this.initGeo = true;
          },
          (error) => {
            // alert(
            //   "「此時此地」的功能需要獲取您的當前經緯度資訊進行計算，目前監測到您未開啟定位權限，請至裝置定位服務設定中，開啟瀏覽器或 Line 的位置存取權，再重新登入使用此功能喔！"
            // );
            alert(
              "因「此時此地」需要取得當前位置才能更精準的計算，請於瀏覽器彈窗選擇「允許/確定由本服務使用您的位置資訊」，若仍有問題請至您的裝置定位功能中開啟瀏覽器及Line的位置存取權。"
            );
            this.initGeo = true;
            this.$store.commit("setLoading_force", false);
            this.nowTimeNowPlaceIng = false;
          }
        );
      } else {
        alert(
          "提醒您，目前所使用的瀏覽器不支援取得當前座標地點，因此將無法正常使用「天象星盤」，若要使用此服務請換其他瀏覽器操作看看喔。"
        );
        this.initGeo = true;
        this.$store.commit("setLoading_force", false);
        this.$router.replace("/");
      }
    },
    updateTime($event) {
      this.format_time = $event;
    },
    update_timeLineData() {
      if (this.Arg && this.nowUserData) {
        this.$API.POST_TcAstralInfo(this.Arg).then((res) => {
          this.$store.commit("setLoading_force", false);
          this.ready = true;
          if (res.Data.Result) {
            this.TcAstralInfo = res.Data.AstralInfo;
          } else {
            alert("國家地點找不到");
            this.$router.replace("/friend?mode=timeline");
          }
        });
      }
    },
    findPlaceEngName(ChineseName) {
      let engName = TaiwanList.find((item) => {
        return item.text == ChineseName;
      });
      if (!engName) alert("找無該區");
      return engName ? engName.value : "Taipei";
    },
  },
  watch: {
    Arg() {
      if (this.first) {
        this.first = false;
      } else {
        this.update_timeLineData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.row-wrap {
  @include align(space-around, flex-start);
  margin: 36px auto;
  @include laptop {
    flex-direction: column;
    @include center;
    .params_box {
      order: 2;
    }
  }
  @include pad {
    margin: 0 auto;
    padding: 0;
  }
}

.container {
  max-width: 1056px;
}

.params_box {
  width: 44%; //448px
  @include align(flex-start, center);
  flex-direction: column;
  margin-right: 30px;
  @include laptop {
    margin-right: 0;
    margin-top: 2px;
    width: 70%;
  }
  @include pad {
    width: 100%;
    padding: 0 16px;
  }
}
</style>

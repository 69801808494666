<template>
  <transition name="fade">
    <div class="noticePopupContainer" v-show="noticePopup">
      <div class="noticePopup">
        <div class="noticePopup__title">
          {{ title }}
        </div>
        <div class="noticePopup__content">
          <div v-html="content"></div>
        </div>
        <div class="noticePopup__btn">
          <div class="noticePopup__btn__cancel" @click="cancel">取消</div>
          <div class="noticePopup__btn__ok" @click="ok">
            {{ okText }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    return {
      noticePopup: false,
      event: null,
      isOk: false,
      title: "",
      content: "",
      okText: "我瞭解了",
    };
  },
  created() {
    Vue.prototype.$noticeDiag = (title, content, okText, event) => {
      this.title = title;
      this.content = content;
      this.okText = okText;
      this.event = event;
      this.noticePopup = true;
    };
  },
  methods: {
    cancel() {
      this.noticePopup = false;
      setTimeout(() => {
        this.title = "";
        this.content = "";
        this.okText = "";
      }, 100);
    },
    ok() {
      this.event ? this.event() : () => {};
      this.noticePopup = false;
      setTimeout(() => {
        this.title = "";
        this.content = "";
        this.okText = "";
      }, 100);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";
.noticePopupContainer {
  @include overlay;
  width: 100vw;
  height: 100vh;
  background: rgba(240, 242, 244, 0.3);
  backdrop-filter: blur(20px);
  .noticePopup {
    width: 520px;
    padding: 40px 36px;
    background-color: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    @include mobile {
      width: 296px;
      padding: 24px 39px 28px;
      border-radius: 80px;
    }
    &__title {
      text-align: center;
      letter-spacing: 0.016em;
      font-weight: 700;
      font-size: 28px;
      line-height: 68px;
      color: #17445c;
      @include mobile {
        font-size: 20px;
        line-height: 30px;
        color: #798da5;
      }
    }
    &__content {
      margin-top: 16px;
      display: flex;
      color: #9b9b9b;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      text-align: center;
      @include mobile {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__btn {
      margin-top: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__ok {
        @include btn-default(144px, 48px, 20px);
        line-height: 30px;
        transition: 0.2s;
        @include mobile {
          @include btn-default(132px, 32px, 14px);
        }
      }
      &__cancel {
        @include btn(144px, 48px, transparent, #9b9b9b, 20px);
        line-height: 30px;
        transition: 0.2s;
        @include mobile {
          @include btn(132px, 32px,  transparent, #9b9b9b,14px);
        }
      }
    }
  }
}
</style>
